import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;

  table {
    position: relative;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        padding: 10px 0;
        vertical-align: middle;
        text-align: end;

        &:after {
          content: '';
          position: absolute;
          display: block;

          left: 0;
          bottom: 0;

          height: 2px;
          width: 100%;

          background-color: #181818;
        }
      }

      th:nth-child(3),
      th:nth-child(4) {
        vertical-align: middle;
        text-align: start;
      }
    }

    td {
      padding: 1px 2px;
    }

    tbody {
      tr {
        td:nth-child(1) {
          padding-right: 8px;
        }

        td:nth-child(3),
        td:nth-child(4) {
          vertical-align: middle;
          text-align: start;
        }

        td {
          vertical-align: middle;
          text-align: end;
        }
      }
    }
  }

  .loading-placeholder {
    display: flex;
    height: 20px;
    margin: 1px 0;
    line-height: 20px;
    background: ${({ theme }) => theme.colors.primaryBg};
    width: 100%;
  }
`;
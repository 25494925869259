import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

import { DataService } from '../../services/DataService';

import { Action } from '../commonTypes';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IDirectory, IDirectoryAnalysisRequestBody, IDirectoryAnalysisResponseBody } from '../../services/DataService/types';

import { directoryActions } from './actions';
import { selectActiveProjectID } from '../projects/selectors';
import { DirectoryActionTypes, IDataRestrictionsPayload, ISortChangePayload } from './types';
import { SortOrder } from '../../enums/data';
import { selectData, selectSortFeature, selectSortOrder, selectSummaryDataRestrictions } from './selectors';
import { sanitizeDirectoryData, sortDirectoryDataByKey } from '../../utils/kpi/data';
import { setDirectoryNextLevel } from '../../utils/directory';

function* directoryReload(action: Action<DirectoryActionTypes.DIRECTORY_RELOAD, IDirectoryAnalysisRequestBody>): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  if (!action.payload.root) {
    yield put(directoryActions.uiMerge({ loading: true }));
  } else {
    yield put(directoryActions.uiMerge({ folderLoading: action.payload.root }));
  }

  const requestBody: IDirectoryAnalysisRequestBody = action.payload;

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IDirectoryAnalysisResponseBody> = yield call(DataService.directoryAnalysis, projectID, requestBody, options);
  if (!result?.data) {
    yield put(directoryActions.uiMerge({ loading: false }));
    yield put(directoryActions.uiMerge({ folderLoading: '' }));

    return;
  }

  const { data } = result.data;


  if (action.payload.level && action.payload.root) {
    const oldData = yield select(selectData);
    const newData = setDirectoryNextLevel(oldData, sanitizeDirectoryData(data), action.payload.root, action.payload.level - 1);

    yield put(directoryActions.directoryRefresh(cloneDeep(newData)));
  } else {
    yield put(directoryActions.directoryRefresh(data));
  }
  yield put(directoryActions.uiMerge({ loading: false }));
  yield put(directoryActions.uiMerge({ folderLoading: '' }));
}

function* sortChange(action: Action<DirectoryActionTypes.SORT_CHANGE, ISortChangePayload>): any {
  const { feature, order } = action.payload;

  if (order !== undefined) {
    yield put(directoryActions.sortRefresh({ feature, order }));
    return;
  }

  const currentFeature = yield select(selectSortFeature);
  const currentOrder = yield select(selectSortOrder);

  if (currentFeature !== feature) {
    yield put(directoryActions.sortRefresh({ feature, order: SortOrder.Desc }));
    return;
  }

  if (currentOrder === SortOrder.Asc) {
    yield put(directoryActions.sortRefresh({ feature, order: SortOrder.Desc }));
    return;
  }

  yield put(directoryActions.sortRefresh({ feature, order: SortOrder.Asc }));
}

function* summaryDataUpdate(): any {
  const data: IDirectory[] = yield select(selectData);
  const { sortBy, order }: IDataRestrictionsPayload = yield select(selectSummaryDataRestrictions);
  const sanitizedData = sanitizeDirectoryData(data);
  const sortedData = yield call(sortDirectoryDataByKey, sanitizedData, sortBy as string, order as SortOrder);

  yield put(directoryActions.directoryRefresh(sortedData));
}

export default function* directorySaga() {
  yield all([
    takeLatest(DirectoryActionTypes.DIRECTORY_RELOAD, directoryReload),
    takeLatest(DirectoryActionTypes.SORT_CHANGE, sortChange),
    takeLatest(DirectoryActionTypes.DATA_UPDATE, summaryDataUpdate),
  ]);
}

import { AppActions } from './actions';
import { AppActionTypes, AppState } from './types';
import { Languages } from '../../enums/locales/languages';
import { AppContext, DeviceType } from '../../enums/auth';
import { LanguagePayload, DeviceIdentifierPayload, IUIPayload } from './types';

const initState: AppState = {
  appStarted: false,
  language: Languages.En,
  apiAppContext: AppContext.Cwv,
  deviceType: DeviceType.Web,
  deviceIdentifier: null,
  UI: {
    isTopBarVisible: false,
    isSideBarVisible: false,
    isSideBarCollapsed: false,
    isFullscreen: false,
    isLabData: false,
    showColors: false,
    showBenchmarks: false,
  },
};

export function appReducer(state: AppState = initState, action: AppActions): AppState {
  switch (action.type) {
    case AppActionTypes.APP_STARTED: {
      return {
        ...state,
        appStarted: true,
      };
    }
    case AppActionTypes.LANGUAGE_REFRESH: {
      return {
        ...state,
        language: action.payload as LanguagePayload,
      };
    }
    case AppActionTypes.DEVICE_IDENTIFIER_REFRESH: {
      return {
        ...state,
        deviceIdentifier: action.payload as DeviceIdentifierPayload,
      };
    }
    case AppActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

import styled from 'styled-components';
import { hexToRGBA } from '../../../utils/theme';

export const StyledBodyWrapper = styled.div`
  max-height: 75vh;
  margin-right: 10px;
  padding: 30px;
  overflow: auto;

  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #171C2810 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #555BF0 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  .header-cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    cursor: pointer;

    .sort-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        transform: scale(0.65);
      }
    }

    &:not(.active) {
      padding-right: 0px;

      .sort-icon {
        display: none;
      }
    }

    &.active.position {
      padding-right: 0;
    }

    &.position {
      padding-right: 8px;
    }

    &.url,
    &.resource-type {
      justify-content: flex-start;
    }

    &.time,
    &.resource-size,
    &.transfer-size {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .data-cell {
    min-height: 30px;



    &.url-link {
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }

    &.url {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 30px;
    }

    &.resource-type {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .indicator {
        height: 8px;
        width: 8px;
        margin-right: 8px;
        border-radius: 2px;
      }
    }

    &.time,
    &.resource-size,
    &.transfer-size {
      display: flex;
      justify-content: center;
      align-items: center;

      padding-left: 10px;
      padding-right: 10px;

      .data-value {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        width: 100%;

        position: relative;

        .indicator {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 3px;
        }
      }
    }

    &.time {
      .data-value {
        .indicator {
          background: ${hexToRGBA('#35C996', 0.47)} 0% 0% no-repeat padding-box;
        }
      }
    }

    &.resource-size {
      .data-value {
        .indicator {
          background: ${hexToRGBA('#F2C75D', 0.47)} 0% 0% no-repeat padding-box;
        }
      }
    }

    &.transfer-size {
      .data-value {
        .indicator {
          background: ${hexToRGBA('#969AFF', 0.47)} 0% 0% no-repeat padding-box;
        }
      }
    }
  }

  .resource-type-filters {
    position: sticky;
    z-index: 2;
    top: -30px;
    background: white;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;

    .resource-type-container {
      display: flex;
      flex-wrap: wrap;

      .resource-type {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 6px 12px;
        background: ${({ theme }) => theme.colors.primaryBg};
        border-radius: 20px;
        margin-right: 12px;
        margin-bottom: 4px;

        font-style: normal;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.17px;

        &.active {
          background: #181818;
          box-shadow: 0px 2px 10px #0000000F;
          color: white;

          span {
            color: white;
          }
        }

        span {
          color: ${hexToRGBA('#181818', 0.4)};
        }

        span:first-child {
          margin-right: 6px;
          color: inherit;
          text-transform: uppercase;
        }

        span:last-child {
          margin-left: 6px;
        }
      }
    }

    .export { 
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      .search-input {
        height: 36px;
        max-width: 262px;
        font-size: 12px;
        line-height: 17px;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 260px;
      }

      .icon-container {
        top: 9px;
        right: 8px;
        width: 22px;
        height: 22px;
      }

      .download-csv {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 14px 0 24px;
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid #DBDBDB;
        border-radius: 20px;
        color: ${({ theme }) => theme.colors.secondaryDark};
        font-size: 14px;
        font-weight: 600;
        margin-left: 24px;

        svg {
          margin-left: 8px;
        }

        &:hover,
        &:visited,
        &:focus {
          color: ${({ theme }) => theme.colors.secondaryDark};
          font-size: 14px;
          font-weight: 600;
        }

        &.loading {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

  .resource-monitor-container {
    display: grid;
    grid-template-columns: 3fr 5fr 5fr;
    min-height: 270px;
    grid-gap: 50px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 14px #00000015;
    border-radius: 12px;
    padding: 20px;

    .resource-monitor {
      width: 100%;
      height: 100%;
      
      table {
        table-layout: fixed;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 6px;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.primaryDark};

        .loading-placeholder {
          display: flex;
          height: 20px;
          margin: 1px 0;
          line-height: 20px;
          background: ${({ theme }) => theme.colors.primaryBg};
          width: 100%;
        }

        thead {
          font-weight: 600;
          height: 42px;
          tr {
            th:nth-child(1) {
              width: 60%;
            }
            th:nth-child(2) {
              text-align: center;
              width: 10%;
            }
            th:nth-child(3) {
              width: 30%;
              text-align: end;
            }
          }
        }

        tbody {
          tr {
            td:nth-child(1) {
              padding-left: 20px;
            }
            td:nth-child(2) {
              text-align: center;
            }
            td:nth-child(3) {
              text-align: end;
            }
          }
        }
      }
    }

    .tree-map-container {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
`;

export const ColoredTD = styled.div<{ color: string }>`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 3px;
    background: ${({ color }) => color};
    border-radius: 4px;
    width: 12px;
    height: 12px;
  }
`;
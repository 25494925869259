import { SiderSteps } from "../../../enums/locales/modals";

export const requiredSteps = [0, 1, 2, 3];
export const requiredTestSteps = [0, 1, 2];

export const testProjectSteps = [
  {
    title: SiderSteps.DomainAndProjectName,
    isOptional: false,
  },
  {
    title: SiderSteps.TypeAndFrequency,
    isOptional: false,
  },
  {
    title: SiderSteps.Urls,
    isOptional: false,
  },
  {
    title: SiderSteps.URLGroups,
    isOptional: true,
  },
  {
    title: SiderSteps.InviteUsers,
    isOptional: true,
  },
  {
    title: SiderSteps.Goals,
    isOptional: true,
  },
  {
    title: SiderSteps.WarmUpUrls,
    isOptional: true,
  },
];

export const projectSteps = [
  {
    title: SiderSteps.DomainAndProjectName,
    isOptional: false,
  },
  {
    title: SiderSteps.Country,
    isOptional: false,
  },
  {
    title: SiderSteps.TypeAndFrequency,
    isOptional: false,
  },
  {
    title: SiderSteps.Keywords,
    isOptional: false,
  },
  {
    title: SiderSteps.Benchmarks,
    isOptional: true,
  },
  {
    title: SiderSteps.URLGroups,
    isOptional: true,
  },
  {
    title: SiderSteps.InviteUsers,
    isOptional: true,
  },
  {
    title: SiderSteps.Goals,
    isOptional: true,
  },
];
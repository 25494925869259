import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import AntdModal from 'antd/lib/modal';

import { MetricsKeys } from '../../../enums/locales/metrics';
import { NetworkRequestsAnalysisKeys } from '../../../enums/locales/compoundAnalysis';
import { ANIMATION_CLASSES } from '../../../constants/animations';

import { CompoundAnalysisUrl, ICompoundAnalysisThumbnails } from '../../../services/DataService/types';
import { compoundAnalysisActions } from '../../../redux/compound-analysis/actions';
import { modalsActions } from '../../../redux/modals/actions';
import {
  selectActiveUrlData,
  selectNetworkDetails,
  selectNetworkResourceTypes,
  selectDisabledResourceTypes,
  selectThumbnailsLCPElementLabel,
  selectThumbnailsLCPElementSnippet,
  selectScreenshotThumbnails,
  selectFinalScreenshot,
  selectThumbnailsFCPValue,
  selectThumbnailsFMPValue,
  selectThumbnailsLCPValue,
  selectUI,
} from '../../../redux/compound-analysis/selectors';
import { selectThumbnailsVisible } from '../../../redux/modals/selectors';

import { IconClose, IconExternalLink } from '../../../components/styled/icons';
import { ScreenshotCard } from '../../../containers/thumbnails/ScreenshotCard';
import { Filmstrip } from '../../../containers/thumbnails/Filmstrip';
import { StyledBodyWrapper } from './ThumbnailsModal.styled';
import { selectLatestCrawlCreated } from '../../../redux/projects/selectors';

const ThumbnailsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectThumbnailsVisible);
  const activeUrlData = useSelector(selectActiveUrlData) as CompoundAnalysisUrl;
  const thumbnailsLCPElementLabel = useSelector(selectThumbnailsLCPElementLabel);
  const thumbnailLCPElementSnippet = useSelector(selectThumbnailsLCPElementSnippet);
  const screenshotThumbnails = useSelector(selectScreenshotThumbnails);
  const finalScreenshot = useSelector(selectFinalScreenshot);
  const networkDetailsData = useSelector(selectNetworkDetails);
  const networkResourceTypes = useSelector(selectNetworkResourceTypes);
  const disabledResourceTypes = useSelector(selectDisabledResourceTypes);
  const rawFCPValue = useSelector(selectThumbnailsFCPValue);
  const rawFMPValue = useSelector(selectThumbnailsFMPValue);
  const rawLCPValue = useSelector(selectThumbnailsLCPValue);
  const { thumbnailsLoading, networkDetailsLoading } = useSelector(selectUI);
  const crawlDate = useSelector(selectLatestCrawlCreated);

  const filmstripLoading = thumbnailsLoading || networkDetailsLoading;

  const roundedFCPValue = Math.round(rawFCPValue);
  const roundedFMPValue = Math.round(rawFMPValue);
  const roundedLCPValue = Math.round(rawLCPValue);

  const openUrl = useCallback((url: string) => () => {
    window.open(url, '_blank');
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.thumbnailsHide());
    dispatch(compoundAnalysisActions.activeUrlIDRefresh(null));
    dispatch(compoundAnalysisActions.thumbnailsRefresh({} as ICompoundAnalysisThumbnails));
    dispatch(compoundAnalysisActions.disabledResourceTypesRefresh([]));
  }, [dispatch]);

  const handleClickLegendItem = useCallback((key: string) => () => {
    const filteredDisabledResourceTypes = disabledResourceTypes.filter(type => type !== key);
    if (filteredDisabledResourceTypes.length === disabledResourceTypes.length) {
      filteredDisabledResourceTypes.push(key);
    }
    dispatch(compoundAnalysisActions.disabledResourceTypesRefresh(filteredDisabledResourceTypes));
  }, [dispatch, disabledResourceTypes]);

  const filmstripData = useMemo(() => {
    return screenshotThumbnails.map(({ data, timing, timestamp }, i, arr) => {
      const index = i < arr.length - 1 ? i + 1 : i;
      const nextTiming = arr[index].timing;

      let fcpValue = roundedFCPValue >= timing && roundedFCPValue < nextTiming ? roundedFCPValue : undefined;
      let fmpValue = roundedFMPValue >= timing && roundedFMPValue < nextTiming ? roundedFMPValue : undefined;
      let lcpValue = roundedLCPValue >= timing && roundedLCPValue < nextTiming ? roundedLCPValue : undefined;

      if (i === arr.length - 1) {
        if (roundedFCPValue >= nextTiming) {
          fcpValue = nextTiming;
        }
        if (roundedFMPValue >= nextTiming) {
          fmpValue = nextTiming;
        }
        if (roundedLCPValue >= nextTiming) {
          lcpValue = nextTiming;
        }
      }

      return {
        src: data,
        timing,
        timestamp,
        nextTiming,
        fcpValue,
        fmpValue,
        lcpValue,
      };
    });
  }, [screenshotThumbnails, roundedFCPValue, roundedFMPValue, roundedLCPValue]);

  return (
    <AntdModal
      centered
      title={(
        <>
          <div className="thumbnails-modal-title">
            {t(MetricsKeys.DetailsTitle)}
          </div>
          <div className="thumbnails-modal-link">
            <IconExternalLink onClick={openUrl(activeUrlData?.url)} />
            <a href={activeUrlData?.url} target="_blank" rel="noreferrer">{activeUrlData?.url}</a>
          </div>
          <div className="thumbnails-modal-crawl-date">
            ({crawlDate})
          </div>
        </>
      )}
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="thumbnails-modal-container"
      width={'90%'}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className="top-section">
          <div className={clsx('filmstrip-container', { empty: filmstripLoading, [ANIMATION_CLASSES.animatedSkeleton]: filmstripLoading })}>
            <div className="filmstrip-title">
              {t(NetworkRequestsAnalysisKeys.Filmstrip)}
            </div>
            <Filmstrip
              currentFCPValue={roundedFCPValue}
              currentFMPValue={roundedFMPValue}
              currentLCPValue={roundedLCPValue}
              filmstripData={filmstripData}
              networkDetailsData={networkDetailsData}
              networkResourceTypes={networkResourceTypes}
              disabledResourceTypes={disabledResourceTypes}
              onClickLegendItem={handleClickLegendItem}
            />
          </div>
          <div className={clsx('final-screenshot-container', { empty: thumbnailsLoading, [ANIMATION_CLASSES.animatedSkeleton]: thumbnailsLoading })}>
            <div className="final-screenshot-title">
              {t(NetworkRequestsAnalysisKeys.FinalScreenshot)}
            </div>
            <ScreenshotCard
              src={finalScreenshot?.data as string}
              timing={finalScreenshot?.timing as number}
            />
          </div>
        </div>
        <div className="bottom-section">
          <div className={clsx('lcp-element-container', { empty: thumbnailsLoading, [ANIMATION_CLASSES.animatedSkeleton]: thumbnailsLoading })}>
            <div className="lcp-element-title">
              {t(NetworkRequestsAnalysisKeys.LCPElement)}
            </div>
            <div className="lcp-element-data">
              <div className="lcp-element-label">
                {thumbnailsLCPElementLabel}
              </div>
              <div className="lcp-element-snippet">
                {thumbnailLCPElementSnippet}
              </div>
            </div>
          </div>
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default ThumbnailsModal;

import { AccountsDataKeys } from '../../enums/accounts';
import { SortOrder } from '../../enums/data';
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { ILicense } from '../../services/LicensesService/types';
import { AccountsActions } from './actions';
import { AccountsActionTypes, AccountsState } from './types';
import { IUIPayload } from './types';

const initState: AccountsState = {
  accounts: [],
  accountsSortOrder: SortOrder.Desc,
  accountsSortKey: AccountsDataKeys.CreatedAt,
  accountsSearch: '',
  UI: {
    loading: false,
    offset: 0,
    limit: ROWS_COUNTS.TwentyFive,
    count: null,
  },
};

export function accountsReducer(state: AccountsState = initState, action: AccountsActions): AccountsState {
  switch (action.type) {
    case AccountsActionTypes.ACCOUNTS_REFRESH: {
      return {
        ...state,
        accounts: action.payload as ILicense[],
      };
    }
    case AccountsActionTypes.ACCOUNTS_SEARCH_REFRESH: {
      return {
        ...state,
        accountsSearch: action.payload as string,
      };
    }
    case AccountsActionTypes.ACCOUNTS_SORT_KEY_REFRESH: {
      return {
        ...state,
        accountsSortKey: action.payload as AccountsDataKeys,
      };
    }
    case AccountsActionTypes.ACCOUNTS_SORT_ORDER_REFRESH: {
      return {
        ...state,
        accountsSortOrder: action.payload as SortOrder,
      };
    }
    case AccountsActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

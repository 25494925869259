export enum Strategy {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export enum CountryCode {
  De = 'de',
}

export enum RecrawlInterval {
  Daily = 'daily',
  MondayToFriday = 'monday-to-friday',
  Weekly = 'weekly',
  BiWeekly = 'bi-weekly',
  Monthly = 'monthly',
}

export enum ProjectsOverviewDataKeys {
  ID = 'id',
  Name = 'name',
  Domain = 'domain',
  Type = 'type',
  Frequency = 'frequency',
  Country = 'country',
  Keywords = 'keywords',
  Benchmarks = 'benchmarks',
  LatestCrawl = 'latest_crawl',
  CreatedAt = 'created_at',
}

export enum ProjectsDataKeys {
  ProjectID = 'project_id',
  Name = 'name',
  Domain = 'domain',
  Strategy = 'strategy',
  RecrawlInterval = 'recrawl_interval',
  Keywords = 'keywords',
  Benchmarks = 'benchmarks',
  LatestCrawl = 'latest_crawl',
  CreatedAt = 'created_at',
}

export enum CrawlsDataKeys {
  CrawlID = 'crawl_id',
  CreatedAt = 'created_at',
}

import React from 'react';
import clsx from 'clsx';

import { IProps as INavigationGroupProps } from './NavigationGroup/NavigationGroup';
import { NavigationGroup } from './NavigationGroup';
import { StyledWrapper as Wrapper } from './NavigationMenu.styled';

export interface INavigationGroup extends Omit<INavigationGroupProps, 'collapsed' | 'selectedKey' | 'onSelect'> {}

export interface IProps extends Pick<INavigationGroupProps, 'collapsed' | 'selectedKey' | 'onSelect'> {
  groups: INavigationGroup[];
}

const NavigationMenu: React.FC<IProps> = ({
  collapsed,
  selectedKey,
  groups,
  onSelect,
}) => {
  return (
    <Wrapper className={clsx({ collapsed })}>
      {groups.map(({ groupKey, ...restGroupProps }) => (
        <NavigationGroup
          key={groupKey}
          collapsed={collapsed}
          selectedKey={selectedKey}
          groupKey={groupKey}
          onSelect={onSelect}
          {...restGroupProps}
        />
      ))}
    </Wrapper>
  );
};

export default NavigationMenu;

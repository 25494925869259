import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AntdModal from 'antd/lib/modal';

import { modalsActions } from '../../../redux/modals/actions';
import { selectLimitVisible } from '../../../redux/modals/selectors';
import { IconClose } from '../../../components/styled/icons';
import { InputNumber } from '../../../components/styled/InputNumber';
import { Button, ButtonType } from '../../../components/styled/Button';
import { StyledBodyWrapper } from './LimitModal.styled';
import { SiderHeading, SiderSteps } from '../../../enums/locales/modals';
import { AccountDetailsKeys, AccountsKeys } from '../../../enums/locales/accounts';
import { ButtonTextKeys, FieldButtonTextKeys } from '../../../enums/locales/forms';
import { selectUI } from '../../../redux/profile-settings/selectors';
import { selectAccountByID } from '../../../redux/accounts/selectors';
import { accountsActions } from '../../../redux/accounts/actions';
import { selectAccountLicenseID, selectAccountLicense } from '../../../redux/account-details/selectors';

const LimitModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectLimitVisible);
  const { editingLicenseID } = useSelector(selectUI);
  const accountLicenseID = useSelector(selectAccountLicenseID);
  const editingAccount = useSelector(selectAccountByID(editingLicenseID || ''));
  const detailsAccount = useSelector(selectAccountLicense);

  const account = useMemo(() => accountLicenseID ? detailsAccount : editingAccount, [accountLicenseID, detailsAccount, editingAccount]);

  const [resources, setResources] = useState({
    user_slots: account?.user_slots || 0,
    project_slots: account?.project_slots || 0,
    keyword_slots: account?.keyword_slots || 0,
    url_slots: account?.url_slots || 0,
  });

  useEffect(() => {
    setResources({
      user_slots: account?.user_slots || 0,
      project_slots: account?.project_slots || 0,
      keyword_slots: account?.keyword_slots || 0,
      url_slots: account?.url_slots || 0,
    });
  }, [account, setResources]);

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.limitHide());
  }, [dispatch]);

  const onChange = useCallback((name: string) => (value: number) => {
    setResources({ ...resources, [name]: value > 0 ? value : 0 });
  }, [resources, setResources]);

  const onResourceUpdate = useCallback(() => {
    dispatch(accountsActions.accountResourcesUpdate(resources));
    dispatch(modalsActions.limitHide());
  }, [dispatch, resources]);

  return (
    <AntdModal
      centered
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="limit-modal-container"
      maskStyle={{ zIndex: 10001 }}
      width={'500px'}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className="limit-modal-title">
          {t(AccountDetailsKeys.EditAccountButton)} {t(SiderHeading.AccountResources)}
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(FieldButtonTextKeys.EndUsers) })}:
          </div>
          <InputNumber value={resources.user_slots || 0} onChange={onChange('user_slots')} />
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(AccountsKeys.ProjectsLabel) })}:
          </div>
          <InputNumber value={resources.project_slots || 0} onChange={onChange('project_slots')} />
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(FieldButtonTextKeys.EndKeywords) })}:
          </div>
          <InputNumber value={resources.keyword_slots || 0} onChange={onChange('keyword_slots')} />
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(SiderSteps.Urls) })}:
          </div>
          <InputNumber value={resources.url_slots || 0} onChange={onChange('url_slots')} />
        </div>
        <div className="update-btn-container">
          <Button
            type={ButtonType.Primary}
            onClick={onResourceUpdate}
            title={t(ButtonTextKeys.Update)}
          />
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default LimitModal;

import { Feature, SortOrder } from '../../enums/data';
import { IDirectory } from '../../services/DataService/types';
import { DirectoryActions } from './actions';
import { IUIPayload, DirectoryActionTypes, DirectoryState, ISortRefreshPayload, IDataRestrictionsPayload } from './types';

const initState: DirectoryState = {
  data: [],
  openedFolders: [],
  sort: {
    feature: Feature.PerformanceScore,
    order: SortOrder.Desc,
  },
  summaryDataRestrictions: {
    sortBy: 'url_count',
    order: SortOrder.Desc,
  },
  UI: {
    loading: false,
    folderLoading: '',
  },
};

export function directoryAnalysisReducer(state: DirectoryState = initState, action: DirectoryActions): DirectoryState {
  switch (action.type) {
    case DirectoryActionTypes.DIRECTORY_REFRESH: {
      return {
        ...state,
        data: action.payload as IDirectory[],
      }
    }
    case DirectoryActionTypes.OPENED_FOLDERS_REFRESH: {
      return {
        ...state,
        openedFolders: action.payload as string[],
      }
    }
    case DirectoryActionTypes.SORT_REFRESH: {
      return {
        ...state,
        sort: {
          ...action.payload as ISortRefreshPayload,
        },
      };
    }
    case DirectoryActionTypes.DATA_RESTRICTIONS_MERGE: {
      return {
        ...state,
        summaryDataRestrictions: {
          ...state.summaryDataRestrictions,
          ...action.payload as IDataRestrictionsPayload,
        },
      };
    }
    case DirectoryActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case DirectoryActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

import { FieldTypes } from "../../../enums/forms";
import { ButtonTextKeys, FieldButtonTextKeys, FieldLabelKeys, FieldListTitleKeys, FieldPlaceholderKeys, FieldSubLabelKeys, FieldTableTitleKeys } from "../../../enums/locales/forms";
import { SiderSteps, SiderStepsSuffixes } from "../../../enums/locales/modals";
import { FIELD_NAMES } from "../../../enums/projectSettings";

import { recrawlsItems, strategyItems } from "../../../constants/projectSettings";
import { RadioType } from "../../../components/styled/Radio/Radio";

export const formSteps = [
  [FIELD_NAMES.Domain, FIELD_NAMES.Name],
  [FIELD_NAMES.CountryCode],
  [FIELD_NAMES.Strategy],
  [FIELD_NAMES.Keywords],
  [FIELD_NAMES.Benchmarks],
  [FIELD_NAMES.UrlGroups],
  [FIELD_NAMES.Invites],
  [FIELD_NAMES.Goals],
];

export const GoalsFields = [
  {
    fieldType: FieldTypes.GoalsList,
    name: FIELD_NAMES.Goals,
    labelKey: FieldLabelKeys.Goals,
  },
];

export const UrlGroupsFields = [
  {
    fieldType: FieldTypes.GroupList,
    name: FIELD_NAMES.UrlGroups,
    labelKey: FieldLabelKeys.UrlGroups,
  },
];

export const InvitesFields = [
  {
    fieldType: FieldTypes.SimpleList,
    name: FIELD_NAMES.Invites,
    labelKey: FieldLabelKeys.Invites,
    addPlaceholder: FieldPlaceholderKeys.AddUsers,
    listTitle: FieldListTitleKeys.Invites,
    sufBtnText: ButtonTextKeys.Add,
    affBtnText: FieldButtonTextKeys.EndUsers,
  },
];

export const BenchmarksFields = [
  {
    fieldType: FieldTypes.SimpleList,
    name: FIELD_NAMES.Benchmarks,
    labelKey: FieldLabelKeys.Benchmarks,
    addPlaceholder: FieldPlaceholderKeys.AddBenchmarks,
    listTitle: FieldListTitleKeys.Benchmarks,
  },
];

export const KeywordsFields = [
  {
    fieldType: FieldTypes.CheckableList,
    name: FIELD_NAMES.Keywords,
    labelKey: FieldLabelKeys.Keywords,
    placeholder: FieldPlaceholderKeys.Keywords,
    sufBtnText: ButtonTextKeys.Add,
    affBtnText: FieldButtonTextKeys.EndKeywords,
    tableTitle: SiderSteps.Keywords,
    searchPlaceholder: FieldPlaceholderKeys.SearchKeywords,
    headerTitle: FieldTableTitleKeys.HeaderKeywords,
  },
];

export const TypeAndFrequencyFields = [
  {
    fieldType: FieldTypes.Radio,
    name: FIELD_NAMES.Strategy,
    labelKey: FieldLabelKeys.Strategy,
    items: strategyItems,
    radioType: RadioType.Large,
  },
  {
    fieldType: FieldTypes.Radio,
    name: FIELD_NAMES.RecrawlInterval,
    labelKey: FieldLabelKeys.Recrawl,
    items: recrawlsItems,
    subLabel: FieldSubLabelKeys.Recrawl,
  },
];

export const countryFields = [
  {
    fieldType: FieldTypes.Select,
    name: FIELD_NAMES.CountryCode,
    height: 42,
    labelKey: FieldLabelKeys.Country,
    options: [],
  },
];

export const domainFields = [
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Domain,
    labelKey: FieldLabelKeys.Domain,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Name,
    labelKey: FieldLabelKeys.ProjectName,
  },
];


export const projectSettingsSteps = [
  {
    id: 'step-0',
    className: 'domain-and-project',
    title: SiderSteps.DomainAndProjectName,
    fields: domainFields,
  },
  {
    id: 'step-1',
    className: '',
    title: SiderSteps.Country,
    fields: countryFields,
  },
  {
    id: 'step-2',
    className: '',
    title: SiderSteps.TypeAndFrequency,
    fields: TypeAndFrequencyFields,
  },
  {
    id: 'step-3',
    className: '',
    title: SiderSteps.Keywords,
    fields: KeywordsFields,
  },
  {
    id: 'step-4',
    className: '',
    title: SiderSteps.Benchmarks,
    fields: BenchmarksFields,
  },
  {
    id: 'step-5',
    className: '',
    title: SiderSteps.URLGroups,
    suffix: SiderStepsSuffixes.Optional,
    fields: UrlGroupsFields,
  },
  {
    id: 'step-6',
    className: '',
    title: SiderSteps.InviteUsers,
    suffix: SiderStepsSuffixes.Optional,
    fields: InvitesFields,
  },
  {
    id: 'step-7',
    className: '',
    title: SiderSteps.Goals,
    suffix: SiderStepsSuffixes.Optional,
    fields: GoalsFields,
  },
]
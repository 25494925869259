import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  &.collapsed {
    width: auto;
    transition: width 0.2s linear;
  }

  &:hover {
    .navigation-group-title {
      .navigation-group-name {
        z-index: 0;
      }
    }
  }
`;

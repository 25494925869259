import { ModalsActions } from './actions';
import { ModalsActionTypes, ModalsState, IRequestResultModalPayload } from './types';
import { LoaderModalMessageKeys } from '../../enums/locales/modals';

const initState: ModalsState = {
  sider: {
    visible: false,
  },
  adminSider: {
    visible: false,
  },
  onboard: {
    visible: false,
  },
  profileSettings: {
    visible: false,
  },
  accountSettings: {
    visible: false,
  },
  userSettings: {
    visible: false,
  },
  annotation: {
    visible: false,
  },
  urlDetails: {
    visible: false,
  },
  networkDetails: {
    visible: false,
  },
  limit: {
    visible: false,
  },
  license: {
    visible: false,
  },
  thumbnails: {
    visible: false,
  },
  projectCrawls: {
    visible: false,
  },
  projectMove: {
    visible: false,
  },
  loader: {
    visible: false,
    message: LoaderModalMessageKeys.Default,
  },
  requestResult: {
    type: null,
    visible: false,
    retryActions: [],
  }
};

export function modalsReducer(state: ModalsState = initState, action: ModalsActions): ModalsState {
  switch (action.type) {
    case ModalsActionTypes.LOADER_SHOW: {
      return {
        ...state,
        loader: {
          visible: true,
          message: action.payload as LoaderModalMessageKeys || LoaderModalMessageKeys.Default,
        },
      };
    }
    case ModalsActionTypes.LOADER_HIDE: {
      return {
        ...state,
        loader: {
          visible: false,
          message: LoaderModalMessageKeys.Default,
        },
      };
    }
    case ModalsActionTypes.ONBOARD_SHOW: {
      return {
        ...state,
        onboard: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.ONBOARD_HIDE: {
      return {
        ...state,
        onboard: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.SIDER_SHOW: {
      return {
        ...state,
        sider: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.SIDER_HIDE: {
      return {
        ...state,
        sider: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.ADMIN_SIDER_SHOW: {
      return {
        ...state,
        adminSider: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.ADMIN_SIDER_HIDE: {
      return {
        ...state,
        adminSider: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.PROFILE_SETTINGS_SHOW: {
      return {
        ...state,
        profileSettings: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.PROFILE_SETTINGS_HIDE: {
      return {
        ...state,
        profileSettings: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.ACCOUNT_SETTINGS_SHOW: {
      return {
        ...state,
        accountSettings: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.ACCOUNT_SETTINGS_HIDE: {
      return {
        ...state,
        accountSettings: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.USER_SETTINGS_SHOW: {
      return {
        ...state,
        userSettings: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.USER_SETTINGS_HIDE: {
      return {
        ...state,
        userSettings: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.ANNOTATION_SHOW: {
      return {
        ...state,
        annotation: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.ANNOTATION_HIDE: {
      return {
        ...state,
        annotation: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.URL_DETAILS_SHOW: {
      return {
        ...state,
        urlDetails: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.URL_DETAILS_HIDE: {
      return {
        ...state,
        urlDetails: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.NETWORK_DETAILS_SHOW: {
      return {
        ...state,
        networkDetails: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.NETWORK_DETAILS_HIDE: {
      return {
        ...state,
        networkDetails: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.LIMIT_SHOW: {
      return {
        ...state,
        limit: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.LIMIT_HIDE: {
      return {
        ...state,
        limit: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.LICENSE_SHOW: {
      return {
        ...state,
        license: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.LICENSE_HIDE: {
      return {
        ...state,
        license: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.THUMBNAILS_SHOW: {
      return {
        ...state,
        thumbnails: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.THUMBNAILS_HIDE: {
      return {
        ...state,
        thumbnails: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.PROJECT_CRAWLS_SHOW: {
      return {
        ...state,
        projectCrawls: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.PROJECT_CRAWLS_HIDE: {
      return {
        ...state,
        projectCrawls: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.PROJECT_MOVE_SHOW: {
      return {
        ...state,
        projectMove: {
          visible: true,
        },
      };
    }
    case ModalsActionTypes.PROJECT_MOVE_HIDE: {
      return {
        ...state,
        projectMove: {
          visible: false,
        },
      };
    }
    case ModalsActionTypes.REQUEST_RESULT_SHOW: {
      return {
        ...state,
        requestResult: {
          ...state.requestResult,
          visible: true,
          ...action.payload as IRequestResultModalPayload,
        },
      };
    }
    case ModalsActionTypes.REQUEST_RESULT_HIDE: {
      return {
        ...state,
        requestResult: {
          type: null,
          visible: false,
          retryActions: [],
        },
      };
    }
    case ModalsActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

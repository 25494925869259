import styled from "styled-components";

export const Wrapper = styled.thead`
  th {
    position: sticky;
    z-index: 2;
    top: 0px;
    background: #f9f9f9;
  }

  tr {
    height: 83px;
  }
`;

export const StyledTH = styled.th<{ width?: string }>`
  width: ${({ width }) => width || 'auto'};
  vertical-align: middle;
  padding: 0 25px;
  font-weight: 600;

  @media (max-width: 1440px) {
    &:not(:first-child) {
      padding: 0 18px;
    }
  }
  @media (max-width: 1280px) {
    &:not(:first-child) {
      padding: 0 6px;
    }
  }
  
  &:first-child {
    text-align: start;
  }
`;
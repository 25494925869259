import { ROWS_COUNTS } from "../enums/projectSettings";
import { IOption } from "../types/common.types";

export const paginationLimitOptions: IOption<number>[] = [
  {
    value: ROWS_COUNTS.TwentyFive,
    label: `${ROWS_COUNTS.TwentyFive}`,
  },
  {
    value: ROWS_COUNTS.Fifty,
    label: `${ROWS_COUNTS.Fifty}`,
  },
  {
    value: ROWS_COUNTS.OneHundred,
    label: `${ROWS_COUNTS.OneHundred}`,
  },
  {
    value: ROWS_COUNTS.TwoHundredFifty,
    label: `${ROWS_COUNTS.TwoHundredFifty}`,
  },
];

import { createSelector } from 'reselect';
import { RootState } from '../store';

const personal = (state: RootState) => state.ProfileSettings.personal;
const company = (state: RootState) => state.ProfileSettings.company;
const users = (state: RootState) => state.ProfileSettings.users;
const licenses = (state: RootState) => state.ProfileSettings.licenses;
const UI = (state: RootState) => state.ProfileSettings.UI;

export const selectPersonal = createSelector(
  [personal],
  (personal) => personal,
);

export const selectCompany = createSelector(
  [company],
  (company) => company,
);

export const selectUsers = createSelector(
  [users],
  (users) => users,
);

export const selectLicenses = createSelector(
  [licenses],
  (licenses) => licenses,
);

export const selectLicenseByID = (licenseID: string) => createSelector(
  [licenses],
  (licenses) => licenses.find(({ license_id }) => license_id === licenseID),
);

export const selectUI = createSelector(
  [UI],
  (UI) => UI,
);

import { AxiosError } from 'axios';
import { BaseAPIService } from '../BaseAPIService';
import { IBaseResponse } from '../BaseAPIService/types';
import { IAuthToken, IGoogleSignInBody, IPasswordSignInBody, IUser, IRefreshTokensOptions, IUploadRequest, IProfileRequestBody, IResetPasswordStartBody } from './types';
import { userManagementApiUrl } from '../../config';
import { API_USER_MANAGEMENT_ROUTES } from '../../constants/routes';
import { IChangePassword } from '../../redux/profile-settings/types';

class AuthService extends BaseAPIService {

  constructor(url: string) {
    super(url);
    this.signInWithGoogle = this.signInWithGoogle.bind(this);
    this.signInWithPassword = this.signInWithPassword.bind(this);
    this.refreshTokens = this.refreshTokens.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getProfilePicture = this.getProfilePicture.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.resetPasswordStart = this.resetPasswordStart.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  async signInWithGoogle(body: IGoogleSignInBody): Promise<IBaseResponse<IAuthToken>> {
    try {
      const response: IBaseResponse<IAuthToken> = await this.post<IAuthToken, IGoogleSignInBody>(
        `${API_USER_MANAGEMENT_ROUTES.signInWithGoogle}`,
        body,
        {}
      );

      const result: IBaseResponse<IAuthToken> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async signInWithPassword(body: IPasswordSignInBody): Promise<IBaseResponse<IAuthToken>> {
    try {
      const response: IBaseResponse<IAuthToken> = await this.post<IAuthToken, IPasswordSignInBody>(
        `${API_USER_MANAGEMENT_ROUTES.signInWithPassword}`,
        body,
        {}
      );

      const result: IBaseResponse<IAuthToken> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async refreshTokens(options?: IRefreshTokensOptions): Promise<IBaseResponse<IAuthToken>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IAuthToken> = await this.post<IAuthToken>(
        `${API_USER_MANAGEMENT_ROUTES.refreshTokens}`,
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IAuthToken> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getUser(options?: IRefreshTokensOptions): Promise<IBaseResponse<IUser>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IUser> = await this.get<IUser>(
        `${API_USER_MANAGEMENT_ROUTES.getUser}`,
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IUser> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getProfilePicture(options?: IRefreshTokensOptions): Promise<IBaseResponse<IUploadRequest>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IUploadRequest> = await this.post<IUploadRequest>(
        `${API_USER_MANAGEMENT_ROUTES.getProfilePicture}`,
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IUploadRequest> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateUser(body: IProfileRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProfileRequestBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProfileRequestBody> = await this.patch<IProfileRequestBody, IProfileRequestBody>(
        `${API_USER_MANAGEMENT_ROUTES.getUser}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProfileRequestBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async resetPasswordStart(body: IResetPasswordStartBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<string>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<string> = await this.post<string, IResetPasswordStartBody>(
        `${API_USER_MANAGEMENT_ROUTES.resetPasswordStart}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<string> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async changePassword(body: IChangePassword, options?: IRefreshTokensOptions): Promise<IBaseResponse<string>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<string> = await this.post<string, IChangePassword>(
        `${API_USER_MANAGEMENT_ROUTES.changePassword}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<string> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }
}

const ServiceInstance = new AuthService(userManagementApiUrl);

export default ServiceInstance;
export { ServiceInstance as AuthService };

import styled from 'styled-components';

import { hexToRGBA } from '../../../utils/theme';

export const StyledBodyWrapper = styled.div`
  max-height: 600px;
  margin-right: 10px;
  padding-right: 20px;
  
  overflow: auto;

  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #171C2810 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #555BF0 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  .url-details-skeleton {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    > div {
      height: 16px;
      width: 100%;
      margin-bottom: 16px;
      background: ${({ theme }) => hexToRGBA(theme.colors.secondaryDark, 0.05)};
    }
  }
`;

import styled from 'styled-components';
import { ReactComponent as RawArrowRightSVG } from '../../../../assets/icons/icon_arrow_right.svg';

export const IconArrowRight = styled(RawArrowRightSVG)`
  .a, .b {
    fill: none;
  }
  
  .b {
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
  }
`;

import { DomainHistoryInterval, Feature } from "../../enums/data";
import { ILeaderboardBenchmarkRank, ILeaderboardData, ILeaderboardHistoryData } from "../../services/DataService/types";

export enum LeaderboardActionTypes {
  FEATURE_REFRESH = '[Leaderboard] feature-refresh',

  DATA_RELOAD = '[Leaderboard] data-reload',
  DATA_REFRESH = '[Leaderboard] data-refresh',

  RANK_RELOAD = '[Leaderboard] rank-reload',
  RANK_REFRESH = '[Leaderboard] rank-refresh',

  BENCHMARK_RANKS_RELOAD = '[Leaderboard] benchmark-ranks-reload',
  BENCHMARK_RANKS_REFRESH = '[Leaderboard] benchmark-ranks-refresh',

  HISTORY_RELOAD = '[Leaderboard] history-reload',
  HISTORY_REFRESH = '[Leaderboard] history-refresh',

  HISTORY_INTERVAL_REFRESH = '[Leaderboard] history-interval-refresh',

  UI_MERGE = '[Leaderboard] ui-merge',

  RESET_ALL = '[Leaderboard] reset-all',
}

export interface IUIPayload {
  dataLoading?: boolean;
  rankLoading?: boolean;
  benchmarkRanksLoading?: boolean;
  historyLoading?: boolean;
}

export interface LeaderboardState {
  data: ILeaderboardData[],
  rank: number | null,
  benchmarkRanks: ILeaderboardBenchmarkRank[],
  history: ILeaderboardHistoryData,
  historyInterval: DomainHistoryInterval,
  feature: Feature,
  UI: IUIPayload;
}

import { IDomainKpi } from '../../services/DataService/types';
import { Feature } from '../../enums/data';

export enum KpiActionTypes {
  DOMAIN_KPI_RELOAD = '[KPI] domain-kpi-reload',
  DOMAIN_KPI_REFRESH = '[KPI] domain-kpi-refresh',

  DOMAIN_KPI_RELOAD_ALL = '[KPI] domain-kpi-reload-all',
  DOMAIN_KPI_RESET_ALL = '[KPI] domain-kpi-reset-all',

  ACTIVE_FEATURES_REFRESH = '[KPI] active-features-refresh',

  FEATURE_LOADING_MERGE = '[KPI] feature-loading-merge',
  UI_MERGE = '[KPI] ui-merge',

  RESET_ALL = '[KPI] reset-all',
}

export type KpiPayload = {
  [key in Feature]?: IDomainKpi;
}

export interface IUIPayload {
  loading?: boolean;
}

export type FeatureLoadingPayload = {
  [key in Feature]?: boolean;
}

export interface KpiState {
  activeFeatures: Feature[];
  data: KpiPayload;
  loading: FeatureLoadingPayload;
  UI: IUIPayload;
}

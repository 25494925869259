import { PageTitleKeys, RouteTitleKeys } from "../enums/locales/helmet";
import { NavigationMenuKeys } from "../enums/locales/sideBar";
import { UI_ACTUAL_ROUTES } from "./routes";

export const ROUTE_TITLES_MAP = {
  [UI_ACTUAL_ROUTES.signIn]: {
    title: PageTitleKeys.PageTitle,
    options: { title: RouteTitleKeys.Login },
  },
  [UI_ACTUAL_ROUTES.dashboard]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.Dashboard },
  },
  [UI_ACTUAL_ROUTES.metricsPerformanceScore]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.PerformanceScorePM },
  },
  [UI_ACTUAL_ROUTES.metricsFirstContentfulPaint]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.FirstContentfulPaintPM },
  },
  [UI_ACTUAL_ROUTES.metricsFirstInputDelay]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.FirstInputDelayPM },
  },
  [UI_ACTUAL_ROUTES.metricsFirstMeaningfulPaint]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.FirstMeaningfulPaintPM },
  },
  [UI_ACTUAL_ROUTES.metricsLargestContentfulPaint]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.LargestContentfulPaintPM },
  },
  [UI_ACTUAL_ROUTES.metricsCumulativeLayoutShift]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.CumulativeLayoutShiftPM },
  },
  [UI_ACTUAL_ROUTES.metricsServerResponseTime]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.ServerResponseTimePM },
  },
  [UI_ACTUAL_ROUTES.metricsTimeToInteractive]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.TimeToInteractivePM },
  },
  [UI_ACTUAL_ROUTES.metricsTotalBlockingTime]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.TotalBlockingTimePM },
  },
  [UI_ACTUAL_ROUTES.metricsDOMSize]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.DOMSizePM },
  },
  [UI_ACTUAL_ROUTES.metricsModernImages]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.ModernImagesPM },
  },
  [UI_ACTUAL_ROUTES.metricsUnminifiedJS]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.UnminifiedJSPM },
  },
  [UI_ACTUAL_ROUTES.metricsUnminifiedCSS]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.UnminifiedCSSPM },
  },
  [UI_ACTUAL_ROUTES.metricsUnusedJS]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.UnusedJSPM },
  },
  [UI_ACTUAL_ROUTES.metricsUnusedCSS]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.UnusedCSSPM },
  },
  [UI_ACTUAL_ROUTES.metricsOptimizedImages]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.OptimizedImagesPM },
  },
  [UI_ACTUAL_ROUTES.metricsRenderBlockingResources]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.RenderBlockingResourcesPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumTasksOver50ms]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumTasksOver50msPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumTasksOver100ms]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumTasksOver100msPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumTasksOver500ms]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumTasksOver500msPM },
  },
  [UI_ACTUAL_ROUTES.metricsSpeedIndex]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.SpeedIndexPM },
  },
  [UI_ACTUAL_ROUTES.metricsUsesTextCompression]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.UsesTextCompressionPM },
  },
  [UI_ACTUAL_ROUTES.metricsOffscreenImages]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.OffscreenImagesPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumFonts]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumFontsPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumTasks]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumTasksPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumRequests]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumRequestsPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumStylesheets]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumStylesheetsPM },
  },
  [UI_ACTUAL_ROUTES.metricsNumScripts]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumScriptsPM },
  },
  [UI_ACTUAL_ROUTES.metricsTotalByteWeight]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.TotalByteWeightPM },
  },
  [UI_ACTUAL_ROUTES.metricsTotalTaskTime]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.TotalTaskTimePM },
  },
  [UI_ACTUAL_ROUTES.leaderboardsPerformanceScore]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.PerformanceScore },
  },
  [UI_ACTUAL_ROUTES.leaderboardsLargestContentfulPaint]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.LargestContentfulPaint },
  },
  [UI_ACTUAL_ROUTES.leaderboardsFirstMeaningfulPaint]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.FirstContentfulPaint },
  },
  [UI_ACTUAL_ROUTES.leaderboardsServerResponseTime]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.ServerResponseTime },
  },
  [UI_ACTUAL_ROUTES.leaderboardsDOMSize]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.DOMSize },
  },
  [UI_ACTUAL_ROUTES.leaderboardsModernImages]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.ModernImages },
  },
  [UI_ACTUAL_ROUTES.leaderboardsFirstContentfulPaint]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.FirstContentfulPaint },
  },
  [UI_ACTUAL_ROUTES.leaderboardsFirstInputDelay]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.FirstInputDelay },
  },
  [UI_ACTUAL_ROUTES.leaderboardsCumulativeLayoutShift]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.CumulativeLayoutShift },
  },
  [UI_ACTUAL_ROUTES.leaderboardsTimeToInteractive]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.TimeToInteractive },
  },
  [UI_ACTUAL_ROUTES.leaderboardsTotalBlockingTime]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.TotalBlockingTime },
  },
  [UI_ACTUAL_ROUTES.leaderboardsUnminifiedCSS]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.UnminifiedCSS },
  },
  [UI_ACTUAL_ROUTES.leaderboardsUnminifiedJS]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.UnminifiedJS },
  },
  [UI_ACTUAL_ROUTES.leaderboardsUnusedCSS]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.UnusedCSS },
  },
  [UI_ACTUAL_ROUTES.leaderboardsUnusedJS]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.UnusedJS },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumTasksOver50ms]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.NumTasksOver50ms },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumTasksOver100ms]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.NumTasksOver100ms },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumTasksOver500ms]: {
    title: PageTitleKeys.LeaderboardPageTitle,
    options: { title: NavigationMenuKeys.NumTasksOver500ms },
  },
  [UI_ACTUAL_ROUTES.leaderboardsOptimizedImages]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.OptimizedImages },
  },
  [UI_ACTUAL_ROUTES.leaderboardsRenderBlockingResources]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.RenderBlockingResources },
  },
  [UI_ACTUAL_ROUTES.leaderboardsSpeedIndex]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.SpeedIndex },
  },
  [UI_ACTUAL_ROUTES.leaderboardsUsesTextCompression]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.UsesTextCompression },
  },
  [UI_ACTUAL_ROUTES.leaderboardsOffscreenImages]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.OffscreenImages },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumFonts]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumFonts },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumTasks]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumTasks },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumRequests]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumRequests },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumStylesheets]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumStylesheets },
  },
  [UI_ACTUAL_ROUTES.leaderboardsNumScripts]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NumScripts },
  },
  [UI_ACTUAL_ROUTES.leaderboardsTotalByteWeight]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.TotalByteWeight },
  },
  [UI_ACTUAL_ROUTES.leaderboardsTotalTaskTime]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.TotalTaskTime },
  },
  [UI_ACTUAL_ROUTES.networkRequestsAnalysis]: {
    title: PageTitleKeys.PageTitle,
    options: { title: NavigationMenuKeys.NetworkRequestsAnalysis },
  },
};

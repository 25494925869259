import React from 'react';
import { FieldProps } from 'formik';
import { FieldTypes } from '../../enums/forms';
import { FieldLabelKeys, FieldPlaceholderKeys } from '../../enums/locales/forms';

import { Input } from '../../components/form-controls/Input';
import { Select } from '../../components/form-controls/Select';
import { Radio } from '../../components/form-controls/Radio';
import { CheckableList } from '../../components/form-controls/CheckableList';
import { SimpleList } from '../../components/form-controls/SimpleList';
import { GroupList } from '../../components/form-controls/GroupList';
import { GoalsList } from '../../components/form-controls/GoalsList';
import { Checkbox } from '../../components/form-controls/Checkbox';
import { Switch } from '../../components/form-controls/Switch';

export interface IFieldConfig extends Record<string, unknown> {
  fieldType?: FieldTypes;
  type?: string;
  name?: string;
  labelKey?: FieldLabelKeys,
  placeholderKey?: FieldPlaceholderKeys,
  autoComplete?: string,
  className?: string,
}

export const createInput = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <Input
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createSelect = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <Select
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createRadio = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <Radio
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createCheckableList = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <CheckableList
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createSimpleList = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <SimpleList
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createGroupList = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <GroupList
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createCheckbox = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <Checkbox
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createGoalsList = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <GoalsList
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createSwitch = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  return (
    <Switch
      {...formikFieldProps}
      {...fieldConfig}
    />
  );
};

export const createField = (formikFieldProps: FieldProps, fieldConfig: IFieldConfig) => {
  const { fieldType, ...restFieldConfig } = fieldConfig;
  let control;
  switch (fieldType) {
    case FieldTypes.Input:
    case FieldTypes.Password: {
      control = createInput(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.Select: {
      control = createSelect(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.Radio: {
      control = createRadio(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.CheckableList: {
      control = createCheckableList(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.SimpleList: {
      control = createSimpleList(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.GroupList: {
      control = createGroupList(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.Checkbox: {
      control = createCheckbox(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.GoalsList: {
      control = createGoalsList(formikFieldProps, restFieldConfig);
      break;
    }
    case FieldTypes.Switch: {
      control = createSwitch(formikFieldProps, restFieldConfig);
      break;
    }
    default:
      control = createInput(formikFieldProps, restFieldConfig);
  }

  return control;
}

export const createFormBag = () => {
  return {
    values: {} as any,
    errors: {} as any,
    touched: {} as any,
    submitForm: () => { },
    validateForm: (): any => { },
    setValues: (...args: any) => { },
    setFieldValue: (...args: any) => { },
    setTouched: (...args: any) => { },
    setFieldTouched: (...args: any): any => { },
    setErrors: () => { },
    setFieldError: () => { },
    setSubmitting: (...args: any) => { },
    resetForm: (...args: any) => { },
  };
}

export const fillFormBag = (formBag: any, formikProps: any) => {
  Object.keys(formBag).forEach(key => {
    formBag[key] = formikProps[key];
  });
}

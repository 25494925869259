import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux'

import { selectIsAppStarted, selectUI } from '../../redux/app/selectors';

import { TopBar } from '../../containers/TopBar';
import { SideBar } from '../../containers/SideBar';
import { StyledWrapper as Wrapper } from './Layout.styled';
import { selectGlobalLoading } from '../../redux/globalSelectors';

const Layout: React.FC = ({ children }) => {
  const { isTopBarVisible, isSideBarVisible, isSideBarCollapsed } = useSelector(selectUI);
  const isAppStarted = useSelector(selectIsAppStarted);
  const globalLoading = useSelector(selectGlobalLoading);

  const className = clsx({
    topbar: isTopBarVisible && isAppStarted && !globalLoading,
    sidebar: isSideBarVisible && isAppStarted && !globalLoading,
    collapsed: isSideBarCollapsed,
  });

  return (
    <Wrapper className={className}>
      <div className="topbar-container">
        <TopBar />
      </div>
      <div className="sidebar-container">
        <SideBar />
      </div>
      <div className="main-content">
        {children}
      </div>
    </Wrapper>
  );
};

export default Layout;

import React, { useCallback } from 'react';

import { IProps as IProjectListItemProps } from './ProjectListItem/ProjectListItem';
import { ProjectListItem } from './ProjectListItem';
import { StyledWrapper as Wrapper } from './ProjectList.styled';

export interface IProjectListOption extends Omit<IProjectListItemProps, 'active | onClickEditProject | onClick'> { }

export interface IProps {
  selectedProjectID?: string | null;
  selectedProjectGroupID?: string | null;
  projectOptions: IProjectListOption[];
  onSelectProject?: (projectID: string, groupID?: string) => void;
  onClickEditProject?: (projectID: string) => void;
}

const ProjectList: React.FC<IProps> = ({
  selectedProjectGroupID,
  selectedProjectID,
  projectOptions,
  onSelectProject,
  onClickEditProject,
}) => {
  const handleClickProjectItem = useCallback((projectID: string, groupID?: string) => {
    onSelectProject && onSelectProject(projectID, groupID);
  }, [onSelectProject]);

  return (
    <Wrapper>
      {projectOptions.map(({ projectID, ...restProps }) => {
        const isActive = selectedProjectID === projectID;
        const isGroupActive = !!restProps.urlGroups.find(({ url_group_id }) => url_group_id === selectedProjectGroupID);

        return (
          <ProjectListItem
            key={projectID}
            active={isActive}
            projectID={projectID}
            groupActive={isGroupActive}
            groupID={selectedProjectGroupID}
            onClickEditProject={onClickEditProject}
            onClick={handleClickProjectItem}
            {...restProps}
          />
        );
      })}
    </Wrapper>
  );
}

export default ProjectList;

import { createSelector } from 'reselect';
import { RootState } from '../store';

const appStarted = (state: RootState) => state.App.appStarted;
const language = (state: RootState) => state.App.language;
const deviceIdentifier = (state: RootState) => state.App.deviceIdentifier;
const deviceType = (state: RootState) => state.App.deviceType;
const apiAppContext = (state: RootState) => state.App.apiAppContext;
const UI = (state: RootState) => state.App.UI;

export const selectIsAppStarted = createSelector(
	[appStarted],
	(appStarted) => appStarted,
);

export const selectLanguage = createSelector(
	[language],
	(language) => language,
);

export const selectDeviceIdentifier = createSelector(
	[deviceIdentifier],
	(deviceIdentifier) => deviceIdentifier,
);

export const selectDeviceType = createSelector(
	[deviceType],
	(deviceType) => deviceType,
);

export const selectApiAppContext = createSelector(
	[apiAppContext],
	(apiAppContext) => apiAppContext,
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

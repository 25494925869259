import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .project-section {
    margin-right: 20px;
  }
  
  .add-new-project-section {
    margin-right: 80px;

    button {
      max-width: none;

      .title-wrapper {
        padding-left: 22px;
        padding-right: 22px;
      }
    }

    &.hidden {
      button {
        display: none;
      }
    }
  }
`;

const {
  REACT_APP_LOCALES_NAME_SPACE,
  REACT_APP_USER_MANAGEMENT_API_URL,
  REACT_APP_DATA_SERVICE_API_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
} = process.env;

const googleSharedStuffFaviconUrl = 'https://www.google.com/s2/favicons?domain=';
const googleClientID = REACT_APP_GOOGLE_CLIENT_ID || '640366344470-eelbvq4fcpe5pbsl45uj1bav482iv32m.apps.googleusercontent.com';
const localesNameSpace = REACT_APP_LOCALES_NAME_SPACE || 'Standard';
const userManagementApiUrl = REACT_APP_USER_MANAGEMENT_API_URL || 'https://user-management.api.yetanothermobileapp.com/v1';
const dataServiceApiUrl = REACT_APP_DATA_SERVICE_API_URL || 'https://cwv.api.yetanothermobileapp.com/v1';

export {
  googleSharedStuffFaviconUrl,
  googleClientID,
  localesNameSpace,
  userManagementApiUrl,
  dataServiceApiUrl,
};

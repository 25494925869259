import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardWebVitalsGroups } from '../../../../enums/locales/dashboard';
import { ButtonTextKeys } from '../../../../enums/locales/forms';

import { Button, ButtonType } from '../../Button';
import { Checkbox } from "../../Checkbox";

import { Wrapper } from './Suggestions.styled';

export interface IProps {
  title: string;
  list: string[];
  onAdd: (items: string[]) => void;
}

const Suggestions: React.FC<IProps> = ({
  title,
  list,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<string[]>([]);

  const onSingleCheck = useCallback((newItem: string) => () => {
    if (checked.includes(newItem)) {
      setChecked(checked.filter((item) => item !== newItem));
      return;
    }
    setChecked([...checked, newItem]);
  }, [setChecked, checked]);

  const onItemsAdd = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onAdd([...checked]);
    setChecked([]);
  }, [onAdd, checked, setChecked]);

  const isDisabled = useMemo(() => !checked.length, [checked.length]);

  const btnTitle = useMemo(() => `${t(ButtonTextKeys.Add)}${!isDisabled ? ` ${checked.length} ` : ' '}${t(DashboardWebVitalsGroups.Benchmarks)}`, [t, isDisabled, checked.length]);

  return (
    <Wrapper>
      <div className="heading">{title}:</div>
      <div className="list">
        {list.map((item, index) => (
          <div key={`${item}-${index}`} className={clsx('list-item', { 'item-checked': checked.includes(item) })}>
            <Checkbox
              checked={checked.includes(item)}
              onChange={onSingleCheck(item)}
              small
              label={item}
            />
          </div>
        ))}
      </div>
      <Button
        type={ButtonType.Primary}
        title={btnTitle}
        disabled={isDisabled}
        onClick={onItemsAdd}
      />
    </Wrapper>
  );
}

export default Suggestions;
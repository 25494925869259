import React, { useCallback, InputHTMLAttributes } from "react";
import clsx from 'clsx';

import { StyledWrapper as Wrapper } from './Input.styled';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: JSX.Element,
  disabled?: boolean;
  error?: boolean;
  onClickIcon?: () => void;
}

const Input: React.FC<IProps> = ({
  icon,
  disabled = false,
  error,
  onClickIcon,
  ...restProps
}) => {
  const className = clsx({
    icon,
    disabled,
    error,
  });

  const handleClickIcon = useCallback(() => {
    onClickIcon && onClickIcon();
  }, [onClickIcon]);

  return (
    <Wrapper className={className}>
      <input disabled={disabled} {...restProps} />
      <div
        className="icon-container"
        onClick={handleClickIcon}
      >
        {icon}
      </div>
    </Wrapper>
  );
};

export default Input;

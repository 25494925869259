import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;
  width: 100%;

  padding-top: 90px;
  padding-bottom: 18px;
  padding-left: 36px;

  .navigation-menu-section {
    flex-grow: 1;
    width: 100%;
  }

  .bottom-menu-section {
    .navigation-menu-expand-trigger {
      cursor: pointer;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    
    top: 0;
    left: 0;

    height: 100%;
    width: 36px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 10px;

    &::before {
      width: 10px;
    }
  }
`;

import { IProject, ICrawl } from '../../services/ProjectService/types';

export enum ProjectsActionTypes {
  LIST_RELOAD = '[Projects] list-reload',
  LIST_REFRESH = '[Projects] list-refresh',

  PROJECT_REMOVE = '[Projects] project-remove',

  LATEST_CRAWL_RELOAD = '[Projects] latest-crawl-reload',
  LATEST_CRAWL_REFRESH = '[Projects] latest-crawl-refresh',

  ANNOTATIONS_UPDATE = '[Projects] annotations-update',

  CRAWLS_RELOAD = '[Projects] crawls-reload',
  CRAWLS_REFRESH = '[Projects] crawls-refresh',

  ACTIVE_CRAWLS_INIT = '[Projects] active-crawls-init',
  ACTIVE_CRAWLS_CHECK_START = '[Projects] active-crawls-check-start',
  ACTIVE_CRAWLS_CHECK_STOP = '[Projects] active-crawls-check-stop',

  ACTIVE_PROJECT_ID_STORE = '[Projects] active-project-id-store',
  ACTIVE_PROJECT_ID_RESTORE = '[Projects] active-project-id-restore',

  ACTIVE_PROJECT_GROUP_ID_STORE = '[Projects] active-project-group-id-store',
  ACTIVE_PROJECT_GROUP_ID_RESTORE = '[Projects] active-project-group-id-restore',

  ACTIVE_PROJECT_ID_REFRESH = '[Projects] active-project-id-refresh',
  ACTIVE_PROJECT_ID_RESET = '[Projects] active-project-id-reset',

  ACTIVE_PROJECT_GROUP_ID_REFRESH = '[Projects] active-project-group-id-refresh',
  ACTIVE_PROJECT_GROUP_ID_RESET = '[Projects] active-project-group-id-reset',

  CRAWLS_IN_PROGRESS_MERGE = '[Projects] crawls-in-progress-merge',
  CRAWLS_IN_PROGRESS_RESET = '[Projects] crawls-in-progress-reset',

  UI_MERGE = '[Projects] ui-merge',

  RESET_ALL = '[Projects] reset-all',
}

export type ProjectsListPayload = IProject[];

export type LatestCrawlPayload = ICrawl | null;

export type CrawlsPayload = ICrawl[];

export type ProjectRemovePayload = string;

export type ActiveProjectIDPayload = string | null;

export type ActiveProjectGroupIDPayload = string | null;

export interface ICrawlsInProgressPayload {
  [keyName: string]: number | undefined,
}
export interface IUIPayload {
  loading?: boolean;
  crawlsChecking?: boolean;
  projectsMenuOpen?: boolean;
  testMode?: boolean;
  restoringProject?: boolean;
  restoringGroup?: boolean;
  editingAnnotationDate?: string | null;
}

export interface ProjectsState {
  activeProjectID: ActiveProjectIDPayload;
  activeProjectGroupID: ActiveProjectGroupIDPayload;
  latestCrawl: LatestCrawlPayload;
  crawls: ICrawl[];
  list: ProjectsListPayload;
  crawlsInProgress: ICrawlsInProgressPayload;
  UI: IUIPayload;
}

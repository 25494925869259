import React from 'react';
import { useTranslation } from 'react-i18next';

import { DashboardLabelKeys } from '../../../enums/locales/dashboard';

import { StyledWrapper as Wrapper } from './ScreenshotCard.styled';

export interface IProps {
 src: string;
 timing: number;
}

const ScreenshotCard: React.FC<IProps> = ({ src, timing }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="image-holder">
        <img src={src} alt="Screenshot" />
      </div>
      <div className="timing-label">
        <div className="timing-value">
          {timing}
        </div>
        <div className="timing-suffix">
          {t(DashboardLabelKeys.Milliseconds)}
        </div>
      </div>
    </Wrapper>
  );
};

export default ScreenshotCard;

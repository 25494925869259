import React, { InputHTMLAttributes, useCallback } from "react";
import clsx from 'clsx';

import { StyledWrapper as Wrapper } from './InputNumber.styled';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: JSX.Element,
  disabled?: boolean;
  error?: boolean;
  onChange?: (...args: any) => void;
  value: number;
}

const InputNumber: React.FC<IProps> = ({
  icon,
  disabled = false,
  error,
  onChange = () => { },
  value,
  ...restProps
}) => {
  const className = clsx({
    icon,
    disabled,
    error,
  });

  const onMinus = useCallback(() => {
    onChange(Number(value) - 1);
  }, [onChange, value]);

  const onPlus = useCallback(() => {
    onChange(Number(value) + 1);
  }, [onChange, value]);

  const onInputChange = useCallback((e) => {
    onChange(Number(e.target.value));
  }, [onChange]);

  return (
    <Wrapper className={className}>
      <div onClick={onMinus} className="minus">-</div>
      <input type="number" min={0} value={value} disabled={disabled} onChange={onInputChange} {...restProps} />
      <div onClick={onPlus} className="plus">+</div>
    </Wrapper>
  );
};

export default InputNumber;

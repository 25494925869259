import { ActionUnion, createAction } from '../commonTypes';
import { OverviewActionTypes, OverviewPayload, IUIPayload } from './types';
import { EmptyObject } from '../../types/common.types';

export const overviewActions = {
  overviewReload: (): { type: OverviewActionTypes, payload: EmptyObject } => createAction(
    OverviewActionTypes.FEATURE_OVERVIEW_RELOAD,
  ),
  overviewRefresh: (payload: OverviewPayload): { type: OverviewActionTypes, payload: OverviewPayload } => createAction(
    OverviewActionTypes.FEATURE_OVERVIEW_REFRESH,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: OverviewActionTypes, payload: IUIPayload } => createAction(
    OverviewActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: OverviewActionTypes, payload: EmptyObject } => createAction(
    OverviewActionTypes.RESET_ALL,
  ),
}

export type OverviewActions = ActionUnion<typeof overviewActions>;

import { ROWS_COUNTS } from '../../enums/projectSettings';
import { IUserSimple } from '../../services/AuthService/types';
import { UsersActions } from './actions';
import { UsersActionTypes, UsersState } from './types';
import { IUIPayload } from './types';
import { SortOrder } from '../../enums/data';
import { UsersDataKeys } from '../../enums/users';

const initState: UsersState = {
  users: [],
  usersSortOrder: SortOrder.Desc,
  usersSortKey: UsersDataKeys.CreatedAt,
  usersSearch: '',
  UI: {
    loading: false,
    offset: 0,
    limit: ROWS_COUNTS.TwentyFive,
    count: null,
  },
};

export function usersReducer(state: UsersState = initState, action: UsersActions): UsersState {
  switch (action.type) {
    case UsersActionTypes.USERS_REFRESH: {
      return {
        ...state,
        users: action.payload as IUserSimple[],
      };
    }
    case UsersActionTypes.USERS_SORT_ORDER_REFRESH: {
      return {
        ...state,
        usersSortOrder: action.payload as SortOrder,
      };
    }
    case UsersActionTypes.USERS_SORT_KEY_REFRESH: {
      return {
        ...state,
        usersSortKey: action.payload as UsersDataKeys,
      };
    }
    case UsersActionTypes.USERS_SEARCH_REFRESH: {
      return {
        ...state,
        usersSearch: action.payload as string,
      };
    }
    case UsersActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

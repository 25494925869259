import { LoaderModalMessageKeys } from '../../enums/locales/modals';
import { RequestResultModalType } from '../../enums/modals';

export enum ModalsActionTypes {
  LOADER_SHOW = '[Modals] loader-show',
  LOADER_HIDE = '[Modals] loader-hide',

  ONBOARD_SHOW = '[Modals] onboard-show',
  ONBOARD_HIDE = '[Modals] onboard-hide',

  SIDER_SHOW = '[Modals] sider-show',
  SIDER_HIDE = '[Modals] sider-hide',

  ADMIN_SIDER_SHOW = '[Modals] admin-sider-show',
  ADMIN_SIDER_HIDE = '[Modals] admin-sider-hide',

  PROFILE_SETTINGS_SHOW = '[Modals] profile-settings-show',
  PROFILE_SETTINGS_HIDE = '[Modals] profile-settings-hide',

  ACCOUNT_SETTINGS_SHOW = '[Modals] account-settings-show',
  ACCOUNT_SETTINGS_HIDE = '[Modals] account-settings-hide',

  USER_SETTINGS_SHOW = '[Modals] user-settings-show',
  USER_SETTINGS_HIDE = '[Modals] user-settings-hide',

  ANNOTATION_SHOW = '[Modals] annotation-show',
  ANNOTATION_HIDE = '[Modals] annotation-hide',

  REQUEST_RESULT_SHOW = '[Modals] request-result-show',
  REQUEST_RESULT_HIDE = '[Modals] request-result-hide',

  URL_DETAILS_SHOW = '[Modals] url-details-show',
  URL_DETAILS_HIDE = '[Modals] url-details-hide',

  NETWORK_DETAILS_SHOW = '[Modals] network-details-show',
  NETWORK_DETAILS_HIDE = '[Modals] network-details-hide',

  LIMIT_SHOW = '[Modals] limit-show',
  LIMIT_HIDE = '[Modals] limit-hide',

  LICENSE_SHOW = '[Modals] license-show',
  LICENSE_HIDE = '[Modals] license-hide',

  THUMBNAILS_SHOW = '[Modals] thumbnails-show',
  THUMBNAILS_HIDE = '[Modals] thumbnails-hide',

  PROJECT_CRAWLS_SHOW = '[Modals] project-crawls-show',
  PROJECT_CRAWLS_HIDE = '[Modals] project-crawls-hide',

  PROJECT_MOVE_SHOW = '[Modals] project-move-show',
  PROJECT_MOVE_HIDE = '[Modals] project-move-hide',

  RESET_ALL = '[Modals] thumbnails-hide',
}

export interface IRequestResultModalPayload {
  type: RequestResultModalType | null;
  retryActions?: unknown[];
}

export interface ILoaderModalState {
  visible: boolean;
  message: LoaderModalMessageKeys;
}

export interface IRequestResultModalState extends Required<IRequestResultModalPayload> {
  visible: boolean;
}

export interface ModalsState {
  sider: {
    visible: boolean;
  };
  adminSider: {
    visible: boolean;
  };
  onboard: {
    visible: boolean;
  };
  profileSettings: {
    visible: boolean;
  };
  accountSettings: {
    visible: boolean;
  };
  userSettings: {
    visible: boolean;
  };
  annotation: {
    visible: boolean;
  };
  urlDetails: {
    visible: boolean;
  };
  networkDetails: {
    visible: boolean;
  };
  limit: {
    visible: boolean;
  };
  license: {
    visible: boolean;
  };
  thumbnails: {
    visible: boolean;
  };
  projectCrawls: {
    visible: boolean;
  };
  projectMove: {
    visible: boolean;
  };
  loader: ILoaderModalState;
  requestResult: IRequestResultModalState;
}

export enum ProjectsOverviewKeys {
  HeaderTitle = 'projects_overview_header_title',
  TableTitle = 'projects_overview_table_title',
  CreateButton = 'projects_overview_create_button_title',
  ProjectsCountIndicatorTitle = 'projects_overview_projects_count_indicator_title',
  UsersCountIndicatorTitle = 'projects_overview_users_count_indicator_title',
  DeleteConfirmMessage = 'projects_overview_delete_confirm_message',
  DeleteConfirmButtonTitle = 'projects_overview_delete_confirm_button_title',
  DeleteCancelButtonTitle = 'projects_overview_delete_cancel_button_title',
  RequestMoreResourcesTitle = 'projects_overview_request_more_resources_title',
}

export enum ProjectsOverviewFieldsKeys {
  ID = 'projects-overview_field_id_title',
  Name = 'projects-overview_field_name_title',
  Domain = 'projects-overview_field_domain_title',
  LastCrawled = 'projects-overview_last_crawled_title',
  Type = 'projects-overview_field_type_title',
  Frequency = 'projects-overview_field_frequency_title',
  Country = 'projects-overview_field_country_title',
  Keywords = 'projects-overview_field_keywords_title',
  Options = 'projects-overview_field_options_title',
  Created = 'projects-overview_field_created_title',
}

export enum RecrawlIntervalKeys {
  Daily = 'projects-overview_recrawl_interval_daily',
  MondayToFriday = 'projects-overview_recrawl_interval_monday_to_friday',
  Weekly = 'projects-overview_recrawl_interval_weekly',
  BiWeekly = 'projects-overview_recrawl_interval_bi_weekly',
  Monthly = 'projects-overview_recrawl_interval_monthly',
}

export enum ProjectTypesKeys {
  TestProject = 'project_form_test',
  LiveProject = 'project_form_live',
}
import { CoreWebVitalsValues } from '../../enums/coreWebVitals';
import { FeatureComparison } from '../../services/DataService/types';
import { EmptyObject } from '../../types/common.types';
import { ActionUnion, createAction } from '../commonTypes';
import { IUIPayload, VitalsActionTypes } from './types';

export const vitalsActions = {
  vitalsReload: (): { type: VitalsActionTypes, payload: EmptyObject } => createAction(
    VitalsActionTypes.VITALS_RELOAD
  ),
  vitalsRefresh: (payload: FeatureComparison): { type: VitalsActionTypes, payload: FeatureComparison } => createAction(
    VitalsActionTypes.VITALS_REFRESH,
    payload,
  ),
  quantifierRefresh: (payload: CoreWebVitalsValues | string): { type: VitalsActionTypes, payload: CoreWebVitalsValues | string } => createAction(
    VitalsActionTypes.QUANTIFIER_REFRESH,
    payload,
  ),
  comparatorRefresh: (payload: string): { type: VitalsActionTypes, payload: string } => createAction(
    VitalsActionTypes.COMPARATOR_REFRESH,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: VitalsActionTypes, payload: IUIPayload } => createAction(
    VitalsActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: VitalsActionTypes, payload: EmptyObject } => createAction(
    VitalsActionTypes.RESET_ALL,
  ),
}

export type VitalsActions = ActionUnion<typeof vitalsActions>;

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import i18next from '../../lib/i18next';
import * as StorageUtils from '../../utils/storage';

import { Languages } from '../../enums/locales/languages';
import { validateLanguage } from '../../utils/router';
import { appActions } from '../../redux/app/actions';
import { projectsActions } from '../../redux/projects/actions';
import { modalsActions } from '../../redux/modals/actions';
import { selectIsAppStarted, selectLanguage } from '../../redux/app/selectors';
import { selectIsLoggedIn, selectUser } from '../../redux/auth/selectors';
import { selectActualRoute, selectLangParam, selectPathPattern, selectQueryParams } from '../../redux/router/selectors';
import { selectActiveProjectID, selectActiveProjectGroupID, selectActiveProjectTestMode, selectProjects } from '../../redux/projects/selectors';
import { selectActiveLicenseID } from '../../redux/licenses/selectors';

import { Layout } from '../Layout';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Routes } from '../Routes';
import { LoaderModal } from '../modals/LoaderModal';
import { Sider } from '../modals/Sider';
import { AdminSider } from '../modals/AdminSider';
import { RequestResultModal } from '../modals/RequestResultModal';
import { UrlDetailsModal } from '../modals/UrlDetailsModal';
import { NetworkDetailsModal } from '../modals/NetworkDetailsModal';
import { ThumbnailsModal } from '../modals/ThumbnailsModal';
import { ProjectCrawlsModal } from '../modals/ProjectCrawlsModal';
import { ProjectMoveModal } from '../modals/ProjectMoveModal';
import { ROUTE_TITLES_MAP } from '../../constants/helmet';
import { ProfileSettings } from '../modals/ProfileSettings';
import { OnboardModal } from '../modals/OnboardModal';
import { compoundAnalysisActions } from '../../redux/compound-analysis/actions';
import { directoryActions } from '../../redux/directory-analysis/actions';
import { historyActions } from '../../redux/history/actions';
import { kpiActions } from '../../redux/kpi/actions';
import { kpiSummaryActions } from '../../redux/kpi-summary/actions';
import { leaderboardActions } from '../../redux/leaderboard/actions';
import { metricsActions } from '../../redux/metrics/actions';
import { overviewActions } from '../../redux/overview/actions';
import { projectsOverviewActions } from '../../redux/projects-overview/actions';
import { vitalsActions } from '../../redux/vitals/actions';
import { licensesActions } from '../../redux/licenses/actions';
import { selectGlobalLoading } from '../../redux/globalSelectors';
import { selectLoaderVisible } from '../../redux/modals/selectors';
import { useQueryParams } from '../../hooks/useQueryParams';
import { AccountSettings } from '../modals/AccountSettings';
import { UserSettings } from '../modals/UserSettings';
import { LimitModal } from '../modals/LimitModal';
import { LicenseModal } from '../modals/LicenseModal';
import { AnnotationModal } from '../modals/AnnotationModal';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAppStarted = useSelector(selectIsAppStarted);
  const currentLanguage = useSelector(selectLanguage);
  const currentLangParam = useSelector(selectLangParam);
  const currentPathPattern = useSelector(selectPathPattern);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const activeProjectID = useSelector(selectActiveProjectID);
  const activeProjectGroupID = useSelector(selectActiveProjectGroupID);
  const activeLicenseID = useSelector(selectActiveLicenseID);
  const actualRoute = useSelector(selectActualRoute);
  const testMode = useSelector(selectActiveProjectTestMode);
  const projects = useSelector(selectProjects);
  const user = useSelector(selectUser);
  const globalLoading = useSelector(selectGlobalLoading);
  const isLoaderVisible = useSelector(selectLoaderVisible);
  useQueryParams();

  const isProjectsEmpty = !projects.length;
  const storedTestMode = Boolean(StorageUtils.restoreTestMode());

  useEffect(() => {
    dispatch(modalsActions.loaderShow());
    dispatch(appActions.deviceIdentifierUpdate());
    dispatch(appActions.appStart());
  }, [dispatch]);

  useEffect(() => {
    if (isAppStarted && !globalLoading) {
      dispatch(modalsActions.loaderHide());
    }
  }, [dispatch, isAppStarted, globalLoading]);

  useEffect(() => {
    if (!isLoaderVisible && globalLoading) {
      dispatch(modalsActions.loaderShow());
    }
  }, [dispatch, isLoaderVisible, globalLoading]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(appActions.uiMerge({
        isTopBarVisible: true,
        isSideBarVisible: true,
      }));
      if (!isProjectsEmpty && !activeProjectID) {
        dispatch(projectsActions.activeProjectIDRestore());
      }
      if (activeProjectID && !activeProjectGroupID) {
        dispatch(projectsActions.activeProjectGroupIDRestore());
      }
    } else {
      dispatch(appActions.uiMerge({
        isTopBarVisible: false,
        isSideBarVisible: false,
        isFullscreen: false,
      }));
      dispatch(projectsActions.activeCrawlsCheckStop());
      dispatch(projectsActions.uiMerge({ restoringGroup: false, restoringProject: false }));
    }
  }, [dispatch, isLoggedIn, isProjectsEmpty, activeProjectID]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(projectsActions.uiMerge({ restoringGroup: true, restoringProject: true }));
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (activeLicenseID && isLoggedIn) {
      dispatch(projectsActions.resetAll());
      dispatch(projectsActions.listReload());
      dispatch(modalsActions.resetAll());
      dispatch(compoundAnalysisActions.resetAll());
      dispatch(directoryActions.resetAll());
      dispatch(historyActions.resetAll());
      dispatch(kpiActions.resetAll());
      dispatch(kpiSummaryActions.resetAll());
      dispatch(leaderboardActions.resetAll());
      dispatch(metricsActions.resetAll());
      dispatch(overviewActions.resetAll());
      dispatch(projectsOverviewActions.resetAll());
      dispatch(vitalsActions.resetAll());
    }
  }, [dispatch, activeLicenseID, isLoggedIn]);

  useEffect(() => {
    if (activeProjectID) {
      dispatch(projectsActions.activeProjectIDStore());
      dispatch(projectsActions.latestCrawlReload());
      dispatch(projectsActions.crawlsReload());
    }
  }, [dispatch, activeProjectID]);

  useEffect(() => {
    if (activeProjectGroupID) {
      dispatch(projectsActions.activeProjectGroupIDStore());
    }
  }, [dispatch, activeProjectGroupID]);

  useEffect(() => {
    if (activeLicenseID && user) {
      dispatch(licensesActions.activeLicenseIDStore());
    }
  }, [dispatch, activeLicenseID, user]);

  useEffect(() => {
    if (!isProjectsEmpty) {
      StorageUtils.storeTestMode(testMode);
    }
  }, [dispatch, testMode, isProjectsEmpty]);

  useEffect(() => {
    if (isProjectsEmpty) {
      dispatch(projectsActions.uiMerge({ testMode: storedTestMode }));
    } else {
      dispatch(projectsActions.uiMerge({ testMode }));
    }
  }, [dispatch, testMode, storedTestMode, isProjectsEmpty]);

  useEffect(() => {
    if (validateLanguage(currentLangParam as Languages)) {
      dispatch(appActions.languageRefresh(currentLangParam as Languages));
    }
  }, [dispatch, currentLangParam]);

  useEffect(() => {
    i18next.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  const currentPageTitle = useMemo(() => !!ROUTE_TITLES_MAP[actualRoute] ? t(ROUTE_TITLES_MAP[actualRoute].title, { title: t(ROUTE_TITLES_MAP[actualRoute].options.title) }) : 'Webexactly', [actualRoute, t]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{currentPageTitle}</title>
      </Helmet>
      {isLoggedIn && !globalLoading && <Breadcrumbs route={currentPathPattern} />}
      {isAppStarted && !globalLoading && <Routes />}
      <Sider />
      <AdminSider />
      <UserSettings />
      <AccountSettings />
      <ProfileSettings />
      <OnboardModal />
      <LoaderModal />
      <UrlDetailsModal />
      <NetworkDetailsModal />
      <ThumbnailsModal />
      <ProjectCrawlsModal />
      <ProjectMoveModal />
      <LicenseModal />
      <LimitModal />
      <AnnotationModal />
      <RequestResultModal />
    </Layout>
  );
};

export default App;

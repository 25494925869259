import { FieldLabelKeys, FieldValidationMessageKeys } from '../../../enums/locales/forms';
import yup from '../../../lib/yup';
import * as FieldValidators from '../fields';

export const signInSchema = yup.object().shape({
	email: FieldValidators.emailRequired(),
	password: FieldValidators.passwordRequired(),
});

export const resetPasswordSchema = yup.object().shape({
	email: FieldValidators.emailRequired(),
	newPassword: FieldValidators.passwordRequired(),
	confirmPassword: FieldValidators.passwordRequired().oneOf([yup.ref('newPassword'), null], { key: FieldValidationMessageKeys.PasswordConfirm }),
});

export const projectSchema = yup.object().shape({
	domain: FieldValidators.domainRequired(FieldLabelKeys.Domain),
	name: FieldValidators.stringRequired(FieldLabelKeys.ProjectName),
	country_code: FieldValidators.stringRequired(FieldLabelKeys.Country),
	strategy: FieldValidators.stringRequired(FieldLabelKeys.Strategy),
	recrawl_interval: FieldValidators.string(FieldLabelKeys.Recrawl).nullable(),
	benchmarks: FieldValidators.arrayOfBenchmarks(),
	keywords: FieldValidators.arrayRequired(FieldLabelKeys.Keywords),
	url_groups: FieldValidators.arrayObject(FieldLabelKeys.UrlGroups),
	goals: FieldValidators.arrayGoals(FieldLabelKeys.Goals),
	invites: FieldValidators.arrayEmail(FieldLabelKeys.Invites),
});

export const profileSchema = yup.object().shape({
	first_name: FieldValidators.stringRequired(FieldLabelKeys.FirstName),
	last_name: FieldValidators.stringRequired(FieldLabelKeys.LastName),
	email: FieldValidators.emailRequired(),
});

export const companySchema = yup.object().shape({
	name: FieldValidators.stringRequired(FieldLabelKeys.CompanyName),
	address: FieldValidators.stringRequired(FieldLabelKeys.Address),
	zip: FieldValidators.stringRequired(FieldLabelKeys.Zip),
	city: FieldValidators.stringRequired(FieldLabelKeys.City),
	country: FieldValidators.stringRequired(FieldLabelKeys.CompanyCountry),
	vat: FieldValidators.string(FieldLabelKeys.Vat).nullable(),
});

export const testProjectSchema = yup.object().shape({
	domain: FieldValidators.domainRequired(FieldLabelKeys.Domain),
	name: FieldValidators.stringRequired(FieldLabelKeys.ProjectName),
	strategy: FieldValidators.stringRequired(FieldLabelKeys.Strategy),
	recrawl_interval: FieldValidators.string(FieldLabelKeys.Recrawl).nullable(),
	urls: FieldValidators.arrayRequired(FieldLabelKeys.Keywords),
	url_groups: FieldValidators.arrayObject(FieldLabelKeys.UrlGroups),
	invites: FieldValidators.arrayEmail(FieldLabelKeys.Invites),
});

import { all, put, call, select, takeEvery, delay } from 'redux-saga/effects';

import { DataService } from '../../services/DataService';

import { Action } from '../commonTypes';
import { KpiActionTypes } from './types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IDomainKpiRequestBody, IDomainKpiResponseBody } from '../../services/DataService/types';
import { Feature } from '../../enums/data';

import { kpiActions } from './actions';
import { selectActiveProjectID, selectActiveProjectGroupID } from '../projects/selectors';
import { selectActiveFeatures } from './selectors';

function* kpiReloadAll(): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  const features: Feature[] = yield select(selectActiveFeatures);

  for (const feature of features) {
    yield put(kpiActions.kpiReload(feature));
  }
}

function* kpiReload(action: Action<KpiActionTypes.DOMAIN_KPI_RELOAD, Feature>): any {
  const { payload: feature } = action;

  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  const groupID = yield select(selectActiveProjectGroupID);

  yield put(kpiActions.featureLoadingMerge({ [feature]: true }));

  const requestBody: IDomainKpiRequestBody = {
    feature,
    compare_historical: false,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IDomainKpiResponseBody> = yield call(DataService.domainKpi, projectID, groupID, requestBody, options);
  if (!result?.data) {
    yield put(kpiActions.featureLoadingMerge({ [feature]: false }));
    return;
  }

  const response: IDomainKpiResponseBody = result?.data;

  const { data: rawData } = response;
  const data = { [feature]: rawData };

  yield put(kpiActions.kpiRefresh(data));
  yield put(kpiActions.featureLoadingMerge({ [feature]: false }));
}

export default function* kpiSaga() {
  yield all([
    takeEvery(KpiActionTypes.DOMAIN_KPI_RELOAD_ALL, kpiReloadAll),
    takeEvery(KpiActionTypes.DOMAIN_KPI_RELOAD, kpiReload),
  ]);
}

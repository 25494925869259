import { ILicense, ILicenseCompany } from '../../services/LicensesService/types';
import { ProfileSettingsActions } from './actions';
import {
  IProfilePersonal,
  ProfileSettingsActionTypes,
  ProfileSettingsState,
  IUIPayload,
} from './types';

const initState: ProfileSettingsState = {
  personal: {
    first_name: '',
    last_name: '',
    email: '',
  },
  company: {
    name: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    vat: '',
  },
  users: [],
  licenses: [],
  UI: {
    loading: false,
    editingLicenseID: '',
    editingUserID: '',
    personalLoading: false,
    companyLoading: false,
    passwordReseted: null,
    passwordReseting: false,
    licensesLoading: false,
    changeMessage: '',
  },
};

export function profileSettingsReducer(state: ProfileSettingsState = initState, action: ProfileSettingsActions): ProfileSettingsState {
  switch (action.type) {
    case ProfileSettingsActionTypes.PERSONAL_REFRESH: {
      return {
        ...state,
        personal: {
          ...state.personal,
          ...action.payload as IProfilePersonal,
        },
      }
    }
    case ProfileSettingsActionTypes.COMPANY_REFRESH: {
      return {
        ...state,
        company: {
          ...state.company,
          ...action.payload as ILicenseCompany,
        },
      }
    }
    case ProfileSettingsActionTypes.LICENSES_REFRESH: {
      return {
        ...state,
        licenses: action.payload as ILicense[],
      }
    }
    case ProfileSettingsActionTypes.USERS_REFRESH: {
      return {
        ...state,
        users: action.payload as string[],
      }
    }
    case ProfileSettingsActionTypes.RESET: {
      return initState;
    }
    case ProfileSettingsActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export enum NetworkRequestsAnalysisKeys {
  YourURLs = 'network_requests_analysis_your_urls',
  ShowDetails = 'network_requests_analysis_show_details',
  Requests = 'network_requests_analysis_requests',
  Thumbnails = 'network_requests_analysis_thumbnails',
  Filmstrip = 'network_requests_analysis_filmstrip',
  FinalScreenshot = 'network_requests_analysis_final_screenshot',
  LCPElement = 'network_requests_analysis_lcp_element',
  LoadingTimes = 'network_requests_analysis_loading_times',
  Export = 'network_requests_analysis_export',
  FilterUrls = 'network_requests_analysis_filter_urls',
  PageTitle = 'network_requests_analysis_page_title',
  QuantityTreeTitle = 'network_requests_quantity_tree_title',
  SizeTreeTitle = 'network_requests_size_tree_title',
  ResourceMonitorQuantityLabel = 'network_requests_recource_monitor_quantity_label',
  ResourceMonitorSizeLabel = 'network_requests_recource_monitor_size_label',
  ResourceMonitorTypeLabel = 'network_requests_recource_monitor_type_label',
}

export enum DirectoryAnalysisKeys {
  PageTitle = 'directory_analysis_page_title',
  ListTitle = 'directory_analysis_list_title',
  HeaderTitle = 'directory_analysis_header_title',
}

export enum NetworkRequestsAnalysisDataKeysTitle {
  ResourceType = 'network_requests_analysis_resource_type_title',
  StartTime = 'network_requests_analysis_start_time_title',
  EndTime = 'network_requests_analysis_end_time_title',
  Time = 'network_requests_analysis_time_title',
  ResourceSize = 'network_requests_analysis_resource_size_title',
  TransferSize = 'network_requests_analysis_transfer_size_title',
}

import { CompoundAnalysisUrl, ICompoundAnalysisNetworkDetail, ICompoundAnalysisThumbnails } from '../../services/DataService/types';
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { Feature, SortOrder } from '../../enums/data';
import { URLsDataKeys, NetworkDetailsDataKeys } from '../../enums/compoundAnalysis';

export enum CompoundAnalysisActionTypes {
  URLS_RELOAD = '[Compound-Analysis] urls-reload',
  URLS_REFRESH = '[Compound-Analysis] urls-refresh',

  ALL_URLS_RELOAD = '[Compound-Analysis] all-urls-reload',
  ALL_URLS_REFRESH = '[Compound-Analysis] all-urls-refresh',

  NETWORK_DETAILS_RELOAD = '[Compound-Analysis] network-details-reload',
  NETWORK_DETAILS_REFRESH = '[Compound-Analysis] network-details-refresh',

  THUMBNAILS_RELOAD = '[Compound-Analysis] thumbnails-reload',
  THUMBNAILS_REFRESH = '[Compound-Analysis] thumbnails-refresh',

  ACTIVE_URL_ID_REFRESH = '[Compound-Analysis] active-url-id-refresh',

  RESOURCE_TYPE_FILTER_REFRESH = '[Compound-Analysis] resource-type-filter-refresh',
  DISABLED_RESOURCE_TYPES_REFRESH = '[Compound-Analysis] disabled-resource-types-refresh',

  URL_SEARCH_REFRESH = '[Compound-Analysis] url-search-refresh',
  URLS_SORT_ORDER_REFRESH = '[Compound-Analysis] urls-sort-order-refresh',
  URLS_SORT_KEY_REFRESH = '[Compound-Analysis] urls-sort-key-refresh',

  NETWORK_DETAILS_SORT_ORDER_REFRESH = '[Compound-Analysis] network-details-sort-order-refresh',
  NETWORK_DETAILS_SORT_KEY_REFRESH = '[Compound-Analysis] network-details-sort-key-refresh',
  NETWORK_DETAILS_SORT_APPLY = '[Compound-Analysis] network-details-sort-apply',

  UI_MERGE = '[Compound-Analysis] ui-merge',

  RESET_ALL = '[Compound-Analysis] reset-all',
}

export interface IUIPayload {
  loading?: boolean;
  count?: number | null;
  limit?: ROWS_COUNTS;
  offset?: number;
  urlsLoading?: boolean;
  allUrlsLoading?: boolean;
  networkDetailsLoading?: boolean;
  thumbnailsLoading?: boolean;
}

export interface CompoundAnalysisState {
  urls: CompoundAnalysisUrl[];
  allUrls: CompoundAnalysisUrl[];
  networkDetails: ICompoundAnalysisNetworkDetail[];
  thumbnails: ICompoundAnalysisThumbnails,
  activeUrlID: string | null;
  resourceTypeFilter: string;
  disabledResourceTypes: string[];
  urlsSortOrder: SortOrder;
  urlsSortKey: URLsDataKeys | Feature;
  urlSearch: string;
  networkDetailsSortOrder: SortOrder;
  networkDetailsSortKey: NetworkDetailsDataKeys;
  UI: IUIPayload;
}

import { createSelector } from 'reselect';
import { RootState } from '../store';

const rankingCorrelationData = (state: RootState) => state.Metrics.rankingCorrelationData;
const urlGroupsData = (state: RootState) => state.Metrics.urlGroupsData;
const urlsData = (state: RootState) => state.Metrics.urlsData;
const urlsFullData = (state: RootState) => state.Metrics.urlsFullData;
const urlsSortOrder = (state: RootState) => state.Metrics.urlsSortOrder;
const urlsSortKey = (state: RootState) => state.Metrics.urlsSortKey;
const feature = (state: RootState) => state.Metrics.feature;
const urlGroupsQuantifier = (state: RootState) => state.Metrics.urlGroupsQuantifier;
const urlsQuantifier = (state: RootState) => state.Metrics.urlsQuantifier;
const urlDetails = (state: RootState) => state.Metrics.urlDetails;
const activeUrlID = (state: RootState) => state.Metrics.activeUrlID;
const urlSearch = (state: RootState) => state.Metrics.urlSearch;
const UI = (state: RootState) => state.Metrics.UI;

export const selectRankingCorrelationData = createSelector(
  [rankingCorrelationData],
  (rankingCorrelationData) => rankingCorrelationData,
);

export const selectUrlGroupsData = createSelector(
  [urlGroupsData],
  (urlGroupsData) => urlGroupsData,
);

export const selectUrlsData = createSelector(
  [urlsData],
  (urlsData) => urlsData,
);

export const selectActiveUrlID = createSelector(
  [activeUrlID],
  (activeUrlID) => activeUrlID,
);

export const selectActiveUrlData = createSelector(
  [urlsData, activeUrlID],
  (urlsData, activeUrlID) => urlsData.find(({ url_id }) => url_id === activeUrlID),
);

export const selectUrlDataByID = (id: string) => createSelector(
  [urlsData],
  (urlsData) => urlsData.find(({ url_id }) => url_id === id),
);

export const selectUrlsFullData = createSelector(
  [urlsFullData],
  (urlsFullData) => urlsFullData,
);

export const selectUrlsSortOrder = createSelector(
  [urlsSortOrder],
  (urlsSortOrder) => urlsSortOrder,
);

export const selectUrlsSortKey = createSelector(
  [urlsSortKey],
  (urlsSortKey) => urlsSortKey,
);


export const selectFeature = createSelector(
  [feature],
  (feature) => feature,
);

export const selectUrlGroupsQuantifier = createSelector(
  [urlGroupsQuantifier],
  (urlGroupsQuantifier) => urlGroupsQuantifier,
);

export const selectUrlsQuantifier = createSelector(
  [urlsQuantifier],
  (urlsQuantifier) => urlsQuantifier,
);

export const selectUrlDetails = createSelector(
  [urlDetails],
  (urlDetails) => urlDetails,
);

export const selectUrlSearch = createSelector(
  [urlSearch],
  (urlSearch) => urlSearch,
);

export const selectUI = createSelector(
  [UI],
  (UI) => UI,
);

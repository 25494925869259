import React, { useCallback, useMemo, useState } from 'react';

import { Input } from "../../../Input";
import { Checkbox } from "../../../Checkbox";
import { IUrlGroup } from '../../../../../redux/project-settings/types';
import { IconCheckmarkSmall, IconClose, IconDelete, IconEdit } from '../../../icons';
import clsx from 'clsx';

export interface EditableRowProps {
  cell: IUrlGroup;
  editKey: string;
  onCheck: (arg: string) => void;
  onEdit: (...args: any) => void;
  onCancel: (...args: any) => void;
  onSubmit: (arg: IUrlGroup) => void;
  onRowDelete: (arg: string) => void;
  checked: boolean;
}

const EditableRow: React.FC<EditableRowProps> = ({
  cell,
  onCheck,
  checked,
  onEdit,
  editKey,
  onCancel,
  onRowDelete,
  onSubmit,
}) => {
  const [changedItem, setChangedItem] = useState<IUrlGroup>({ regex: '', name: '' });

  const onChange = useCallback((value) => () => {
    onCheck(value);
  }, [onCheck]);

  const onDelete = useCallback((value) => () => {
    onRowDelete(value);
  }, [onRowDelete]);

  const onItemChange = useCallback((e) => {
    setChangedItem({ ...changedItem, [e.target.name]: e.target.value });
  }, [setChangedItem, changedItem]);

  const onItemSubmit = useCallback(() => {
    onSubmit({ ...changedItem });
    setChangedItem({ regex: '', name: '' });
  }, [onSubmit, setChangedItem, changedItem]);

  const onItemCancel = useCallback(() => {
    onCancel();
    setChangedItem({ regex: '', name: '' });
  }, [onSubmit, setChangedItem, changedItem]);

  const onClickEdit = useCallback(() => {
    setChangedItem({ ...cell });
    onEdit();
  }, [onEdit, setChangedItem]);

  const isEditting = !!editKey;
  const isCurrentEditting = editKey === cell.regex;

  const regex = isCurrentEditting ? <Input name="regex" onChange={onItemChange} value={changedItem.regex} /> : cell.regex;
  const name = isCurrentEditting ? <Input name="name" onChange={onItemChange} value={changedItem.name} /> : cell.name;

  const operations = useMemo(() => isCurrentEditting ? (
    <>
      <IconCheckmarkSmall onClick={onItemSubmit} />
      <IconClose id="cancel" onClick={onItemCancel} />
    </>
  ) : (
    <>
      <IconEdit className={clsx({ disabled: isEditting })} onClick={onClickEdit} />
      <IconDelete className={clsx({ disabled: isEditting })} onClick={onDelete(cell.regex)} />
    </>
  ), [isCurrentEditting, isEditting, onItemSubmit, onItemCancel, onClickEdit, onDelete]);

  return (
    <tr>
      <td>
        <Checkbox
          onChange={onChange(cell.regex)}
          checked={checked}
          small
        />
      </td>
      <td>
        {regex}
      </td>
      <td>
        {name}
      </td>
      <td>
        {operations}
      </td>
    </tr>
  );
};

export default EditableRow;
import { HistoryActions } from './actions';
import { HistoryActionTypes, HistoryState, HistoryPayload, IUIPayload } from './types';
import { DomainHistoryInterval, Feature } from '../../enums/data';

const initState: HistoryState = {
  currentFeature: Feature.PerformanceScore,
  currentHistoryInterval: DomainHistoryInterval.LastOneHundredAndEightyDays,
  data: {},
  UI: {
    showAnnotations: true,
    loading: false,
  },
};

export function historyReducer(state: HistoryState = initState, action: HistoryActions): HistoryState {
  switch (action.type) {
    case HistoryActionTypes.CURRENT_FEATURE_REFRESH: {
      return {
        ...state,
        currentFeature: action.payload as Feature,
      };
    }
    case HistoryActionTypes.CURRENT_HISTORY_INTERVAL_REFRESH: {
      return {
        ...state,
        currentHistoryInterval: action.payload as DomainHistoryInterval,
      };
    }
    case HistoryActionTypes.DOMAIN_HISTORY_REFRESH: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload as HistoryPayload,
        },
      };
    }
    case HistoryActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case HistoryActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

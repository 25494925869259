import styled from "styled-components";

import { IProps } from "./GroupList";

export const Wrapper = styled.div<Pick<IProps, 'error'>>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;

  .data-input {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .label {
      width: 25%;
      margin-bottom: 0!important;
      font-size: 14px!important;
    }

    .input {
      width: 75%;

      & > div {
        height: 42px;
        max-width: none;
      }
    }
  }

  button {
    margin-top: 10px;
    max-width: none;
    width: fit-content;
    padding: 0 30px;
    height: 38px;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 12px;
    margin-top: 34px;
    padding: 20px;
    border: 2px solid ${({ theme }) => theme.colors.grey};
    border-radius: 10px;

    .ant-checkbox-inner::after {
      left: 24%;
    }
  }
  
  .secondary {
    margin-top: 12px;
    margin-bottom: 0;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.red};
    font-size: 16px;

    .title {
      font-weight: 500!important;
    }

    .b {
      fill:  ${({ theme }) => theme.colors.red};
    }
  }
`;
import styled from "styled-components";

export const StyledWrapper = styled.div`
  > div {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 6px;
  }

  thead tr {
    height: 66px!important;
  }

  thead th,
  tbody tr td {
    padding: 0 12px!important;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      text-align: start!important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        cursor: pointer;
      }
    }
  }

  tbody tr {
    position: relative;
    background: #ffffff!important;
    border-top: 0!important;
    border-radius: 12px!important;
    box-shadow: 0px 2px 10px #0000000F!important;

    td:first-child {
      border-top-left-radius: 12px;
    }
    td:last-child {
      border-top-right-radius: 12px;
    }
    td:first-child {
      border-bottom-left-radius: 12px;
    }
    td:last-child {
      border-bottom-right-radius: 12px;
    }
    
    &.current {
      td {
        border-top: 3px solid #5468F0;
        border-bottom: 3px solid #5468F0;
      }
      td:first-child {
        border-left: 3px solid #5468F0;
      }
      td:last-child {
        border-right: 3px solid #5468F0;
      }
    }

    &.selected {
      td {
        border-top: 3px solid #5468F0;
        border-bottom: 3px solid #5468F0;
        background-color: #5468F0;
        color: #ffffff
      }
      td:first-child {
        border-left: 3px solid #5468F0;
      }
      td:last-child {
        border-right: 3px solid #5468F0;
      }
    }
  }

  tbody::after {
    box-shadow: none!important;
  }
`;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";

import { Button, ButtonType } from "../Button";
import { IconDelete, IconSearch } from "../icons";
import { Input } from "../Input";
import { Pagination } from "../../Pagination";
import { Checkbox } from "../Checkbox";
import { Tabs } from "../../Tabs";
import { ITabOption } from "../../Tabs/Tabs";

import { ButtonTextKeys } from "../../../enums/locales/forms";
import { ROWS_COUNTS } from "../../../enums/projectSettings";
import { TabsKeys } from "../../../enums/locales/modals";
import { IPagination } from "../../../types/common.types";

import { KeywordsSuggestions } from "./KeywordsSuggestions";
import { Wrapper } from './CheckableList.styled';

export interface IProps extends Partial<IPagination> {
  value?: string[];
  name?: string;
  error?: boolean;
  placeholder?: string;
  sufBtnText?: string;
  affBtnText?: string;
  tableTitle?: string;
  suggestions?: any[];
  loading?: boolean;
  keywordsCount?: number | null;
  searchPlaceholder?: string;
  headerTitle?: string;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
}

const CheckableList: React.FC<IProps> = ({
  value = [],
  placeholder = '',
  sufBtnText = '',
  tableTitle = '',
  affBtnText = '',
  suggestions = [],
  loading = false,
  keywordsCount = null,
  searchPlaceholder = '',
  headerTitle = '',
  offset: suggestionsOffset,
  limit: suggestionsLimit,
  onLimitChange: onSuggestionsLimit,
  onOffsetChange: onSuggestionsOffset,
  error,
  onChange = () => { },
  onBlur = () => { }
}) => {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [resetScroll, setResetScroll] = useState(false);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState<string[]>([]);
  const [suggestedItems, setSuggestedItems] = useState<string[]>([]);
  const [textareaValue, setTextareaValue] = useState('');
  const [checked, setChecked] = useState<string[]>([]);
  const [oldValue, setOldValue] = useState(value);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(ROWS_COUNTS.TwentyFive);
  const [activeTab, setActiveTab] = useState<TabsKeys>(TabsKeys.Manual);

  useEffect(() => {
    if (!isEqual(oldValue, value)) {
      onBlur();
      setOldValue(value);
    }
  }, [value, onBlur, oldValue]);

  useEffect(() => {
    if (keywordsCount === 0 && suggestions.length === 0 && activeTab === TabsKeys.Suggested) {
      setActiveTab(TabsKeys.Manual);
    }
  }, [keywordsCount, suggestions.length, activeTab]);

  const originalFilteredValue = useMemo(() => {
    return value.filter((item) => item.toLowerCase().includes(search)).sort();
  }, [search, value]);

  const filteredValue = useMemo(() => {
    const resValue = cloneDeep(originalFilteredValue);
    return resValue.splice(offset, limit);
  }, [originalFilteredValue, offset, limit]);

  useEffect(() => {
    const filtered = checked.filter((checkedItem) => filteredValue.includes(checkedItem));
    if (!isEqual(checked, filtered)) {
      setChecked(filtered);
    }
  }, [filteredValue, checked, setChecked]);

  const onSearch = useCallback((e) => {
    setSearch(e.target.value);
    setOffset(0);
  }, [setSearch, setOffset]);

  const onChangeTextarea = useCallback((e) => {
    const itemsToAdd: string[] = e.target.value.split('\n').filter(Boolean);
    const uniqItems = uniq(itemsToAdd.filter((itemToAdd) => !value.includes(itemToAdd)));

    setTextareaValue(e.target.value);
    setItems(uniqItems);
  }, [setItems, setTextareaValue, value]);

  const onCheckSuggestion = useCallback((newItems: string[]) => {
    const uniqItems = uniq(newItems.filter((newItem) => !value.includes(newItem)));
    setSuggestedItems(uniqItems);
  }, [setSuggestedItems, value]);

  const onAdd = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (activeTab === TabsKeys.Manual) {
      onChange([...value, ...items]);
      setTextareaValue('');
      setItems([]);
    }
    if (activeTab === TabsKeys.Suggested) {
      onChange([...value, ...suggestedItems]);
      setSuggestedItems([]);
    }
  }, [onChange, setItems, setTextareaValue, value, activeTab, setSuggestedItems, items, suggestedItems]);

  const onDelete = useCallback(() => {
    onChange(value.filter((item) => !checked.includes(item)));
    setChecked([]);
    setSearch('');
  }, [setChecked, checked, value, onChange]);

  const isAllChecked = useMemo(() => isEqual(filteredValue.sort(), checked.sort()), [filteredValue, checked]);

  const checkAll = useCallback(() => {
    if (isAllChecked) {
      setChecked([]);
      return;
    }
    setChecked(filteredValue);
  }, [setChecked, filteredValue, isAllChecked]);

  const onSingleCheck = useCallback((item) => () => {
    if (checked.includes(item)) {
      setChecked(checked.filter((checkedItem) => item !== checkedItem));
      return;
    }
    setChecked([...checked, item]);
  }, [checked, setChecked]);

  const onLimitChange = useCallback((newLimit) => {
    setLimit(newLimit);
    setOffset(0);

    const resetScrollValue = limit > newLimit;
    if (resetScrollValue) {
      setResetScroll(resetScrollValue);
    }
  }, [setOffset, setLimit, setResetScroll, limit]);

  const onOffsetChange = useCallback((newOffset) => {
    setOffset(newOffset);
  }, [setOffset]);

  const onTabChange = useCallback((key) => {
    setActiveTab(key);
  }, [setActiveTab]);

  useEffect(() => {
    if (resetScroll && headerRef.current) {
      headerRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      setResetScroll(false);
    }
  }, [resetScroll, headerRef.current]);

  const tabsOptions: ITabOption[] = useMemo(() => [
    { key: TabsKeys.Manual, value: value.length },
    { key: TabsKeys.Suggested, value: keywordsCount || 0 },
  ], [value.length, keywordsCount]);

  const isDisabled = !items.length;
  const isSuggestedDisabled = !suggestedItems.length;
  const showList = !!value.length;
  const showDeleteBtn = !!checked.length;
  const showTabs = !!keywordsCount;
  const isManual = activeTab === TabsKeys.Manual;
  const isAddDisabled = isManual ? isDisabled : isSuggestedDisabled;
  const addBtnText = `${t(sufBtnText)} ${(isManual ? items.length : suggestedItems.length) || ''} ${t(affBtnText)}`;

  return (
    <Wrapper error={error}>
      {showTabs && <Tabs
        selectedKey={activeTab}
        options={tabsOptions}
        onSelect={onTabChange}
      />}
      {isManual && (
        <div className="textarea-block">
          {placeholder && <div className="placeholder">{t(placeholder)}</div>}
          <textarea
            value={textareaValue}
            onChange={onChangeTextarea}
            rows={6}
          />
        </div>
      )}
      {!isManual && showTabs && (
        <KeywordsSuggestions
          total={keywordsCount}
          loading={loading}
          offset={suggestionsOffset}
          limit={suggestionsLimit}
          checked={suggestedItems}
          onCheckSuggestion={onCheckSuggestion}
          onLimitChange={onSuggestionsLimit}
          onOffsetChange={onSuggestionsOffset}
          list={suggestions}
        />
      )}
      <Button
        type={ButtonType.Primary}
        onClick={onAdd}
        disabled={isAddDisabled}
        title={addBtnText}
      />
      {showList && (
        <div ref={headerRef} className="table-list">
          <div className="table-list-header">
            <div className="header-title">{t(tableTitle)}<span>{value.length}</span></div>
            <div className="header-search">
              <Input
                className="search-input"
                value={search}
                onChange={onSearch}
                placeholder={`${t(searchPlaceholder)}...`}
                icon={<IconSearch />}
              />
            </div>
          </div>
          <div className="table-list-content">
            <div className="table-list-content-header">
              <Checkbox
                onChange={checkAll}
                checked={isAllChecked}
                small
                label={t(headerTitle)}
              />
            </div>
            <div className="divider"></div>
            <div className="table-list-content-items">
              {filteredValue.map((item, index) =>
                <div key={`${item}-${index}`} className={clsx('list-item', { 'item-checked': checked.includes(item) })}>
                  <Checkbox
                    checked={checked.includes(item)}
                    onChange={onSingleCheck(item)}
                    small
                    label={item}
                  />
                </div>
              )}
            </div>
            {showDeleteBtn && <Button
              type={ButtonType.Secondary}
              onClick={onDelete}
              icon={<IconDelete />}
              title={t(ButtonTextKeys.DeleteSelected)}
            />}
            <Pagination
              className="pagination"
              total={originalFilteredValue.length}
              offset={offset}
              limit={limit}
              onLimitChange={onLimitChange}
              onOffsetChange={onOffsetChange}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CheckableList;

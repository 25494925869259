import styled from 'styled-components';

export const StyledBodyWrapper = styled.div`
  max-height: 75vh;
  margin-right: 10px;
  padding: 30px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #171C2810 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #555BF0 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  width: 100%;

  .limit-modal-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryDark};
    margin-bottom: 30px;
  }

  .inputs-container {
    display: flex;
    align-items: center;
    margin-top: 10px;

    textarea {
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      min-height: 125px;
      border: 2px solid ${({ theme }) => theme.colors.grey};
      padding: 16px 20px;
      outline: none;
	    appearance: none;
      border-radius: 8px;
    }

    .react-datepicker-wrapper {
      width: 100%;

      input {
        text-align: center;
        width: 100%;
        height: 30px;
        border: 2px solid ${({ theme }) => theme.colors.grey};
        border-radius: 8px;
      }
    }
  }

  .update-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
`;

import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { NavigationMenuKeys } from '../../../../enums/locales/sideBar';
import { NavigationItem } from './NavigationItem';
import { StyledWrapper as Wrapper } from './NavigationGroup.styled';

export interface IProps {
  collapsed?: boolean;
  selectedKey?: NavigationMenuKeys | null;
  groupKey: NavigationMenuKeys | string;
  navKey?: NavigationMenuKeys | null;
  subKeys?: NavigationMenuKeys[];
  icon?: JSX.Element;
  onSelect?: (navKey: NavigationMenuKeys | null) => void;
}

const NavigationGroup: React.FC<IProps> = ({
  collapsed,
  selectedKey,
  groupKey,
  navKey,
  subKeys,
  icon,
  onSelect,
}) => {
  const { t } = useTranslation();

  const isGroupActive = selectedKey === navKey || subKeys?.includes(selectedKey as NavigationMenuKeys);

  const handleClickNavigationGroupTitle = useCallback(() => {
    const key = navKey || (subKeys && subKeys[0]) || null;
    onSelect && onSelect(key);
  }, [onSelect, navKey]);

  const getClickNavigationItemHandler = useCallback((key: NavigationMenuKeys) => () => {
    onSelect && onSelect(key);
  }, [onSelect]);

  return (
    <Wrapper className={clsx({ active: isGroupActive, subkeys: !!subKeys?.length, collapsed })}>
      <div
        data-group-title={t(groupKey)}
        className="navigation-group-title"
        onClick={handleClickNavigationGroupTitle}
      >
        <div className="navigation-group-icon">{icon}</div>
        <div className="navigation-group-name">{t(groupKey)}</div>
      </div>
      <div className="navigation-group-items">
        {subKeys?.map((itemNavKey) => (
          <NavigationItem
            key={itemNavKey}
            active={selectedKey === itemNavKey}
            navKey={itemNavKey}
            onClick={getClickNavigationItemHandler(itemNavKey)}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default NavigationGroup;

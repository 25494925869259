import { KpiActions } from './actions';
import { KpiActionTypes, KpiState, KpiPayload, FeatureLoadingPayload, IUIPayload } from './types';
import { Feature } from '../../enums/data';

const initState: KpiState = {
  activeFeatures: [],
  data: {},
  loading: {},
  UI: {
    loading: false,
  },
};

export function kpiReducer(state: KpiState = initState, action: KpiActions): KpiState {
  switch (action.type) {
    case KpiActionTypes.ACTIVE_FEATURES_REFRESH: {
      return {
        ...state,
        activeFeatures: [...action.payload as Feature[]],
      };
    }
    case KpiActionTypes.DOMAIN_KPI_REFRESH: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload as KpiPayload,
        },
      };
    }
    case KpiActionTypes.DOMAIN_KPI_RESET_ALL: {
      return {
        ...state,
        data: {},
      };
    }
    case KpiActionTypes.FEATURE_LOADING_MERGE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload as FeatureLoadingPayload,
        },
      };
    }
    case KpiActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case KpiActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

import { createSelector } from 'reselect';
import { RootState } from '../store';

const loader = (state: RootState) => state.Modals.loader;
const sider = (state: RootState) => state.Modals.sider;
const adminSider = (state: RootState) => state.Modals.adminSider;
const onboard = (state: RootState) => state.Modals.onboard;
const profileSettings = (state: RootState) => state.Modals.profileSettings;
const accountSettings = (state: RootState) => state.Modals.accountSettings;
const userSettings = (state: RootState) => state.Modals.userSettings;
const annotation = (state: RootState) => state.Modals.annotation;
const urlDetails = (state: RootState) => state.Modals.urlDetails;
const networkDetails = (state: RootState) => state.Modals.networkDetails;
const limit = (state: RootState) => state.Modals.limit;
const license = (state: RootState) => state.Modals.license;
const requestResult = (state: RootState) => state.Modals.requestResult;
const thumbnails = (state: RootState) => state.Modals.thumbnails;
const projectCrawls = (state: RootState) => state.Modals.projectCrawls;
const projectMove = (state: RootState) => state.Modals.projectMove;

export const selectLoaderVisible = createSelector(
	[loader],
	(loader) => loader.visible,
);

export const selectOnboardVisible = createSelector(
	[onboard],
	(onboard) => onboard.visible,
);

export const selectLoaderMessage = createSelector(
	[loader],
	(loader) => loader.message,
);

export const selectSiderVisible = createSelector(
	[sider],
	(sider) => sider.visible,
);

export const selectAdminSiderVisible = createSelector(
	[adminSider],
	(adminSider) => adminSider.visible,
);

export const selectProfileSettingsVisible = createSelector(
	[profileSettings],
	(profileSettings) => profileSettings.visible,
);

export const selectAccountSettingsVisible = createSelector(
	[accountSettings],
	(accountSettings) => accountSettings.visible,
);

export const selectUserSettingsVisible = createSelector(
	[userSettings],
	(userSettings) => userSettings.visible,
);

export const selectAnnotationVisible = createSelector(
	[annotation],
	(annotation) => annotation.visible,
);

export const selectUrlDetailsVisible = createSelector(
	[urlDetails],
	(urlDetails) => urlDetails.visible,
);

export const selectNetworkDetailsVisible = createSelector(
	[networkDetails],
	(networkDetails) => networkDetails.visible,
);

export const selectLimitVisible = createSelector(
	[limit],
	(limit) => limit.visible,
);

export const selectLicenseVisible = createSelector(
	[license],
	(license) => license.visible,
);

export const selectRequestResultVisible = createSelector(
	[requestResult],
	(requestResult) => requestResult.visible,
);

export const selectRequestResultType = createSelector(
	[requestResult],
	(requestResult) => requestResult.type,
);

export const selectRequestResultRetryActions = createSelector(
	[requestResult],
	(requestResult) => requestResult.retryActions,
);

export const selectThumbnailsVisible = createSelector(
	[thumbnails],
	(thumbnails) => thumbnails.visible,
);

export const selectProjectCrawlsVisible = createSelector(
	[projectCrawls],
	(projectCrawls) => projectCrawls.visible,
);

export const selectProjectMoveVisible = createSelector(
	[projectMove],
	(projectMove) => projectMove.visible,
);

import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import { sub, format } from 'date-fns';

import { sortHistoryData } from '../../utils/history';
import { DOMAIN_HISTORY_DATE_FORMAT } from '../../constants/formats';
import { DataService } from '../../services/DataService';

import { Action } from '../commonTypes';
import { SortOrder } from '../../enums/data';
import { HistoryActionTypes } from './types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IDomainHistoryRequestBody, IDomainHistoryResponseBody } from '../../services/DataService/types';
import { EmptyObject } from '../../types/common.types';

import { historyActions } from './actions';
import { selectActiveProjectID } from '../projects/selectors';
import { selectCurrentFeature, selectCurrentHistoryInterval } from './selectors';

function* historyReload(action: Action<HistoryActionTypes.DOMAIN_HISTORY_RELOAD, EmptyObject>): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  yield put(historyActions.uiMerge({ loading: true }));

  const currentFeature = yield select(selectCurrentFeature);
  const currentHistoryInterval = yield select(selectCurrentHistoryInterval);

  const requestBody: IDomainHistoryRequestBody = {
    feature: currentFeature,
    date_from: format(sub(new Date(), { days: currentHistoryInterval }), DOMAIN_HISTORY_DATE_FORMAT),
    date_to: format(new Date(), DOMAIN_HISTORY_DATE_FORMAT),
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IDomainHistoryResponseBody> = yield call(DataService.domainHistory, projectID, requestBody, options);
  if (!result?.data) {
    yield put(historyActions.uiMerge({ loading: false }));
    return;
  }

  const response: IDomainHistoryResponseBody = result?.data;

  const { data: rawData } = response;
  const sortedRawData = sortHistoryData(rawData, SortOrder.Asc);
  const data = { [currentFeature]: sortedRawData };

  yield put(historyActions.historyRefresh(data));
  yield put(historyActions.uiMerge({ loading: false }));
}

export default function* historySaga() {
  yield all([
    takeLatest(HistoryActionTypes.DOMAIN_HISTORY_RELOAD, historyReload),
  ]);
}

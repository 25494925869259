import styled from "styled-components";
import { THEME } from "../../../../constants/theme";
import { hexToRGBA } from "../../../../utils/theme";

const { colors } = THEME;

export const Wrapper = styled.div`
  padding: 0 20px;
  overflow: auto;

  .ant-steps-item {
    cursor: pointer!important;
    height: 38px;

    .ant-steps-icon {
      background: ${colors.white};
      border: 2px solid ${hexToRGBA(colors.black, 0.15)};
    }

    &.ant-steps-item-finish {
      .ant-steps-icon {
        background: ${colors.green};
        border: none;
      }
    }

    .ant-steps-item-content {
      min-height: 38px;
    }

    .ant-steps-item-container .ant-steps-item-tail {
      left: 11px;
      padding: 27px 0 3px;
      &::after {
        width: 2px;
      }
    }

    .ant-steps-item-icon {
      .ant-steps-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      width: 24px;
      height: 24px;
    }

    .ant-steps-item-title {
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      color: ${colors.secondaryDark}!important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${colors.darkGrey};
      }
    }
  }

  .ant-steps-icon {

    svg {
      height: 14px;
      width: 14px;
    }

    svg .b {
      stroke: ${colors.white};
      stroke-width: 3px;
    }
  }

  .ant-steps-item-tail::after {
    width: 2px;
    background: ${hexToRGBA(colors.black, 0.15)}!important;
  }
`;
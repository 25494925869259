import styled from "styled-components";

import { THEME } from "../../../constants/theme";
import { hexToRGBA } from "../../../utils/theme";

const { colors } = THEME;

export const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: ${({ visible }) => visible ? 'translate(0, 0)' : 'translate(100%, 0)'};
  background: ${({ visible }) => visible ? hexToRGBA(colors.sider.mask, colors.sider.maskOpacity) : hexToRGBA(colors.sider.mask, 0)};
  transition: ${({ visible }) => !visible ? 'background .5s, transform .1s .5s' : 'transform .1s, background .5s .1s'};
  
  .sider-body {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 70vw;

    @media (max-width: 1600px) {
      width: 75vw;
    }
    @media (max-width: 1440px) {
      width: 80vw;
    }
    @media (max-width: 1280px) {
      width: 90vw;
    }

    transform: ${({ visible }) => visible ? 'translate(0, 0)' : 'translate(100%, 0)'};
    background: white;
    transition: all .5s ease;

    .sider-header {
      display: flex;
      align-items: center;
      height: 100px;
      width: 100%;
      padding: 0 32px;
      background: ${colors.white};
      font-size: 28px;
      font-weight: 600;
      color: ${colors.primaryDark};
      box-shadow: 0px 3px 12px #0000000D;

      .btn-back {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background: ${hexToRGBA(colors.secondaryDark, 0.08)};
        margin-right: 30px;
        cursor: pointer;

        svg {
          transform: rotate(180deg);

          .b {
            stroke: ${colors.primaryDark};
          }
        }
      }
    }

    .sider-body-content {
      display: flex;

      .sider-body-content-form {
        position: relative;
        overflow: auto;
        scroll-behavior: smooth;
        width: 75%;
        height: calc(100vh - 100px);
        padding-left: 8.5%;
        padding-right: 1.6%;
        padding-top: 40px;
        padding-bottom: 60px;

        .security-container {
          .security-title {
            font-size: 20px;
            font-weight: bold;
            color: ${colors.primaryDark};
            margin-top: 60px;
          }

          .password-reset {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            max-width: 430px;
            margin-top: 10px;

            .password-reset-label {
              font-size: 16px;
              font-weight: 400;
            }

            .password-reset-button {
              .reset-info {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                max-width: 430px;
                color: ${colors.red};
                font-size: 12px;
                font-weight: 400;

                &.success {
                  color: ${colors.green};
                }
              }

              button {
                background: ${colors.white};
                color: ${colors.linkBlue};

                .title-wrapper {
                  justify-content: flex-end;
                }

                .title {
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 30px;
                  height: 20px;
                }
              }
            }
          }
        }

        .manage-user {
          .manage-user-title {
            font-size: 20px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.primaryDark};
            margin-bottom: 14px;
          }

          .manage-user-subtitle {
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.primaryDark};
            opacity: 0.8;
            margin-bottom: 40px;
          }
        }

        .avatar-container {
          display: flex;
          align-items: center;

          .avatar-image {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100px;
            height: 100px;

            .mask {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              transform: rotate(-90deg);
              transition: all .3s ease;
              width: 100px;
              height: 100px;
              top: 0;
              left: 0;
              background: ${hexToRGBA('#ffffff', 0.5)};
              opacity: 0;
            }

            &:hover .mask {
              opacity: 1;
            }
          }

          .description {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: #8c8c8c;
            margin-left: 30px;
            max-width: 300px;
          }
        }
      }

      .sider-body-content-meta {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: content-box;
        top: -130px;
        right: 0;
        width: 25%;
        height: calc(100vh - 130px);
        padding: 50px 3.77% 80px;

        .navigation {
          width: 100%;
          height: calc(100vh - 220px);
          display: flex;
          flex-direction: column;
        }

        .btn-container {
          width: 100%;
          margin-top: 40px;
          padding-left: 20px;
        }

        .switch-container {
            & > div {
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
            }
            margin-bottom: 10px;
          }

        .continue-btn {
          width: 100%;
          padding: 0 60px 0 0;
        }
      }
    }
  }
`;
import React from 'react';

import { IRadioItems } from '../components/styled/Radio/Radio';

import { Strategy, RecrawlInterval } from '../enums/projects';
import { RecrawlIntervalsKeys, StrategiesKeys } from "../enums/locales/forms";

import {
  IconMobile,
  IconDesktop,
} from '../components/styled/icons';

export const countriesCodes = [
  'US',
  'GB',
  'DE',
  'AT',
  'CH',
  'FR',
  'AR',
  'AU',
  'BE',
  'BR',
  'CA',
  'CL',
  'CN',
  'CO',
  'DK',
  'ES',
  'FI',
  'GR',
  'HK',
  'IN',
  'IE',
  'IT',
  'JP',
  'MX',
  'NL',
  'NZ',
  'NG',
  'NO',
  'PE',
  'PL',
  'PT',
  'RO',
  'RU',
  'SG',
];

export const strategyItems: IRadioItems[] = [
  {
    value: Strategy.Mobile,
    label: StrategiesKeys.Mobile,
    icon: <IconMobile />,
  },
  {
    value: Strategy.Desktop,
    label: StrategiesKeys.Desktop,
    icon: <IconDesktop />,
  },
];

export const recrawlsItems = [
  {
    value: null,
    label: RecrawlIntervalsKeys.OneTime,
  },
  {
    value: RecrawlInterval.Daily,
    label: RecrawlIntervalsKeys.Daily,
  },
  {
    value: RecrawlInterval.MondayToFriday,
    label: RecrawlIntervalsKeys.MondayToFriday,
  },
  {
    value: RecrawlInterval.Weekly,
    label: RecrawlIntervalsKeys.Weekly,
  },
  {
    value: RecrawlInterval.BiWeekly,
    label: RecrawlIntervalsKeys.BiWeekly,
  },
  {
    value: RecrawlInterval.Monthly,
    label: RecrawlIntervalsKeys.Monthly,
  },
];

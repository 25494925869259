import { ActionUnion, createAction } from '../commonTypes';
import {
  KpiSummaryActionTypes,
  IKpiSummaryDataRestrictionsPayload,
  KpiSummaryDataPayload,
  IKpiSummarySortChangePayload,
  IKpiSummarySortRefreshPayload,
} from './types';
import { EmptyObject } from '../../types/common.types';

export const kpiSummaryActions = {
  sortChange: (payload: IKpiSummarySortChangePayload): { type: KpiSummaryActionTypes, payload: IKpiSummarySortChangePayload } => createAction(
    KpiSummaryActionTypes.KPI_SUMMARY_SORT_CHANGE,
    payload,
  ),
  sortRefresh: (payload: IKpiSummarySortRefreshPayload): { type: KpiSummaryActionTypes, payload: IKpiSummarySortRefreshPayload } => createAction(
    KpiSummaryActionTypes.KPI_SUMMARY_SORT_REFRESH,
    payload,
  ),
  dataRestrictionsMerge: (payload: IKpiSummaryDataRestrictionsPayload): { type: KpiSummaryActionTypes, payload: IKpiSummaryDataRestrictionsPayload } => createAction(
    KpiSummaryActionTypes.KPI_SUMMARY_DATA_RESTRICTIONS_MERGE,
    payload,
  ),
  dataUpdate: (): { type: KpiSummaryActionTypes, payload: EmptyObject } => createAction(
    KpiSummaryActionTypes.KPI_SUMMARY_DATA_UPDATE,
  ),
  dataRefresh: (payload: KpiSummaryDataPayload): { type: KpiSummaryActionTypes, payload: KpiSummaryDataPayload } => createAction(
    KpiSummaryActionTypes.KPI_SUMMARY_DATA_REFRESH,
    payload,
  ),
  resetAll: (): { type: KpiSummaryActionTypes, payload: EmptyObject } => createAction(
    KpiSummaryActionTypes.RESET_ALL,
  ),
}

export type KpiSummaryActions = ActionUnion<typeof kpiSummaryActions>;

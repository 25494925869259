import { AccountDetailsActions } from './actions';
import { AccountDetailsActionTypes, AccountDetailsState, IUIPayload } from './types';
import { IProject, ICrawl } from '../../services/ProjectService/types';
import { ILicense } from '../../services/LicensesService/types';
import { IUserSimple } from '../../services/AuthService/types';
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { ProjectsDataKeys, CrawlsDataKeys } from '../../enums/projects';
import { SortOrder } from '../../enums/data';

const initState: AccountDetailsState = {
  accountLicenseID: null,
  targetLicenseID: null,
  projectID: null,
  accounts: [],
  creator: {},
  license: {},
  projects: [],
  crawls: [],
  accountsSearch: '',
  projectsSearch: '',
  projectsSortOrder: SortOrder.Desc,
  projectsSortKey: ProjectsDataKeys.CreatedAt,
  crawlsSearch: '',
  crawlsSortOrder: SortOrder.Desc,
  crawlsSortKey: CrawlsDataKeys.CreatedAt,
  UI: {
    accountsLoading: false,
    creatorLoading: false,
    licenseLoading: false,
    projectsLoading: false,
    crawlsLoading: false,
    projectRemoving: false,
    crawlRemoving: false,
    offset: 0,
    limit: ROWS_COUNTS.TwentyFive,
    count: null,
  },
};

export function accountDetailsReducer(state: AccountDetailsState = initState, action: AccountDetailsActions): AccountDetailsState {
  switch (action.type) {
    case AccountDetailsActionTypes.ACCOUNT_LICENSE_ID_REFRESH: {
      return {
        ...state,
        accountLicenseID: action.payload as string | null,
      };
    }
    case AccountDetailsActionTypes.TARGET_LICENSE_ID_REFRESH: {
      return {
        ...state,
        targetLicenseID: action.payload as string | null,
      };
    }
    case AccountDetailsActionTypes.PROJECT_ID_REFRESH: {
      return {
        ...state,
        projectID: action.payload as string | null,
      };
    }
    case AccountDetailsActionTypes.CREATOR_DATA_REFRESH: {
      return {
        ...state,
        creator: { ...action.payload as IUserSimple },
      };
    }
    case AccountDetailsActionTypes.LICENSE_DATA_REFRESH: {
      return {
        ...state,
        license: { ...action.payload as ILicense },
      };
    }
    case AccountDetailsActionTypes.ACCOUNTS_DATA_REFRESH: {
      return {
        ...state,
        accounts: [...action.payload as ILicense[]],
      };
    }
    case AccountDetailsActionTypes.PROJECTS_DATA_REFRESH: {
      return {
        ...state,
        projects: [...action.payload as IProject[]],
      };
    }
    case AccountDetailsActionTypes.CRAWLS_DATA_REFRESH: {
      return {
        ...state,
        crawls: [...action.payload as ICrawl[]],
      };
    }
    case AccountDetailsActionTypes.ACCOUNTS_SEARCH_REFRESH: {
      return {
        ...state,
        accountsSearch: action.payload as string,
      };
    }
    case AccountDetailsActionTypes.PROJECTS_SEARCH_REFRESH: {
      return {
        ...state,
        projectsSearch: action.payload as string,
      };
    }
    case AccountDetailsActionTypes.PROJECTS_SORT_ORDER_REFRESH: {
      return {
        ...state,
        projectsSortOrder: action.payload as SortOrder,
      };
    }
    case AccountDetailsActionTypes.PROJECTS_SORT_KEY_REFRESH: {
      return {
        ...state,
        projectsSortKey: action.payload as ProjectsDataKeys,
      };
    }
    case AccountDetailsActionTypes.CRAWLS_SEARCH_REFRESH: {
      return {
        ...state,
        crawlsSearch: action.payload as string,
      };
    }
    case AccountDetailsActionTypes.CRAWLS_SORT_ORDER_REFRESH: {
      return {
        ...state,
        crawlsSortOrder: action.payload as SortOrder,
      };
    }
    case AccountDetailsActionTypes.CRAWLS_SORT_KEY_REFRESH: {
      return {
        ...state,
        crawlsSortKey: action.payload as CrawlsDataKeys,
      };
    }
    case AccountDetailsActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case AccountDetailsActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

import { takeLatest, takeEvery, all, call, put, select } from 'redux-saga/effects';

import { UsersActionTypes } from './types'
import { Action } from '../commonTypes';
import { EmptyObject } from '../../types/common.types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { usersActions } from './actions';
import { AdminService } from '../../services/AdminService';
import { IAdminLicenseRequestBody, IAdminLicenseResponseBody, IAdminUserResponseBody } from '../../services/AdminService/types';
import { selectUI, selectUsersSearch, selectUsersSortOrder, selectUsersSortKey } from './selectors';

function* usersReload(action: Action<UsersActionTypes.USERS_RELOAD, EmptyObject>): any {
  yield put(usersActions.uiMerge({ loading: true }));
  const usersSearch = yield select(selectUsersSearch);
  const sortKey = yield select(selectUsersSortKey);
  const sortOrder = yield select(selectUsersSortOrder);
  const { offset, limit } = yield select(selectUI);

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestBody: IAdminLicenseRequestBody = {
    filter: usersSearch,
    offset,
    limit,
    sort: sortKey,
    sort_direction: sortOrder,
  }

  const result: IBaseResponse<IAdminUserResponseBody> = yield call(AdminService.getUsers, requestBody, options);
  if (!result?.data) {
    yield put(usersActions.uiMerge({ loading: false }));
    return;
  }

  const response: IAdminUserResponseBody = result?.data;
  const userList = response.users;

  yield put(usersActions.usersRefresh(userList));
  yield put(usersActions.uiMerge({ loading: false, count: response.count }));
}

function* usersDelete(action: Action<UsersActionTypes.USER_DELETE, string>): any {
  yield put(usersActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IAdminLicenseResponseBody> = yield call(AdminService.deleteUser, action.payload, options);
  if (!result) {
    yield put(usersActions.uiMerge({ loading: false }));
    return;
  }

  yield put(usersActions.usersReload());
}

export default function* usersSaga() {
  yield all([
    takeLatest(UsersActionTypes.USERS_RELOAD, usersReload),
    takeEvery(UsersActionTypes.USER_DELETE, usersDelete),
  ]);
}

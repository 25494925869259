import { ActionUnion, createAction } from '../commonTypes';
import { ProjectsActionTypes, ProjectsListPayload, ProjectRemovePayload, ActiveProjectIDPayload, ActiveProjectGroupIDPayload, LatestCrawlPayload, IUIPayload, CrawlsPayload, ICrawlsInProgressPayload } from './types';
import { EmptyObject } from '../../types/common.types';
import { IAnnotation } from '../../services/ProjectService/types';

export const projectsActions = {
  listReload: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.LIST_RELOAD,
  ),
  listRefresh: (payload: ProjectsListPayload): { type: ProjectsActionTypes, payload: ProjectsListPayload } => createAction(
    ProjectsActionTypes.LIST_REFRESH,
    payload,
  ),
  projectRemove: (payload: ProjectRemovePayload): { type: ProjectsActionTypes, payload: ProjectRemovePayload } => createAction(
    ProjectsActionTypes.PROJECT_REMOVE,
    payload,
  ),
  annotationsUpdate: (payload: IAnnotation[]): { type: ProjectsActionTypes, payload: IAnnotation[] } => createAction(
    ProjectsActionTypes.ANNOTATIONS_UPDATE,
    payload,
  ),
  latestCrawlReload: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.LATEST_CRAWL_RELOAD,
  ),
  latestCrawlRefresh: (payload: LatestCrawlPayload): { type: ProjectsActionTypes, payload: LatestCrawlPayload } => createAction(
    ProjectsActionTypes.LATEST_CRAWL_REFRESH,
    payload,
  ),
  crawlsReload: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.CRAWLS_RELOAD,
  ),
  crawlsRefresh: (payload: CrawlsPayload): { type: ProjectsActionTypes, payload: CrawlsPayload } => createAction(
    ProjectsActionTypes.CRAWLS_REFRESH,
    payload,
  ),
  activeCrawlsInit: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_CRAWLS_INIT,
  ),
  activeCrawlsCheckStart: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_CRAWLS_CHECK_START,
  ),
  activeCrawlsCheckStop: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_CRAWLS_CHECK_STOP,
  ),
  activeProjectIDStore: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_ID_STORE,
  ),
  activeProjectIDRestore: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_ID_RESTORE,
  ),
  activeProjectGroupIDStore: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_STORE,
  ),
  activeProjectGroupIDRestore: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_RESTORE,
  ),
  activeProjectIDRefresh: (payload: ActiveProjectIDPayload): { type: ProjectsActionTypes, payload: ActiveProjectIDPayload } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_ID_REFRESH,
    payload,
  ),
  activeProjectIDReset: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_ID_RESET,
  ),
  activeProjectGroupIDRefresh: (payload: ActiveProjectGroupIDPayload): { type: ProjectsActionTypes, payload: ActiveProjectGroupIDPayload } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_REFRESH,
    payload,
  ),
  activeProjectGroupIDReset: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_RESET,
  ),
  crawlsInProgressMerge: (payload: ICrawlsInProgressPayload): { type: ProjectsActionTypes, payload: ICrawlsInProgressPayload } => createAction(
    ProjectsActionTypes.CRAWLS_IN_PROGRESS_MERGE,
    payload,
  ),
  crawlsInProgressReset: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.CRAWLS_IN_PROGRESS_RESET,
  ),
  uiMerge: (payload: IUIPayload): { type: ProjectsActionTypes, payload: IUIPayload } => createAction(
    ProjectsActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: ProjectsActionTypes, payload: EmptyObject } => createAction(
    ProjectsActionTypes.RESET_ALL,
  ),
}

export type ProjectsActions = ActionUnion<typeof projectsActions>;

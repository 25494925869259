import { takeLatest, all, put, call, takeEvery } from 'redux-saga/effects';

import * as LocaleStorageUtils from '../../utils/storage';
import { AuthService } from '../../services/AuthService';

import { Action } from '../commonTypes';
import { AuthActionTypes, IGoogleSignInPayload, IPasswordSignInPayload, IRefreshTokensOptionsPayload } from './types';
import { IBaseResponse, IBaseConfig, IBasicHeaders } from '../../services/BaseAPIService/types';
import { IAuthToken, IUser, IRefreshTokensOptions } from '../../services/AuthService/types';
import { EmptyObject } from '../../types/common.types';
import { LoaderModalMessageKeys } from '../../enums/locales/modals';
import { authActions } from './actions';
import { appActions } from '../app/actions';
import { modalsActions } from '../modals/actions';
import { licensesActions } from '../licenses/actions';
import { compoundAnalysisActions } from '../compound-analysis/actions';
import { directoryActions } from '../directory-analysis/actions';
import { historyActions } from '../history/actions';
import { kpiActions } from '../kpi/actions';
import { kpiSummaryActions } from '../kpi-summary/actions';
import { leaderboardActions } from '../leaderboard/actions';
import { metricsActions } from '../metrics/actions';
import { overviewActions } from '../overview/actions';
import { projectsActions } from '../projects/actions';
import { projectsOverviewActions } from '../projects-overview/actions';
import { vitalsActions } from '../vitals/actions';

function* signInWithGoogle(action: Action<AuthActionTypes.SIGN_IN_WITH_GOOGLE, IGoogleSignInPayload>): any {
  yield put(modalsActions.loaderShow(LoaderModalMessageKeys.Auth));
  yield put(authActions.uiMerge({ loading: true }));

  const { payload } = action;

  const result: IBaseResponse<IAuthToken> = yield call(AuthService.signInWithGoogle, payload);
  if (!result?.data) {
    yield put(authActions.uiMerge({ loading: false }));
    return;
  }

  const { token, refresh_token } = result?.data?.auth_token;

  yield call(LocaleStorageUtils.storeTokens, token, refresh_token);
  yield put(authActions.userReload());
  yield put(licensesActions.licensesReload());
}

function* signInWithPassword(action: Action<AuthActionTypes.SIGN_IN_WITH_PASSWORD, IPasswordSignInPayload>): any {
  yield put(modalsActions.loaderShow(LoaderModalMessageKeys.Auth));
  yield put(authActions.uiMerge({ loading: true }));

  const { payload } = action;

  const result: IBaseResponse<IAuthToken> = yield call(AuthService.signInWithPassword, payload);
  if (!result?.data) {
    yield put(authActions.uiMerge({ loading: false }));
    return;
  }

  const { token, refresh_token } = result?.data?.auth_token;

  yield call(LocaleStorageUtils.storeTokens, token, refresh_token);
  yield put(authActions.userReload());
  yield put(licensesActions.licensesReload());
}

function* userReload(action: Action<AuthActionTypes.USER_RELOAD, EmptyObject>): any {
  yield put(authActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IUser> = yield call(AuthService.getUser, options);
  if (!result?.data) {
    yield put(appActions.appStarted());
    yield put(authActions.uiMerge({ loading: false }));
    return;
  }

  const { user } = result?.data;

  yield put(authActions.userRefresh({ ...user }));
  yield put(appActions.appStarted());
  yield put(authActions.uiMerge({ loading: false }));
}

function* tokensRefresh(action: Action<AuthActionTypes.TOKENS_REFRESH, IRefreshTokensOptionsPayload>): any {
  yield put(authActions.uiMerge({ loading: true }));

  const headers: IBasicHeaders = {};

  const refreshToken = yield call(LocaleStorageUtils.restoreRefreshToken);

  if (refreshToken) {
    headers.Authorization = `Bearer ${refreshToken}`;
  }

  const config: IBaseConfig = { headers };

  const options: IRefreshTokensOptions = {
    config,
  };

  const result: IBaseResponse<IAuthToken> = yield call(AuthService.refreshTokens, options);
  if (!result?.data) {
    yield put(authActions.uiMerge({ loading: false }));
    return;
  }

  const { token, refresh_token } = result?.data?.auth_token;

  yield call(LocaleStorageUtils.storeTokens, token, refresh_token);
  yield put(authActions.uiMerge({ loading: false }));

  yield call(AuthService.retryAllActions);
}

function* signOut(): any {
  yield call(LocaleStorageUtils.clearAccessToken);
  yield put(authActions.userReset());
  yield put(modalsActions.resetAll());
  yield put(compoundAnalysisActions.resetAll());
  yield put(directoryActions.resetAll());
  yield put(historyActions.resetAll());
  yield put(kpiActions.resetAll());
  yield put(kpiSummaryActions.resetAll());
  yield put(leaderboardActions.resetAll());
  yield put(metricsActions.resetAll());
  yield put(overviewActions.resetAll());
  yield put(projectsActions.resetAll());
  yield put(projectsOverviewActions.resetAll());
  yield put(vitalsActions.resetAll());
  yield put(licensesActions.resetAll());
  yield put(projectsActions.uiMerge({ restoringProject: false, restoringGroup: false }));
}

export default function* authSaga() {
  yield all([
    takeLatest(AuthActionTypes.SIGN_IN_WITH_GOOGLE, signInWithGoogle),
    takeLatest(AuthActionTypes.SIGN_IN_WITH_PASSWORD, signInWithPassword),
    takeLatest(AuthActionTypes.USER_RELOAD, userReload),
    takeLatest(AuthActionTypes.TOKENS_REFRESH, tokensRefresh),
    takeLatest(AuthActionTypes.SIGN_OUT, signOut),
  ]);
}

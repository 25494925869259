import { ActionUnion, createAction } from '../commonTypes';
import { CompoundAnalysisActionTypes, IUIPayload } from './types';
import { CompoundAnalysisUrl, ICompoundAnalysisNetworkDetail, ICompoundAnalysisThumbnails } from "../../services/DataService/types";
import { EmptyObject } from '../../types/common.types';
import { Feature, SortOrder } from '../../enums/data';
import { URLsDataKeys, NetworkDetailsDataKeys } from '../../enums/compoundAnalysis';

export const compoundAnalysisActions = {
  urlsReload: (): { type: CompoundAnalysisActionTypes, payload: EmptyObject } => createAction(
    CompoundAnalysisActionTypes.URLS_RELOAD,
  ),
  urlsRefresh: (payload: CompoundAnalysisUrl[]): { type: CompoundAnalysisActionTypes, payload: CompoundAnalysisUrl[] } => createAction(
    CompoundAnalysisActionTypes.URLS_REFRESH,
    payload,
  ),
  allUrlsReload: (): { type: CompoundAnalysisActionTypes, payload: EmptyObject } => createAction(
    CompoundAnalysisActionTypes.ALL_URLS_RELOAD,
  ),
  allUrlsRefresh: (payload: CompoundAnalysisUrl[]): { type: CompoundAnalysisActionTypes, payload: CompoundAnalysisUrl[] } => createAction(
    CompoundAnalysisActionTypes.ALL_URLS_REFRESH,
    payload,
  ),
  networkDetailsReload: (): { type: CompoundAnalysisActionTypes, payload: EmptyObject } => createAction(
    CompoundAnalysisActionTypes.NETWORK_DETAILS_RELOAD,
  ),
  networkDetailsRefresh: (payload: ICompoundAnalysisNetworkDetail[]): { type: CompoundAnalysisActionTypes, payload: ICompoundAnalysisNetworkDetail[] } => createAction(
    CompoundAnalysisActionTypes.NETWORK_DETAILS_REFRESH,
    payload,
  ),
  thumbnailsReload: (): { type: CompoundAnalysisActionTypes, payload: EmptyObject } => createAction(
    CompoundAnalysisActionTypes.THUMBNAILS_RELOAD,
  ),
  thumbnailsRefresh: (payload: ICompoundAnalysisThumbnails): { type: CompoundAnalysisActionTypes, payload: ICompoundAnalysisThumbnails } => createAction(
    CompoundAnalysisActionTypes.THUMBNAILS_REFRESH,
    payload,
  ),
  activeUrlIDRefresh: (payload: string | null): { type: CompoundAnalysisActionTypes, payload: string | null } => createAction(
    CompoundAnalysisActionTypes.ACTIVE_URL_ID_REFRESH,
    payload,
  ),
  resourceTypeFilterRefresh: (payload: string): { type: CompoundAnalysisActionTypes, payload: string } => createAction(
    CompoundAnalysisActionTypes.RESOURCE_TYPE_FILTER_REFRESH,
    payload,
  ),
  disabledResourceTypesRefresh: (payload: string[]): { type: CompoundAnalysisActionTypes, payload: string[] } => createAction(
    CompoundAnalysisActionTypes.DISABLED_RESOURCE_TYPES_REFRESH,
    payload,
  ),
  urlSearchRefresh: (payload: string): { type: CompoundAnalysisActionTypes, payload: string } => createAction(
    CompoundAnalysisActionTypes.URL_SEARCH_REFRESH,
    payload,
  ),
  urlsSortOrderRefresh: (payload: SortOrder): { type: CompoundAnalysisActionTypes, payload: SortOrder } => createAction(
    CompoundAnalysisActionTypes.URLS_SORT_ORDER_REFRESH,
    payload,
  ),
  urlsSortKeyRefresh: (payload: URLsDataKeys | Feature): { type: CompoundAnalysisActionTypes, payload: URLsDataKeys | Feature } => createAction(
    CompoundAnalysisActionTypes.URLS_SORT_KEY_REFRESH,
    payload,
  ),
  networkDetailsSortOrderRefresh: (payload: SortOrder): { type: CompoundAnalysisActionTypes, payload: SortOrder } => createAction(
    CompoundAnalysisActionTypes.NETWORK_DETAILS_SORT_ORDER_REFRESH,
    payload,
  ),
  networkDetailsSortKeyRefresh: (payload: NetworkDetailsDataKeys): { type: CompoundAnalysisActionTypes, payload: NetworkDetailsDataKeys } => createAction(
    CompoundAnalysisActionTypes.NETWORK_DETAILS_SORT_KEY_REFRESH,
    payload,
  ),
  networkDetailsSortApply: (): { type: CompoundAnalysisActionTypes, payload: EmptyObject } => createAction(
    CompoundAnalysisActionTypes.NETWORK_DETAILS_SORT_APPLY,
  ),
  uiMerge: (payload: IUIPayload): { type: CompoundAnalysisActionTypes, payload: IUIPayload } => createAction(
    CompoundAnalysisActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: CompoundAnalysisActionTypes, payload: EmptyObject } => createAction(
    CompoundAnalysisActionTypes.RESET_ALL,
  ),
}

export type CompoundAnalysisActions = ActionUnion<typeof compoundAnalysisActions>;

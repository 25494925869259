import { ActionUnion, createAction } from '../commonTypes';
import { KpiActionTypes, KpiPayload, FeatureLoadingPayload, IUIPayload } from './types';
import { EmptyObject } from '../../types/common.types';
import { Feature } from '../../enums/data';

export const kpiActions = {
  kpiReloadAll: (): { type: KpiActionTypes, payload: EmptyObject } => createAction(
    KpiActionTypes.DOMAIN_KPI_RELOAD_ALL,
  ),
  kpiResetAll: (): { type: KpiActionTypes, payload: EmptyObject } => createAction(
    KpiActionTypes.DOMAIN_KPI_RESET_ALL,
  ),
  kpiReload: (payload: Feature): { type: KpiActionTypes, payload: Feature } => createAction(
    KpiActionTypes.DOMAIN_KPI_RELOAD,
    payload,
  ),
  kpiRefresh: (payload: KpiPayload): { type: KpiActionTypes, payload: KpiPayload } => createAction(
    KpiActionTypes.DOMAIN_KPI_REFRESH,
    payload,
  ),
  activeFeaturesRefresh: (payload: Feature[]): { type: KpiActionTypes, payload: Feature[] } => createAction(
    KpiActionTypes.ACTIVE_FEATURES_REFRESH,
    payload,
  ),
  featureLoadingMerge: (payload: FeatureLoadingPayload): { type: KpiActionTypes, payload: FeatureLoadingPayload } => createAction(
    KpiActionTypes.FEATURE_LOADING_MERGE,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: KpiActionTypes, payload: IUIPayload } => createAction(
    KpiActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: KpiActionTypes, payload: EmptyObject } => createAction(
    KpiActionTypes.RESET_ALL,
  ),
}

export type KpiActions = ActionUnion<typeof kpiActions>;

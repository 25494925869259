import i18next, { InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import ChainedBackend from 'i18next-chained-backend';
// import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from "react-i18next";

import { localesNameSpace } from '../../config';
// import { I18NEXT_LOCALESTORAGE_PREFIX, I18NEXT_LOCALESTORAGE_CACHE_EXPIRATION_TIME } from '../../constants/locales';

const loadPath = `/locales/{{lng}}/${localesNameSpace}.json`;

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    ns: [localesNameSpace],
    defaultNS: localesNameSpace,

    supportedLngs: ["de","en"],
    
    backend: {
      backends: [
        // LocalStorageBackend,
        HttpBackend
      ],
      backendOptions: [
        // {
        //   prefix: I18NEXT_LOCALESTORAGE_PREFIX,
        //   expirationTime: I18NEXT_LOCALESTORAGE_CACHE_EXPIRATION_TIME,
        // },
        {
          loadPath,
        }
      ],
    },

    react: {
      useSuspense: false,
    },
  } as InitOptions,
);

export default i18next;

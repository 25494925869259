import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RecrawlInterval } from "../enums/projects";
import { selectAccountLicenseCounts } from "../redux/account-details/selectors";
import { selectActiveLisenseCounts } from "../redux/licenses/selectors";
import { selectKeywordsCount, selectRecrawlInterval, selectUI, selectUrlsCount } from "../redux/project-settings/selectors";
import { getCrawlFrequencyMultiplier } from "../utils/resources";

export const useResourceLimits = (isAdmin: boolean) => {
  const { keywordsInitCount = 0, urlsInitCount = 0, initRecrawlInterval, isNew } = useSelector(selectUI);
  const keywordsCount = useSelector(selectKeywordsCount);
  const urlsCount = useSelector(selectUrlsCount);
  const recrawlInterval = useSelector(selectRecrawlInterval);
  const {
    user_slots,
    user_slots_used,
    url_slots,
    url_slots_used,
    project_slots,
    project_slots_used,
    keyword_slots,
    keyword_slots_used,
  } = useSelector(isAdmin ? selectAccountLicenseCounts : selectActiveLisenseCounts);

  const changedProjectKeywordsSlotsUsed = useMemo(() => keywordsCount * getCrawlFrequencyMultiplier(recrawlInterval as RecrawlInterval), [
    recrawlInterval,
    keywordsCount,
  ]);
  const initProjectKeywordsSlotsUsed = useMemo(() => keywordsInitCount * getCrawlFrequencyMultiplier(initRecrawlInterval as RecrawlInterval), [
    initRecrawlInterval,
    keywordsInitCount,
  ]);
  const evaluatedKeywordsSlotsUsed = useMemo(() => keyword_slots_used - initProjectKeywordsSlotsUsed + changedProjectKeywordsSlotsUsed, [
    keyword_slots_used,
    changedProjectKeywordsSlotsUsed,
    initProjectKeywordsSlotsUsed,
  ]);

  const changedProjectUrlsSlotsUsed = useMemo(() => urlsCount * getCrawlFrequencyMultiplier(recrawlInterval as RecrawlInterval), [
    recrawlInterval,
    urlsCount,
  ]);
  const initProjectUrlsSlotsUsed = useMemo(() => urlsInitCount * getCrawlFrequencyMultiplier(initRecrawlInterval as RecrawlInterval), [
    initRecrawlInterval,
    urlsInitCount,
  ]);
  const evaluatedUrlsSlotsUsed = useMemo(() => url_slots_used - initProjectUrlsSlotsUsed + changedProjectUrlsSlotsUsed, [
    url_slots_used,
    changedProjectUrlsSlotsUsed,
    initProjectUrlsSlotsUsed,
  ]);

  const isLimitReached = evaluatedKeywordsSlotsUsed > keyword_slots || evaluatedUrlsSlotsUsed > url_slots || (isNew && project_slots_used >= project_slots);

  return {
    isLimitReached,
    evaluatedUrlsSlotsUsed,
    evaluatedKeywordsSlotsUsed,
    user_slots,
    user_slots_used,
    url_slots,
    project_slots,
    project_slots_used,
    keyword_slots,
  }
}
import styled from 'styled-components';

export interface IStyledWrapperProps {
  deviation?: number;
}

export const StyledWrapper = styled.div<IStyledWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  border-radius: 20px;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    min-height: 32px;

    padding-left: 22px;
    padding-right: 22px;

    border-radius: 20px;

    font-style: normal;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.17px;
    text-transform: uppercase;
    color: #242D46;
    transition: all 0.2s ease;
    cursor: pointer;

    ::before {
      content: '';
      display: block;

      height: 100%;
      width: 100%;

      position: absolute;

      background: #181818 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 10px #0000000F;
      border-radius: 20px;
      
      z-index: -1;

      opacity: 0;

    }

    &:first-child {
      ::before {
        transform: translateX(100%);
      }
    }

    &:last-child {
      ::before {
        transform: translateX(-100%);
      }
    }

    &.active {
      color: #FFFFFF;

      ::before {
        opacity: 1;
        transform: translateX(0);
        transition: transform 0.2s ease;
      }
    }

    span {
      opacity: 0.4;
      margin-left: 8px;
      font-weight: 600;
    }
  }

  ::before {
    content: '';
    display: block;

    position: absolute;

    height: 100%;
    width: 100%;

    background: #DBDBDB34 0% 0% no-repeat padding-box;
    border-radius: 20px;
    z-index: -1;
  }
`;

import { IDirectory, IDirectoryAnalysisRequestBody } from '../../services/DataService/types';
import { EmptyObject } from '../../types/common.types';
import { ActionUnion, createAction } from '../commonTypes';
import { IUIPayload, DirectoryActionTypes, ISortChangePayload, ISortRefreshPayload, IDataRestrictionsPayload } from './types';

export const directoryActions = {
  directoryReload: (payload: IDirectoryAnalysisRequestBody): { type: DirectoryActionTypes, payload: IDirectoryAnalysisRequestBody } => createAction(
    DirectoryActionTypes.DIRECTORY_RELOAD,
    payload,
  ),
  directoryRefresh: (payload: IDirectory[]): { type: DirectoryActionTypes, payload: IDirectory[]; } => createAction(
    DirectoryActionTypes.DIRECTORY_REFRESH,
    payload,
  ),
  openedFoldersRefresh: (payload: string[]): { type: DirectoryActionTypes, payload: string[]; } => createAction(
    DirectoryActionTypes.OPENED_FOLDERS_REFRESH,
    payload,
  ),
  sortChange: (payload: ISortChangePayload): { type: DirectoryActionTypes, payload: ISortChangePayload } => createAction(
    DirectoryActionTypes.SORT_CHANGE,
    payload,
  ),
  sortRefresh: (payload: ISortRefreshPayload): { type: DirectoryActionTypes, payload: ISortRefreshPayload } => createAction(
    DirectoryActionTypes.SORT_REFRESH,
    payload,
  ),
  dataRestrictionsMerge: (payload: IDataRestrictionsPayload): { type: DirectoryActionTypes, payload: IDataRestrictionsPayload } => createAction(
    DirectoryActionTypes.DATA_RESTRICTIONS_MERGE,
    payload,
  ),
  dataUpdate: (): { type: DirectoryActionTypes, payload: EmptyObject } => createAction(
    DirectoryActionTypes.DATA_UPDATE,
  ),
  uiMerge: (payload: IUIPayload): { type: DirectoryActionTypes, payload: IUIPayload } => createAction(
    DirectoryActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: DirectoryActionTypes, payload: EmptyObject } => createAction(
    DirectoryActionTypes.RESET_ALL,
  ),
}

export type DirectoryActions = ActionUnion<typeof directoryActions>;

import { all, takeLatest, select, put, call } from "redux-saga/effects";
import { sub, format } from 'date-fns';

import { IRefreshTokensOptions } from "../../services/AuthService/types";
import { IBaseResponse } from "../../services/BaseAPIService/types";
import { DataService } from "../../services/DataService";
import {
  ILeaderboardBenchmarkRankRequestBody,
  ILeaderboardBenchmarkRankResponseBody,
  ILeaderboardData,
  ILeaderboardDataRequestBody,
  ILeaderboardDataResponseBody,
  ILeaderboardHistoryRequestBody,
  ILeaderboardHistoryResponseBody,
  ILeaderboardRankRequestBody,
  ILeaderboardRankResponseBody
} from "../../services/DataService/types";

import { selectActiveProjectID } from "../projects/selectors";
import { Action } from "../commonTypes";
import { leaderboardActions } from "./actions";
import { selectFeature, selectHistoryInterval } from "./selectors";
import { selectGroupByID } from '../projects/selectors';
import { LeaderboardActionTypes } from "./types";
import { DOMAIN_HISTORY_DATE_FORMAT } from "../../constants/formats";

function* dataReload(action: Action<LeaderboardActionTypes.DATA_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const feature = yield select(selectFeature);

  if (!projectID || !feature) {
    return;
  }

  yield put(leaderboardActions.uiMerge({ dataLoading: true }));

  const requestBody: ILeaderboardDataRequestBody = {
    feature,
    limit: 30,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ILeaderboardDataResponseBody> = yield call(DataService.leaderboardData, projectID, requestBody, options);
  if (!result?.data) {
    yield put(leaderboardActions.uiMerge({ dataLoading: false }));
    yield put(leaderboardActions.dataRefresh([]));
    return;
  }

  const response: ILeaderboardDataResponseBody = result?.data;

  const { data } = response;

  const sanitizedData: ILeaderboardData[] = [];
  let dataIndex = 0;

  while (dataIndex < data.length) {
    const dataItem = data[dataIndex];
    const group = yield select(selectGroupByID(dataItem.domain));
    sanitizedData.push({ ...dataItem, domain: group?.name || dataItem.domain });
    dataIndex++;
  }

  yield put(leaderboardActions.dataRefresh(sanitizedData));
  yield put(leaderboardActions.uiMerge({ dataLoading: false }));
}

function* rankReload(action: Action<LeaderboardActionTypes.RANK_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const feature = yield select(selectFeature);

  if (!projectID || !feature) {
    return;
  }

  yield put(leaderboardActions.uiMerge({ rankLoading: true }));

  const requestBody: ILeaderboardRankRequestBody = {
    feature,
    limit: 500,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ILeaderboardRankResponseBody> = yield call(DataService.leaderboardRank, projectID, requestBody, options);
  if (!result?.data) {
    yield put(leaderboardActions.uiMerge({ rankLoading: false }));
    yield put(leaderboardActions.rankRefresh(null));
    return;
  }

  const response: ILeaderboardRankResponseBody = result?.data;

  const { data } = response;

  yield put(leaderboardActions.rankRefresh(Array.isArray(data) ? null : data.rank));
  yield put(leaderboardActions.uiMerge({ rankLoading: false }));
}

function* benchmarkRanksReload(action: Action<LeaderboardActionTypes.BENCHMARK_RANKS_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const feature = yield select(selectFeature);

  if (!projectID || !feature) {
    return;
  }

  yield put(leaderboardActions.uiMerge({ benchmarkRanksLoading: true }));

  const requestBody: ILeaderboardBenchmarkRankRequestBody = {
    feature,
    limit: 500,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ILeaderboardBenchmarkRankResponseBody> = yield call(DataService.leaderboardBenchmarkRank, projectID, requestBody, options);
  if (!result?.data) {
    yield put(leaderboardActions.uiMerge({ benchmarkRanksLoading: false }));
    yield put(leaderboardActions.benchmarkRanksRefresh([]));
    return;
  }

  const response: ILeaderboardBenchmarkRankResponseBody = result?.data;

  const { data } = response;

  yield put(leaderboardActions.benchmarkRanksRefresh(data));
  yield put(leaderboardActions.uiMerge({ benchmarkRanksLoading: false }));
}

function* historyReload(action: Action<LeaderboardActionTypes.HISTORY_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const feature = yield select(selectFeature);
  const historyInterval = yield select(selectHistoryInterval);

  if (!projectID || !feature) {
    return;
  }

  yield put(leaderboardActions.uiMerge({ historyLoading: true }));

  const requestBody: ILeaderboardHistoryRequestBody = {
    feature,
    date_from: format(sub(new Date(), { days: historyInterval }), DOMAIN_HISTORY_DATE_FORMAT),
    date_to: format(new Date(), DOMAIN_HISTORY_DATE_FORMAT),
    limit: 200,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ILeaderboardHistoryResponseBody> = yield call(DataService.leaderboardHistory, projectID, requestBody, options);
  if (!result?.data) {
    yield put(leaderboardActions.uiMerge({ historyLoading: false }));
    yield put(leaderboardActions.historyRefresh({}));
    return;
  }

  const response: ILeaderboardHistoryResponseBody = result?.data;

  const { data } = response;

  yield put(leaderboardActions.historyRefresh(data));
  yield put(leaderboardActions.uiMerge({ historyLoading: false }));
}

export default function* leaderboardSaga() {
  yield all([
    takeLatest(LeaderboardActionTypes.DATA_RELOAD, dataReload),
    takeLatest(LeaderboardActionTypes.RANK_RELOAD, rankReload),
    takeLatest(LeaderboardActionTypes.BENCHMARK_RANKS_RELOAD, benchmarkRanksReload),
    takeLatest(LeaderboardActionTypes.HISTORY_RELOAD, historyReload),
  ]);
}
import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DISPLAYED_LICENSES_LIMIT } from '../../../../../constants/licenses';
import { Languages } from '../../../../../enums/locales/languages';
import { ProfileMenuKeys } from '../../../../../enums/locales/topBar';
import { ILanguageOption } from '../../../../../types/locales.types';
import { ILicense } from '../../../../../services/LicensesService/types';

import {
  IconAccounts,
  IconCheckmarkPurple,
  IconSignOut,
  IconUserlist,
} from '../../../../../components/styled/icons';

import { Button, ButtonType } from '../../../../../components/styled/Button';
import { ProfileAvatar } from '../../../../../components/ProfileAvatar';

import {
  StyledWrapper as Wrapper,
  StyledIconDropdown as IconDropdown,
  StyledIconDropdownActive as IconDropdownActive,
} from './ProfileCard.styled';
import { selectTotalLoading } from '../../../../../redux/kpi/selectors';
import { selectUser } from '../../../../../redux/auth/selectors';
import { UI_ACTUAL_ROUTES } from '../../../../../constants/routes';
import { push } from 'connected-react-router';

export interface ILicenseOption extends Pick<ILicense, 'license_id' | 'creator_name' | 'creator_email' | 'company'> { }

export interface IProps {
  collapsed?: boolean;
  url?: string;
  name?: string;
  email?: string;
  selectedLanguageKey?: Languages;
  selectedLicenseID?: string;
  languageOptions?: ILanguageOption[];
  licenseOptions?: ILicenseOption[];
  onClickProfile: () => void;
  onSelectLanguage?: (key: Languages) => void;
  onSelectLicense?: (key: string) => void;
  onClickProfileSettings?: () => void;
  onClickSignOut?: () => void;
}

const ProfileCard: React.FC<IProps> = ({
  collapsed,
  url,
  name = 'Guest',
  email = '',
  selectedLanguageKey,
  selectedLicenseID,
  languageOptions,
  licenseOptions,
  onClickProfile,
  onSelectLanguage,
  onSelectLicense,
  onClickProfileSettings,
  onClickSignOut,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disabled = useSelector(selectTotalLoading);
  const user = useSelector(selectUser);
  const [showAllLicenses, setShowAllLicenses] = useState(false);

  const className = clsx({
    collapsed,
  });

  const isShowAllLicensesVisible = licenseOptions?.length as number > DISPLAYED_LICENSES_LIMIT;

  const reducedLicenseOptions = useMemo(() => showAllLicenses ? licenseOptions : licenseOptions?.slice(0, DISPLAYED_LICENSES_LIMIT), [licenseOptions, showAllLicenses]);

  const profileDropIcon = collapsed ? <IconDropdown /> : <IconDropdownActive />;

  const handleClickShowAllLicenses = useCallback(() => {
    setShowAllLicenses(prevState => !prevState);
  }, [setShowAllLicenses]);

  const getSelectLicenseHandler = useCallback((key: string) => () => {
    if (!disabled) {
      onSelectLicense && onSelectLicense(key);
      onClickProfile();
    }
  }, [onSelectLicense, disabled]);

  const getSelectLanguageHandler = useCallback((key: Languages) => () => {
    if (!disabled) {
      onSelectLanguage && onSelectLanguage(key);
      onClickProfile();
    }
  }, [onSelectLanguage, disabled]);

  const goToAdminPage = useCallback((route: string) => () => {
    dispatch(push(route));
    onClickProfile();
  }, [dispatch]);

  return (
    <Wrapper className={className}>
      <div className="profile-info" onClick={onClickProfile}>
        <div className="profile-avatar">
          <ProfileAvatar url={url} name={name} />
        </div>
        <div className="profile-name">
          <div className="user-name">{name}</div>
          <div className="user-email">{email}</div>
        </div>
        <div className="profile-drop-icon">
          {profileDropIcon}
        </div>
      </div>
      {!!user?.is_admin && (
        <div className="admin-section">
          <div className="admin-header">
            {t(ProfileMenuKeys.adminSectionTitle)}
          </div>
          <div className="admin-page" onClick={goToAdminPage(UI_ACTUAL_ROUTES.adminAccounts)}><IconAccounts /> {t(ProfileMenuKeys.adminAccountsTitle)}</div>
          <div className="admin-page" onClick={goToAdminPage(UI_ACTUAL_ROUTES.adminUsers)}><IconUserlist /> {t(ProfileMenuKeys.adminUserListTitle)}</div>
        </div>
      )}
      <div className="license-select">
        <div className="license-select-header">
          <div className="license-select-title">
            {t(ProfileMenuKeys.licenseSelectTitle)}
          </div>
          <div
            className={clsx('license-select-all', { hidden: !isShowAllLicensesVisible })}
            onClick={handleClickShowAllLicenses}
          >
            {t(showAllLicenses ? ProfileMenuKeys.licenseSelectViewHideTitle : ProfileMenuKeys.licenseSelectViewTitle)}
          </div>
        </div>
        <div className={clsx('license-select-options', { disabled })}>
          {reducedLicenseOptions?.map(({ license_id, creator_name, creator_email, company }) => {

            const optionClassName = clsx('license-option', {
              active: license_id === selectedLicenseID,
            });

            return (
              <div
                key={license_id}
                className={optionClassName}
                onClick={getSelectLicenseHandler(license_id)}
              >
                <div className="license-option-title">
                  <div className="license-option-creator-name">{company?.name || creator_name}</div>
                  <div className="license-option-creator-email">{creator_email}</div>
                </div>
                <div className="license-option-icon">{<IconCheckmarkPurple />}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="language-select">
        <div className="language-select-title">
          {t(ProfileMenuKeys.languageSelectTitle)}
        </div>
        <div className="language-select-options">
          {languageOptions?.map(({ key, title }) => {

            const optionClassName = clsx('language-option', {
              active: key === selectedLanguageKey,
            });

            return (
              <div
                key={key}
                className={optionClassName}
                onClick={getSelectLanguageHandler(key)}
              >
                <div className="language-option-icon">{<IconCheckmarkPurple />}</div>
                <div className="language-option-title">{title}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="profile-settings" onClick={onClickProfileSettings}>
        {t(ProfileMenuKeys.profileSettingsTitle)}
      </div>
      <div className="sign-out">
        <Button
          small
          type={ButtonType.Secondary}
          icon={<IconSignOut />}
          title={t(ProfileMenuKeys.signOutButtonTitle)}
          onClick={onClickSignOut}
        />
      </div>
    </Wrapper>
  );
};

export default ProfileCard;

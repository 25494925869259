import React, { useCallback, useState } from 'react';
import clsx from 'clsx';

import { googleSharedStuffFaviconUrl } from '../../config';

import { StyledWrapper as Wrapper } from './DomainPictureHolder.styled';

export interface IProps {
  domainName?: string;
  domainPictureUrl?: string;
}

const DomainPictureHolder: React.FC<IProps> = ({
  domainName = '',
  domainPictureUrl,
}) => {
  const [isFaviconLoadingFailed, setIsFaviconLoadingFailed] = useState(false);

  const handleImageError = useCallback(() => {
    setIsFaviconLoadingFailed(true);
  }, [setIsFaviconLoadingFailed]);

  const className = clsx({
    empty: !domainName,
  });

  const domainPicture = !isFaviconLoadingFailed && domainName
    ? <img className="domain-picture" src={domainPictureUrl || `${googleSharedStuffFaviconUrl}${domainName}`} onError={handleImageError} />
    : <div className="domain-template">{domainName?.replace('www.', '').slice(0, 1).toUpperCase()}</div>

  return (
    <Wrapper className={className}>
      {domainPicture}
    </Wrapper>
  );
};

export default DomainPictureHolder;

import { createSelector } from 'reselect';
import { RootState } from '../store';

const sort = (state: RootState) => state.ProjectsOverview.sort;
const data = (state: RootState) => state.ProjectsOverview.data;

export const selectSortDataKey = createSelector(
	[sort],
	(sort) => sort.dataKey,
);

export const selectSortOrder = createSelector(
	[sort],
	(sort) => sort.order,
);

export const selectData = createSelector(
	[data],
	(data) => data,
);

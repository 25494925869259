import { createSelector } from 'reselect';
import { RootState } from '../store';

const data = (state: RootState) => state.Overview.data;
const UI = (state: RootState) => state.Overview.UI;

export const selectOverviewData = createSelector(
	[data],
	(data) => data,
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

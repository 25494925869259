export enum DashboardHeaderKeys {
  MainHeaderTitle = 'dashboard_main_header_title',
  HistoricalDevelopmentSubheaderTitle = 'dashboard_historical_development_subheader_title',
  MetricsSubheaderTitle = 'dashboard_metrics_subheader_title',
  MetricsSubheaderTestTitle = 'dashboard_metrics_subheader_test_title',
  CoreWebVitalsSubheaderTitle = 'dashboard_core_web_vitals_subheader_title',
}

export enum DashboardTopMenuKeys {
  CrawledURLsInfoTitle = 'dashboard_top_menu_crawled_urls_info_title',
  PCTitle = 'dashboard_top_menu_pc_title',
  MobileTitle = 'dashboard_top_menu_mobile_title',
  FieldDataTitle = 'dashboard_top_menu_field_data_title',
  LabDataTitle = 'dashboard_top_menu_lab_data_title',
  TestModeLabelTitle = 'dashboard_top_menu_test_mode_title',
}

export enum DashboardKpiKeys {
  PerformanceScore = 'dashboard_kpi_performance_score_title',
  FirstContentfulPaint = 'dashboard_kpi_first_contentful_paint_title',
  FirstMeaningfulPaint = 'dashboard_kpi_first_meaningful_paint_title',
  LargestContentfulPaint = 'dashboard_kpi_largest_contentful_paint_title',
  CumulativeLayoutShift = 'dashboard_kpi_cumulative_layout_shift_title',
  InteractionToNextPaint = 'dashboard_kpi_experimental_interaction_to_next_paint_title',
  ExperimentalTimeToFirstByte = 'dashboard_kpi_experimental_time_to_first_byte_title',
  FirstInputDelay = 'dashboard_kpi_first_input_delay_title',
  SpeedIndex = 'dashboard_kpi_speed_index_title',
  ServerResponseTime = 'dashboard_kpi_server_response_time_title',
  DOMSize = 'dashboard_kpi_dom_size_title',
  NumTasksOver50ms = 'dashboard_kpi_num_tasks_over_50_ms_title',
  NumTasksOver100ms = 'dashboard_kpi_num_tasks_over_100_ms_title',
  NumTasksOver500ms = 'dashboard_kpi_num_tasks_over_500_ms_title',
  NumTasks = 'dashboard_kpi_num_tasks_title',
  TotalTaskTime = 'dashboard_kpi_total_task_time_title',
  TotalByteWeight = 'dashboard_kpi_total_byte_weight_title',
  UsesTextCompression = 'dashboard_kpi_uses_text_compression_title',
  NumFonts = 'dashboard_kpi_num_fonts_title',
  NumRequests = 'dashboard_kpi_num_requests_title',
  NumScripts = 'dashboard_kpi_num_scripts_title',
  NumStylesheets = 'dashboard_kpi_num_stylesheets_title',
  TotalBlockingTime = 'dashboard_kpi_total_blocking_time_title',
  TimeToInteractive = 'dashboard_kpi_time_to_interactive_title',
  UnusedCssTitle = 'dashboard_kpi_savings_in_bytes_full_title',
  UnusedCss = 'dashboard_kpi_unused_css_rules_title',
  UnusedJs = 'dashboard_kpi_unused_js_rules_title',
  UnminifiedCss = 'dashboard_kpi_unminified_css_rules_title',
  UnminifiedJs = 'dashboard_kpi_unminified_js_rules_title',
  ModernImage = 'dashboard_kpi_modern_image_rules_title',
  OffscreenImage = 'dashboard_kpi_offscreen_image_rules_title',
  OptimizedImages = 'dashboard_kpi_optimized_images_rules_title',
  RenderBlockingResources = 'dashboard_kpi_render_blocking_resources_rules_title',
}

export enum DashboardKpiSummaryHeaderKeys {
  Domain = 'dashboard_kpi_summary_domain_title',
  PerformanceScore = 'dashboard_kpi_summary_performance_score_title',
  FirstContentfulPaint = 'dashboard_summary_kpi_first_contentful_paint_title',
  FirstMeaningfulPaint = 'dashboard_summary_kpi_first_meaningful_paint_title',
  LargestContentfulPaint = 'dashboard_summary_kpi_largest_contentful_paint_title',
  CumulativeLayoutShift = 'dashboard_summary_kpi_cumulative_layout_shift_title',
  ExperimentalInteractionToNextPaint = 'dashboard_summary_kpi_experimental_interaction_to_next_paint_title',
  ExperimentalTimeToFirstByte = 'dashboard_summary_kpi_experimental_time_to_first_byte_title',
  FirstInputDelay = 'dashboard_kpi_summary_first_input_delay_title',
  ServerResponseTime = 'dashboard_kpi_summary_server_response_time_title',
  TotalBlockingTime = 'dashboard_kpi_summary_total_blocking_time_title',
  TimeToInteractive = 'dashboard_kpi_summary_time_to_interactive_title',
}

export enum DashboardDonutLabels {
  Unminified = 'dashboard_kpi_savings_in_bytes_unminified',
  Unused = 'dashboard_kpi_savings_in_bytes_unused',
  Modern = 'dashboard_kpi_savings_in_bytes_modern',
}

export enum DashboardLabelKeys {
  Benchmark = 'dashboard_kpi_performance_score_benchmark_label',
  MarketAvg = 'dashboard_kpi_performance_score_market_avg_label',
  MarketAvgShort = 'dashboard_kpi_market_avg_short_label',
  Milliseconds = 'dashboard_kpi_ms_label_short',
  DomainTBT = 'dashboard_kpi_domain_tbt_label',
  DomainTTI = 'dashboard_kpi_domain_tti_label',
  LabData = 'dashboard_kpi_domain_lab_data_label',
  ShowBenchmarksLabel = 'dashboard_show_benchmarks_label',
  ShowAnnotationsLabel = 'dashboard_show_annotations_label',
  ShowColorsLabel = 'dashboard_show_colors_label',
  Goal = 'dashboard_goal_label',
  CreateAnnotationLabel = 'dashboard_create_annotation_label',
  EditAnnotationLabel = 'dashboard_edit_annotation_label',
}

export enum DashboardKpiSubtitleKeys {
  LabData = 'dashboard_kpi_subtitle_lab_data',
  FieldData = 'dashboard_kpi_subtitle_field_data',
  Ms = 'dashboard_kpi_ms_label',
  Lab = 'dashboard_kpi_subtitle_lab',
  FD = 'dashboard_kpi_subtitle_fd',
  SavingsInBytes = 'dashboard_kpi_savings_in_bytes_title',
  SavingsInMs = 'dashboard_kpi_savings_in_ms',
  Byte = 'dashboard_kpi_subtitle_bytes',
  Diagnostics = 'dashboard_kpi_subtitle_diagnostics',
}

export enum DashboardKpiHintKeys {
  PerformanceScore = 'dashboard_kpi_performance_score_info_text',
  FirstContentfulPaint = 'dashboard_kpi_first_contentful_paint_info_text',
  FirstMeaningfulPaint = 'dashboard_kpi_first_meaningful_paint_info_text',
  LargestContentfulPaint = 'dashboard_kpi_largest_contentful_paint_info_text',
  CumulativeLayoutShift = 'dashboard_kpi_cumulative_layout_shift_info_text',

  InteractionToNextPaint = 'dashboard_kpi_experimental_interaction_to_next_paint_info_text',
  ExperimentalTimeToFirstByte = 'dashboard_kpi_experimental_time_to_first_byte_info_text',

  FirstInputDelay = 'dashboard_kpi_first_input_delay_info_text',
  DOMSize = 'dashboard_kpi_dom_size_info_text',
  ModernImage = 'dashboard_kpi_modern_image_info_text',
  ServerResponseTime = 'dashboard_kpi_server_response_time_info_text',
  TotalBlockingTime = 'dashboard_kpi_total_blocking_time_info_text',
  TimeToInteractive = 'dashboard_kpi_time_to_interactive_info_text',
  UnusedCss = 'dashboard_kpi_savings_in_bytes_info_text',
  UnusedCssInfo = 'dashboard_kpi_unused_css_info_text',
  UnminifiedJs = 'dashboard_kpi_unminified_js_info_text',
  UnminifiedCss = 'dashboard_kpi_unminified_css_info_text',
  UnusedJs = 'dashboard_kpi_unused_js_info_text',
  OptimizedImages = 'dashboard_kpi_optimized_images_info_text',
  RenderBlockingResources = 'dashboard_kpi_render_blocking_resources_info_text',
  NumTasksOver50ms = 'dashboard_kpi_num_tasks_over_50_ms_info_text',
  NumTasksOver100ms = 'dashboard_kpi_num_tasks_over_100_ms_info_text',
  NumTasksOver500ms = 'dashboard_kpi_num_tasks_over_500_ms_info_text',
  SpeedIndex = 'dashboard_kpi_speed_index_info_text',
  UsesTextCompression = 'dashboard_kpi_uses_text_compression_info_text',
  OffscreenImage = 'dashboard_kpi_offscreen_images_info_text',
  NumFonts = 'dashboard_kpi_num_fonts_info_text',
  NumRequests = 'dashboard_kpi_num_requests_info_text',
  NumTasks = 'dashboard_kpi_num_tasks_info_text',
  NumScripts = 'dashboard_kpi_num_scripts_info_text',
  NumStylesheets = 'dashboard_kpi_num_stylesheets_info_text',
  TotalByteWeight = 'dashboard_kpi_total_byte_weight_info_text',
  TotalTaskTime = 'dashboard_kpi_total_task_time_info_text',
}

export enum DashboardIntervalKeys {
  LastNinetyDays = 'dashboard_interval_last_ninety_days',
  LastOneHundredAndEightyDays = 'dashboard_interval_last_one_hundred_and_eighty_days',
  LastThreeHundredAndSixtyDays = 'dashboard_interval_last_three_hundred_and_sixty_days',
}

export enum DashboardLegendsKeys {
  MarketAvg = 'dashboard_legend_top_5_market_average',
  BenchmarkAvg = 'dashboard_legend_benchmarks',
}

export enum DashboardWebVitalsFilters {
  Top5Market = 'dashboard_core_web_vitals_top_5_market',
  Top10Market = 'dashboard_core_web_vitals_top_10_market',
}

export enum DashboardWebVitalsGroups {
  Groups = 'dashboard_core_web_vitals_groups',
  Projects = 'dashboard_core_web_vitals_projects',
  TestProjects = 'dashboard_core_web_vitals_test_projects',
  MarketAverages = 'dashboard_core_web_vitals_market_avg',
  Benchmarks = 'dashboard_core_web_vitals_benchmarks',
  PastCrawls = 'dashboard_core_web_vitals_past_crawls',
}

export enum DashboardWebVitalsKeys {
  Deviation = 'dashboard_core_web_vitals_column_deviation',
  Feature = 'dashboard_core_web_vitals_column_feature',
  EmailTemplateSentToMessage = 'dashboard_core_web_vitals_email_send_to_message',
  EmailTemplateTopMessage = 'dashboard_core_web_vitals_email_template_top_message',
  EmailTemplateBottomMessage = 'dashboard_core_web_vitals_email_template_bottom_message',
  EmailTemplateGoButtonMessage = 'dashboard_core_web_vitals_email_template_go_button_message',
  EmailTemplateViewInBrowserMessage = 'dashboard_core_web_vitals_email_template_view_in_browser_message',
  EmailTemplateWrapperBottomInfoMessage = 'dashboard_core_web_vitals_email_template_wrapper_bottom_info_message',
}

export enum DashboardHistoricalKeys {
  TooltipTitle = 'dashboard_historical_development_tooltip_title'
}

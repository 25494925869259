import styled from 'styled-components';
import { ReactComponent as RawEyeOffSVG } from '../../../../assets/icons/eye_off.svg';

export const IconEyeOff = styled(RawEyeOffSVG)`
  .a {
    fill: rgba(255,255,255,0);
  }
  
  .b {
    fill: #5468f0;
  }
`;

import styled from 'styled-components';

export const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-height: 75vh;
  width: 100%;
  padding-top: 36px;
  padding-bottom: 36px;
  padding-right: 60px;
  padding-left: 48px;

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;

    .filmstrip-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 1;

      height: 100%;
      padding-right: 20px;

      .filmstrip-title {
        margin-bottom: 14px;

        font-style: normal;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 0px;
        color: #181818;
      }

      &.empty {
        div:last-child {
          height: 260px;
          background: ${({ theme }) => theme.colors.primaryBg};
  
          > div {
            display: none;
          }
        }
      }
    }

    .final-screenshot-container {
      display: flex;
      flex-direction: column;

      height: 440px;
      width: 160px;

      .final-screenshot-title {
        margin-bottom: 14px;

        font-style: normal;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 0px;
        color: #181818;
      }

      > div {
        width: 100%;
        justify-content: flex-end;
      }

      &.empty {
        height: 300px;
        width: 160px;
        
        > div:last-child {
          height: 300px;
          width: 100%;
  
          background: ${({ theme }) => theme.colors.primaryBg};

          > div {
            display: none;
          }
        }
      }
    }
  }

  .bottom-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    .lcp-element-container {
      display: flex;
      flex-direction: column;

      .lcp-element-title {
        margin-bottom: 14px;

        font-style: normal;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 0px;
        color: #181818;
      }

      .lcp-element-data {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .lcp-element-label {
          margin-bottom: 9px;

          font-style: normal;
          font-size: 12px;
          font-weight: medium;
          line-height: 18px;
          letter-spacing: 0px;
          color: #171C28;
        }

        .lcp-element-snippet {
          background: #F2F2F2 0% 0% no-repeat padding-box;
          border-radius: 4px;
          padding: 8px;

          font-style: normal;
          font-family: 'Courier New', Courier, monospace;
          font-size: 12px;
          font-weight: bold;
          line-height: 14px;
          letter-spacing: 0px;
          color: #171C28;
        }
      }

      &.empty {
        width: 100%;

        .lcp-element-data {
          height: 100px;
          width: 100%;
  
          background: ${({ theme }) => theme.colors.primaryBg};
        }
      }
    }
  }
`;

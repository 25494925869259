import clsx from 'clsx';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import { Button, ButtonType } from '../../../components/styled/Button';
import { IconArrowRight, IconTest, IconLive } from '../../../components/styled/icons';
import { SiderButtomTextKeys, SiderHeading } from '../../../enums/locales/modals';
import { modalsActions } from '../../../redux/modals/actions';

import { selectSiderVisible } from '../../../redux/modals/selectors';
import { ResourceCard } from './ResourceCard';
import { Stepper } from './Stepper';

import { Wrapper } from './Sider.styled';
import { ProjectForm, ProjectFormBag } from '../../forms/ProjectForm';
import { projectSettingsActions } from '../../../redux/project-settings/actions';
import { selectData, selectFinishedSteps, selectUI } from '../../../redux/project-settings/selectors';
import { Radio } from '../../../components/styled/Radio';
import { Switch } from '../../../components/styled/Switch';
import { RadioType } from '../../../components/styled/Radio/Radio';
import { TestProjectForm, TestProjectFormBag } from '../../forms/TestProjectForm';

import { requiredSteps, requiredTestSteps, projectSteps, testProjectSteps } from './assets';
import { ProjectTypesKeys } from '../../../enums/locales/projects';
import { useResourceLimits } from '../../../hooks/useResourceLimits';

const testSwitchItems = [
  {
    label: ProjectTypesKeys.LiveProject,
    value: false,
    icon: <IconLive />,
  },
  {
    label: ProjectTypesKeys.TestProject,
    value: true,
    icon: <IconTest />,
  },
];

const Sider: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visible = useSelector(selectSiderVisible);
  const ref = useRef(null);
  const btnRef = useRef(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const finishedSteps = useSelector(selectFinishedSteps);
  const { isTest, isNew, isCrawlNow, isCrawled, loading } = useSelector(selectUI);
  const { recrawl_interval } = useSelector(selectData);
  const { isLimitReached } = useResourceLimits(false);

  const SettingsForm = useMemo(() => isTest ? TestProjectForm : ProjectForm, [isTest]);
  const SettingsFormBag = useMemo(() => isTest ? TestProjectFormBag : ProjectFormBag, [isTest]);

  const onBackClick = useCallback((e) => {
    const pathOfSvgRef = svgRef.current?.children[0].children[1];
    if (e.target === ref.current || e.target === btnRef.current || e.target === svgRef.current || e.target === pathOfSvgRef) {
      dispatch(modalsActions.siderHide());
      SettingsFormBag.resetForm();
      dispatch(projectSettingsActions.reset());
    }
  }, [dispatch]);

  const onContinue = useCallback(() => {
    SettingsFormBag.submitForm();
  }, [SettingsFormBag]);

  const onSubmit = useCallback((values) => {
    dispatch(projectSettingsActions.dataSave());
  }, [dispatch]);

  const onTestChange = useCallback((value) => {
    dispatch(projectSettingsActions.reset());
    dispatch(projectSettingsActions.uiMerge({ isTest: value }));
    SettingsFormBag.resetForm();
  }, [dispatch]);

  const onCrawlChange = useCallback(() => {
    dispatch(projectSettingsActions.uiMerge({ isCrawlNow: !isCrawlNow }));
  }, [isCrawlNow]);

  const steps = useMemo(() => isTest ? testProjectSteps : projectSteps, [isTest]);
  const nessesarySteps = useMemo(() => isTest ? requiredTestSteps : requiredSteps, [isTest]);

  const isDisabled = useMemo(() => isLimitReached || loading || !nessesarySteps.every((step) => finishedSteps.includes(step)), [finishedSteps, loading, isLimitReached]);

  return (
    <Wrapper
      ref={ref}
      onMouseDown={onBackClick}
      className={clsx({ disapear: !visible })}
      visible={visible}
    >
      <div className="sider-mask"></div>
      <div className="sider-body">
        <div className="sider-header">
          <div ref={btnRef} onClick={onBackClick} className="btn-back">
            <IconArrowRight ref={svgRef} />
          </div>
          {isNew ? t(SiderHeading.SetupNewProject) : t(SiderHeading.ProjectSettings)}
        </div>
        <div className="sider-body-content">
          {visible && <>
            <div className="sider-body-content-form">
              <Radio
                radioType={RadioType.Large}
                items={testSwitchItems}
                value={isTest}
                onChange={onTestChange}
              />
              <SettingsForm onSubmit={onSubmit} />
            </div>
            <div className="sider-body-content-meta">
              <div className="navigation">
                <ResourceCard />
                <Stepper steps={steps} />
              </div>
              <div className="btn-container">
                {isLimitReached && <div className="limits-message">{t(SiderHeading.LimitMessage)}</div>}
                {(!isCrawled || !recrawl_interval) && (
                  <div className="switch-container">
                    <Switch
                      label={t(SiderButtomTextKeys.StartCrawl)}
                      onChange={onCrawlChange}
                      disabled={isDisabled}
                      checked={isCrawlNow}
                    />
                  </div>
                )}
                <div className="continue-btn">
                  <Button
                    type={ButtonType.Primary}
                    title={t(SiderButtomTextKeys.Save)}
                    disabled={isDisabled}
                    icon={loading ? <Spin size='small' spinning /> : null}
                    onClick={onContinue}
                  />
                </div>
              </div>
            </div>
          </>}
        </div>
      </div>
    </Wrapper>
  );
};

export default Sider;
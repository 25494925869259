import { FeatureOverview } from '../../services/DataService/types';

export enum OverviewActionTypes {
  FEATURE_OVERVIEW_RELOAD = '[Overview] feature-overview-reload',
  FEATURE_OVERVIEW_REFRESH = '[Overview] feature-overview-refresh',

  UI_MERGE = '[Overview] ui-merge',

  RESET_ALL = '[Overview] reset-all',
}

export type OverviewPayload  = FeatureOverview[];

export interface IUIPayload {
  loading?: boolean;
}

export interface OverviewState {
  data: OverviewPayload;
  UI: IUIPayload;
}

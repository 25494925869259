export enum FieldTypes {
  Input = 'input',
  Select = 'select',
  Radio = 'radio',
  CheckableList = 'checkable-list',
  SimpleList = 'simple-list',
  GroupList = 'group-list',
  Password = 'password',
  Checkbox = 'checkbox',
  GoalsList = 'goals-list',
  Switch = 'switch',
}

import React, { useCallback } from 'react';

import { IconLogoColored } from '../../components/styled/icons';
import { TopBarMenu } from './TopBarMenu';
import { StyledWrapper as Wrapper } from './TopBar.styled';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { UI_ACTUAL_ROUTES } from '../../constants/routes';

const TopBar: React.FC = () => {
  const dispatch = useDispatch();

  const onLogoClick = useCallback(() => {
    dispatch(push(UI_ACTUAL_ROUTES.dashboard));
  }, [dispatch]);

  return (
    <Wrapper>
      <div className="left-section">
        <IconLogoColored onClick={onLogoClick} />
      </div>
      <div className="right-section">
        <TopBarMenu />
      </div>
    </Wrapper>
  );
};

export default TopBar;

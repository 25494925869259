import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCrawlsColumns } from './assets';
import { ISortParams } from '../../../../types/crawls.types';
import { CrawlsDataKeys } from '../../../../enums/projects';
import { SortOrder } from '../../../../enums/data';

import { accountDetailsActions } from '../../../../redux/account-details/actions';
import {
  selectAccountProjectCrawls,
  selectCrawlsSearch,
  selectCrawlsSortKey,
  selectCrawlsSortOrder,
  selectUI,
} from '../../../../redux/account-details/selectors';

import { Table } from '../../../../components/Table';
import { StyledWrapper as Wrapper } from './CrawlsTable.styled';

const CrawlsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const crawls = useSelector(selectAccountProjectCrawls);
  const crawlsSearch = useSelector(selectCrawlsSearch);
  const sortKey = useSelector(selectCrawlsSortKey);
  const sortOrder = useSelector(selectCrawlsSortOrder);
  const { crawlsLoading } = useSelector(selectUI);

  const onProjectDelete = useCallback((projectID: string) => () => {
    dispatch(accountDetailsActions.projectRemove(projectID));
  }, [dispatch]);

  const onCrawlDelete = useCallback((crawlId: string) => () => {
    dispatch(accountDetailsActions.crawlRemove(crawlId));
  }, [dispatch]);

  const onSortChange = useCallback((key: CrawlsDataKeys) => () => {
    if (key !== sortKey) {
      dispatch(accountDetailsActions.crawlsSortKeyRefresh(key));
    }
    dispatch(accountDetailsActions.crawlsSortOrderRefresh(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc));
  }, [dispatch, sortOrder, sortKey]);

  useEffect(() => {
    dispatch(accountDetailsActions.crawlsReload());
  }, [dispatch, crawlsSearch, sortKey, sortOrder]);

  const columns = useMemo(() => {
    const sortParams: ISortParams = { sortKey, sortOrder, onSortChange };
    return getCrawlsColumns(t, onCrawlDelete, sortParams);
  }, [t, onCrawlDelete, sortKey, sortOrder, onSortChange]);

  return (
    <Wrapper>
      <Table
        columns={columns}
        loading={crawlsLoading}
        data={crawls}
      />
    </Wrapper>
  );
};

export default CrawlsTable;

export enum FIELD_NAMES {
  Domain = 'domain',
  Name = 'name',
  CountryCode = 'country_code',
  Strategy = 'strategy',
  RecrawlInterval = 'recrawl_interval',
  Keywords = 'keywords',
  Benchmarks = 'benchmarks',
  UrlGroups = 'url_groups',
  Invites = 'invites',
  Urls = 'urls',
  Goals = 'goals',
  WarmUpUrls = 'is_warmup_urls',
}

export enum ROWS_COUNTS {
  Ten = 10,
  Twenty = 20,
  TwentyFive = 25,
  Thirty = 30,
  Fourty = 40,
  Fifty = 50,
  OneHundred = 100,
  TwoHundred = 200,
  TwoHundredFifty = 250,
}

export enum CONDITIONS {
  Less = 'less',
  More = 'more',
}
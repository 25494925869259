import React, { useCallback, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import { UrlGroupTableKeys } from '../../../../enums/locales/forms';
import { IUrlGroup } from '../../../../redux/project-settings/types';

import { Checkbox } from "../../Checkbox";

import { EditableRow } from './EditableRow';
import { Wrapper } from './EditableTable.styled';

export interface IProps {
  data: IUrlGroup[];
  checked: string[];
  onCheck: (arg: string[]) => void;
  onDataChange: (...args: any) => void;
}

const EditableTable: React.FC<IProps> = ({
  data,
  checked,
  onCheck,
  onDataChange,
}) => {
  const { t } = useTranslation();
  const [editKey, setEditKey] = useState('');

  const isAllChecked = useMemo(() => isEqual(data.map(({ regex }) => regex).sort(), checked.sort()), [data, checked]);

  const onAllCheck = useCallback(() => {
    if (isAllChecked) {
      onCheck([]);
      return;
    }
    onCheck(data.map(({ regex }) => regex));
  }, [onCheck, data, isAllChecked]);

  const onCheckRow = useCallback((value) => {
    if (checked.includes(value)) {
      onCheck(checked.filter((item) => item !== value));
      return;
    }
    onCheck([...checked, value]);
  }, [onCheck, checked]);

  const onRowDelete = useCallback((value) => {
    onDataChange(data.filter(({ regex }) => regex !== value));
  }, [onDataChange, data]);

  const onEdit = useCallback((value) => () => {
    setEditKey(value);
  }, [setEditKey]);

  const onSubmit = useCallback(({ regex, name }) => {
    const filteredData = data.filter((item) => item.regex !== editKey);
    const isValid = !!regex && !!name && !filteredData.find((item) => item.regex === regex || item.name === name);

    if (!isValid) {
      setEditKey('');
      return;
    }

    const resultData = data.map((item) => {
      if (item.regex === editKey) {
        return { regex, name };
      }
      return item;
    });

    onDataChange(resultData);
    setEditKey('');
  }, [setEditKey, data, editKey, onDataChange]);

  return (
    <Wrapper>
      <thead>
        <tr>
          <th>
            <Checkbox
              checked={isAllChecked}
              onChange={onAllCheck}
              small
            />
          </th>
          <th>{t(UrlGroupTableKeys.Url)}</th>
          <th>{t(UrlGroupTableKeys.Name)}</th>
          <th>{t(UrlGroupTableKeys.Options)}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <EditableRow
            key={item.regex}
            cell={item}
            onEdit={onEdit(item.regex)}
            editKey={editKey}
            onSubmit={onSubmit}
            onCancel={onEdit('')}
            onRowDelete={onRowDelete}
            checked={checked.includes(item.regex)}
            onCheck={onCheckRow}
          />
        ))}
      </tbody>
    </Wrapper>
  );
};

export default EditableTable;
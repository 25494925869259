import { createSelector } from 'reselect';
import { RootState } from '../store';

const sort = (state: RootState) => state.KpiSummary.sort;
const summaryDataRestrictions = (state: RootState) => state.KpiSummary.summaryDataRestrictions;
const summaryData = (state: RootState) => state.KpiSummary.summaryData;

export const selectSortFeature = createSelector(
	[sort],
	(sort) => sort.feature,
);

export const selectSortOrder = createSelector(
	[sort],
	(sort) => sort.order,
);

export const selectSummaryDataRestrictions = createSelector(
	[summaryDataRestrictions],
	(summaryDataRestrictions) => summaryDataRestrictions,
);

export const selectSummaryData = createSelector(
	[summaryData],
	(summaryData) => summaryData,
);

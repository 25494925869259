export enum LeaderboardKeys {
  LeaderboardLCP = 'leaderboard_lcp',
  LeaderboardLCPValue = 'leaderboard_lcp_value',
  LeaderboardLCPValueNotInMS = 'leaderboard_lcp_value_not_in_ms',
  LeaderboardLCPRank = 'leaderboard_lcp_rank',
  LeaderboardLineChartTitle = 'leaderboard_lcp_line_chart_title',
  LeaderboardLCPAvg = 'leaderboard_avg_lcp',
  LeaderboardLCPDeviation = 'leaderboard_lcp_deviation',
  LeaderboardLCPUrlCount = 'leaderboard_lcp_url_count',
  LeaderboardLCPAverage = 'leaderboard_lcp_average',
  LCPBarChart = 'leaderboard_bar_chart',
  LCPTable = 'leaderboard_table',
  LeaderboardTopTitle = 'leaderboard_top_title',
  LeaderboardTopSubtitle = 'leaderboard_top_subtitle',
  LeaderboardCompetitorTitle = 'leaderboard_competitor_title',
  LeaderboardCompetitorSubtitle = 'leaderboard_competitor_subtitle',
}

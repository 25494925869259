import { ActionUnion, createAction } from '../commonTypes';
import { HistoryActionTypes, HistoryPayload, IUIPayload } from './types';
import { EmptyObject } from '../../types/common.types';
import { DomainHistoryInterval, Feature } from '../../enums/data';

export const historyActions = {
  historyReload: (): { type: HistoryActionTypes, payload: EmptyObject } => createAction(
    HistoryActionTypes.DOMAIN_HISTORY_RELOAD,
  ),
  historyRefresh: (payload: HistoryPayload): { type: HistoryActionTypes, payload: HistoryPayload } => createAction(
    HistoryActionTypes.DOMAIN_HISTORY_REFRESH,
    payload,
  ),
  historyFeatureRefresh: (payload: Feature): { type: HistoryActionTypes, payload: Feature } => createAction(
    HistoryActionTypes.CURRENT_FEATURE_REFRESH,
    payload,
  ),
  historyIntervalRefresh: (payload: DomainHistoryInterval): { type: HistoryActionTypes, payload: DomainHistoryInterval } => createAction(
    HistoryActionTypes.CURRENT_HISTORY_INTERVAL_REFRESH,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: HistoryActionTypes, payload: IUIPayload } => createAction(
    HistoryActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: HistoryActionTypes, payload: EmptyObject } => createAction(
    HistoryActionTypes.RESET_ALL,
  ),
}

export type HistoryActions = ActionUnion<typeof historyActions>;

import { UI_ROUTES } from './routes';

export const BREADCRUMBS = {
  [UI_ROUTES.metrics]: [
    UI_ROUTES.metricsPerformanceScore,
    UI_ROUTES.metricsLargestContentfulPaint,
    UI_ROUTES.metricsFirstInputDelay,
    UI_ROUTES.metricsTotalBlockingTime,
    UI_ROUTES.metricsTimeToInteractive,
    UI_ROUTES.metricsFirstContentfulPaint,
    UI_ROUTES.metricsDOMSize,
    UI_ROUTES.metricsServerResponseTime,
    UI_ROUTES.metricsCumulativeLayoutShift,
    UI_ROUTES.metricsInteractionToNextPaint,
    UI_ROUTES.metricsExperimentalTimeToFirstByte,
    UI_ROUTES.metricsFirstMeaningfulPaint,
    UI_ROUTES.metricsModernImages,
    UI_ROUTES.metricsUnminifiedJS,
    UI_ROUTES.metricsUnminifiedCSS,
    UI_ROUTES.metricsUnusedJS,
    UI_ROUTES.metricsUnusedCSS,
    UI_ROUTES.metricsOptimizedImages,
    UI_ROUTES.metricsRenderBlockingResources,
    UI_ROUTES.metricsNumTasksOver50ms,
    UI_ROUTES.metricsNumTasksOver100ms,
    UI_ROUTES.metricsNumTasksOver500ms,
    UI_ROUTES.metricsSpeedIndex,
    UI_ROUTES.metricsUsesTextCompression,
    UI_ROUTES.metricsOffscreenImages,
    UI_ROUTES.metricsNumFonts,
    UI_ROUTES.metricsNumRequests,
    UI_ROUTES.metricsNumTasks,
    UI_ROUTES.metricsNumScripts,
    UI_ROUTES.metricsNumStylesheets,
    UI_ROUTES.metricsTotalByteWeight,
    UI_ROUTES.metricsTotalTaskTime,
  ],
  [UI_ROUTES.leaderboards]: [
    UI_ROUTES.leaderboardsPerformanceScore,
    UI_ROUTES.leaderboardsLargestContentfulPaint,
    UI_ROUTES.leaderboardsFirstInputDelay,
    UI_ROUTES.leaderboardsTotalBlockingTime,
    UI_ROUTES.leaderboardsTimeToInteractive,
    UI_ROUTES.leaderboardsFirstContentfulPaint,
    UI_ROUTES.leaderboardsDOMSize,
    UI_ROUTES.leaderboardsServerResponseTime,
    UI_ROUTES.leaderboardsCumulativeLayoutShift,
    UI_ROUTES.leaderboardsFirstMeaningfulPaint,
    UI_ROUTES.leaderboardsModernImages,
    UI_ROUTES.leaderboardsUnminifiedJS,
    UI_ROUTES.leaderboardsUnminifiedCSS,
    UI_ROUTES.leaderboardsUnusedJS,
    UI_ROUTES.leaderboardsUnusedCSS,
    UI_ROUTES.leaderboardsOptimizedImages,
    UI_ROUTES.leaderboardsRenderBlockingResources,
    UI_ROUTES.leaderboardsNumTasksOver50ms,
    UI_ROUTES.leaderboardsNumTasksOver100ms,
    UI_ROUTES.leaderboardsNumTasksOver500ms,
    UI_ROUTES.leaderboardsSpeedIndex,
    UI_ROUTES.leaderboardsUsesTextCompression,
    UI_ROUTES.leaderboardsOffscreenImages,
    UI_ROUTES.leaderboardsNumFonts,
    UI_ROUTES.leaderboardsNumRequests,
    UI_ROUTES.leaderboardsNumTasks,
    UI_ROUTES.leaderboardsNumScripts,
    UI_ROUTES.leaderboardsNumStylesheets,
    UI_ROUTES.leaderboardsTotalByteWeight,
    UI_ROUTES.leaderboardsTotalTaskTime,
  ],
  [UI_ROUTES.compoundAnalysis]: [
    UI_ROUTES.networkRequestsAnalysis,
  ],
  [UI_ROUTES.analysis]: [
    UI_ROUTES.analysisDirectoryAnalysis,
  ],
  [UI_ROUTES.adminAccounts]: [
    UI_ROUTES.adminAccounts,
    UI_ROUTES.adminAccountDetails,
  ],
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";

export const usePaginatedSearch = (searchValue: string, refreshSearchActionCreator: (value: string) => any, uiActionCreator: (arg: any) => any) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const onSearch = useCallback((e) => setSearch(e.target.value), []);

  const isSearchable = useMemo(() => search !== searchValue, [search, searchValue]);

  const debouncedSearch = useCallback(debounce((value) => {
    dispatch(refreshSearchActionCreator(value));
    dispatch(uiActionCreator({ offset: 0 }));
  }, 1000), [dispatch]);

  const onSearchClick = useCallback(() => {
    if (isSearchable) {
      dispatch(refreshSearchActionCreator(search));
      dispatch(uiActionCreator({ offset: 0 }));
    }
  }, [dispatch, search, isSearchable]);

  const onEnterClick = useCallback((e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (isSearchable) {
        dispatch(refreshSearchActionCreator(search));
        dispatch(uiActionCreator({ offset: 0 }));
      }
    }
    return false;
  }, [search, isSearchable, dispatch]);

  useEffect(() => {
    if (isSearchable) {
      debouncedSearch(search);
    }
  }, [search, debouncedSearch, isSearchable]);

  return {
    search,
    onSearch,
    onEnterClick,
    onSearchClick,
  }
}
import { ActionUnion, createAction } from '../commonTypes';
import { IUIPayload, AccountsActionTypes } from './types';
import { EmptyObject } from '../../types/common.types';
import { ILicense } from '../../services/LicensesService/types';
import { SortOrder } from '../../enums/data';
import { AccountsDataKeys } from '../../enums/accounts';

export const accountsActions = {
  accountsReload: (): { type: AccountsActionTypes, payload: EmptyObject } => createAction(
    AccountsActionTypes.ACCOUNTS_RELOAD,
  ),
  accountsRefresh: (payload: ILicense[]): { type: AccountsActionTypes, payload: ILicense[] } => createAction(
    AccountsActionTypes.ACCOUNTS_REFRESH,
    payload,
  ),
  accountsSortKeyRefresh: (payload: AccountsDataKeys): { type: AccountsActionTypes, payload: AccountsDataKeys } => createAction(
    AccountsActionTypes.ACCOUNTS_SORT_KEY_REFRESH,
    payload,
  ),
  accountsSortOrderRefresh: (payload: SortOrder): { type: AccountsActionTypes, payload: SortOrder } => createAction(
    AccountsActionTypes.ACCOUNTS_SORT_ORDER_REFRESH,
    payload,
  ),
  accountDelete: (payload: string): { type: AccountsActionTypes, payload: string } => createAction(
    AccountsActionTypes.ACCOUNT_DELETE,
    payload,
  ),
  accountsSearchRefresh: (payload: string): { type: AccountsActionTypes, payload: string } => createAction(
    AccountsActionTypes.ACCOUNTS_SEARCH_REFRESH,
    payload,
  ),
  accountResourcesUpdate: (payload: Partial<ILicense>): { type: AccountsActionTypes, payload: Partial<ILicense> } => createAction(
    AccountsActionTypes.ACCOUNTS_RESOURCES_UPDATE,
    payload,
  ),
  accountCreate: (payload: Partial<ILicense>): { type: AccountsActionTypes, payload: Partial<ILicense> } => createAction(
    AccountsActionTypes.ACCOUNT_CREATE,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: AccountsActionTypes, payload: IUIPayload } => createAction(
    AccountsActionTypes.UI_MERGE,
    payload,
  ),
}

export type AccountsActions = ActionUnion<typeof accountsActions>;

import React, { useEffect } from 'react';
import { ResponsiveTreeMap } from '@nivo/treemap';

import { Wrapper } from './TreeMap.styled';
import { hexToRGBA } from '../../../utils/theme';
import { Loader } from '../Loader';

export interface IProps {
  data: any;
  title: string;
  loading?: boolean;
}

const TreeMap: React.FC<IProps> = ({ data, title, loading = false }) => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll('.inner-tree-map-container rect').forEach((rect) => {
        rect.setAttributeNS(null, "rx", "4");
        rect.setAttributeNS(null, "ry", "4");
      });
    }, 100);
  }, [data, title]);

  return (
    <Wrapper>
      <div className="tree-map-title">{title}</div>
      <div className="inner-tree-map-container">
        {loading && <Loader width={260} height={160} legendsVisible={false} />}
        {!loading && <ResponsiveTreeMap
          data={data}
          identity="name"
          value="value"
          innerPadding={6}
          nodeOpacity={1}
          enableLabel={true}
          orientLabel={false}
          parentLabelSize={26}
          parentLabelPadding={6}
          parentLabelPosition="top"
          labelTextColor={hexToRGBA('#ffffff', 0.5)}
          labelSkipSize={26}
          parentLabelTextColor={'white'}
          borderWidth={0}
          tile="resquarify"
          colors={(d) => d.data.color}
          theme={{ labels: { text: { fontSize: 12, fontWeight: 600, } } }}
        />}
      </div>
    </Wrapper>
  );
}

export default TreeMap;
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .image-holder {
    display: flex;
    justify-content: center;

    margin-bottom: 18px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;

    img {
      max-height: 270px;
      object-fit: cover;
    }
  }

  .timing-label {
    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-size: medium;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #171C28;
  }
`;

import styled from 'styled-components';

import { hexToRGBA } from '../../../../utils/theme';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: auto;

  margin-bottom: 24px;

  .navigation-group-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    position: relative;

    width: 100%;

    padding-right: 15px;

    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    color: #222A41;

    cursor: pointer;

    .navigation-group-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      min-height: 32px;
      min-width: 32px;

      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 10px;
    }

    .navigation-group-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      cursor: pointer;

      position: absolute;
      left: 40px;
      z-index: -1;
  
      padding-right: 11px;

      white-space: nowrap;

      transition: left 0.1s ease-out;
    }

    transition: width 0.1s ease-out;
  }

  .navigation-group-items {
    display: none;
  }

  &:not(.subkeys).active {
    .navigation-group-items {
      margin-top: 0;
    }
  }

  &.active {
    .navigation-group-title {
      background: ${hexToRGBA('#555BF0', 0.1)} 0% 0% no-repeat padding-box;
      border-radius: 10px;

      .navigation-group-icon {
        position: relative;

        svg {
          path {
            fill: #5468F0;
          }
        }

        ::before {
          content: '';
          position: absolute;

          top: 0;
          left: 0;

          height: 100%;
          width: 100%;

          background: ${hexToRGBA('#555BF0', 0.1)} 0% 0% no-repeat padding-box;
          border-radius: 10px;
        }
      }

      .navigation-group-name {
        color: #5468F0;
      }

      &::after {
        content: attr(data-group-title);
        display: block;
        padding-left: 12px;
        opacity: 0;
        white-space: nowrap;
      }
    }

    .navigation-group-items {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      margin-top: 14px;
      padding-left: 22px;
      max-height: calc(60vh - 150px);
      min-width: 206px;

      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #171C2810 0% 0% no-repeat padding-box;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #555BF0 0% 0% no-repeat padding-box;
        border-radius: 4px;
      }

      /* > * {
        &:first-child {
          margin-top: 14px;
        }
      } */
    }
  }

  &.collapsed {
    .navigation-group-title {
      width: 32px;

      .navigation-group-name {
        left: -220px;
        transition: left 0.1s ease-out;
      }

      transition: width 0.1s ease-out;
    }

    .navigation-group-items {
      display: none;
    }
  }

  &.collapsed.active {
    .navigation-group-title {

      &::after {
        display: none;
      }
    }
  }
`;

import orderBy from 'lodash/orderBy';
import { COMPARISON_RULES, SUB_TITLES, TITLES, CONDITIONAL_FORMATTING_RECOMMENDATIONS, CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET } from '../../constants/coreWebVitals';

import { Feature, SortOrder } from '../../enums/data';
import { CoreWebVitalsTypes } from '../../enums/coreWebVitals';
import { FeatureComparison, FeatureOverview, IDomainKpi, IMetricsUrl, IMetricsUrlGroup, ITableMetricsUrlGroup, ILeaderboardData, IDirectory } from '../../services/DataService/types';
import { ICWVTableData, FormattingRecommendations } from '../../types/coreWebVitals.types';
import { roundNumber } from '../common';
import { IUrlGroup } from '../../services/ProjectService/types';

const NON_FEATURE_KEYS = [
  'domain',
  'url_count',
  'is_project_domain',
  'is_benchmark_domain',
  'folder',
  'level',
  'nextLevel',
  'has_children',
  'root',
];

export const sortOverviewDataByKey = (data: FeatureOverview[], key: Feature | string, order: SortOrder, count?: number): FeatureOverview[] => {
  const sortedData = orderBy(data, [key], [order]);
  const untilIndex = count && count > 0 ? count : sortedData.length;
  const reducedSortedData: FeatureOverview[] = sortedData.slice(0, untilIndex);

  return reducedSortedData;
};

export const sortDirectoryDataByKey = (data: IDirectory[], key: Feature | string, order: SortOrder): IDirectory[] => {
  const sortedData = orderBy(data, [key], [order]);
  return sortedData;
};

export const sanitizeData = (data: FeatureOverview[]): FeatureOverview[] => {
  return data.map((item) => {
    return Object.keys(item).reduce((res, key) => {
      if (NON_FEATURE_KEYS.includes(key)) {
        res[key as keyof FeatureOverview] = item[key as keyof FeatureOverview] as never;
        return res;
      }

      const rawValue = item[key as Feature];
      const value = (typeof rawValue === 'string' && rawValue !== undefined ? +rawValue : rawValue) as number;
      res[key as Feature] = key === Feature.PerformanceScore ? Math.round(value * 100) : roundNumber(value, 10, 1000, 1);
      return res;
    }, {} as FeatureOverview);
  });
};

export const sanitizeDirectoryData = (data: IDirectory[]): IDirectory[] => {
  return data.map((item) => {
    return Object.keys(item).reduce((res, key) => {
      if (NON_FEATURE_KEYS.includes(key)) {
        res[key as keyof IDirectory] = item[key as keyof IDirectory] as never;
        return res;
      }

      const rawValue = item[key as Feature];
      const value = (typeof rawValue === 'string' && rawValue !== undefined ? +rawValue : rawValue) as number;
      res[key as Feature] = key === Feature.PerformanceScore ? Math.round(value * 100) : roundNumber(value, 10, 1000, 1);
      return res;
    }, {} as IDirectory);
  });
};

export const sanitizeComparisonData = (data: FeatureComparison): ICWVTableData[] => {
  return Object.keys(TITLES)
    .filter((key) => !!data[key as Feature])
    .map((key) => {
      const comparisonValue = data[key as Feature]?.comparison || 0;
      const comparison = key === Feature.PerformanceScore ? Math.round(comparisonValue * 100) : roundNumber(comparisonValue, 10, 1000, 1);

      const quantifierValue = data[key as Feature]?.quantifier || 0;
      const quantifier = key === Feature.PerformanceScore ? Math.round(quantifierValue * 100) : roundNumber(quantifierValue, 10, 1000, 1);

      const deviationValue = data[key as Feature]?.deviation || 0;
      const deviation = Math.round(deviationValue);

      return {
        title: TITLES[key as Feature],
        suffix: SUB_TITLES[key as Feature],
        bigger: !!COMPARISON_RULES[key as Feature],
        comparison,
        quantifier,
        deviation,
      }
    });
};

export const createFormattingRecommendations = (data: FeatureOverview[]): FormattingRecommendations => {
  const featureRanges = data.reduce((res, item) => {
    Object.keys(item).forEach((key) => {
      if (!NON_FEATURE_KEYS.includes(key)) {
        res[key as Feature] = [...(res[key as Feature] as number[] || []), item[key as Feature] as number];
      }
    });
    return res;
  }, {} as { [key in Feature]?: number[] });

  return Object.keys(featureRanges).reduce((res, key) => {
    const bestValue = Math.min(...featureRanges[key as Feature] as number[]);
    const worstValue = Math.max(...featureRanges[key as Feature] as number[]);
    const conditionalBase = worstValue - bestValue;

    res[key as Feature] = {
      [CoreWebVitalsTypes.Slow]: {
        from: bestValue + (100 - CONDITIONAL_FORMATTING_RECOMMENDATIONS[CoreWebVitalsTypes.Slow].from) * conditionalBase / 100,
        to: bestValue + (100 - CONDITIONAL_FORMATTING_RECOMMENDATIONS[CoreWebVitalsTypes.Slow].to) * conditionalBase / 100,
      },
      [CoreWebVitalsTypes.Moderate]: {
        from: bestValue + (100 - CONDITIONAL_FORMATTING_RECOMMENDATIONS[CoreWebVitalsTypes.Moderate].from) * conditionalBase / 100,
        to: bestValue + (100 - CONDITIONAL_FORMATTING_RECOMMENDATIONS[CoreWebVitalsTypes.Moderate].to) * conditionalBase / 100,
      },
      [CoreWebVitalsTypes.Fast]: {
        from: bestValue + (100 - CONDITIONAL_FORMATTING_RECOMMENDATIONS[CoreWebVitalsTypes.Fast].from) * conditionalBase / 100,
        to: bestValue + (100 - CONDITIONAL_FORMATTING_RECOMMENDATIONS[CoreWebVitalsTypes.Fast].to) * conditionalBase / 100,
      },
    }

    return res;
  }, {} as FormattingRecommendations);
};

export const createFormattingRecommendationsByTopMarket = (data: { [key in Feature]?: IDomainKpi }): FormattingRecommendations => {
  const featuresTopMarket = Object.keys(data).reduce((res, key) => {
    res[key as Feature] = data[key as Feature]?.market_avg;
    return res;
  }, {} as { [key in Feature]?: number });

  return Object.keys(featuresTopMarket).reduce((res, key) => {
    const conditionalBase = featuresTopMarket[key as Feature] as number;

    res[key as Feature] = {
      [CoreWebVitalsTypes.Slow]: {
        from: CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET[CoreWebVitalsTypes.Slow].from * conditionalBase / 100,
        to: CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET[CoreWebVitalsTypes.Slow].to * conditionalBase / 100,
      },
      [CoreWebVitalsTypes.Moderate]: {
        from: CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET[CoreWebVitalsTypes.Moderate].from * conditionalBase / 100,
        to: CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET[CoreWebVitalsTypes.Moderate].to * conditionalBase / 100,
      },
      [CoreWebVitalsTypes.Fast]: {
        from: CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET[CoreWebVitalsTypes.Fast].from * conditionalBase / 100,
        to: CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET[CoreWebVitalsTypes.Fast].to * conditionalBase / 100,
      },
    }

    return res;
  }, {} as FormattingRecommendations);
}

export const sanitizeLCPURLGroupsData = (data: IMetricsUrlGroup[], urlGroupsList: IUrlGroup[]): ITableMetricsUrlGroup[] => {
  return data.map((item) => {
    const urlGroupName = urlGroupsList.find((group) => item.url_group_id === group.url_group_id)?.name || item.url_group_id;

    const comparisonValue = item.comparison || 0;
    const comparison = roundNumber(comparisonValue, 10, 1000, 1);

    const quantifierValue = item.quantifier || 0;
    const quantifier = roundNumber(quantifierValue, 10, 1000, 1);

    const deviationValue = item.deviation || 0;
    const deviation = Math.round(deviationValue * 100);

    return {
      url_group_name: urlGroupName,
      comparison,
      quantifier,
      deviation,
    }
  });
};

export const sanitizeLCPURLsData = (data: IMetricsUrl[]): IMetricsUrl[] => {
  return data.map((item) => {
    const comparisonValue = item.comparison || 0;
    const comparison = roundNumber(comparisonValue, 10, 1000, 1);

    const quantifierValue = item.quantifier || 0;
    const quantifier = roundNumber(quantifierValue, 10, 1000, 1);

    const deviationValue = item.deviation || 0;
    const deviation = Math.round(deviationValue * 100);

    return {
      ...item,
      comparison,
      quantifier,
      deviation,
    };
  });
};

export const sanitizeLeaderboardData = (data: ILeaderboardData[], isPS: boolean): ILeaderboardData[] => {
  return data.map((item) => {
    const rawValue = item.value || 0;
    const value = roundNumber(rawValue, 10, 1000, 1);

    const rawDeviation = item.deviation || 0;
    const deviation = Math.round(rawDeviation * 10) / 10;

    return {
      ...item,
      value: isPS ? Math.round(value * 100) : value,
      deviation,
    };
  });
};

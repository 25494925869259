import React, { useCallback } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePaginatedSearch } from '../../../hooks/usePaginatedSearch';

import AntdModal from 'antd/lib/modal';
import AntdPopConfirm from 'antd/lib/popconfirm';

import { beautifyUrl } from '../../../utils/common';
import { ACCOUNT_DETAILS_PROJECT_DATE_FORMAT } from '../../../constants/formats';
import { Strategy, RecrawlInterval } from '../../../enums/projects';
import { DashboardTopMenuKeys } from '../../../enums/locales/dashboard';
import { FieldButtonTextKeys } from '../../../enums/locales/forms';
import { DashboardWebVitalsGroups } from '../../../enums/locales/dashboard';
import { ProjectsOverviewKeys, ProjectsOverviewFieldsKeys, RecrawlIntervalKeys } from '../../../enums/locales/projects';
import { AccountsKeys, AccountDetailsKeys } from '../../../enums/locales/accounts';
import { ProfileMenuKeys } from '../../../enums/locales/topBar';

import { accountDetailsActions } from '../../../redux/account-details/actions';
import { modalsActions } from '../../../redux/modals/actions';
import {
  selectProjectID,
  selectAccountProjectByID,
  selectAccountsSearch,
  selectAccountLicenseCounts,
  selectAccountProjectCrawls,
  selectTargetLicenseID,
  selectUI,
} from '../../../redux/account-details/selectors';
import { selectProjectMoveVisible } from '../../../redux/modals/selectors';

import { IconClose, IconSearch, IconPCSmall, IconMobileSmall } from '../../../components/styled/icons';
import { Button, ButtonType } from '../../../components/styled/Button';
import { Input } from '../../../components/styled/Input';
import { DomainPictureHolder } from '../../../components/DomainPictureHolder';
import { AccountsTable } from './AccountsTable';
import { StyledBodyWrapper } from './ProjectMoveModal.styled';

const STRATEGY_ICONS_MAP = {
  [Strategy.Desktop]: <IconPCSmall />,
  [Strategy.Mobile]: <IconMobileSmall />,
};

const STRATEGY_KEYS_MAP = {
  [Strategy.Desktop]: DashboardTopMenuKeys.PCTitle,
  [Strategy.Mobile]: DashboardTopMenuKeys.MobileTitle,
};

const RECRAWL_INTERVAL_KEYS_MAP = {
  [RecrawlInterval.Daily]: RecrawlIntervalKeys.Daily,
  [RecrawlInterval.MondayToFriday]: RecrawlIntervalKeys.MondayToFriday,
  [RecrawlInterval.BiWeekly]: RecrawlIntervalKeys.BiWeekly,
  [RecrawlInterval.Weekly]: RecrawlIntervalKeys.Weekly,
  [RecrawlInterval.Monthly]: RecrawlIntervalKeys.Monthly,
};

const ProjectMoveModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch =  useDispatch();
  const isVisible = useSelector(selectProjectMoveVisible);
  const targetLicenseID = useSelector(selectTargetLicenseID);
  const projectID = useSelector(selectProjectID);
  const project = useSelector(selectAccountProjectByID(projectID));
  const accountsSearch = useSelector(selectAccountsSearch);
  const licenseCounts = useSelector(selectAccountLicenseCounts);
  const crawls = useSelector(selectAccountProjectCrawls);
  const { projectMoving } = useSelector(selectUI);

  const isCrawlsEmpty = !crawls.length;

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.projectMoveHide());
    dispatch(accountDetailsActions.targetLicenseIDRefresh(null));
    dispatch(accountDetailsActions.accountsRefresh([]));

    if (!isCrawlsEmpty) {
      dispatch(modalsActions.projectCrawlsShow());
    }
  }, [dispatch, isCrawlsEmpty]);

  const onProjectMove = useCallback(() => {
    dispatch(accountDetailsActions.projectMove());
  }, [dispatch]);

  const {
    search,
    onEnterClick,
    onSearch,
    onSearchClick,
  } = usePaginatedSearch(accountsSearch, accountDetailsActions.accountsSearchRefresh, accountDetailsActions.uiMerge);

  return (
    <AntdModal
      centered
      title={(
        <>
          <div className="ant-modal-title-top">
            <div className="project-move-modal-title">
              <div className="project-label">
                {t(AccountsKeys.ProjectMoveLabel)}
              </div>
              <div className="project-id">
                {projectID}
              </div>
            </div>
          </div>
          <div className="ant-modal-title-bottom">
            <div className="project-section">
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Name)}
                </div>
                <div className="project-data-value">
                  <div title={project?.name} className="domain-name">
                    <div className="domain-name-picture">
                      <DomainPictureHolder domainName={project?.domain || ''} />
                    </div>
                    <div className="domain-name-value">
                      {project?.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Domain)}
                </div>
                <div className="project-data-value">
                  {beautifyUrl(project?.domain || '')?.replace('/', '')}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Type)}
                </div>
                <div className="project-data-value">
                  <div className="strategy-type">
                    <div className="strategy-type-icon">
                      {STRATEGY_ICONS_MAP[project?.strategy as Strategy]}
                    </div>
                    <div className="strategy-type-title">
                      {t(STRATEGY_KEYS_MAP[project?.strategy as Strategy])}
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Frequency)}
                </div>
                <div className="project-data-value">
                  {t(RECRAWL_INTERVAL_KEYS_MAP[project?.recrawl_interval as RecrawlInterval])}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(FieldButtonTextKeys.EndUsers)}
                </div>
                <div className="project-data-value">
                  {licenseCounts?.url_slots_used}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Keywords)}
                </div>
                <div className="project-data-value">
                  {project?.keywords.length}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(DashboardWebVitalsGroups.Benchmarks)}
                </div>
                <div className="project-data-value">
                  {project?.benchmarks.length}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(AccountsKeys.CreatedAtLabel)}
                </div>
                <div className="project-data-value">
                  {project?.created_at && format(new Date(project?.created_at), ACCOUNT_DETAILS_PROJECT_DATE_FORMAT)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      footer={(
        <div className="project-move-modal-actions">
          <div className="cancel-button">
            <Button
              small
              type={ButtonType.Secondary}
              title={t(AccountDetailsKeys.MoveProjectCancelButton)}
              onClick={handleCancel}
            />
          </div>
          <div className="project-move-button">
            <Button
              small
              disabled={!targetLicenseID || projectMoving}
              type={ButtonType.Primary}
              title={t(AccountDetailsKeys.MoveProjectButton)}
              onClick={onProjectMove}
            />
          </div>
        </div>
      )}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="project-move-modal-container"
      width={'70%'}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className="search-section">
          <div className="left-section">
            {t(ProfileMenuKeys.adminAccountsTitle)}
          </div>
          <div className="right-section">
            <div className="search-input-container">
              <Input
                className="search-input"
                value={search}
                onKeyDown={onEnterClick}
                onChange={onSearch}
                placeholder={`${t(AccountsKeys.SearchAccounts)}...`}
                icon={<IconSearch onClick={onSearchClick} />}
              />
            </div>
          </div>
        </div>
        <div className="accounts-section">
          <AccountsTable />
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default ProjectMoveModal;

import React from "react";
import { SelectProps } from "antd/lib/select";

import { StyledSelect } from './Select.styled';
import { IconDropdown } from "../icons";

export interface IProps extends SelectProps<any> {
  width?: number;
  height?: number;
  error?: boolean;
  onBlur?: (...args: any) => void
}

const Select: React.FC<IProps> = (props) => {
  return (
    <StyledSelect dropdownStyle={{ minWidth: 300 }} suffixIcon={<IconDropdown />} {...props} />
  );
};

export default Select;

import { SortOrder } from '../../enums/data';
import { ProjectsOverviewDataKeys } from '../../enums/projects';
import { IProjectOverviewDataItem } from '../../types/projects.types';

export enum ProjectsOverviewActionTypes {
  PROJECTS_OVERVIEW_SORT_CHANGE = '[Projects-Overview] sort-change',
  PROJECTS_OVERVIEW_SORT_REFRESH = '[Projects-Overview] sort-refresh',

  PROJECTS_OVERVIEW_DATA_UPDATE = '[Projects-Overview] data-update',
  PROJECTS_OVERVIEW_DATA_REFRESH = '[Projects-Overview] data-refresh',

  RESET_ALL = '[Projects-Overview] reset-all',
}

export interface IProjectsOverviewSortChangePayload {
  dataKey: ProjectsOverviewDataKeys;
  order?: SortOrder;
}

export interface IProjectsOverviewSortRefreshPayload extends Required<IProjectsOverviewSortChangePayload> {}

export type ProjectsOverviewDataPayload = IProjectOverviewDataItem[];

export interface ProjectsOverviewState {
  sort: IProjectsOverviewSortRefreshPayload;
  data: ProjectsOverviewDataPayload;
}

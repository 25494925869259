import styled from 'styled-components';
import { ReactComponent as RawGoogleSVG } from '../../../../assets/icons/google.svg';

export const IconGoogle = styled(RawGoogleSVG)`
  .a {
    fill:#fbbb00;
  }

  .b{
    fill:#518ef8;
  }

  .c{
    fill:#28b446;
  }

  .d {
    fill:#f14336;
  }
`;

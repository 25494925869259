import styled from "styled-components";
import { hexToRGBA } from "../../../../utils/theme";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  border: 2px solid ${({ theme }) => theme.colors.grey};
  border-radius: 18px;
  margin-bottom: 18px;

  .table-loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => hexToRGBA(theme.colors.secondaryDark, 0.05)};
    z-index: 200;
    border-radius: 18px;
  }

  .table-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      .header-title {
        font-size: 18px;
        font-weight: 600;
        span {
          opacity: 0.4;
          margin-left: 10px;
        }
      }
    }

  .ant-checkbox {
    top: 0.05rem;
  }

  .suggestions-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;

    thead {

      tr {
        height: 40px;
      }

      th {
        vertical-align: middle;
        text-align: start;
        font-size: 12px;
        font-weight: 600;
        border-bottom: 2px solid ${({ theme }) => theme.colors.primaryDark};

        &:first-child {
          .small {
            justify-content: center;
            .ant-checkbox-inner::after {
              left: 23%!important;
            }
          }
          width: 30px;
        }

        &:nth-child(2),
        &:last-child {
          width: 30%;
        }
      }
    }
    
    tbody tr {
      cursor: pointer;
    }

    tr {
      height: 28px;
      border-spacing: 0;

      &.checked {
        background: ${({ theme }) => hexToRGBA(theme.colors.linkBlue, 0.1)};
        
        td {
          color: ${({ theme }) => theme.colors.linkBlue};
        }

        td:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        td:nth-child(2) {
          font-weight: 600;
        }
      }

      td {
        vertical-align: middle;
        text-align: start;
        font-size: 12px;
        font-weight: medium;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
          .small {
            justify-content: center;
            .ant-checkbox-inner::after {
              left: 23%!important;
            }
          }
        }
      }
    }
  }
`;
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { THEME } from '../../../../../../constants/theme';

import { selectCrawlsInProgress } from '../../../../../../redux/projects/selectors';

import { ProjectMenuKeys } from '../../../../../../enums/locales/topBar';
import { IUrlGroup } from '../../../../../../services/ProjectService/types';

import { StackedIndicator } from '../../../../../../components/StackedIndicator';
import { IconEdit, IconLab } from '../../../../../../components/styled/icons';
import { Button, ButtonType } from '../../../../../../components/styled/Button';
import { DomainPictureHolder } from '../../../../../../components/DomainPictureHolder';
import { StyledWrapper as Wrapper } from './ProjectListItem.styled';

export interface IProps {
  active?: boolean;
  groupActive?: boolean;
  projectID: string;
  groupID?: string | null;
  domainName: string;
  projectName: string;
  urlGroups: IUrlGroup[];
  isTestMode?: boolean;
  onClickEditProject?: (projectID: string) => void;
  onClick?: (projectID: string, groupID?: string) => void;
}

const ProjectListItem: React.FC<IProps> = ({
  active,
  groupActive,
  projectID,
  groupID,
  domainName,
  projectName,
  urlGroups,
  isTestMode,
  onClickEditProject,
  onClick,
}) => {
  const { t } = useTranslation();
  const crawlsInProgress = useSelector(selectCrawlsInProgress);

  const crawlProgress = useMemo(() => crawlsInProgress[projectID], [crawlsInProgress, projectID]);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    onClick && onClick(projectID);
  }, [onClick, projectID]);

  const handleClickEditProject = useCallback((e) => {
    e.stopPropagation();
    onClickEditProject && onClickEditProject(projectID);
  }, [onClickEditProject, projectID]);

  const getClickProjectGroupHandler = useCallback((groupID: string) => (e: any) => {
    e.stopPropagation();
    onClick && onClick(projectID, groupID);
  }, [onClick, projectID]);

  const isActive = !groupActive && active;

  return (
    <Wrapper className={clsx({ ['test-mode']: isTestMode })} onClick={handleClick}>
      <div className={clsx('project-item-title', { active: isActive })}>
        <div className="project-item-domain-picture">
          <DomainPictureHolder domainName={domainName} />
        </div>
        <div title={projectName || domainName} className="project-item-domain-name">
          <span>{projectName || domainName}</span>
          {crawlProgress !== undefined && <div className={clsx('project-item-crawl-in-progress-indicator')}>
            <StackedIndicator
              hideCounter
              color={THEME.colors.primaryBlue}
              value={crawlProgress as number}
              maxValue={100}
            />
          </div>}
        </div>
        {isTestMode && <div className="project-item-test-mode-label">
          <IconLab />
        </div>}
      </div>
      <div className="project-item-groups">
        {urlGroups.map(({ url_group_id, name }) => (
          <div
            key={name}
            className={clsx("project-item-group", { active: groupID === url_group_id })}
            onClick={getClickProjectGroupHandler(url_group_id)}
          >
            {name}
          </div>
        ))}
      </div>
      <div className={clsx('project-item-edit-button', { disabled: crawlProgress !== undefined })}>
        <Button
          small
          rounded
          type={ButtonType.Outline}
          icon={<IconEdit />}
          title={t(ProjectMenuKeys.editProjectButtonTitle)}
          onClick={handleClickEditProject}
        />
      </div>
    </Wrapper>
  );
};

export default ProjectListItem

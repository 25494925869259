import { ROWS_COUNTS } from '../../enums/projectSettings';
import { IProject, ICrawl } from '../../services/ProjectService/types';
import { ILicense } from '../../services/LicensesService/types';
import { IUserSimple } from '../../services/AuthService/types';
import { ProjectsDataKeys, CrawlsDataKeys } from '../../enums/projects';
import { SortOrder } from '../../enums/data';

export enum AccountDetailsActionTypes {
  ACCOUNTS_DATA_RELOAD = '[Account Details] accounts-data-reload',
  ACCOUNTS_DATA_REFRESH = '[Account Details] accounts-data-refresh',

  CREATOR_DATA_RELOAD = '[Account Details] creator-data-reload',
  CREATOR_DATA_REFRESH = '[Account Details] creator-data-refresh',

  LICENSE_DATA_RELOAD = '[Account Details] license-data-reload',
  LICENSE_DATA_REFRESH = '[Account Details] license-data-refresh',

  PROJECTS_DATA_RELOAD = '[Account Details] projects-data-reload',
  PROJECTS_DATA_REFRESH = '[Account Details] projects-data-refresh',

  PROJECTS_SORT_KEY_REFRESH = '[Account Details] projects-sort-key-refresh',
  PROJECTS_SORT_ORDER_REFRESH = '[Account Details] projects-sort-order-refresh',

  CRAWLS_DATA_RELOAD = '[Account Details] crawls-data-reload',
  CRAWLS_DATA_REFRESH = '[Account Details] crawls-data-refresh',

  CRAWLS_SORT_KEY_REFRESH = '[Account Details] crawls-sort-key-refresh',
  CRAWLS_SORT_ORDER_REFRESH = '[Account Details] crawls-sort-order-refresh',

  ACCOUNT_LICENSE_ID_REFRESH = '[Account Details] account-license-id-refresh',
  TARGET_LICENSE_ID_REFRESH = '[Account Details] target-license-id-refresh',
  PROJECT_ID_REFRESH = '[Account Details] project-id-refresh',

  ACCOUNTS_SEARCH_REFRESH = '[Accounts Details] accounts-search-refresh',
  PROJECTS_SEARCH_REFRESH = '[Accounts Details] projects-search-refresh',
  CRAWLS_SEARCH_REFRESH = '[Accounts Details] crawls-search-refresh',

  PROJECT_MOVE = '[Accounts Details] project-move',
  PROJECT_REMOVE = '[Accounts Details] project-remove',
  CRAWL_REMOVE = '[Accounts Details] crawl-remove',

  UI_MERGE = '[Account Details] ui-merge',

  RESET_ALL = '[Account Details] reset-all',
}

export interface IUIPayload {
  accountsLoading?: boolean;
  creatorLoading?: boolean;
  licenseLoading?: boolean;
  projectsLoading?: boolean;
  crawlsLoading?: boolean;
  projectMoving?: boolean;
  projectRemoving?: boolean;
  crawlRemoving?: boolean;
  offset?: number;
  limit?: ROWS_COUNTS;
  count?: number | null;
}

export interface AccountDetailsState {
  accountLicenseID: string | null;
  targetLicenseID: string | null;
  projectID: string | null;
  accounts: ILicense[];
  creator: Partial<IUserSimple>;
  license: Partial<ILicense>;
  projects: IProject[];
  crawls: ICrawl[];
  accountsSearch: string;
  projectsSearch: string;
  projectsSortOrder: SortOrder,
  projectsSortKey: ProjectsDataKeys,
  crawlsSearch: string;
  crawlsSortOrder: SortOrder,
  crawlsSortKey: CrawlsDataKeys,
  UI: IUIPayload;
}

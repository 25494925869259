import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { Languages } from '../../../enums/locales/languages';
import { TopBarMenuKeys } from '../../../enums/locales/topBar';
import { SUPPORTED_LANGUAGES as languagesOptions } from '../../../constants/locales';
import * as ProjectsUtils from '../../../utils/projects';
import { UI_ROUTES } from '../../../constants/routes';

import { authActions } from '../../../redux/auth/actions';
import { projectsActions } from '../../../redux/projects/actions';
import { projectSettingsActions } from '../../../redux/project-settings/actions';
import { licensesActions } from '../../../redux/licenses/actions';
import { selectLanguage } from '../../../redux/app/selectors';
import { selectPathPattern, selectQueryParams } from '../../../redux/router/selectors';
import { selectProfileName, selectProfilePicture, selectUserEmail } from '../../../redux/auth/selectors';
import { selectActiveProjectID, selectActiveProjectGroupID, selectProjects } from '../../../redux/projects/selectors';
import { selectLicenses, selectActiveLicenseID } from '../../../redux/licenses/selectors';

import { Button, ButtonType } from '../../../components/styled/Button';
import { ProjectMenu } from './ProjectMenu';
import { ProfileMenu } from './ProfileMenu';
import { StyledWrapper as Wrapper } from './TopBarMenu.styled';
import { modalsActions } from '../../../redux/modals/actions';
import { profileSettingsActions } from '../../../redux/profile-settings/actions';
import { removeQueryParam, resetQueryParams } from '../../../utils/router';
import { QUERY_PARAMS_KEYS } from '../../../enums/queryParams';
import { clearActiveProjectGroupsFromUser } from '../../../utils/storage';

const TopBarMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguageKey = useSelector(selectLanguage);
  const pathPattern = useSelector(selectPathPattern);
  const avatarUrl = useSelector(selectProfilePicture);
  const name = useSelector(selectProfileName);
  const email = useSelector(selectUserEmail);
  const activeProjectID = useSelector(selectActiveProjectID);
  const activeProjectGroupID = useSelector(selectActiveProjectGroupID);
  const projects = useSelector(selectProjects);
  const activeLicenseID = useSelector(selectActiveLicenseID);
  const licenses = useSelector(selectLicenses);
  const query = useSelector(selectQueryParams);
  const licenseID = useSelector(selectActiveLicenseID);

  const projectOptions = useMemo(() => {
    return ProjectsUtils.createProjectOptions(projects);
  }, [projects])

  const handleSelectProject = useCallback((projectID, groupID?: string) => {
    dispatch(projectsActions.activeProjectIDRefresh(projectID));
    dispatch(projectsActions.activeProjectGroupIDRefresh(groupID || null));
    if (!groupID) {
      removeQueryParam(QUERY_PARAMS_KEYS.UrlGroupID, query);
      if (licenseID) {
        clearActiveProjectGroupsFromUser(licenseID);
      }
    }
  }, [dispatch, licenseID, query]);

  const handleClickEditProject = useCallback((projectID) => {
    dispatch(projectSettingsActions.updateInit(projectID));
  }, [dispatch]);

  const handleClickAddNewProject = useCallback(() => {
    dispatch(modalsActions.siderShow());
  }, [dispatch]);

  const handleClickAllProjects = useCallback(() => {
    dispatch(push(generatePath(UI_ROUTES.projectsOverview, { lang: currentLanguageKey })));
  }, [dispatch, currentLanguageKey]);

  const handleClickSignOut = useCallback(() => {
    dispatch(authActions.signOut());
  }, [dispatch]);

  const handleSelectLicense = useCallback((id: string) => {
    dispatch(licensesActions.activeLicenseIDRefresh(id));
    dispatch(projectsActions.activeProjectIDRefresh(null));
    dispatch(projectsActions.activeProjectGroupIDRefresh(null));
    resetQueryParams();
    if (pathPattern.includes('/admin')) {
      dispatch(push(generatePath(UI_ROUTES.dashboard, { lang: currentLanguageKey })));
    }
  }, [dispatch, pathPattern, currentLanguageKey]);

  const handleSelectLanguage = useCallback((key: Languages) => {
    dispatch(push(generatePath(pathPattern, { lang: key })));
  }, [dispatch, pathPattern]);

  const onProfileSettings = useCallback(() => {
    dispatch(profileSettingsActions.init());
  }, [dispatch]);

  return (
    <Wrapper>
      <div className="project-section">
        <ProjectMenu
          selectedProjectID={activeProjectID}
          selectedProjectGroupID={activeProjectGroupID}
          projectOptions={projectOptions}
          onSelectProject={handleSelectProject}
          onClickEditProject={handleClickEditProject}
          onClickAllProjects={handleClickAllProjects}
        />
      </div>
      <div className="add-new-project-section">
        <Button
          small
          type={ButtonType.Primary}
          title={t(TopBarMenuKeys.addNewProjectButtonTitle)}
          onClick={handleClickAddNewProject}
        />
      </div>
      <div className="profile-section">
        <ProfileMenu
          url={avatarUrl}
          name={name}
          email={email}
          selectedLicenseID={activeLicenseID}
          licenseOptions={licenses}
          selectedLanguageKey={currentLanguageKey}
          languageOptions={languagesOptions}
          onSelectLicense={handleSelectLicense}
          onSelectLanguage={handleSelectLanguage}
          onClickProfileSettings={onProfileSettings}
          onClickSignOut={handleClickSignOut}
        />
      </div>
    </Wrapper>
  );
};

export default TopBarMenu;

import React from "react";
import clsx from 'clsx';
import AntdCheckbox, { CheckboxProps } from 'antd/lib/checkbox';

import { StyledWrapper as Wrapper } from './Checkbox.styled';

export interface IProps extends CheckboxProps {
  label?: string;
  small?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  label,
  small,
  ...restProps
}) => {
  const className = clsx({
    small,
  });

  return (
    <Wrapper className={className}>
      <AntdCheckbox
        {...restProps}
      >
        {label && <span className="label">
          {label}
        </span>}
      </AntdCheckbox>
    </Wrapper>
  );
};

export default Checkbox;

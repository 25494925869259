import cloneDeep from "lodash/cloneDeep";
import { STORAGE_KEYS } from '../../constants/storage';

export const storeValue = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value));
}

export const restoreValue = (key: string, defaultValue: string | null | [] = null) => {
	const value: string | null = localStorage.getItem(key);
	try {
		const parsedValue = JSON.parse(value as string);
		return parsedValue || defaultValue;
	} catch (e) {
		return defaultValue;
	}
}

export const clearValue = (key: string) => {
	localStorage.removeItem(key);
}

export const clearAll = () => {
	localStorage.clear();
}

export const storeAccessToken = (accessToken = '') => {
	storeValue(STORAGE_KEYS.accessToken, accessToken);
}

export const restoreAccessToken = () => {
	return restoreValue(STORAGE_KEYS.accessToken, '');
}

export const clearAccessToken = () => {
	clearValue(STORAGE_KEYS.accessToken);
}

export const storeRefreshToken = (refreshToken = '') => {
	storeValue(STORAGE_KEYS.refreshToken, refreshToken);
}

export const restoreRefreshToken = () => {
	return restoreValue(STORAGE_KEYS.refreshToken, '');
}

export const clearRefreshToken = () => {
	clearValue(STORAGE_KEYS.refreshToken);
}

export const storeTokens = (accessToken: string, refreshToken: string) => {
	storeAccessToken(accessToken);
	storeRefreshToken(refreshToken);
}

export const restoreTokens = () => {
	return {
		accessToken: restoreAccessToken(),
		refreshToken: restoreRefreshToken(),
	};
}

export const clearTokens = () => {
	clearAccessToken();
	clearRefreshToken();
}

export const storeDeviceIdentifier = (deviceIdentifier = '') => {
	storeValue(STORAGE_KEYS.deviceIdentifier, deviceIdentifier);
}

export const restoreDeviceIdentifier = () => {
	return restoreValue(STORAGE_KEYS.deviceIdentifier, '');
}

export const clearDeviceIdentifier = () => {
	clearValue(STORAGE_KEYS.deviceIdentifier);
}

export const storeActiveProjects = (activeProjects: Record<string, string>) => {
	storeValue(STORAGE_KEYS.activeProjects, activeProjects);
}

export const restoreActiveProjects = () => {
	return restoreValue(STORAGE_KEYS.activeProjects);
}

export const clearActiveProjects = () => {
	clearValue(STORAGE_KEYS.activeProjects);
}

export const storeActiveProjectGroups = (activeProjectGroups: Record<string, string>) => {
	storeValue(STORAGE_KEYS.activeProjectGroups, activeProjectGroups);
}

export const restoreActiveProjectGroups = () => {
	return restoreValue(STORAGE_KEYS.activeProjectGroups);
}

export const clearActiveProjectGroupsFromUser = (licenseID: string) => {
	const activeProjectGroups = restoreActiveProjectGroups();
	const currentProjectGroups = cloneDeep(activeProjectGroups || {});
	currentProjectGroups[licenseID] = null;

	storeValue(STORAGE_KEYS.activeProjectGroups, { ...currentProjectGroups });
}

export const clearActiveProjectGroups = () => {
	clearValue(STORAGE_KEYS.activeProjectGroups);
}

export const storeActiveLicenses = (activeLicenses: Record<string, string>) => {
	storeValue(STORAGE_KEYS.activeLicenses, activeLicenses);
}

export const restoreActiveLicenses = () => {
	return restoreValue(STORAGE_KEYS.activeLicenses);
}

export const clearActiveLicenses = () => {
	clearValue(STORAGE_KEYS.activeLicenses);
}

export const storeTestMode = (testMode = false) => {
	storeValue(STORAGE_KEYS.testMode, testMode);
}

export const restoreTestMode = () => {
	return restoreValue(STORAGE_KEYS.testMode, null);
}

export const clearDTestMode = () => {
	clearValue(STORAGE_KEYS.testMode);
}

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Steps from 'antd/lib/steps';

import { IconCheckmarkSmall } from '../../../../components/styled/icons';
import { SiderSteps, SiderStepsSuffixes } from '../../../../enums/locales/modals';
import { Wrapper } from './Stepper.styled';
import { selectFinishedSteps } from '../../../../redux/project-settings/selectors';

const { Step } = Steps;

export interface IStep {
  title: SiderSteps;
  isOptional: boolean;
}

export interface IProps {
  steps: IStep[];
}

const Stepper: React.FC<IProps> = ({ steps }) => {
  const { t } = useTranslation();
  const finishedSteps = useSelector(selectFinishedSteps);

  const onChange = useCallback((value) => () => {
    const element = document.getElementById(`step-${value}`);
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);

  const getStepStatus = useCallback((step) => {
    return finishedSteps.includes(step) ? 'finish' : 'wait';
  }, [finishedSteps]);

  return (
    <Wrapper>
      <Steps direction="vertical">
        {steps.map(({ title, isOptional }, index) => (
          <Step
            key={`${title}-${index}`}
            onClick={onChange(index)}
            icon={<IconCheckmarkSmall />}
            status={getStepStatus(index)}
            title={<>{t(title)} {isOptional && <span>({t(SiderStepsSuffixes.Optional)})</span>}</>}
          />
        ))}
      </Steps>
    </Wrapper>
  )
}

export default Stepper;


import React from 'react';
import { ReactComponent as RawChartSVG } from '../../../assets/icons/historical_development.svg';
import { ReactComponent as RawChartLegendsSVG } from '../../../assets/icons/historical_development_legends.svg';
import { ANIMATION_CLASSES } from '../../../constants/animations';

import { Wrapper } from "./Loader.styled"

export interface IProps {
  legendsVisible?: boolean;
  width?: number;
  height?: number;
}

const Loader: React.FC<IProps> = ({ legendsVisible = true, height, width }) => {
  return (
    <Wrapper width={width} height={height} className={ANIMATION_CLASSES.animatedSkeleton}>
      <RawChartSVG />
      {legendsVisible && <RawChartLegendsSVG />}
    </Wrapper>
  )
};

export default Loader;
import styled from 'styled-components';

export const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 34px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 24px;
    }

    .description {
      text-align: center;
      width: 70%;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.white};
      opacity: 0.8;
      font-weight: 400;
    }
  }

  .types-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 34px 44px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 10px 22px #222B450B;
    border-radius: 20px;
    margin-top: 30px;

    .subtitle {
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondaryDark};
      opacity: 0.8;
      margin-bottom: 35px;
    }

    .types-cards {
      display: flex;
      justify-content: center;
      width: calc(100% - 120px);

      .card {
        position: relative;
        border: 3px solid #ffffff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        padding: 36px 40px;
        max-width: 400px;
        cursor: pointer;

        .check {
          display: none;
          position: absolute;
          top: 24px;
          right: 24px;

          svg {
            width: 22px;
            height: 22px;
          }

          .b {
            stroke: ${({ theme }) => theme.colors.linkBlue}!important;
            stroke-width: 3px!important;
          }
        }

        &.active {
          border-color: ${({ theme }) => theme.colors.linkBlue};
          .check {
            display: block;
          }
        }

        .card-icon {
          svg {
            width: 38px;
            height: 38px;
          }
        }

        .card-title {
          margin-top: 17px;
          font-size: 20px;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.secondaryDark};
        }

        .card-description {
          font-size: 14px;
          font-weight: 400;
          color: #242D46CC;
          margin-top: 16px;
        }

        &:last-child {
          margin-left: 30px;
        }
      }
    }

    button {
      margin-top: 40px;
      margin-bottom: 24px;
      height: 60px;
      max-width: 430px;
    }

    .support-message {
      font-size: 12px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondaryDark};
      opacity: 0.5;
    }
  }
`;

import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import AntdBreadcrumb from 'antd/lib/breadcrumb';

import { BREADCRUMBS } from '../../constants/breadcrumbs';
import { NAVIGATION_MAP as NavMap } from '../../constants/sideBar';

import { selectLanguage } from '../../redux/app/selectors';

import { StyledWrapper as Wrapper } from './Breadcrumbs.styled';

const PARENT_ROUTES = Object.keys(BREADCRUMBS);

export interface IProps {
  route: string;
}

const Breadcrumbs: React.FC<IProps> = ({ route }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectLanguage);

  const parentRoute = useMemo(() => PARENT_ROUTES.find(parentRoute => route.includes(parentRoute)) as string, [route]);
  const childRoute = useMemo(() => BREADCRUMBS[parentRoute as string]?.find(childRoute => childRoute === route) as string, [parentRoute, route]);
  const firstChildRoute = useMemo(() => BREADCRUMBS[parentRoute as string] && BREADCRUMBS[parentRoute as string][0] as string, [parentRoute]);

  const handleParentBreadcrumbClick = useCallback(() => {
    dispatch(push(generatePath(firstChildRoute, { lang: currentLanguage })));
  }, [dispatch, currentLanguage, firstChildRoute]);

  const showBreadcrumbs = childRoute !== parentRoute;

  if (!showBreadcrumbs) {
    return null;
  }

  return (
    <Wrapper className={clsx({ hidden: !parentRoute })}>
      <AntdBreadcrumb separator=">">
        <AntdBreadcrumb.Item onClick={handleParentBreadcrumbClick}>
          {t(NavMap.get(parentRoute) as string)}
        </AntdBreadcrumb.Item>
        <AntdBreadcrumb.Item>
          {t(NavMap.get(childRoute) as string)}
        </AntdBreadcrumb.Item>
      </AntdBreadcrumb>
    </Wrapper>
  );
};

export default Breadcrumbs;

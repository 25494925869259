import { ActionUnion, createAction } from '../commonTypes';
import { AuthActionTypes, IGoogleSignInPayload, IPasswordSignInPayload, IUserPayload, IUIPayload, IRefreshTokensOptionsPayload } from './types';
import { EmptyObject } from '../../types/common.types';

export const authActions = {
  signInWithGoogle: (payload: IGoogleSignInPayload): { type: AuthActionTypes, payload: IGoogleSignInPayload } => createAction(
    AuthActionTypes.SIGN_IN_WITH_GOOGLE,
    payload,
  ),
  signInWithPassword: (payload: IPasswordSignInPayload): { type: AuthActionTypes, payload: IPasswordSignInPayload } => createAction(
    AuthActionTypes.SIGN_IN_WITH_PASSWORD,
    payload,
  ),
  userReload: (): { type: AuthActionTypes, payload: EmptyObject } => createAction(
    AuthActionTypes.USER_RELOAD,
  ),
  userRefresh: (payload: IUserPayload): { type: AuthActionTypes, payload: IUserPayload } => createAction(
    AuthActionTypes.USER_REFRESH,
    payload,
  ),
  userReset: (): { type: AuthActionTypes, payload: EmptyObject } => createAction(
    AuthActionTypes.USER_RESET,
  ),
  tokensRefresh: (payload?: IRefreshTokensOptionsPayload): { type: AuthActionTypes, payload?: IRefreshTokensOptionsPayload } => createAction(
    AuthActionTypes.TOKENS_REFRESH,
    payload,
  ),
  signOut: (): { type: AuthActionTypes, payload: EmptyObject } => createAction(
    AuthActionTypes.SIGN_OUT,
  ),
  uiMerge: (payload: IUIPayload): { type: AuthActionTypes, payload: IUIPayload } => createAction(
    AuthActionTypes.UI_MERGE,
    payload,
  ),
}

export type AuthActions = ActionUnion<typeof authActions>;

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import isEqual from "lodash/isEqual";

import { IconCheckmarkSmall } from "../icons";

import { Wrapper } from './Radio.styled';

export enum RadioType {
  Small = 'small',
  Large = 'large',
}

export interface IRadioItems {
  label: string;
  value: any;
  icon?: JSX.Element;
}

export interface IProps {
  radioType?: RadioType;
  items?: IRadioItems[];
  value?: any;
  name?: string;
  error?: boolean;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
}

const Radio: React.FC<IProps> = ({ radioType = RadioType.Small, items = [], value, name, error, onChange = () => { }, onBlur = () => { } }) => {
  const { t } = useTranslation();
  const [oldValue, setOldValue] = useState(value);
  const isLarge = radioType === RadioType.Large;
  const classNames = clsx({
    large: isLarge,
  });

  useEffect(() => {
    if (!isEqual(oldValue, value)) {
      onBlur();
      setOldValue(value);
    }
  }, [value, onBlur, oldValue]);

  const onClick = useCallback((newValue) => () => {
    onChange(newValue);
  }, [onChange, name]);

  return (
    <Wrapper error={error} className={classNames}>
      {items.map(({ icon, label, value: itemValue }) => {
        const isChecked = value === itemValue;

        return (
          <div
            key={itemValue}
            className={clsx('radio-item', { checked: isChecked })}
            onClick={onClick(itemValue)}
          >
            {isChecked &&
              <div className="radio-item-checked">
                <IconCheckmarkSmall />
              </div>
            }
            {icon && isLarge && <div className="icon">{icon}</div>}
            <div className="radio-item-label">{t(label)}</div>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default Radio;

import styled from 'styled-components';
import { hexToRGBA } from '../../../utils/theme';

export const StyledWrapper = styled.div`
  position: relative;

  height: 34px;
  width: 100%;
  max-width: 156px;

  .plus,
  .minus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 44px;
    background: white;
    border-radius: 10px;
    top: 2px;
    cursor: pointer;
  }

  .plus {
    position: absolute;
    right: 2px;
  }

  .minus {
    position: absolute;
    left: 2px;
  }

  input {
    border: 2px solid ${({ theme }) => theme.colors.inputs.border.default};
    border-radius: 10px;
    outline: none;
    text-align: center;

    background: ${({ theme }) => {
    return hexToRGBA(
      theme.colors.inputs.background.default,
      theme.colors.inputs.background.opacity.default,
    );
  }} 0% 0% no-repeat padding-box;

    height: 100%;
    width: 100%;

    padding-left: 32px;
    padding-right: 20px;

    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    caret-color: #E3E3E3;
    color: ${({ theme }) => {
    return hexToRGBA(
      theme.colors.inputs.font.default,
      theme.colors.inputs.font.opacity.default,
    );
  }};
  }

  input:focus {
    border: 2px solid ${({ theme }) => theme.colors.inputs.border.focus};
    box-shadow: 0px 6px 12px #5468F034;
  }

  &.error {
    input {
      border: 2px solid ${({ theme }) => theme.colors.inputs.border.error};
      box-shadow: 0px 6px 12px #F5545833;
    }
  }

  &.disabled {
    input {
      border: none;

      background: ${({ theme }) => {
    return hexToRGBA(
      theme.colors.inputs.background.disabled,
      theme.colors.inputs.background.opacity.disabled,
    );
  }} 0% 0% no-repeat padding-box;

      color: ${({ theme }) => {
    return hexToRGBA(
      theme.colors.inputs.font.disabled,
      theme.colors.inputs.font.opacity.disabled,
    );
  }};
    }
  }

  .icon-container {
    display: none;
  }

  &.icon {

    input {
      padding-right: 41px;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 9px;
      right: 9px;
      
      height: 32px;
      width: 32px;

      cursor: pointer;
      position: absolute;
    }
  }
`;

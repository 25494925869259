import { Feature, SortOrder } from "../../enums/data";
import { IDirectory } from "../../services/DataService/types";

export enum DirectoryActionTypes {
  DIRECTORY_RELOAD = '[Directory] directory-reload',
  DIRECTORY_REFRESH = '[Directory] directory-refresh',

  OPENED_FOLDERS_REFRESH = '[Directory] opened-folders-refresh',

  SORT_CHANGE = '[Directory] sort-change',
  SORT_REFRESH = '[Directory] sort-refresh',

  DATA_RESTRICTIONS_MERGE = '[Directory] data-restrictions-merge',
  DATA_UPDATE = '[Directory] data-update',

  UI_MERGE = '[Directory] ui-merge',

  RESET_ALL = '[Directory] reset-all',
}

export interface ISortChangePayload {
  feature: Feature;
  order?: SortOrder;
}

export interface ISortRefreshPayload extends Required<ISortChangePayload> { }

export interface IDataRestrictionsPayload {
  sortBy?: Feature | string;
  order?: SortOrder;
}

export interface IUIPayload {
  loading?: boolean;
  folderLoading?: string;
}

export interface DirectoryState {
  data: IDirectory[];
  openedFolders: string[];
  sort: ISortRefreshPayload;
  summaryDataRestrictions: Required<IDataRestrictionsPayload>;
  UI: IUIPayload;
}

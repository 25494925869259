import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StackedIndicator } from '../../../../components/StackedIndicator';
import { getResourceUtilizationColor } from '../../../../utils/resources';
import { SiderHeading, SiderSteps } from '../../../../enums/locales/modals';
import { ProjectsOverviewFieldsKeys, ProjectsOverviewKeys } from '../../../../enums/locales/projects';
import { selectUI } from '../../../../redux/account-details/selectors';
import { useResourceLimits } from '../../../../hooks/useResourceLimits';

import { Wrapper } from './AdminResourceCard.styled';

const AdminResourceCard: React.FC = () => {
  const { t } = useTranslation();
  const { licenseLoading } = useSelector(selectUI);
  const {
    user_slots_used,
    user_slots,
    project_slots_used,
    project_slots,
    evaluatedKeywordsSlotsUsed,
    keyword_slots,
    evaluatedUrlsSlotsUsed,
    url_slots,
  } = useResourceLimits(true);

  return (
    <Wrapper>
      <div className="title">{t(SiderHeading.YourResources)}:</div>
      <div className="count-indicator">
        <StackedIndicator
          title={t(ProjectsOverviewKeys.UsersCountIndicatorTitle)}
          value={user_slots_used}
          maxValue={user_slots}
          color={getResourceUtilizationColor(user_slots_used, user_slots)}
          reset={licenseLoading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={t(ProjectsOverviewKeys.ProjectsCountIndicatorTitle)}
          value={project_slots_used}
          maxValue={project_slots}
          color={getResourceUtilizationColor(project_slots_used, project_slots)}
          reset={licenseLoading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={`${t(ProjectsOverviewFieldsKeys.Keywords)}:`}
          value={evaluatedKeywordsSlotsUsed}
          maxValue={keyword_slots}
          color={getResourceUtilizationColor(evaluatedKeywordsSlotsUsed, keyword_slots)}
          reset={licenseLoading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={`${t(SiderSteps.Urls)}:`}
          value={evaluatedUrlsSlotsUsed}
          maxValue={url_slots}
          color={getResourceUtilizationColor(evaluatedUrlsSlotsUsed, url_slots)}
          reset={licenseLoading}
        />
      </div>
    </Wrapper>
  )
}

export default AdminResourceCard;

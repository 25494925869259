import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  width: 100%;

  padding-left: 40px;
  padding-right: 40px;

  background: #FFFFFF 0% 0% no-repeat padding-box;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  z-index: 10;
`;

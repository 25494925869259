export enum ProfileMenuKeys {
  licenseSelectTitle = 'top_bar_profile_menu_license_select_title',
  adminSectionTitle = 'top_bar_profile_menu_admin_section_title',
  adminAccountsTitle = 'top_bar_profile_menu_admin_accounts_title',
  adminUserListTitle = 'top_bar_profile_menu_admin_user_list_title',
  licenseSelectViewTitle = 'top_bar_profile_menu_license_select_view_title',
  licenseSelectViewHideTitle = 'top_bar_profile_menu_license_select_view_hide_title',
  languageSelectTitle = 'top_bar_profile_menu_language_select_title',
  profileSettingsTitle = 'top_bar_profile_menu_profile_settings_title',
  signOutButtonTitle = 'top_bar_profile_menu_sign_out_button_title',
}

export enum ProjectMenuKeys {
  openTitle = 'top_bar_project_menu_open_title',
  editProjectButtonTitle = 'top_bar_project_menu_edit_button_title',
  allProjectsButtonTitle = 'top_bar_project_menu_all_projects_button_title',
  crawlInProgressIndicatorTitle = 'top_bar_project_menu_crawl_in_progress_indicator_title',
}

export enum TopBarMenuKeys {
  addNewProjectButtonTitle = 'top_bar_menu_add_new_project_button_title',
}

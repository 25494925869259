import React, { useCallback } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePaginatedSearch } from '../../../hooks/usePaginatedSearch';

import AntdModal from 'antd/lib/modal';
import AntdPopConfirm from 'antd/lib/popconfirm';

import { beautifyUrl } from '../../../utils/common';
import { ACCOUNT_DETAILS_PROJECT_DATE_FORMAT } from '../../../constants/formats';
import { Strategy, RecrawlInterval } from '../../../enums/projects';
import { DashboardTopMenuKeys } from '../../../enums/locales/dashboard';
import { FieldButtonTextKeys } from '../../../enums/locales/forms';
import { DashboardWebVitalsGroups } from '../../../enums/locales/dashboard';
import { ProjectsOverviewKeys, ProjectsOverviewFieldsKeys, RecrawlIntervalKeys } from '../../../enums/locales/projects';
import { AccountsKeys, AccountDetailsKeys } from '../../../enums/locales/accounts';

import { accountDetailsActions } from '../../../redux/account-details/actions';
import { projectSettingsActions } from '../../../redux/project-settings/actions';
import { modalsActions } from '../../../redux/modals/actions';
import { selectProjectID, selectAccountProjectByID, selectCrawlsSearch, selectAccountLicenseCounts } from '../../../redux/account-details/selectors';
import { selectProjectCrawlsVisible } from '../../../redux/modals/selectors';

import { IconClose, IconMove, IconEdit, IconDelete, IconSearch, IconPCSmall, IconMobileSmall } from '../../../components/styled/icons';
import { Button, ButtonType } from '../../../components/styled/Button';
import { Input } from '../../../components/styled/Input';
import { DomainPictureHolder } from '../../../components/DomainPictureHolder';
import { CrawlsTable } from './CrawlsTable';
import { StyledBodyWrapper } from './ProjectCrawlsModal.styled';

const STRATEGY_ICONS_MAP = {
  [Strategy.Desktop]: <IconPCSmall />,
  [Strategy.Mobile]: <IconMobileSmall />,
};

const STRATEGY_KEYS_MAP = {
  [Strategy.Desktop]: DashboardTopMenuKeys.PCTitle,
  [Strategy.Mobile]: DashboardTopMenuKeys.MobileTitle,
};

const RECRAWL_INTERVAL_KEYS_MAP = {
  [RecrawlInterval.Daily]: RecrawlIntervalKeys.Daily,
  [RecrawlInterval.MondayToFriday]: RecrawlIntervalKeys.MondayToFriday,
  [RecrawlInterval.BiWeekly]: RecrawlIntervalKeys.BiWeekly,
  [RecrawlInterval.Weekly]: RecrawlIntervalKeys.Weekly,
  [RecrawlInterval.Monthly]: RecrawlIntervalKeys.Monthly,
};

const ProjectCrawlsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch =  useDispatch();
  const isVisible = useSelector(selectProjectCrawlsVisible);
  const projectID = useSelector(selectProjectID);
  const project = useSelector(selectAccountProjectByID(projectID));
  const crawlsSearch = useSelector(selectCrawlsSearch);
  const licenseCounts = useSelector(selectAccountLicenseCounts);

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.projectCrawlsHide());
    dispatch(accountDetailsActions.projectIDRefresh(null));
    dispatch(accountDetailsActions.crawlsRefresh([]));
  }, [dispatch]);

  const onProjectMove = useCallback(() => {
    dispatch(modalsActions.projectMoveShow());
    dispatch(modalsActions.projectCrawlsHide());
  }, [dispatch]);

  const onProjectEdit = useCallback(() => {
    if (projectID) {
      dispatch(projectSettingsActions.adminUpdateInit(projectID));
    }
  }, [dispatch, projectID]);

  const onProjectDelete = useCallback(() => {
    if (projectID) {
      dispatch(accountDetailsActions.projectRemove(projectID));
      dispatch(modalsActions.projectCrawlsHide());
      dispatch(accountDetailsActions.projectIDRefresh(null));
      dispatch(accountDetailsActions.crawlsRefresh([]));
    }
  }, [dispatch, projectID]);

  const {
    search,
    onEnterClick,
    onSearch,
    onSearchClick,
  } = usePaginatedSearch(crawlsSearch, accountDetailsActions.crawlsSearchRefresh, accountDetailsActions.uiMerge);

  return (
    <AntdModal
      centered
      title={(
        <>
          <div className="ant-modal-title-top">
            <div className="project-crawls-modal-title">
              <div className="project-label">
                {t(ProjectsOverviewFieldsKeys.ID)}
              </div>
              <div className="project-id">
                {projectID}
              </div>
            </div>
            <div className="project-crawls-modal-actions">
              <div className="project-move-button">
                <Button
                  rounded
                  small
                  type={ButtonType.Outline}
                  icon={<IconMove />}
                  title={t(AccountDetailsKeys.MoveProjectButton)}
                  onClick={onProjectMove}
                />
              </div>
              <div className="project-edit-button">
                <Button
                  rounded
                  small
                  type={ButtonType.Outline}
                  icon={<IconEdit />}
                  title={t(AccountDetailsKeys.EditAccountButton)}
                  onClick={onProjectEdit}
                />
              </div>
              <div className="project-delete-button">
                <AntdPopConfirm
                  getPopupContainer={(triggerNode: any) => triggerNode}
                  icon={null}
                  placement="left"
                  title={t(AccountsKeys.DeleteProjectMessage)}
                  okText={t(ProjectsOverviewKeys.DeleteConfirmButtonTitle)}
                  cancelText={t(ProjectsOverviewKeys.DeleteCancelButtonTitle)}
                  onConfirm={onProjectDelete}
                >
                  <Button
                    rounded
                    small
                    type={ButtonType.Outline}
                    icon={<IconDelete />}
                    title={t(AccountDetailsKeys.DeleteProjectButton)}
                  />
                </AntdPopConfirm>
              </div>
            </div>
          </div>
          <div className="ant-modal-title-bottom">
            <div className="project-section">
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Name)}
                </div>
                <div className="project-data-value">
                  <div title={project?.name} className="domain-name">
                    <div className="domain-name-picture">
                      <DomainPictureHolder domainName={project?.domain || ''} />
                    </div>
                    <div className="domain-name-value">
                      {project?.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Domain)}
                </div>
                <div className="project-data-value">
                  {beautifyUrl(project?.domain || '')?.replace('/', '')}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Type)}
                </div>
                <div className="project-data-value">
                  <div className="strategy-type">
                    <div className="strategy-type-icon">
                      {STRATEGY_ICONS_MAP[project?.strategy as Strategy]}
                    </div>
                    <div className="strategy-type-title">
                      {t(STRATEGY_KEYS_MAP[project?.strategy as Strategy])}
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Frequency)}
                </div>
                <div className="project-data-value">
                  {t(RECRAWL_INTERVAL_KEYS_MAP[project?.recrawl_interval as RecrawlInterval])}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(FieldButtonTextKeys.EndUsers)}
                </div>
                <div className="project-data-value">
                  {licenseCounts?.url_slots_used}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(ProjectsOverviewFieldsKeys.Keywords)}
                </div>
                <div className="project-data-value">
                  {project?.keywords.length}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(DashboardWebVitalsGroups.Benchmarks)}
                </div>
                <div className="project-data-value">
                  {project?.benchmarks.length}
                </div>
              </div>
              <div className="project-data-cell">
                <div className="project-data-label">
                  {t(AccountsKeys.CreatedAtLabel)}
                </div>
                <div className="project-data-value">
                  {project?.created_at && format(new Date(project?.created_at), ACCOUNT_DETAILS_PROJECT_DATE_FORMAT)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="project-crawls-modal-container"
      width={'70%'}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className="search-section">
          <div className="left-section">
            {t(AccountsKeys.CrawlsLabel)}
          </div>
          <div className="right-section">
            <div className="search-input-container">
              <Input
                className="search-input"
                value={search}
                onKeyDown={onEnterClick}
                onChange={onSearch}
                placeholder={`${t(AccountsKeys.SearchCrawls)}...`}
                icon={<IconSearch onClick={onSearchClick} />}
              />
            </div>
          </div>
        </div>
        <div className="crawls-section">
          <CrawlsTable />
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default ProjectCrawlsModal;

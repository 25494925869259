import styled from 'styled-components';
import { IconDropdown, IconDropdownActive } from '../../../../../components/styled/icons';
import { hexToRGBA } from '../../../../../utils/theme';

export const StyledIconDropdown = styled(IconDropdown)`
  transform: rotate(90deg);
`;

export const StyledIconDropdownActive = styled(IconDropdownActive)`
  transform: rotate(180deg);
`;


export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: auto;
  min-width: 320px;
  
  .admin-section,
  .license-select,
  .language-select,
  .profile-settings,
  .sign-out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    padding-left: 6px;
    padding-right: 6px;
  }

  &:not(.collapsed) {
    padding-top: 21px;
    padding-bottom: 30px;
    padding-left: 26px;
    padding-right: 26px;

    .profile-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 80px;
      width: 100%;

      margin-bottom: 28px;

      position: relative;

      font-style: normal;
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
      letter-spacing: 0px;
      color: #171C28;

      transition: font-size 0.15s linear;

      .profile-avatar {
        margin-top: 2px;
        margin-bottom: 18px;

        position: absolute;

        top: 0;
        left: 0;

        transform: translate(8px, 20px);
        transition: transform 0.15s linear;

        .profile-avatar-image,
        .profile-avatar-template {
          transform: scale(1.2);
          transition: transform 0.15s linear;
        }
      }

      .profile-name {
        display: flex;
        flex-direction: column;
        justify-content: center;

        position: absolute;

        top: 0;

        transform: translateX(36px) translateY(24px);
        transition: transform 0.15s linear;

        .user-email {
          max-width: 210px;

          font-style: normal;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          color: #171C28;
          opacity: 0.4;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .profile-drop-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -2px;
        right: 0;
        cursor: pointer;

        transform: rotate(-180deg) translateX(9px);
        transition: transform 0.2s linear;
      }
    }

    .admin-section {
      margin-bottom: 17px;

      .admin-header {
        width: 100%;
        margin-bottom: 13px;
        font-style: normal;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        color: #171C28;
        opacity: 0.3;
      }

      .admin-page {
        display: flex;
        align-items: center;
        color: #222A41;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 13px;
        cursor: pointer;

        svg {
          width: 18px;
          margin-right: 10px;
        }
      }
    }

    .license-select {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      margin-bottom: 16px;

      .license-select-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;

        margin-bottom: 13px;

        font-style: normal;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        color: #171C28;

        .license-select-title {
          opacity: 0.3;
        }

        .license-select-all {
          cursor: pointer;
          font-weight: 600;
          color: ${({ theme }) => theme.colors.linkBlue};

          &.hidden {
            display: none;
          }
        }
      }

      .license-select-options {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;
        max-height: 250px;

        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #171C2810 0% 0% no-repeat padding-box;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: #555BF0 0% 0% no-repeat padding-box;
          border-radius: 4px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }

        .license-option {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;

          padding-bottom: 6px;
          margin-bottom: 10px;
          border-bottom: 1px solid ${hexToRGBA('#171C28', 0.1)};

          font-style: normal;
          font-size: 12px;
          font-weight: normal;
          line-height: 21px;
          letter-spacing: 0px;
          color: #171C28;
          opacity: 0.8;

          cursor: pointer;

          .license-option-title {
            font-weight: 500;

            .license-option-creator-email {
              opacity: 0.3;
            }
          }

          .license-option-icon {
            display: none;
          }

          &.active {
            .license-option-title {
              .license-option-creator-name {
                font-weight: bold;
              }
              .license-option-creator-email {
                opacity: 0.4;
              }
            }

            .license-option-icon {
              display: flex;

              svg {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }

    .language-select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      margin-bottom: 32px;

      .language-select-title {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 13px;

        font-style: normal;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        color: #171C28;
        opacity: 0.3;
      }

      .language-select-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .language-option {
          display: flex;
          justify-content: center;
          align-items: center;

          font-style: normal;
          font-size: 14px;
          font-weight: normal;
          line-height: 21px;
          letter-spacing: 0px;
          color: #171C28;
          opacity: 0.8;

          .language-option-icon {
            width: 16px;
            margin-right: 9px;

            svg {
              display: none;
            }
          }

          .language-option-title {
            cursor: pointer;
          }

          &.active {
            .language-option-icon {
              svg {
                display: block;
              }
            }

            .language-option-title {
              font-weight: 500;
              opacity: 1;
            }
          }

          :first-child {
            margin-bottom: 8px;
          }
        }
      }
    }

    .profile-settings {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      margin-bottom: 40px;

      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      color: #171C28;

      cursor: pointer;
    }

    .sign-out {
      width: 100%;

      button {
        height: 36px;
        width: 100%;
        max-width: unset;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #00000012;
      border-radius: 18px;

      z-index: -1;

      transition: height 0.2s ease-in;
    }
  }

  &.collapsed {
    height: auto;
    min-width: 250px;

    .profile-info {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      cursor: pointer;

      width: 100%;

      font-style: normal;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0px;
      color: #171C28;

      .profile-avatar {
        margin-right: 15px;

        transform: translate(0px, 0px);
        transition: transform 0.15s linear;

        .profile-avatar-image,
        .profile-avatar-template {
          transform: scale(1);
          transition: transform 0.15s linear;
        }
      }

      .profile-name {
        flex-grow: 1;
        margin-right: 15px;

        transform: translateY(0px);
        transition: transform 0.15s linear;

        .user-email {
          font-style: normal;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          color: #171C28;
          opacity: 0.3;
        }
      }

      .profile-drop-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
        transform: rotate(-90deg);
        transition: transform 0.2s linear;
      }
    }

    .admin-section,
    .license-select,
    .language-select,
    .profile-settings,
    .sign-out {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      height: 0;
      width: 100%;
    }
  }
`;

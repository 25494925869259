import { ActionUnion, createAction } from '../commonTypes';
import {
  ProjectsOverviewActionTypes,
  ProjectsOverviewDataPayload,
  IProjectsOverviewSortChangePayload,
  IProjectsOverviewSortRefreshPayload,
} from './types';
import { EmptyObject } from '../../types/common.types';

export const projectsOverviewActions = {
  sortChange: (payload: IProjectsOverviewSortChangePayload): { type: ProjectsOverviewActionTypes, payload: IProjectsOverviewSortChangePayload } => createAction(
    ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_SORT_CHANGE,
    payload,
  ),
  sortRefresh: (payload: IProjectsOverviewSortRefreshPayload): { type: ProjectsOverviewActionTypes, payload: IProjectsOverviewSortRefreshPayload } => createAction(
    ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_SORT_REFRESH,
    payload,
  ),
  dataUpdate: (): { type: ProjectsOverviewActionTypes, payload: EmptyObject } => createAction(
    ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_DATA_UPDATE,
  ),
  dataRefresh: (payload: ProjectsOverviewDataPayload): { type: ProjectsOverviewActionTypes, payload: ProjectsOverviewDataPayload } => createAction(
    ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_DATA_REFRESH,
    payload,
  ),
  resetAll: (): { type: ProjectsOverviewActionTypes, payload: EmptyObject } => createAction(
    ProjectsOverviewActionTypes.RESET_ALL,
  ),
}

export type ProjectsOverviewActions = ActionUnion<typeof projectsOverviewActions>;

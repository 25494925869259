import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as LocaleStorageUtils from '../../utils/storage';
import { FingerprintService } from '../../services/FingerprintService';

import { AppActionTypes } from './types'
import { appActions } from './actions';
import { authActions } from '../auth/actions';
import { licensesActions } from '../licenses/actions';

function* appStart(): any {
  yield put(authActions.userReload());
  yield put(licensesActions.licensesReload());
}

function* deviceIdentifierUpdate(): any {
  let deviceIdentifier = yield call(LocaleStorageUtils.restoreDeviceIdentifier);

  if (deviceIdentifier) {
    yield put(appActions.deviceIdentifierRefresh(deviceIdentifier));
    return;
  }

  const fingerprint = yield call(FingerprintService.getFingerprint);
  deviceIdentifier = fingerprint?.visitorId;

  yield call(LocaleStorageUtils.storeDeviceIdentifier, deviceIdentifier);
  yield put(appActions.deviceIdentifierRefresh(deviceIdentifier));
}

export default function* appSaga() {
  yield all([
    takeLatest(AppActionTypes.APP_START, appStart),
    takeLatest(AppActionTypes.DEVICE_IDENTIFIER_UPDATE, deviceIdentifierUpdate),
  ]);
}

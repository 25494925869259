import { ActionUnion, createAction } from '../commonTypes';
import { IUIPayload, LeaderboardActionTypes } from './types';
import { DomainHistoryInterval, Feature } from '../../enums/data';
import { EmptyObject } from '../../types/common.types';
import { ILeaderboardBenchmarkRank, ILeaderboardData, ILeaderboardHistoryData } from '../../services/DataService/types';

export const leaderboardActions = {
  featureRefresh: (payload: Feature): { type: LeaderboardActionTypes, payload: Feature } => createAction(
    LeaderboardActionTypes.FEATURE_REFRESH,
    payload,
  ),
  dataReload: (): { type: LeaderboardActionTypes, payload: EmptyObject } => createAction(
    LeaderboardActionTypes.DATA_RELOAD,
  ),
  dataRefresh: (payload: ILeaderboardData[]): { type: LeaderboardActionTypes, payload: ILeaderboardData[] } => createAction(
    LeaderboardActionTypes.DATA_REFRESH,
    payload,
  ),
  rankReload: (): { type: LeaderboardActionTypes, payload: EmptyObject } => createAction(
    LeaderboardActionTypes.RANK_RELOAD,
  ),
  rankRefresh: (payload: number | null): { type: LeaderboardActionTypes, payload: number | null } => createAction(
    LeaderboardActionTypes.RANK_REFRESH,
    payload,
  ),
  benchmarkRanksReload: (): { type: LeaderboardActionTypes, payload: EmptyObject } => createAction(
    LeaderboardActionTypes.BENCHMARK_RANKS_RELOAD,
  ),
  benchmarkRanksRefresh: (payload: ILeaderboardBenchmarkRank[]): { type: LeaderboardActionTypes, payload: ILeaderboardBenchmarkRank[] } => createAction(
    LeaderboardActionTypes.BENCHMARK_RANKS_REFRESH,
    payload,
  ),
  historyReload: (): { type: LeaderboardActionTypes, payload: EmptyObject } => createAction(
    LeaderboardActionTypes.HISTORY_RELOAD,
  ),
  historyRefresh: (payload: ILeaderboardHistoryData): { type: LeaderboardActionTypes, payload: ILeaderboardHistoryData } => createAction(
    LeaderboardActionTypes.HISTORY_REFRESH,
    payload,
  ),
  historyIntervalRefresh: (payload: DomainHistoryInterval): { type: LeaderboardActionTypes, payload: DomainHistoryInterval } => createAction(
    LeaderboardActionTypes.HISTORY_INTERVAL_REFRESH,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: LeaderboardActionTypes, payload: IUIPayload } => createAction(
    LeaderboardActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: LeaderboardActionTypes, payload: EmptyObject } => createAction(
    LeaderboardActionTypes.RESET_ALL,
  ),
}

export type LeaderboardActions = ActionUnion<typeof leaderboardActions>;

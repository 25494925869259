import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";

import { ButtonTextKeys, SimpleListKeys } from "../../../enums/locales/forms";

import { Button, ButtonType } from "../Button";
import { IconDelete } from "../icons";
import { Input } from "../Input";

import { Wrapper } from './SimpleList.styled';
import Suggestions from "./Suggestions";

export enum ListType {
  Suggestions = 'suggestions',
  Invites = 'invites',
}

export interface IProps {
  listType?: ListType;
  value?: string[];
  error?: boolean;
  addPlaceholder?: string;
  suggestions?: string[];
  listTitle?: string;
  customValidate?: (...args: any) => boolean;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
}

const SimpleList: React.FC<IProps> = ({
  listType = ListType.Suggestions,
  value = [],
  error,
  suggestions = [],
  addPlaceholder = '',
  listTitle = '',
  customValidate = Boolean,
  onChange = () => { },
  onBlur = () => { },
}) => {
  const { t } = useTranslation();
  const [oldValue, setOldValue] = useState(value);
  const [newItem, setNewItem] = useState('');

  useEffect(() => {
    if (!isEqual(oldValue, value)) {
      onBlur();
      setOldValue(value);
    }
  }, [value, onBlur, oldValue]);

  const onNewItemChange = useCallback((e) => {
    setNewItem(e.target.value);
  }, [setNewItem]);

  const onAdd = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onChange([...value, newItem]);
    setNewItem('');
  }, [onChange, value, setNewItem, newItem]);

  const onSuggestionsAdd = useCallback((newItems) => {
    onChange([...value, ...newItems]);
  }, [onChange, value]);

  const isDisabled = useMemo(() => !customValidate(newItem) || value.includes(newItem), [value, newItem]);

  const onSubmit = useCallback((e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
    }

    const isSubmittable = !isDisabled && e.keyCode === 13;
    if (isSubmittable) {
      onChange([...value, newItem]);
      setNewItem('');
    }
    return false;
  }, [onChange, isDisabled, setNewItem, value, newItem]);

  const onDelete = useCallback((item) => () => {
    onChange(value.filter((val) => val !== item));
  }, [onChange, value]);

  const filteredSuggestions = useMemo(() => suggestions.filter((suggestion) => !value.includes(suggestion)), [value, suggestions]);

  const showList = !!value.length;
  const showSuggestions = listType === ListType.Suggestions && !!suggestions.length;

  return (
    <Wrapper showSuggestions={showSuggestions} error={error}>
      <div className="main-list">
        <Input
          className="add-input"
          value={newItem}
          onKeyDown={onSubmit}
          onChange={onNewItemChange}
          placeholder={`${t(addPlaceholder)}...`}
          icon={
            <Button
              type={ButtonType.Primary}
              onClick={onAdd}
              disabled={isDisabled}
              title={t(ButtonTextKeys.Add)}
            />
          }
        />
        {showList && (
          <div className="list-container">
            <div className="list-header">
              {t(listTitle)}:
            </div>
            <div className="list-items">
              {value.map((item) =>
                <div key={item} className="item">
                  <IconDelete onClick={onDelete(item)} /> {item}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {showSuggestions && (
        <div className="suggestions">
          <Suggestions
            title={t(SimpleListKeys.SuggestionsTitle)}
            list={filteredSuggestions}
            onAdd={onSuggestionsAdd}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default SimpleList;

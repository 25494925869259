import { takeLatest, all, put, call, select, delay } from 'redux-saga/effects';
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import * as LocaleStorageUtils from '../../utils/storage';
import { ProjectService } from '../../services/ProjectService';
import { LicensesService } from '../../services/LicensesService';

import { Action } from '../commonTypes';
import { ProjectsActionTypes, ProjectRemovePayload, IUIPayload, ICrawlsInProgressPayload } from './types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { ILicenseProjectsResponseBody, IProjectsResponseBody, ICrawlLatestResponseBody, ICrawlsResponseBody, ICrawl, IAnnotation, IAnnotationsBody, IProjectResponseBody, IProject } from '../../services/ProjectService/types';
import { EmptyObject } from '../../types/common.types';
import { projectsActions } from './actions';
import { modalsActions } from '../modals/actions';
import { selectActiveLicenseID, selectLicenses } from '../licenses/selectors';
import { selectActiveProjectID, selectActiveProjectGroupID, selectActiveProjectDomain, selectUI, selectCrawlsInProgress, selectProjects, selectActiveProjectGroups } from '../projects/selectors';
import { vitalsActions } from '../vitals/actions';
import { selectQueryParams } from '../router/selectors';
import { licensesActions } from '../licenses/actions';

function* listReload(action: Action<ProjectsActionTypes.LIST_RELOAD, EmptyObject>): any {
  const licenseID = yield select(selectActiveLicenseID);
  if (!licenseID) {
    return;
  }

  yield put(projectsActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ILicenseProjectsResponseBody> = yield call(LicensesService.getProjects, licenseID, options);
  if (!result?.data) {
    yield put(projectsActions.uiMerge({ loading: false }));
    return;
  }

  const response: ILicenseProjectsResponseBody = result?.data;

  const { license: list } = response;

  yield put(projectsActions.listRefresh(list));
  yield put(projectsActions.uiMerge({ loading: false }));
  if (list.length === 0) {
    yield put(vitalsActions.resetAll());
    yield put(projectsActions.activeProjectIDRefresh(null));
    yield put(projectsActions.activeProjectGroupIDRefresh(null));
    yield put(modalsActions.onboardShow());
    yield put(projectsActions.uiMerge({ restoringGroup: false, restoringProject: false }));
  }
}

function* annotationsUpdate(action: Action<ProjectsActionTypes.ANNOTATIONS_UPDATE, IAnnotation[]>): any {
  const projectID = yield select(selectActiveProjectID);
  const oldProjectList: IProject[] = yield select(selectProjects);
  const payloadAnnotations = action.payload;
  const newOldProjects = cloneDeep(oldProjectList).map((project) => {
    if (project.project_id === projectID) {
      project.annotations = payloadAnnotations;
    }
    return project;
  });

  yield put(projectsActions.listRefresh(newOldProjects));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestBody: IAnnotationsBody = {
    annotations: action.payload,
  }

  const result: IBaseResponse<IProjectResponseBody> = yield call(ProjectService.updateAnnotations, projectID, requestBody, options);

  if (!result?.data) {
    yield put(projectsActions.listRefresh(oldProjectList));
    return;
  }

  const projectList: IProject[] = yield select(selectProjects);
  const savedAnnotations = result.data?.project.annotations;
  const newProjects = cloneDeep(projectList).map((project) => {
    if (project.project_id === projectID) {
      project.annotations = savedAnnotations;
    }
    return project;
  });

  if (!isEqual(newProjects, newOldProjects)) {
    yield put(projectsActions.listRefresh(newProjects));
  }
}

function* projectRemove(action: Action<ProjectsActionTypes.PROJECT_REMOVE, ProjectRemovePayload>): any {
  const projectID = action.payload;

  if (!projectID) {
    return;
  }

  yield put(projectsActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IProjectsResponseBody> = yield call(ProjectService.deleteEntry, projectID, options);

  if (!result) {
    yield put(projectsActions.uiMerge({ loading: false }));
    return;
  }

  yield put(projectsActions.listReload());
}

function* latestCrawlReload(action: Action<ProjectsActionTypes.LATEST_CRAWL_RELOAD, EmptyObject>): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  yield put(projectsActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ICrawlLatestResponseBody> = yield call(ProjectService.latestCrawl, projectID, options);
  if (!result?.data) {
    yield put(projectsActions.uiMerge({ loading: false }));
    return;
  }

  const response: ICrawlLatestResponseBody = result?.data;

  const { crawl, url_groups } = response;

  yield put(projectsActions.latestCrawlRefresh({ ...crawl, url_groups: { ...url_groups } }));
  yield put(projectsActions.uiMerge({ loading: false }));
}

function* crawlsReload(action: Action<ProjectsActionTypes.CRAWLS_RELOAD, EmptyObject>): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  yield put(projectsActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ICrawlsResponseBody> = yield call(ProjectService.crawls, projectID, options);
  if (!result?.data) {
    yield put(projectsActions.uiMerge({ loading: false }));
    return;
  }

  const response: ICrawlsResponseBody = result?.data;

  const { crawls } = response;

  yield put(projectsActions.crawlsRefresh(crawls));
  yield put(projectsActions.uiMerge({ loading: false }));
}

function* activeProjectIDStore(): any {
  const licenseID = yield select(selectActiveLicenseID);
  const activeProjectID = yield select(selectActiveProjectID);

  if (!licenseID || !activeProjectID) {
    return;
  }

  const currentActiveProjects = yield call(LocaleStorageUtils.restoreActiveProjects);
  const activeProjects = currentActiveProjects || {};

  yield call(LocaleStorageUtils.storeActiveProjects, { ...activeProjects, [licenseID]: activeProjectID });
}

function* activeProjectIDRestore(): any {
  const licenseID = yield select(selectActiveLicenseID);

  yield put(projectsActions.uiMerge({ loading: true }));

  if (!licenseID) {
    yield put(projectsActions.uiMerge({ loading: false }));
    return;
  }

  const currentActiveProjects = yield call(LocaleStorageUtils.restoreActiveProjects);
  const activeProjects = currentActiveProjects || {};
  let activeProjectID = activeProjects[licenseID] || null;
  const projectsList = yield select(selectProjects);
  const licensesList = yield select(selectLicenses);

  const { project_id: queryProjectID } = yield select(selectQueryParams);

  if (queryProjectID) {
    const isCurrentLicense = !!projectsList.find(({ project_id }: any) => project_id === queryProjectID);
    if (!isCurrentLicense) {
      const projectLicenseID = licensesList.find(({ projects }: any) => (projects || []).includes(queryProjectID))?.license_id;

      if (projectLicenseID) {
        yield put(licensesActions.activeLicenseIDRefresh(projectLicenseID));
        yield put(projectsActions.uiMerge({ loading: false, restoringProject: false }));
        return;
      }
    } else {
      activeProjectID = queryProjectID;
    }
  }

  const isValid = !!projectsList.find(({ project_id }: any) => project_id === activeProjectID);
  if (!isValid) {
    yield put(vitalsActions.resetAll());
    yield put(projectsActions.activeProjectIDRefresh(null));
    yield put(projectsActions.activeProjectGroupIDRefresh(null));
    yield put(projectsActions.uiMerge({ loading: false, restoringProject: false, restoringGroup: false }));

    return;
  }

  yield put(projectsActions.activeProjectIDRefresh(activeProjectID));

  const domain = yield select(selectActiveProjectDomain);

  yield put(vitalsActions.comparatorRefresh(domain));
  yield put(projectsActions.uiMerge({ loading: false, restoringProject: false }));
}

function* activeProjectGroupIDStore(): any {
  const licenseID = yield select(selectActiveLicenseID);
  const activeProjectGroupID = yield select(selectActiveProjectGroupID);

  if (!licenseID) {
    return;
  }

  const currentActiveProjectGroups = yield call(LocaleStorageUtils.restoreActiveProjectGroups);
  const activeProjectGroups = currentActiveProjectGroups || {};

  yield call(LocaleStorageUtils.storeActiveProjectGroups, { ...activeProjectGroups, [licenseID]: activeProjectGroupID });
}

function* activeProjectGroupIDRestore(): any {
  const licenseID = yield select(selectActiveLicenseID);
  yield put(projectsActions.uiMerge({ loading: true }));

  if (!licenseID) {
    yield put(projectsActions.uiMerge({ loading: false, restoringGroup: false }));

    return;
  }

  const currentActiveProjectGroups = yield call(LocaleStorageUtils.restoreActiveProjectGroups);
  const activeProjectGroups = currentActiveProjectGroups || {};
  let activeProjectGroupID = activeProjectGroups[licenseID] || null;
  const projectGroups = yield select(selectActiveProjectGroups);

  const { url_group_id: queryProjectGroupID } = yield select(selectQueryParams);

  if (queryProjectGroupID) {
    const isCurrentProject = !!projectGroups.find(({ url_group_id }: any) => url_group_id === queryProjectGroupID);
    if (isCurrentProject) {
      activeProjectGroupID = queryProjectGroupID;
    }
  }

  const isValid = !!projectGroups.find(({ url_group_id }: any) => url_group_id === activeProjectGroupID);
  if (!isValid) {
    yield put(projectsActions.uiMerge({ loading: false, restoringGroup: false }));
    return;
  }

  yield put(projectsActions.activeProjectGroupIDRefresh(activeProjectGroupID));
  yield put(vitalsActions.comparatorRefresh(activeProjectGroupID));
  yield put(projectsActions.uiMerge({ loading: false, restoringGroup: false }));
}

function* activeCrawlsCheckStart(action: Action<ProjectsActionTypes.ACTIVE_CRAWLS_CHECK_START, EmptyObject>): any {
  const licenseID = yield select(selectActiveLicenseID);
  if (!licenseID) {
    return;
  }

  yield put(projectsActions.uiMerge({ crawlsChecking: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const { crawlsChecking }: IUIPayload = yield select(selectUI);
  let isChecking = crawlsChecking;

  while (isChecking) {
    const result: IBaseResponse<ICrawlsResponseBody> = yield call(LicensesService.crawlsActive, licenseID, options);

    if (!result?.data) {
      yield put(projectsActions.crawlsInProgressReset());
      yield put(projectsActions.uiMerge({ crawlsChecking: false }));
      return;
    }

    const currentCrawlsInProgress = yield select(selectCrawlsInProgress);

    const activeCrawls: ICrawl[] = [...Object.values(result?.data.crawls)];

    const newCrawlsInProgress = activeCrawls.reduce((res, { project_id, no_jobs, no_jobs_done, no_jobs_failed }) => {
      const progress = Math.ceil(((no_jobs_done + no_jobs_failed) / no_jobs) * 100);
      res[project_id] = progress;
      return res;
    }, {} as ICrawlsInProgressPayload);

    const crawlsNotInProgress = Object.keys(currentCrawlsInProgress).reduce((res, key) => {
      if (!newCrawlsInProgress[key]) {
        res[key] = undefined;
      }
      return res;
    }, {} as ICrawlsInProgressPayload);

    yield put(projectsActions.crawlsInProgressMerge({ ...crawlsNotInProgress, ...newCrawlsInProgress }));
    yield delay(10000);

    const { crawlsChecking }: IUIPayload = yield select(selectUI);
    isChecking = crawlsChecking;
  }

  yield put(projectsActions.uiMerge({ crawlsChecking: false }));
}

function* activeCrawlsInit(action: Action<ProjectsActionTypes.ACTIVE_CRAWLS_INIT, EmptyObject>): any {
  const licenseID = yield select(selectActiveLicenseID);
  if (!licenseID) {
    return;
  }

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ICrawlsResponseBody> = yield call(LicensesService.crawlsActive, licenseID, options);

  if (!result?.data) {
    yield put(projectsActions.crawlsInProgressReset());
    return;
  }

  const currentCrawlsInProgress = yield select(selectCrawlsInProgress);

  const activeCrawls: ICrawl[] = [...Object.values(result?.data.crawls)];

  const newCrawlsInProgress = activeCrawls.reduce((res, { project_id, no_jobs, no_jobs_done, no_jobs_failed }) => {
    const progress = Math.ceil(((no_jobs_done + no_jobs_failed) / no_jobs) * 100);
    res[project_id] = progress;
    return res;
  }, {} as ICrawlsInProgressPayload);

  const crawlsNotInProgress = Object.keys(currentCrawlsInProgress).reduce((res, key) => {
    if (!newCrawlsInProgress[key]) {
      res[key] = undefined;
    }
    return res;
  }, {} as ICrawlsInProgressPayload);

  yield put(projectsActions.crawlsInProgressMerge({ ...crawlsNotInProgress, ...newCrawlsInProgress }));
}

export default function* projectsSaga() {
  yield all([
    takeLatest(ProjectsActionTypes.LIST_RELOAD, listReload),
    takeLatest(ProjectsActionTypes.PROJECT_REMOVE, projectRemove),
    takeLatest(ProjectsActionTypes.ANNOTATIONS_UPDATE, annotationsUpdate),
    takeLatest(ProjectsActionTypes.LATEST_CRAWL_RELOAD, latestCrawlReload),
    takeLatest(ProjectsActionTypes.CRAWLS_RELOAD, crawlsReload),
    takeLatest(ProjectsActionTypes.ACTIVE_PROJECT_ID_STORE, activeProjectIDStore),
    takeLatest(ProjectsActionTypes.ACTIVE_PROJECT_ID_RESTORE, activeProjectIDRestore),
    takeLatest(ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_STORE, activeProjectGroupIDStore),
    takeLatest(ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_RESTORE, activeProjectGroupIDRestore),
    takeLatest(ProjectsActionTypes.ACTIVE_CRAWLS_CHECK_START, activeCrawlsCheckStart),
    takeLatest(ProjectsActionTypes.ACTIVE_CRAWLS_INIT, activeCrawlsInit),
  ]);
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import clsx from 'clsx';

import { Checkbox } from "../../Checkbox";
import { Pagination } from "../../../Pagination";
import { FieldTableTitleKeys, UrlGroupTableKeys } from '../../../../enums/locales/forms';

import { Wrapper } from './KeywordsSuggestions.styled';
import { IKeyword } from '../../../../redux/project-settings/types';
import { IPagination } from '../../../../types/common.types';

export interface IProps extends Partial<IPagination> {
  list: IKeyword[];
  loading: boolean;
  onCheckSuggestion: (items: string[]) => void;
  checked: string[];
}

const KeywordsSuggestions: React.FC<IProps> = ({
  total = 0,
  limit = 25,
  offset = 0,
  onLimitChange = () => { },
  onOffsetChange = () => { },
  onCheckSuggestion,
  checked,
  loading,
  list,
}) => {
  const { t } = useTranslation();
  const blockRef = useRef<HTMLDivElement | null>(null);
  const [resetScroll, setResetScroll] = useState(false);
  const [oldLimit, setOldLimit] = useState(limit);

  useEffect(() => {
    if (resetScroll && !loading && blockRef.current) {
      blockRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      setResetScroll(false);
    }
  }, [resetScroll, loading, blockRef.current]);

  useEffect(() => {
    if (loading && limit) {
      if (limit < oldLimit) {
        setResetScroll(true);
      }
      setOldLimit(limit);
    }
  }, [setResetScroll, setOldLimit, oldLimit, loading, limit]);

  useEffect(() => {
    onCheckSuggestion([]);
  }, [onCheckSuggestion, limit, offset]);

  const onSingleCheck = useCallback((value) => () => {
    if (checked.includes(value)) {
      onCheckSuggestion(checked.filter((item) => item !== value));
      return;
    }
    onCheckSuggestion([...checked, value]);
  }, [onCheckSuggestion, checked]);

  const isAllChecked = useMemo(() => list.every((item) => checked.includes(item.keyword)), [list, checked]);

  const onAllCheck = useCallback(() => {
    if (isAllChecked) {
      onCheckSuggestion([]);
      return;
    }
    onCheckSuggestion(list.map(({ keyword }) => keyword));
  }, [onCheckSuggestion, checked, list, isAllChecked]);


  return (
    <Wrapper ref={blockRef}>
      {loading && <div className="table-loader"><Spin size="large" /></div>}
      <div className="table-list-header">
        <div className="header-title">{t(FieldTableTitleKeys.Keywords)}<span>{total}</span></div>
      </div>
      <table className="suggestions-table">
        <thead>
          <tr>
            <th>
              <Checkbox
                onChange={onAllCheck}
                checked={isAllChecked}
                small
              />
            </th>
            <th>{t(FieldTableTitleKeys.HeaderKeywords)}</th>
            <th>{t(FieldTableTitleKeys.HeaderPosition)}</th>
            <th>{t(FieldTableTitleKeys.HeaderSearchVolume)}</th>
            <th>{t(UrlGroupTableKeys.Url)}</th>
          </tr>
        </thead>
        <tbody>
          {list.map(({ keyword, url, position, traffic }, index) => (
            <tr onClick={onSingleCheck(keyword)} className={clsx({ checked: checked.includes(keyword) })} key={`${index}-${keyword}-${url}-${traffic}-${position}`}>
              <td>
                <Checkbox
                  onChange={onSingleCheck(keyword)}
                  checked={checked.includes(keyword)}
                  small
                />
              </td>
              <td title={keyword}>{keyword}</td>
              <td title={position.toString()}>{position}</td>
              <td title={traffic.toString()}>{traffic}</td>
              <td title={url}>{url}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        total={total}
        limit={limit}
        offset={offset}
        onLimitChange={onLimitChange}
        onOffsetChange={onOffsetChange}
      />
    </Wrapper>
  );
}

export default KeywordsSuggestions;
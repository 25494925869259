import styled from 'styled-components';

export const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    margin-bottom: 24px;

    &.hidden {
      display: none;
    }
  }

  .body-title {
    margin-bottom: 12px;

    font-style: normal;
    font-size: 26px;
    font-weight: bold;
    line-height: 39px;
    letter-spacing: 0px;
    color: #242D46;
  }

  .body-message {
    margin-bottom: 40px;

    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    color: #242D46CC;
  }

  .actions-button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

import styled from 'styled-components';

import { IconDropdown, IconDropdownActive } from '../../../../components/styled/icons';

export const StyledIconDropdown = styled(IconDropdown)`
  transform: rotate(-90deg);
`;

export const StyledIconDropdownActive = styled(IconDropdownActive)`
  transform: rotate(90deg);
`;

export const StyledWrapper = styled.div`
  position: relative;

  height: 40px;
  width: 276px;

  .drop-icon {
    display: flex;

    position: absolute;
    top: 10px;
    right: 14px;

    cursor: pointer;

    transform: rotate(90deg);
    transition: transform 0.2s ease-out;
  }

  .project-title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    cursor: pointer;

    .project-item-crawl-in-progress-indicator {
      position: absolute;
      width: 262px;
      bottom: -4px;
      left: -2px;

      .stacked-indicator-statistics {
        display: none;
      }

      .stacked-indicator-statistics + div {
        min-height: 4px;
        height: 4px;
        border-radius: 100px;
        background:#dbdbdb;

        &::before {
          border-radius: 100px;
        }
      }
    }

    > .domain-picture {
      margin-right: 5px;
    }

    .domain-name {
      flex-grow: 1;

      font-style: normal;
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
      letter-spacing: 0px;
      color: #171C28;

      max-width: 170px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.group {
        font-size: 9px;
        line-height: 12px;
        font-weight: 500;
        color: #8c8d93;
      }

      .domain-group-name {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #171C28;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .lab-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: #181818 0% 0% no-repeat padding-box;
      border-radius: 50%;
      margin-left: 5px;
    }
  }

  .project-list {
    overflow: auto;
    overflow-x: hidden;

    width: 100%;
    max-height: 562px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #171C2810 0% 0% no-repeat padding-box;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #555BF0 0% 0% no-repeat padding-box;
      border-radius: 4px;
    }
  }

  .project-container {
    position: absolute;
    top: 0;
    right: 0;

    padding-left: 8px;
    padding-right: 20px;

    height: auto;
    min-height: 40px;
    width: 276px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    transition: all .2s ease;

    &:hover {
      top: 2px;

      border: none;
      box-shadow: 0px 10px 30px #0000001F;

      .project-title {
        height: 40px;
        .project-item-crawl-in-progress-indicator {
          display: none;
        }
      }
    }

    .project-title {
      height: 36px;
      transition: all .2s ease;
    }

    .project-list-title {
      padding-left: 8px;
      transform: translateY(-10px);
    }

    .project-list-title,
    .project-all-button {
      display: none;
    }

    .project-list {
      margin-bottom: 0;
      height: 0;
      opacity: 0;

      transition-property: height, opacity;
      transition-duration:  0.15s;
      transition-timing-function: ease-out;
    }

    transform: translateX(0px);

    transition-property: height, width, transform;
    transition-duration:  0.15s;
    transition-timing-function: ease-out;
  }

  &.open {
    .project-container {
      position: absolute;
      top: 0;
      right: 0;

      padding: 20px;
      padding-right: 24px;

      height: auto;
      width: 320px;

      box-shadow: 0px 10px 30px #0000001F;
      border-radius: 18px;
      border: none;

      .drop-icon {
        top: 24px;
        right: 18px;
        transform: rotate(-90deg);
        transition: transform 0.2s ease-out;
      }

      .project-title {
        display: none;
      }

      .project-list-title,
      .project-list,
      .project-all-button {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
      }

      .project-list-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 20px;

        font-style: normal;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0px;
        color: #171C28;
        opacity: 1;

        cursor: pointer;

        transform: translateY(0px);
      }

      .project-list {
        margin-bottom: 26px;
        height: 100%;
        max-height: 562px;
        opacity: 1;

        transition-property: height, opacity;
        transition-duration:  0.15s;
        transition-timing-function: ease-out;
      }

      .project-all-button {
        button {
          max-width: none;
          width: 100%;
        }
      }

      transform: translate(12px, -10px);

      transition-property: height, width, transform;
      transition-duration:  0.15s;
      transition-timing-function: ease-out;
    }
  }
`;

import { Languages as LanguagePayload } from '../../enums/locales/languages';
import { AppContext, DeviceType } from '../../enums/auth';
import { DeviceIdentifier } from '../../types/auth.types';

export enum AppActionTypes {
  APP_START = '[App] app-start',
  APP_STARTED = '[App] app-started',
  LANGUAGE_REFRESH = '[App] language-refresh',
  DEVICE_IDENTIFIER_UPDATE = '[App] device-identifier-update',
  DEVICE_IDENTIFIER_REFRESH = '[App] device-identifier-refresh',
  UI_MERGE = '[App] ui-merge',
}

export interface IUIPayload {
  isTopBarVisible?: boolean;
  isSideBarVisible?: boolean;
  isSideBarCollapsed?: boolean;
  isFullscreen?: boolean;
  isLabData?: boolean;
  showColors?: boolean;
  showBenchmarks?: boolean;
}

export interface AppState {
  appStarted: boolean;
  language: LanguagePayload;
  apiAppContext: AppContext;
  deviceType: DeviceType;
  deviceIdentifier: DeviceIdentifierPayload;
  UI: IUIPayload;
}

export type DeviceIdentifierPayload = DeviceIdentifier;

export { LanguagePayload };

import styled from 'styled-components';
import { ReactComponent as RawLogoSymbolLightSVG } from '../../../../assets/icons/logo_symbol_light.svg';

export const IconLogoSymbolLight = styled(RawLogoSymbolLightSVG)`
  .a, .b {
    fill: #fff;
  }
  
  .a {
    opacity: 0.404;
  }
`;

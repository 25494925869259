import clsx from 'clsx';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import { IconArrowRight } from '../../../components/styled/icons';
import { ProjectSettingsKeys } from '../../../enums/locales/modals';

import { Button, ButtonType } from "../../../components/styled/Button";
import { selectUserSettingsVisible } from '../../../redux/modals/selectors';

import { Wrapper } from './UserSettings.styled';
import { modalsActions } from '../../../redux/modals/actions';
import { profileSettingsActions } from '../../../redux/profile-settings/actions';
import { ProfileForm, ProfileFormBag } from '../../forms/ProfileForm';
import { selectUI as selectProfileUI } from '../../../redux/profile-settings/selectors';
import { LicensesTable } from './LicensesTable';

const UserSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visible = useSelector(selectUserSettingsVisible);
  const ref = useRef(null);
  const btnRef = useRef(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const { passwordReseted, passwordReseting, licensesLoading } = useSelector(selectProfileUI);

  const onReset = useCallback(() => {
    dispatch(profileSettingsActions.resetPasswordStart());
  }, [dispatch]);

  const onBackClick = useCallback((e) => {
    const pathOfSvgRef = svgRef.current?.children[0].children[1];
    if (e.target === ref.current || e.target === btnRef.current || e.target === svgRef.current || e.target === pathOfSvgRef) {
      dispatch(modalsActions.userSettingsHide());
      ProfileFormBag.resetForm();
      dispatch(profileSettingsActions.reset());
    }
  }, [dispatch]);

  const onSubmit = useCallback((values) => {
    dispatch(profileSettingsActions.personalRefresh(values));
    dispatch(profileSettingsActions.adminUserPersonalSave());
  }, [dispatch]);

  const resetedMessage = useMemo(() => {
    if (passwordReseted === null) {
      return '';
    }
    if (passwordReseted) {
      return ProjectSettingsKeys.ResetedSuccessful;
    }

    return ProjectSettingsKeys.ResetedError;
  }, [passwordReseted]);

  const resetBtnText = useMemo(() => passwordReseted ? t(ProjectSettingsKeys.ResetResend) : t(ProjectSettingsKeys.ResetPassword), [t, passwordReseted]);

  return (
    <Wrapper
      ref={ref}
      onMouseDown={onBackClick}
      className={clsx({ disapear: !visible })}
      visible={visible}
    >
      <div className="sider-mask"></div>
      <div className="sider-body">
        <div className="sider-header">
          <div ref={btnRef} onClick={onBackClick} className="btn-back">
            <IconArrowRight ref={svgRef} />
          </div>
          {t(ProjectSettingsKeys.EditUser)}
        </div>
        <div className="sider-body-content">
          {visible && (
            <>
              <div className="sider-body-content-form">
                <ProfileForm onSubmit={onSubmit} />
                <div className="security-container">
                  <div className="security-title">
                    {t(ProjectSettingsKeys.Security)}
                  </div>
                  <div className="password-reset">
                    <div className="password-reset-label">{t(ProjectSettingsKeys.PasswordLabel)}</div>
                    <div className="password-reset-button">
                      <Button
                        type={ButtonType.Secondary}
                        onClick={onReset}
                        disabled={passwordReseting}
                        title={resetBtnText}
                      />
                      <div className={clsx("reset-info", { success: !!passwordReseted })}>
                        {t(resetedMessage)}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="licenses-title">
                    {t(ProjectSettingsKeys.Licenses)}
                  </div>
                  {licensesLoading && <Spin size="large" />}
                  {!licensesLoading && <LicensesTable />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default UserSettings;
import { createSelector } from 'reselect';
import { RootState } from '../store';

const accounts = (state: RootState) => state.Accounts.accounts;
const accountsSearch = (state: RootState) => state.Accounts.accountsSearch;
const accountsSortOrder = (state: RootState) => state.Accounts.accountsSortOrder;
const accountsSortKey = (state: RootState) => state.Accounts.accountsSortKey;
const UI = (state: RootState) => state.Accounts.UI;

export const selectAccounts = createSelector(
	[accounts],
	(accounts) => accounts,
);

export const selectAccountCreatorEmailByID = (id: string) => createSelector(
	[accounts],
	(accounts) => accounts.find(({ license_id }) => license_id === id)?.creator_email,
);

export const selectAccountsSortOrder = createSelector(
	[accountsSortOrder],
	(accountsSortOrder) => accountsSortOrder,
);

export const selectAccountsSortKey = createSelector(
	[accountsSortKey],
	(accountsSortKey) => accountsSortKey,
);

export const selectAccountByID = (id: string) => createSelector(
	[accounts],
	(accounts) => accounts.find(({ license_id }) => id === license_id),
)

export const selectAccountsSearch = createSelector(
	[accountsSearch],
	(accountsSearch) => accountsSearch,
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 2px solid #DBDBDB;
  border-radius: 10px;
  padding: 12px 18px 8px;

  table {
    position: relative;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    td, th {
      font-size: 12px;
      text-align: center;
      color: #24242A;
      vertical-align: middle;
      padding: 0 4px;

      &:first-child,
      &:nth-child(2) {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    th {
      font-weight: 600;
      width: 13%;
      color: ${({ theme }) => theme.colors.secondaryDark};

      &:first-child {
        width: 55%;
      }
      &:nth-child(2) {
        width: 19%;
      }
    }

    thead tr {
      height: 40px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryDark} !important;
    }

    tbody tr {
      height: 48px;

      &:nth-child(2n) {
        height: 30px;
        td {
          color: ${({ theme }) => theme.colors.red};
          font-weight: 600;
          cursor: pointer;
          vertical-align: top;
        }
      }

      &:not(:last-child):nth-child(2n) {
        border-bottom: 2px solid #DBDBDB !important;
      }
    }
  }
`;
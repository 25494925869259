import { AxiosError } from 'axios';
import { BaseAPIService } from '../BaseAPIService';
import { IBaseResponse } from '../BaseAPIService/types';
import { dataServiceApiUrl } from '../../config';
import { API_ADMIN_ROUTES } from '../../constants/routes';
import { IRefreshTokensOptions, IUserSimple } from '../AuthService/types';
import { IProject, IProjectResponseBody, ICrawlsResponseBody } from '../ProjectService/types';
import {
  IAdminLicenseRequestBody,
  IAdminProjectsRequestBody,
  IAdminProjectMoveRequestBody,
  IAdminCrawlsRequestBody,
  IAdminLicenseResponseBody,
  IAdminProjectsResponseBody,
  IUsersResponseBody,
  ISingleUserResponseBody,
} from './types';
import { ILicense, ILicenseResponseBody, ILicenseEntryResponseBody, ILicenseCompany, ILicenseUserRequestBody } from '../LicensesService/types';


class AdminService extends BaseAPIService {

  constructor(url: string) {
    super(url);
    this.getUsers = this.getUsers.bind(this);
    this.getUser = this.getUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.getLicenses = this.getLicenses.bind(this);
    this.getLicense = this.getLicense.bind(this);
    this.getLicenseProjects = this.getLicenseProjects.bind(this);
    this.getLicenseProjectCrawls = this.getLicenseProjectCrawls.bind(this);
    this.createLicense = this.createLicense.bind(this);
    this.updateLicense = this.updateLicense.bind(this);
    this.updateLicenseCompany = this.updateLicenseCompany.bind(this);
    this.updateLicenseUser = this.updateLicenseUser.bind(this);
    this.moveLicenseProject = this.moveLicenseProject.bind(this);
    this.updateLicenseProject = this.updateLicenseProject.bind(this);
    this.deleteLicense = this.deleteLicense.bind(this);
    this.deleteLicenseProject = this.deleteLicenseProject.bind(this);
    this.deleteLicenseProjectCrawl = this.deleteLicenseProjectCrawl.bind(this);
  }

  async getUsers(body: IAdminLicenseRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IUsersResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IUsersResponseBody> = await this.get<IUsersResponseBody, IAdminLicenseRequestBody>(
        `${API_ADMIN_ROUTES.users}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IUsersResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getUser(userID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ISingleUserResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ISingleUserResponseBody> = await this.get<ISingleUserResponseBody>(
        `${API_ADMIN_ROUTES.users}/${userID}`,
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ISingleUserResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateUser(body: Partial<IUserSimple>, userID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<IUsersResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IUsersResponseBody> = await this.patch<IUsersResponseBody, Partial<IUserSimple>>(
        `${API_ADMIN_ROUTES.users}/${userID}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IUsersResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async deleteUser(userID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<IUsersResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IUsersResponseBody> = await this.delete<IUsersResponseBody>(
        `${API_ADMIN_ROUTES.users}/${userID}`,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IUsersResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getLicenses(body: IAdminLicenseRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IAdminLicenseResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IAdminLicenseResponseBody> = await this.get<IAdminLicenseResponseBody, IAdminLicenseRequestBody>(
        `${API_ADMIN_ROUTES.licenses}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IAdminLicenseResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getLicense(licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicenseEntryResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicenseEntryResponseBody> = await this.get<ILicenseEntryResponseBody>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}`,
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicenseEntryResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getLicenseProjects(licenseID: string, body: IAdminProjectsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IAdminProjectsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IAdminProjectsResponseBody> = await this.get<IAdminProjectsResponseBody, IAdminProjectsRequestBody>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/projects`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IAdminProjectsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getLicenseProjectCrawls(projectID: string, licenseID: string, body: IAdminCrawlsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICrawlsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICrawlsResponseBody> = await this.get<ICrawlsResponseBody, IAdminCrawlsRequestBody>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/projects/${projectID}/crawls`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICrawlsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async createLicense(body: Partial<ILicense>, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicenseResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicenseResponseBody> = await this.post<ILicenseResponseBody, Partial<ILicense>>(
        `${API_ADMIN_ROUTES.licenses}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicenseResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateLicense(body: Partial<ILicense>, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicenseResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicenseResponseBody> = await this.patch<ILicenseResponseBody, Partial<ILicense>>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicenseResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateLicenseCompany(body: ILicenseCompany, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicenseResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicenseResponseBody> = await this.patch<ILicenseResponseBody, ILicenseCompany>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/company`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicenseResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateLicenseUser(body: ILicenseUserRequestBody, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicenseResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicenseResponseBody> = await this.post<ILicenseResponseBody, ILicenseUserRequestBody>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/user`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicenseResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async moveLicenseProject(body: IAdminProjectMoveRequestBody, projectID: string, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<unknown>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<unknown> = await this.post<unknown, IAdminProjectMoveRequestBody>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/projects/${projectID}/move`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<unknown> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateLicenseProject(body: Partial<IProject>, projectID: string, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProjectResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProjectResponseBody> = await this.patch<IProjectResponseBody, Partial<IProject>>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/projects/${projectID}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProjectResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async deleteLicenseProject(projectID: string, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<unknown>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<unknown> = await this.delete<unknown>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/projects/${projectID}`,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<unknown> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async deleteLicenseProjectCrawl(crawlID: string, projectID: string, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<unknown>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<unknown> = await this.delete<unknown>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}/projects/${projectID}/crawls/${crawlID}`,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<unknown> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async deleteLicense(licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicenseResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicenseResponseBody> = await this.delete<ILicenseResponseBody>(
        `${API_ADMIN_ROUTES.licenses}/${licenseID}`,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicenseResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }
}

const ServiceInstance = new AdminService(dataServiceApiUrl);

export default ServiceInstance;
export { ServiceInstance as AdminService };

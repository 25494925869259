import { takeLatest, all, put, call, select } from 'redux-saga/effects';

import { isCrawlID } from '../../utils/common';
import { DataService } from '../../services/DataService';

import { Action } from '../commonTypes';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IFeatureComparisonRequestBody, IFeatureComparisonResponseBody } from '../../services/DataService/types';
import { IProject } from '../../services/ProjectService/types';

import { vitalsActions } from './actions';
import { selectQuantifier, selectComparator } from './selectors';
import { selectActiveProjectID, selectActiveProjectDomain, selectActiveProjectGroupID, selectProjectByID, selectProjectByGroupID } from '../projects/selectors';
import { VitalsActionTypes } from './types';

function* vitalsReload(action: Action<VitalsActionTypes.VITALS_RELOAD, never>): any {
  const quantifier = yield select(selectQuantifier);
  const comparator = yield select(selectComparator);
  const projectID = yield select(selectActiveProjectID);
  const groupID = yield select(selectActiveProjectGroupID);
  const activeProjectDomain = yield select(selectActiveProjectDomain);
  const projectByID: IProject = yield select(selectProjectByID(quantifier));
  const projectByGroupID: IProject = yield select(selectProjectByGroupID(quantifier));
  const projectToCompare = projectByID || projectByGroupID;

  if (!projectID || !(quantifier || comparator)) {
    return;
  }

  yield put(vitalsActions.uiMerge({ loading: true }));

  const requestBody: IFeatureComparisonRequestBody = {
    quantifier: projectByID ? projectByID.domain : isCrawlID(quantifier) ? groupID || activeProjectDomain : quantifier,
    quantifier_crawl_id: isCrawlID(quantifier) ? quantifier : null,
    quantifier_project_id: projectToCompare ? projectToCompare.project_id : projectID,
    comparator: isCrawlID(comparator) ? groupID || activeProjectDomain : comparator,
    comparator_crawl_id: isCrawlID(comparator) ? comparator : null,
    comparator_project_id: projectID,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IFeatureComparisonResponseBody> = yield call(DataService.featureComparison, requestBody, options);
  if (!result?.data) {
    yield put(vitalsActions.uiMerge({ loading: false }));
    return;
  }

  const response: IFeatureComparisonResponseBody = result?.data;

  const { data } = response;

  yield put(vitalsActions.vitalsRefresh(data));
  yield put(vitalsActions.uiMerge({ loading: false }));
}

export default function* vitalsSaga() {
  yield all([
    takeLatest(VitalsActionTypes.VITALS_RELOAD, vitalsReload),
  ]);
}

import { Strategy, RecrawlInterval } from '../../enums/projects';
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { IGoal } from '../../services/ProjectService/types';

export enum ProjectSettingsActionTypes {
  UPDATE_INIT = '[Project Settings] update-init',
  ADMIN_UPDATE_INIT = '[Project Settings] admin-update-init',

  DATA_SAVE = '[Project Settings] data-save',
  ADMIN_DATA_SAVE = '[Project Settings] admin-data-save',
  DATA_REFRESH = '[Project Settings] data-refresh',

  PROJECT_ID_SET = '[Project Settings] project-id-set',
  PROJECT_ID_RESET = '[Project Settings] project-id-reset',

  BENCHMARKS_SUGGESTIONS_RELOAD = '[Project Settings] benchmarks-suggenstions-reload',
  BENCHMARKS_SUGGESTIONS_REFRESH = '[Project Settings] benchmarks-suggenstions-refresh',

  KEYWORDS_SUGGESTIONS_RELOAD = '[Project Settings] keywords-suggenstions-reload',
  KEYWORDS_SUGGESTIONS_REFRESH = '[Project Settings] keywords-suggenstions-refresh',

  FINISHED_STEPS_REFRESH = '[Project Settings] finished-steps-refresh',

  RESET = '[Project Settings] reset',

  UI_MERGE = '[Project Settings] ui-merge',
}

export interface IUrlGroup {
  name: string;
  regex: string;
  url_group_id?: string;
}

export interface IKeyword {
  keyword: string;
  url: string;
  position: number;
  traffic: number;
}

export interface IProjectSettingsData {
  domain?: string;
  name?: string;
  country_code?: string;
  strategy?: Strategy | '';
  recrawl_interval?: RecrawlInterval | null,
  keywords?: string[];
  benchmarks?: string[];
  url_groups?: IUrlGroup[];
  invites?: string[];
  goals?: IGoal[];
  urls?: string[];
  is_warmup_urls?: boolean;
}

export interface IUIPayload {
  isNew?: boolean;
  isTest?: boolean;
  isCrawled?: boolean;
  isCrawlNow?: boolean;
  loading?: boolean;
  keywordsCount?: number | null;
  keywordsOffset?: number;
  keywordsLimit?: ROWS_COUNTS;
  keywordsLoading?: boolean;
  keywordsInitCount?: number;
  urlsInitCount?: number;
  initRecrawlInterval?: RecrawlInterval | null,
}

export interface ProjectSettingsState {
  data: IProjectSettingsData;
  projectID: string | null;
  benchmarksSuggestions: string[];
  keywordsSuggestions: IKeyword[];
  finishedSteps: number[];
  UI: IUIPayload;
}
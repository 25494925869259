import { ILicense, ILicenseCompany } from "../../services/LicensesService/types";

export enum ProfileSettingsActionTypes {
  INIT = '[Profile Settings] init',
  ADMIN_INIT = '[Profile Settings] admin-init',
  ADMIN_USER_INIT = '[Profile Settings] admin-user-init',

  AVATAR_UPLOAD = '[Profile Settings] avatar-upload',

  RESET_PASSWORD_START = '[Profile Settings] reset-password-start',

  CHANGE_PASSWORD = '[Profile Settings] change-password',

  AVATAR_REFRESH = '[Profile Settings] avatar-refresh',

  PERSONAL_SAVE = '[Profile Settings] personal-save',
  PERSONAL_REFRESH = '[Profile Settings] personal-refresh',
  ADMIN_PERSONAL_SAVE = '[Profile Settings] admin-personal-save',
  ADMIN_USER_PERSONAL_SAVE = '[Profile Settings] admin-user-personal-save',

  COMPANY_SAVE = '[Profile Settings] company-save',
  COMPANY_REFRESH = '[Profile Settings] company-refresh',
  ADMIN_COMPANY_SAVE = '[Profile Settings] admin-company-save',

  USERS_UPDATE = '[Profile Settings] users-update',
  USERS_REFRESH = '[Profile Settings] users-refresh',
  ADMIN_USERS_UPDATE = '[Profile Settings] admin-users-update',

  LICENSES_REFRESH = '[Profile Settings] licenses-refresh',
  LICENSES_UPDATE = '[Profile Settings] licenses-update',

  RESET = '[Profile Settings] reset',

  UI_MERGE = '[Profile Settings] ui-merge',
}

export interface IProfileAvatar {
  method: string;
  url: string;
}

export interface IChangePassword {
  email: string;
  password: string;
  validation_code: string;
}

export interface IProfilePersonal {
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface IUIPayload {
  loading?: boolean;
  personalLoading?: boolean;
  companyLoading?: boolean;
  passwordReseted?: boolean | null;
  passwordReseting?: boolean;
  changeMessage?: string;
  editingLicenseID?: string;
  editingUserID?: string;
  licensesLoading?: boolean;
}

export interface ProfileSettingsState {
  personal: IProfilePersonal;
  company: Partial<ILicenseCompany>;
  users: string[];
  licenses: ILicense[];
  UI: IUIPayload;
}
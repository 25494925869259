import React from 'react';

import { IProps } from '../DetailsTable';
import { Wrapper, StyledTH } from './TableHeader.styled';

const TableHeader: React.FC<Pick<IProps, 'columns'>> = ({ columns }) => {
  return (
    <Wrapper>
      <tr>
        {columns.map(({ dataIndex, title, width }) => (
          <StyledTH width={width} key={dataIndex}>
            {title}
          </StyledTH>
        ))}
      </tr>
    </Wrapper>
  );
}

export default TableHeader;
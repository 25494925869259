import styled from 'styled-components';
import { hexToRGBA } from '../../../utils/theme';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  width: 100%;
  margin-bottom: 20px;

  &.no-bottom-margin {
    margin-bottom: 0;
  }

  .label,
  .error-message,
  .success-indicator {
    display: none;
  }

  .control {
    display: flex;
    width: 100%;
  }

  &.label {
    .label {
      display: flex;
      width: 100%;
      margin-bottom: 8px;

      .sub-label {
        margin-left: 14px;
        color: #8E8E8E;
        font-weight: 400;
      }

      font-style: normal;
      font-size: ${({ theme }) => theme.fontSize.inputLabels};
      font-weight: ${({ theme }) => theme.fontWeight.inputLabels};
      line-height: ${({ theme }) => theme.lineHeight.inputLabels};
      letter-spacing: ${({ theme }) => theme.letterSpacing.inputLabels};
      color: ${({ theme }) => {
    return hexToRGBA(
      theme.colors.inputLabels.font.default,
      theme.colors.inputLabels.font.opacity.default,
    );
  }};
    }
  }

  &.error.touched {
    .error-message {
      display: flex;
      flex-wrap: wrap;

      margin-top: 13px;

      font-style: normal;
      font-size: ${({ theme }) => theme.fontSize.inputMessages};
      font-weight: ${({ theme }) => theme.fontWeight.inputMessages};
      line-height: ${({ theme }) => theme.lineHeight.inputMessages};
      letter-spacing: ${({ theme }) => theme.letterSpacing.inputMessages};
      color: ${({ theme }) => {
    return hexToRGBA(
      theme.colors.inputMessages.font.error,
      theme.colors.inputMessages.font.opacity.error,
    );
  }};
    }
  }

  &:not(.error).touched {
    .success-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 13px;
      right: -32px;
    }
  }
`;

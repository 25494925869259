import { AxiosError } from 'axios';
import { BaseAPIService } from '../BaseAPIService';
import { IBaseResponse } from '../BaseAPIService/types';
import { IRefreshTokensOptions } from '../AuthService/types';
import { RouteParamPlaceholder } from '../../enums/routes'

import {
  IDomainKpiRequestBody,
  IDomainKpiResponseBody,
  IDomainHistoryRequestBody,
  IDomainHistoryResponseBody,
  IFeatureComparisonRequestBody,
  IFeatureComparisonResponseBody,
  FeatureOverviewResponseBody,
  IMetricsRankingCorrelationRequestBody,
  IMetricsRankingCorrelationResponseBody,
  IMetricsUrlGroupsRequestBody,
  IMetricsUrlGroupsResponseBody,
  IMetricsUrlsRequestBody,
  IMetricsUrlsResponseBody,
  ILeaderboardDataResponseBody,
  ILeaderboardDataRequestBody,
  ILeaderboardHistoryRequestBody,
  ILeaderboardHistoryResponseBody,
  ILeaderboardRankResponseBody,
  ILeaderboardRankRequestBody,
  ILeaderboardBenchmarkRankResponseBody,
  ILeaderboardBenchmarkRankRequestBody,
  IUrlDetailsResponseBody,
  IUrlDetailsRequestBody,
  ICompoundAnalysisUrlsResponseBody,
  ICompoundAnalysisUrlsRequestBody,
  ICompoundAnalysisNetworkDetailsResponseBody,
  IDirectoryAnalysisRequestBody,
  IDirectoryAnalysisResponseBody,
  ICompoundAnalysisThumbnailsResponseBody,
} from './types';

import { dataServiceApiUrl } from '../../config';
import { API_DATA_ROUTES } from '../../constants/routes';

class DataService extends BaseAPIService {
  constructor(url: string) {
    super(url);
    this.domainKpi = this.domainKpi.bind(this);
    this.domainHistory = this.domainHistory.bind(this);
    this.featureComparison = this.featureComparison.bind(this);
    this.featureOverview = this.featureOverview.bind(this);

    this.metricsRankingCorrelation = this.metricsRankingCorrelation.bind(this);
    this.metricsUrlGroups = this.metricsUrlGroups.bind(this);
    this.metricsUrls = this.metricsUrls.bind(this);

    this.leaderboardData = this.leaderboardData.bind(this);
    this.leaderboardHistory = this.leaderboardHistory.bind(this);
    this.leaderboardRank = this.leaderboardRank.bind(this);
    this.leaderboardBenchmarkRank = this.leaderboardBenchmarkRank.bind(this);

    this.urlDetails = this.urlDetails.bind(this);

    this.compoundAnalysisUrls = this.compoundAnalysisUrls.bind(this);
    this.compoundAnalysisNetworkDetails = this.compoundAnalysisNetworkDetails.bind(this);
    this.compoundAnalysisThumbnails = this.compoundAnalysisThumbnails.bind(this);

    this.directoryAnalysis = this.directoryAnalysis.bind(this);
  }

  async domainKpi(projectID: string, groupID: string | null, body: IDomainKpiRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IDomainKpiResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IDomainKpiResponseBody> = await this.post<IDomainKpiResponseBody, IDomainKpiRequestBody>(
        API_DATA_ROUTES.domainKpi.replace(RouteParamPlaceholder.ProjectID, `${projectID}`).replace(`/${RouteParamPlaceholder.GroupID}`, Boolean(groupID) ? `/${groupID}` : ''),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IDomainKpiResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async domainHistory(projectID: string, body: IDomainHistoryRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IDomainHistoryResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IDomainHistoryResponseBody> = await this.post<IDomainHistoryResponseBody, IDomainHistoryRequestBody>(
        API_DATA_ROUTES.domainHistory.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IDomainHistoryResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async featureComparison(body: IFeatureComparisonRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IFeatureComparisonResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IFeatureComparisonResponseBody> = await this.post<IFeatureComparisonResponseBody, IFeatureComparisonRequestBody>(
        API_DATA_ROUTES.featureComparison,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IFeatureComparisonResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async featureOverview(projectID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<FeatureOverviewResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<FeatureOverviewResponseBody> = await this.post<FeatureOverviewResponseBody>(
        API_DATA_ROUTES.featureOverview.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<FeatureOverviewResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async metricsRankingCorrelation(projectID: string, body: IMetricsRankingCorrelationRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IMetricsRankingCorrelationResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IMetricsRankingCorrelationResponseBody> = await this.post<IMetricsRankingCorrelationResponseBody, IMetricsRankingCorrelationRequestBody>(
        API_DATA_ROUTES.metricsRankingCorrelation.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IMetricsRankingCorrelationResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async metricsUrlGroups(projectID: string, body: IMetricsUrlGroupsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IMetricsUrlGroupsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IMetricsUrlGroupsResponseBody> = await this.post<IMetricsUrlGroupsResponseBody, IMetricsUrlGroupsRequestBody>(
        API_DATA_ROUTES.metricsUrlGroups.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IMetricsUrlGroupsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async metricsUrls(projectID: string, body: IMetricsUrlsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IMetricsUrlsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IMetricsUrlsResponseBody> = await this.post<IMetricsUrlsResponseBody, IMetricsUrlsRequestBody>(
        API_DATA_ROUTES.metricsUrls.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IMetricsUrlsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async leaderboardData(projectID: string, body: ILeaderboardDataRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILeaderboardDataResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILeaderboardDataResponseBody> = await this.post<ILeaderboardDataResponseBody, ILeaderboardDataRequestBody>(
        API_DATA_ROUTES.leaderboardData.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILeaderboardDataResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async leaderboardHistory(projectID: string, body: ILeaderboardHistoryRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILeaderboardHistoryResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILeaderboardHistoryResponseBody> = await this.post<ILeaderboardHistoryResponseBody, ILeaderboardHistoryRequestBody>(
        API_DATA_ROUTES.leaderboardHistory.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILeaderboardHistoryResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async leaderboardRank(projectID: string, body: ILeaderboardRankRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILeaderboardRankResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILeaderboardRankResponseBody> = await this.post<ILeaderboardRankResponseBody, ILeaderboardRankRequestBody>(
        API_DATA_ROUTES.leaderboardRank.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILeaderboardRankResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async leaderboardBenchmarkRank(projectID: string, body: ILeaderboardBenchmarkRankRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILeaderboardBenchmarkRankResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILeaderboardBenchmarkRankResponseBody> = await this.post<ILeaderboardBenchmarkRankResponseBody, ILeaderboardBenchmarkRankRequestBody>(
        API_DATA_ROUTES.leaderboardBenchmarkRank.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILeaderboardBenchmarkRankResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async urlDetails(projectID: string, crawlID: string, urlID: string, body: IUrlDetailsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IUrlDetailsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IUrlDetailsResponseBody> = await this.post<IUrlDetailsResponseBody, IUrlDetailsRequestBody>(
        API_DATA_ROUTES.urlDetails.replace(RouteParamPlaceholder.ProjectID, `${projectID}`).replace(RouteParamPlaceholder.CrawlID, `${crawlID}`).replace(RouteParamPlaceholder.UrlID, `${urlID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IUrlDetailsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async compoundAnalysisUrls(projectID: string, groupID: string, body: ICompoundAnalysisUrlsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICompoundAnalysisUrlsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICompoundAnalysisUrlsResponseBody> = await this.post<ICompoundAnalysisUrlsResponseBody, ICompoundAnalysisUrlsRequestBody>(
        API_DATA_ROUTES.compoundAnalysisUrls.replace(RouteParamPlaceholder.ProjectID, `${projectID}`).replace(`/${RouteParamPlaceholder.GroupID}`, Boolean(groupID) ? `/${groupID}` : ''),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICompoundAnalysisUrlsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async directoryAnalysis(projectID: string, body: IDirectoryAnalysisRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IDirectoryAnalysisResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IDirectoryAnalysisResponseBody> = await this.post<IDirectoryAnalysisResponseBody, IDirectoryAnalysisRequestBody>(
        API_DATA_ROUTES.directoryAnalysis.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IDirectoryAnalysisResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async compoundAnalysisNetworkDetails(projectID: string, crawlID: string, urlID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICompoundAnalysisNetworkDetailsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICompoundAnalysisNetworkDetailsResponseBody> = await this.post<ICompoundAnalysisNetworkDetailsResponseBody>(
        API_DATA_ROUTES.compoundAnalysisNetworkDetails.replace(RouteParamPlaceholder.ProjectID, `${projectID}`).replace(RouteParamPlaceholder.CrawlID, `${crawlID}`).replace(RouteParamPlaceholder.UrlID, `${urlID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICompoundAnalysisNetworkDetailsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async compoundAnalysisThumbnails(projectID: string, crawlID: string, urlID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICompoundAnalysisThumbnailsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICompoundAnalysisThumbnailsResponseBody> = await this.post<ICompoundAnalysisThumbnailsResponseBody>(
        API_DATA_ROUTES.compoundAnalysisThumbnails.replace(RouteParamPlaceholder.ProjectID, `${projectID}`).replace(RouteParamPlaceholder.CrawlID, `${crawlID}`).replace(RouteParamPlaceholder.UrlID, `${urlID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICompoundAnalysisThumbnailsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }
}

const ServiceInstance = new DataService(dataServiceApiUrl);

export default ServiceInstance;
export { ServiceInstance as DataService };

import isEmpty from "lodash/isEmpty";

export const createFinishedSteps = (formStepsFields: string[][], formErrors: any, formTouched: any, formValues: any): number[] => {
  const finishedSteps: number[] = [];

  formStepsFields.forEach((fields: string[], index: number) => {
    const isError = fields.every((field: string) => !Object.keys(formErrors).includes(field));
    const isTouched = fields.every((field: string) => Object.keys(formTouched).includes(field));
    const isNotEmpty = fields.every((field: string) => Array.isArray(formValues[field]) ? !isEmpty(formValues[field]) : !!formValues[field]);

    if (isError && isTouched && isNotEmpty) {
      finishedSteps.push(index);
    }
  });

  return finishedSteps;
}

import { DomainHistoryInterval, Feature } from '../../enums/data';
import { ILeaderboardBenchmarkRank, ILeaderboardData, ILeaderboardHistoryData } from '../../services/DataService/types';
import { LeaderboardActions } from './actions';
import { IUIPayload, LeaderboardActionTypes, LeaderboardState } from './types';

const initState: LeaderboardState = {
  data: [],
  rank: null,
  benchmarkRanks: [],
  history: {},
  historyInterval: DomainHistoryInterval.LastOneHundredAndEightyDays,
  feature: Feature.LargestContentfulPaintFD,
  UI: {
    dataLoading: false,
    rankLoading: false,
    benchmarkRanksLoading: false,
    historyLoading: false,
  },
};

export function leaderboardReducer(state: LeaderboardState = initState, action: LeaderboardActions): LeaderboardState {
  switch (action.type) {
    case LeaderboardActionTypes.FEATURE_REFRESH: {
      return {
        ...state,
        feature: action.payload as Feature,
      };
    }
    case LeaderboardActionTypes.DATA_REFRESH: {
      return {
        ...state,
        data: action.payload as ILeaderboardData[],
      };
    }
    case LeaderboardActionTypes.RANK_REFRESH: {
      return {
        ...state,
        rank: action.payload as number | null,
      };
    }
    case LeaderboardActionTypes.BENCHMARK_RANKS_REFRESH: {
      return {
        ...state,
        benchmarkRanks: action.payload as ILeaderboardBenchmarkRank[],
      };
    }
    case LeaderboardActionTypes.HISTORY_REFRESH: {
      return {
        ...state,
        history: action.payload as ILeaderboardHistoryData,
      };
    }
    case LeaderboardActionTypes.HISTORY_INTERVAL_REFRESH: {
      return {
        ...state,
        historyInterval: action.payload as DomainHistoryInterval,
      };
    }
    case LeaderboardActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case LeaderboardActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

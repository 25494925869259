export enum FieldValidationMessageKeys {
  Required = 'field_validation_message_required',
  StringMin = 'field_validation_message_string_min',
  StringMax = 'field_validation_message_string_max',
  ArrayMin = 'field_validation_message_array_min',
  Email = 'field_validation_message_email',
  PasswordStrong = 'field_validation_message_password_strong',
  PasswordConfirm = 'field_validation_message_password_confirm',
  DomainStrong = 'field_validation_message_domain_strong',
  BenchmarksRequired = 'field_validation_message_benchmarks_required',
  BenchmarksStrong = 'field_validation_message_benchmarks_strong',
}

export enum FieldLabelKeys {
  Email = 'field_email_label',
  FirstName = 'field_first_name_label',
  LastName = 'field_last_name_label',
  Password = 'field_password_label',
  NewPassword = 'field_new_password_label',
  ConfirmPassword = 'field_confirm_password_label',
  RememberMe = 'field_remember_me_label',
  Domain = 'field_domain_label',
  ProjectName = 'field_project_name_label',
  Country = 'field_country_label',
  Strategy = 'field_strategy_label',
  Recrawl = 'field_recrawl_label',
  Benchmarks = 'field_benchmarks_label',
  UrlGroups = 'field_url_groups_label',
  Invites = 'field_invites_label',
  Keywords = 'field_keyword_label',
  Urls = 'field_urls_label',
  Goals = 'field_goals_label',
  CompanyName = 'field_company_name_label',
  Address = 'field_address_label',
  Zip = 'field_zip_label',
  City = 'field_city_label',
  CompanyCountry = 'field_company_country_label',
  Vat = 'field_vat_label',
  WarmUpUrls = 'field_warm_up_urls_label',
}

export enum FieldSubLabelKeys {
  Recrawl = 'field_recrawl_sub_label',
}

export enum FieldPlaceholderKeys {
  Keywords = 'field_keyword_placeholder',
  Urls = 'field_urls_placeholder',
  SearchKeywords = 'field_search_keyword_placeholder',
  SearchUrls = 'field_search_urls_placeholder',
  AddBenchmarks = 'field_add_benchmarks_placeholder',
  AddUsers = 'field_add_users_placeholder',
  Email = 'field_email_placeholder',
  Password = 'field_password_placeholder',
  AnnotationPlaceholder = 'field_annotation_placeholder',
  AnnotationDatePlaceholder = 'field_annotation_date_placeholder',
}

export enum FieldButtonTextKeys {
  EndKeywords = 'field_keyword_button_text_end',
  EndUsers = 'field_users_button_text_end',
}

export enum FieldTableTitleKeys {
  Keywords = 'field_keyword_table_title',
  Urls = 'field_urls_table_title',
  HeaderKeywords = 'field_keyword_table_header_title',
  HeaderUrls = 'field_urls_table_header_title',
  HeaderPosition = 'field_keyword_table_header_position_title',
  HeaderSearchVolume = 'field_keyword_table_header_search_volume_title',
}

export enum RecrawlIntervalsKeys {
  OneTime = 'project_form_recrawl_interval_one_time',
  Daily = 'project_form_recrawl_interval_daily',
  MondayToFriday = 'project_form_recrawl_interval_monday_to_friday',
  Weekly = 'project_form_recrawl_interval_weekly',
  BiWeekly = 'project_form_recrawl_interval_bi_weekly',
  Monthly = 'project_form_recrawl_interval_monthly',
}

export enum StrategiesKeys {
  Mobile = 'project_form_strategy_mobile',
  Desktop = 'project_form_strategy_desktop',
}

export enum SubmitButtonKeys {
  SignIn = 'sign_in_button-title',
  GoToSignIn = 'go_to_sign_in_button-title',
  GoToDashboard = 'go_to_dashboard_button-title',
  Submit = 'submit_button_title',
  SignUp = 'sign_up_button-title',
}

export enum LinkKeys {
  PasswordRecovery = 'link_password_recovery',
  SignUp = 'link_sign_up',
  CreateAccountInfo = 'link_create_account_info',
}

export enum DividerLabelKeys {
  Or = 'form_divider_label_or',
}

export enum ButtonTextKeys {
  DeleteSelected = 'delete_selected',
  Add = 'add',
  AddGoal = 'add_goal',
  RequestMore = 'request_more',
  Update = 'update',
  Create = 'create',
  NewLicense = 'new_license',
  SaveAnnotation = 'save_annotation'
}

export enum UrlGroupKeys {
  UrlRegex = 'url_groups_and_regex_label',
  Name = 'url_groups_name_label',
  RegexPlaceholder = 'url_groups_regex_placeholder',
  NamePlaceholder = 'url_groups_name_placeholder',
}

export enum GoalsKeys {
  ListHeader = 'goals_list_header',
  SwitchLabel = 'goals_switch_label',
}

export enum UrlGroupTableKeys {
  Url = 'url_groups_and_regex_column',
  Name = 'url_groups_name_column',
  Options = 'url_groups_options_column',
}

export enum FieldListTitleKeys {
  Benchmarks = 'list_title_benchmarks',
  Invites = 'list_title_invites',
}

export enum SimpleListKeys {
  SuggestionsTitle = 'suggestions_title',
}

export enum ConditionsKeys {
  Less = 'less',
  More = 'more',
}
import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { RootState } from '../store';

import { SortOrder } from '../../enums/data';

const feature = (state: RootState) => state.Leaderboard.feature;
const data = (state: RootState) => state.Leaderboard.data;
const rank = (state: RootState) => state.Leaderboard.rank;
const benchmarkRanks = (state: RootState) => state.Leaderboard.benchmarkRanks;
const history = (state: RootState) => state.Leaderboard.history;
const historyInterval = (state: RootState) => state.Leaderboard.historyInterval;
const UI = (state: RootState) => state.Leaderboard.UI;

export const selectFeature = createSelector(
  [feature],
  (feature) => feature,
);

export const selectData = createSelector(
  [data],
  (data) => data,
);

export const selectDataByDomain = (domain: string) => createSelector(
  [data],
  (data) => data.find((item) => item.domain === domain),
);

export const selectDomainData = createSelector(
  [data],
  (data) => data.find(({ is_project_domain }) => is_project_domain),
);

export const selectRank = createSelector(
  [rank],
  (rank) => rank,
);

export const selectBenchmarkRanks = createSelector(
  [benchmarkRanks],
  (benchmarkRanks) => orderBy(benchmarkRanks, ['value'], [SortOrder.Asc]),
);

export const selectCompetitors = createSelector(
  [benchmarkRanks],
  (benchmarkRanks) => orderBy(benchmarkRanks, ['value'], [SortOrder.Asc]),
);

export const selectHistory = createSelector(
  [history],
  (history) => history,
);

export const selectHistoryInterval = createSelector(
  [historyInterval],
  (historyInterval) => historyInterval,
);

export const selectUI = createSelector(
  [UI],
  (UI) => UI,
);

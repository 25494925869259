import { RouteParamPlaceholder } from '../enums/routes';

export const UI_ROUTES = {
  signIn: `/${RouteParamPlaceholder.Lang}/signin`,
  resetPassword: `/${RouteParamPlaceholder.Lang}/change/:id`,
  signUp: `/${RouteParamPlaceholder.Lang}/signup`,
  dashboard: `/${RouteParamPlaceholder.Lang}/dashboard`,
  metrics: `/${RouteParamPlaceholder.Lang}/metrics`,
  metricsPerformanceScore: `/${RouteParamPlaceholder.Lang}/metrics/ps`,
  metricsLargestContentfulPaint: `/${RouteParamPlaceholder.Lang}/metrics/lcp`,
  metricsFirstInputDelay: `/${RouteParamPlaceholder.Lang}/metrics/fid`,
  metricsTotalBlockingTime: `/${RouteParamPlaceholder.Lang}/metrics/tbt`,
  metricsTimeToInteractive: `/${RouteParamPlaceholder.Lang}/metrics/tti`,
  metricsFirstContentfulPaint: `/${RouteParamPlaceholder.Lang}/metrics/fcp`,
  metricsFirstMeaningfulPaint: `/${RouteParamPlaceholder.Lang}/metrics/fmp`,
  metricsCumulativeLayoutShift: `/${RouteParamPlaceholder.Lang}/metrics/cls`,

  metricsInteractionToNextPaint: `/${RouteParamPlaceholder.Lang}/metrics/inp`,
  metricsExperimentalTimeToFirstByte: `/${RouteParamPlaceholder.Lang}/metrics/ttfb`,

  metricsServerResponseTime: `/${RouteParamPlaceholder.Lang}/metrics/srt`,
  metricsDOMSize: `/${RouteParamPlaceholder.Lang}/metrics/doms`,
  metricsModernImages: `/${RouteParamPlaceholder.Lang}/metrics/mi`,
  metricsUnminifiedJS: `/${RouteParamPlaceholder.Lang}/metrics/unminjs`,
  metricsUnminifiedCSS: `/${RouteParamPlaceholder.Lang}/metrics/unmincss`,
  metricsUnusedJS: `/${RouteParamPlaceholder.Lang}/metrics/unusedjs`,
  metricsUnusedCSS: `/${RouteParamPlaceholder.Lang}/metrics/unusedcss`,
  metricsOptimizedImages: `/${RouteParamPlaceholder.Lang}/metrics/oi`,
  metricsRenderBlockingResources: `/${RouteParamPlaceholder.Lang}/metrics/rbr`,
  metricsNumTasksOver50ms: `/${RouteParamPlaceholder.Lang}/metrics/nto50ms`,
  metricsNumTasksOver100ms: `/${RouteParamPlaceholder.Lang}/metrics/nto100ms`,
  metricsNumTasksOver500ms: `/${RouteParamPlaceholder.Lang}/metrics/nto500ms`,
  metricsSpeedIndex: `/${RouteParamPlaceholder.Lang}/metrics/si`,
  metricsUsesTextCompression: `/${RouteParamPlaceholder.Lang}/metrics/utc`,
  metricsOffscreenImages: `/${RouteParamPlaceholder.Lang}/metrics/ofi`,
  metricsNumFonts: `/${RouteParamPlaceholder.Lang}/metrics/nf`,
  metricsNumRequests: `/${RouteParamPlaceholder.Lang}/metrics/nr`,
  metricsNumTasks: `/${RouteParamPlaceholder.Lang}/metrics/nt`,
  metricsNumScripts: `/${RouteParamPlaceholder.Lang}/metrics/nscr`,
  metricsNumStylesheets: `/${RouteParamPlaceholder.Lang}/metrics/nss`,
  metricsTotalByteWeight: `/${RouteParamPlaceholder.Lang}/metrics/tbw`,
  metricsTotalTaskTime: `/${RouteParamPlaceholder.Lang}/metrics/ttt`,
  leaderboards: `/${RouteParamPlaceholder.Lang}/leaderboards`,
  leaderboardsPerformanceScore: `/${RouteParamPlaceholder.Lang}/leaderboards/ps`,
  leaderboardsFirstContentfulPaint: `/${RouteParamPlaceholder.Lang}/leaderboards/fcp`,
  leaderboardsLargestContentfulPaint: `/${RouteParamPlaceholder.Lang}/leaderboards/lcp`,
  leaderboardsFirstInputDelay: `/${RouteParamPlaceholder.Lang}/leaderboards/fid`,
  leaderboardsTimeToInteractive: `/${RouteParamPlaceholder.Lang}/leaderboards/tti`,
  leaderboardsTotalBlockingTime: `/${RouteParamPlaceholder.Lang}/leaderboards/tbt`,
  leaderboardsCumulativeLayoutShift: `/${RouteParamPlaceholder.Lang}/leaderboards/cls`,
  leaderboardsFirstMeaningfulPaint: `/${RouteParamPlaceholder.Lang}/leaderboards/fmp`,
  leaderboardsServerResponseTime: `/${RouteParamPlaceholder.Lang}/leaderboards/srt`,
  leaderboardsDOMSize: `/${RouteParamPlaceholder.Lang}/leaderboards/doms`,
  leaderboardsModernImages: `/${RouteParamPlaceholder.Lang}/leaderboards/mi`,
  leaderboardsUnminifiedJS: `/${RouteParamPlaceholder.Lang}/leaderboards/unminjs`,
  leaderboardsUnminifiedCSS: `/${RouteParamPlaceholder.Lang}/leaderboards/unmincss`,
  leaderboardsUnusedJS: `/${RouteParamPlaceholder.Lang}/leaderboards/unusedjs`,
  leaderboardsUnusedCSS: `/${RouteParamPlaceholder.Lang}/leaderboards/unusedcss`,
  leaderboardsNumTasksOver50ms: `/${RouteParamPlaceholder.Lang}/leaderboards/nto50ms`,
  leaderboardsNumTasksOver100ms: `/${RouteParamPlaceholder.Lang}/leaderboards/nto100ms`,
  leaderboardsNumTasksOver500ms: `/${RouteParamPlaceholder.Lang}/leaderboards/nto500ms`,
  leaderboardsOptimizedImages: `/${RouteParamPlaceholder.Lang}/leaderboards/oi`,
  leaderboardsRenderBlockingResources: `/${RouteParamPlaceholder.Lang}/leaderboards/rbr`,
  leaderboardsSpeedIndex: `/${RouteParamPlaceholder.Lang}/leaderboards/si`,
  leaderboardsUsesTextCompression: `/${RouteParamPlaceholder.Lang}/leaderboards/utc`,
  leaderboardsOffscreenImages: `/${RouteParamPlaceholder.Lang}/leaderboards/ofi`,
  leaderboardsNumFonts: `/${RouteParamPlaceholder.Lang}/leaderboards/nf`,
  leaderboardsNumRequests: `/${RouteParamPlaceholder.Lang}/leaderboards/nr`,
  leaderboardsNumTasks: `/${RouteParamPlaceholder.Lang}/leaderboards/nt`,
  leaderboardsNumScripts: `/${RouteParamPlaceholder.Lang}/leaderboards/nscr`,
  leaderboardsNumStylesheets: `/${RouteParamPlaceholder.Lang}/leaderboards/nss`,
  leaderboardsTotalByteWeight: `/${RouteParamPlaceholder.Lang}/leaderboards/tbw`,
  leaderboardsTotalTaskTime: `/${RouteParamPlaceholder.Lang}/leaderboards/ttt`,

  compoundAnalysis: `/${RouteParamPlaceholder.Lang}/compound-analysis`,
  networkRequestsAnalysis: `/${RouteParamPlaceholder.Lang}/compound-analysis/network-requests`,

  analysis: `/${RouteParamPlaceholder.Lang}/analysis`,
  analysisDirectoryAnalysis: `/${RouteParamPlaceholder.Lang}/analysis/da`,
  projectsOverview: `/${RouteParamPlaceholder.Lang}/projects/overview`,

  adminAccounts: `/${RouteParamPlaceholder.Lang}/admin/accounts`,
  adminAccountDetails: `/${RouteParamPlaceholder.Lang}/admin/accounts/details`,
  adminUsers: `/${RouteParamPlaceholder.Lang}/admin/users`,
};

export const UI_ACTUAL_ROUTES = {
  signIn: `/signin`,
  resetPassword: `/change/:id`,
  signUp: `/signup`,
  dashboard: `/dashboard`,
  metrics: `/metrics`,
  metricsPerformanceScore: `/metrics/ps`,
  metricsLargestContentfulPaint: `/metrics/lcp`,
  metricsFirstInputDelay: `/metrics/fid`,
  metricsTotalBlockingTime: `/metrics/tbt`,
  metricsTimeToInteractive: `/metrics/tti`,
  metricsFirstContentfulPaint: `/metrics/fcp`,
  metricsFirstMeaningfulPaint: `/metrics/fmp`,
  metricsCumulativeLayoutShift: `/metrics/cls`,
  metricsInteractionToNextPaint: `/metrics/inp`,
  metricsExperimentalTimeToFirstByte: `/metrics/ttfb`,
  metricsServerResponseTime: `/metrics/srt`,
  metricsDOMSize: `/metrics/doms`,
  metricsModernImages: `/metrics/mi`,
  metricsUnminifiedJS: `/metrics/unminjs`,
  metricsUnminifiedCSS: `/metrics/unmincss`,
  metricsUnusedJS: `/metrics/unusedjs`,
  metricsUnusedCSS: `/metrics/unusedcss`,
  metricsOptimizedImages: `/metrics/oi`,
  metricsRenderBlockingResources: `/metrics/rbr`,
  metricsNumTasksOver50ms: `/metrics/nto50ms`,
  metricsNumTasksOver100ms: `/metrics/nto100ms`,
  metricsNumTasksOver500ms: `/metrics/nto500ms`,
  metricsSpeedIndex: `/metrics/si`,
  metricsUsesTextCompression: `/metrics/utc`,
  metricsOffscreenImages: `/metrics/ofi`,
  metricsNumFonts: `/metrics/nf`,
  metricsNumRequests: `/metrics/nr`,
  metricsNumTasks: `/metrics/nt`,
  metricsNumScripts: `/metrics/nscr`,
  metricsNumStylesheets: `/metrics/nss`,
  metricsTotalByteWeight: `/metrics/tbw`,
  metricsTotalTaskTime: `/metrics/ttt`,
  leaderboards: `/leaderboards`,
  leaderboardsPerformanceScore: `/leaderboards/ps`,
  leaderboardsFirstContentfulPaint: `/leaderboards/fcp`,
  leaderboardsLargestContentfulPaint: `/leaderboards/lcp`,
  leaderboardsFirstInputDelay: `/leaderboards/fid`,
  leaderboardsTimeToInteractive: `/leaderboards/tti`,
  leaderboardsTotalBlockingTime: `/leaderboards/tbt`,
  leaderboardsCumulativeLayoutShift: `/leaderboards/cls`,
  leaderboardsFirstMeaningfulPaint: `/leaderboards/fmp`,
  leaderboardsServerResponseTime: `/leaderboards/srt`,
  leaderboardsDOMSize: `/leaderboards/doms`,
  leaderboardsModernImages: `/leaderboards/mi`,
  leaderboardsUnminifiedJS: `/leaderboards/unminjs`,
  leaderboardsUnminifiedCSS: `/leaderboards/unmincss`,
  leaderboardsUnusedJS: `/leaderboards/unusedjs`,
  leaderboardsUnusedCSS: `/leaderboards/unusedcss`,
  leaderboardsNumTasksOver50ms: `/leaderboards/nto50ms`,
  leaderboardsNumTasksOver100ms: `/leaderboards/nto100ms`,
  leaderboardsNumTasksOver500ms: `/leaderboards/nto500ms`,
  leaderboardsOptimizedImages: `/leaderboards/oi`,
  leaderboardsRenderBlockingResources: `/leaderboards/rbr`,
  leaderboardsSpeedIndex: `/leaderboards/si`,
  leaderboardsUsesTextCompression: `/leaderboards/utc`,
  leaderboardsOffscreenImages: `/leaderboards/ofi`,
  leaderboardsNumFonts: `/leaderboards/nf`,
  leaderboardsNumRequests: `/leaderboards/nr`,
  leaderboardsNumTasks: `/leaderboards/nt`,
  leaderboardsNumScripts: `/leaderboards/nscr`,
  leaderboardsNumStylesheets: `/leaderboards/nss`,
  leaderboardsTotalByteWeight: `/leaderboards/tbw`,
  leaderboardsTotalTaskTime: `/leaderboards/ttt`,

  compoundAnalysis: `/compound-analysis`,
  networkRequestsAnalysis: `/compound-analysis/network-requests`,

  analysis: `/analysis`,
  analysisDirectoryAnalysis: `/analysis/da`,
  projectsOverview: `/projects/overview`,

  adminAccounts: `/admin/accounts`,
  adminAccountDetails: `/admin/accounts/details`,
  adminUsers: `/admin/users`,
};

export const API_USER_MANAGEMENT_ROUTES = {
  signInWithGoogle: `/auth/google`,
  signInWithPassword: `/auth/password/login`,
  refreshTokens: `/auth/refresh`,
  resetPasswordStart: `/auth/password/change/start`,
  changePassword: `/auth/password/change/finish`,
  getUser: `/users/me`,
  getProfilePicture: `users/my/profile-picture`,
};

export const API_PROJECT_ROUTES = {
  projects: `/projects`,
  latestCrawl: `/projects/${RouteParamPlaceholder.ProjectID}/crawls/latest`,
  crawls: `/projects/${RouteParamPlaceholder.ProjectID}/crawls`,
  startCrawls: `/projects/${RouteParamPlaceholder.ProjectID}/crawls/start`,
  crawlsActive: `/projects/crawls/active`,
  benchmarksSuggestions: `/projects/benchmarksuggestions`,
  keywordsSuggestions: `/projects/keywordsuggestions`,
};

export const API_DATA_ROUTES = {
  features: `/data/features`,
  domainKpi: `/data/${RouteParamPlaceholder.ProjectID}/domain/kpi/${RouteParamPlaceholder.GroupID}`,
  domainHistory: `/data/${RouteParamPlaceholder.ProjectID}/domain/history`,
  featureComparison: `/data/feature/comparison`,
  featureOverview: `/data/${RouteParamPlaceholder.ProjectID}/feature/overview`,
  metricsRankingCorrelation: `/data/${RouteParamPlaceholder.ProjectID}/metrics/rankingcorrelation`,
  metricsUrlGroups: `/data/${RouteParamPlaceholder.ProjectID}/metrics/urlgroups`,
  metricsUrls: `/data/${RouteParamPlaceholder.ProjectID}/metrics/urls`,
  leaderboardData: `/data/${RouteParamPlaceholder.ProjectID}/leaderboard`,
  leaderboardHistory: `/data/${RouteParamPlaceholder.ProjectID}/leaderboard/history`,
  leaderboardRank: `/data/${RouteParamPlaceholder.ProjectID}/leaderboard/rank`,
  leaderboardBenchmarkRank: `/data/${RouteParamPlaceholder.ProjectID}/leaderboard/benchmark/rank`,
  urlDetails: `/data/${RouteParamPlaceholder.ProjectID}/${RouteParamPlaceholder.CrawlID}/${RouteParamPlaceholder.UrlID}`,
  compoundAnalysisUrls: `/data/${RouteParamPlaceholder.ProjectID}/compound/urls/${RouteParamPlaceholder.GroupID}`,
  directoryAnalysis: `/data/${RouteParamPlaceholder.ProjectID}/analysis/directory`,
  compoundAnalysisNetworkDetails: `/data/${RouteParamPlaceholder.ProjectID}/${RouteParamPlaceholder.CrawlID}/compound/urls/${RouteParamPlaceholder.UrlID}/network-requests`,
  compoundAnalysisThumbnails: `/data/${RouteParamPlaceholder.ProjectID}/${RouteParamPlaceholder.CrawlID}/compound/urls/${RouteParamPlaceholder.UrlID}/thumbnails`,
};

export const API_LICENCE_ROUTES = {
  licenses: '/licenses',
  projects: `/licenses/${RouteParamPlaceholder.LicenseID}/projects`,
  company: `/licenses/${RouteParamPlaceholder.LicenseID}/company`,
  user: `/licenses/${RouteParamPlaceholder.LicenseID}/user`,
  crawlsStart: `/licenses/${RouteParamPlaceholder.LicenseID}/projects/${RouteParamPlaceholder.ProjectID}/crawls/start`,
  crawlsActive: `/licenses/${RouteParamPlaceholder.LicenseID}/projects/crawls/active`,
}

export const API_ADMIN_ROUTES = {
  users: '/admin/users',
  licenses: '/admin/licenses',
}
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { SortOrder } from '../../enums/data';
import { UsersDataKeys } from '../../enums/users';
import { IUserSimple } from '../../services/AuthService/types';

export enum UsersActionTypes {
  USERS_RELOAD = '[Users] users-reload',
  USERS_REFRESH = '[Users] users-refresh',

  USERS_SORT_KEY_REFRESH = '[Users] users-sort-key-refresh',
  USERS_SORT_ORDER_REFRESH = '[Users] users-sort-order-refresh',

  USER_DELETE = '[Users] user-delete',

  USERS_SEARCH_REFRESH = '[Users] users-search-refresh',

  UI_MERGE = '[Users] ui-merge',
}

export interface IUIPayload {
  loading?: boolean;
  offset?: number;
  limit?: ROWS_COUNTS;
  count?: number | null;
}

export interface UsersState {
  users: IUserSimple[];
  usersSortOrder: SortOrder,
  usersSortKey: UsersDataKeys,
  usersSearch: string;
  UI: IUIPayload;
}

import { Feature, SortOrder } from '../../enums/data';
import { FeatureOverview } from '../../services/DataService/types';

export enum KpiSummaryActionTypes {
  KPI_SUMMARY_SORT_CHANGE = '[KPI-Summary] sort-change',
  KPI_SUMMARY_SORT_REFRESH = '[KPI-Summary] sort-refresh',

  KPI_SUMMARY_DATA_RESTRICTIONS_MERGE = '[KPI-Summary] data-restrictions-merge',
  KPI_SUMMARY_DATA_UPDATE = '[KPI-Summary] data-update',
  KPI_SUMMARY_DATA_REFRESH = '[KPI-Summary] data-refresh',

  RESET_ALL = '[KPI-Summary] reset-all',
}

export interface IKpiSummarySortChangePayload {
  feature: Feature;
  order?: SortOrder;
}

export interface IKpiSummarySortRefreshPayload extends Required<IKpiSummarySortChangePayload> {}

export interface IKpiSummaryDataRestrictionsPayload {
  sortBy?: Feature | string;
  order?: SortOrder;
  count?: number;
}

export type KpiSummaryDataPayload = FeatureOverview[];

export interface KpiSummaryState {
  sort: IKpiSummarySortRefreshPayload;
  summaryDataRestrictions: Required<IKpiSummaryDataRestrictionsPayload>;
  summaryData: KpiSummaryDataPayload;
}

import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IUrlGroup } from '../../../../services/ProjectService/types';
import { ProjectMenuKeys } from '../../../../enums/locales/topBar';

import { projectsActions } from '../../../../redux/projects/actions';
import { selectActiveProjectTestMode, selectCrawlsInProgress } from '../../../../redux/projects/selectors';

import { IProps as IProjectListProps, IProjectListOption } from './ProjectList/ProjectList';
import { ProjectList } from './ProjectList';
import { DomainPictureHolder } from '../../../../components/DomainPictureHolder';
import { IconArrowRight, IconLab } from '../../../../components/styled/icons';
import { Button, ButtonType } from '../../../../components/styled/Button';
import {
  StyledWrapper as Wrapper,
  StyledIconDropdown as IconDropdown,
  StyledIconDropdownActive as IconDropdownActive,
} from './ProjectMenu.styled';
import { StackedIndicator } from '../../../../components/StackedIndicator';
import { THEME } from '../../../../constants/theme';

export interface IProps extends IProjectListProps {
  selectedProjectGroupID?: string | null;
  onClickAllProjects?: () => void;
}

const ProjectMenu: React.FC<IProps> = ({
  selectedProjectID,
  selectedProjectGroupID,
  projectOptions,
  onSelectProject,
  onClickAllProjects,
  ...restProps
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const isTest = useSelector(selectActiveProjectTestMode);
  const crawlsInProgress = useSelector(selectCrawlsInProgress);
  const crawlProgress = useMemo(() => crawlsInProgress[selectedProjectID || ''], [crawlsInProgress, selectedProjectID]);

  const selectedProjectOptionData: IProjectListOption | undefined = useMemo(() => {
    return projectOptions.find(({ projectID }) => projectID === selectedProjectID);
  }, [projectOptions, selectedProjectID]);

  const selectedProjectOptionGroupData: IUrlGroup | undefined = useMemo(() => {
    return selectedProjectOptionData?.urlGroups.find(({ url_group_id }) => url_group_id === selectedProjectGroupID);
  }, [selectedProjectOptionData, selectedProjectGroupID]);

  const className = clsx({ open });

  const handleClickOpen = useCallback(() => {
    setOpen(prevState => !prevState);
  }, [setOpen]);

  const handleClickOutsideProjectsMenu = useCallback((e) => {
    if (ref?.current && !ref?.current?.contains(e.target)) {
      setOpen(false);
    }
  }, [ref, setOpen]);

  const handleSelectProject = useCallback((projectID: string, groupID?: string) => {
    onSelectProject && onSelectProject(projectID, groupID);
    setOpen(false);
  }, [onSelectProject, setOpen]);

  const handleClickAllProjects = useCallback(() => {
    onClickAllProjects && onClickAllProjects();
    setOpen(false);
  }, [onClickAllProjects, setOpen]);

  useEffect(() => {
    if (open) {
      dispatch(projectsActions.uiMerge({ projectsMenuOpen: true }));
      dispatch(projectsActions.activeCrawlsCheckStart());
    } else {
      dispatch(projectsActions.uiMerge({ projectsMenuOpen: false }));
      dispatch(projectsActions.activeCrawlsCheckStop());
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (!open) {
      if (crawlProgress !== undefined) {
        dispatch(projectsActions.activeCrawlsCheckStart());
      } else {
        dispatch(projectsActions.activeCrawlsCheckStop());
      }
    }
  }, [dispatch, crawlProgress, open]);

  useEffect(() => {
    dispatch(projectsActions.activeCrawlsInit());
  }, [dispatch, selectedProjectID]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideProjectsMenu, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideProjectsMenu, true);
    };
  }, []);

  const dropIcon = open ? <IconDropdownActive /> : <IconDropdown />;

  const progressShow = !open && crawlProgress !== undefined && crawlProgress !== null;

  return (
    <Wrapper className={className}>
      <div ref={ref} className="project-container">
        <div
          className="drop-icon"
          onClick={handleClickOpen}
        >
          {dropIcon}
        </div>
        <div
          className="project-title"
          onClick={handleClickOpen}
        >
          <div className="domain-picture">
            {selectedProjectOptionData?.domainName && (
              <DomainPictureHolder domainName={selectedProjectOptionData?.domainName} />
            )}
          </div>
          <div className={clsx('domain-name', { group: selectedProjectOptionGroupData })}>
            {selectedProjectOptionData?.projectName || selectedProjectOptionData?.domainName}
            {selectedProjectOptionGroupData && (
              <span className="domain-group-name">
                {selectedProjectOptionGroupData.name}
              </span>
            )}
          </div>
          {isTest && (
            <div className="lab-icon">
              <IconLab />
            </div>
          )}
          {progressShow && <div className={clsx('project-item-crawl-in-progress-indicator')}>
            <StackedIndicator
              hideCounter
              color={THEME.colors.primaryBlue}
              value={crawlProgress as number}
              maxValue={100}
            />
          </div>}
        </div>
        <div
          className="project-list-title"
          onClick={handleClickOpen}
        >
          {t(ProjectMenuKeys.openTitle)}:
        </div>
        <div className="project-list">
          <ProjectList
            selectedProjectGroupID={selectedProjectGroupID}
            selectedProjectID={selectedProjectID}
            projectOptions={projectOptions}
            onSelectProject={handleSelectProject}
            {...restProps}
          />
        </div>
        <div className="project-all-button">
          <Button
            small
            type={ButtonType.Primary}
            title={t(ProjectMenuKeys.allProjectsButtonTitle)}
            suffix={<IconArrowRight />}
            onClick={handleClickAllProjects}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ProjectMenu;

import { FieldTypes } from "../../../enums/forms";
import { FieldLabelKeys } from "../../../enums/locales/forms";
import { ProjectSettingsKeys, SiderStepsSuffixes } from "../../../enums/locales/modals";
import { FIELD_NAMES } from "../../../enums/profileSettings";

export const companyFields = [
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Name,
    labelKey: FieldLabelKeys.CompanyName,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Address,
    labelKey: FieldLabelKeys.Address,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Zip,
    labelKey: FieldLabelKeys.Zip,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.City,
    labelKey: FieldLabelKeys.City,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Country,
    labelKey: FieldLabelKeys.CompanyCountry,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Vat,
    labelKey: FieldLabelKeys.Vat,
    subLabel: SiderStepsSuffixes.Optional,
  },
];


export const companySettingsSteps = [
  {
    id: 'step-0',
    className: 'company-info',
    title: ProjectSettingsKeys.CompanyDetails,
    suffix: '',
    fields: companyFields,
  },
]
export enum AccountsKeys {
  LicenseIDLabel = 'accounts_license_id_label',
  CrawlIDLabel = 'accounts_crawl_id_label',
  StatusLabel = 'accounts_status_label',
  UserIDLabel = 'accounts_user_id_label',
  UtilizationLabel = 'accounts_utilization_label',
  CreatedAtLabel = 'accounts_created_at_label',
  CompanyLabel = 'accounts_company_label',
  DeleteAccountMessage = 'accounts_delete_account_message',
  DeleteUserMessage = 'accounts_delete_user_message',
  DeleteProjectMessage = 'accounts_delete_project_message',
  DeleteCrawlMessage = 'accounts_delete_crawl_message',
  SearchAccounts = 'accounts_search_accounts_message',
  SearchUsers = 'accounts_search_users_message',
  SearchProjects = 'accounts_search_projects_message',
  SearchCrawls = 'accounts_search_crawls_message',
  ProjectsLabel = 'accounts_projects_label',
  CrawlsLabel = 'accounts_crawls_label',
  AccountLabel = 'accounts_account_label',
  ProjectMoveLabel = 'accounts_project_move_label',
  RemoveUserMessage = 'accounts_remove_user_message',
  LimitLabel = 'accounts_limit_label',
  CrawlTotalLabel = 'accounts_crawl_total_label',
  CrawlDoneLabel = 'accounts_crawl_done_label',
  CrawlOpenLabel = 'accounts_crawl_open_label',
  CrawlFailedLabel = 'accounts_crawl_failed_label',
}

export enum AccountDetailsKeys {
  SwitchToAccountButton = 'account_details_switch_to_account_button_title',
  DeleteAccountButton = 'account_details_delete_account_button_title',
  DeleteProjectButton = 'account_details_delete_project_button_title',
  EditAccountButton = 'account_details_edit_account_button_title',
  MoveProjectButton = 'account_details_move_project_button_title',
  MoveProjectCancelButton = 'account_details_move_project_cancel_button_title',
}

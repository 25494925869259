import { takeLatest, all, put, call, select } from 'redux-saga/effects';

import { isCrawlID } from '../../utils/common';
import { DataService } from '../../services/DataService';

import { Action } from '../commonTypes';
import { MetricsActionTypes } from './types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import {
  IMetricsRankingCorrelationRequestBody,
  IMetricsRankingCorrelationResponseBody,
  IMetricsUrlGroupsRequestBody,
  IMetricsUrlGroupsResponseBody,
  IMetricsUrlsRequestBody,
  IMetricsUrlsResponseBody,
  IUrlDetailsRequestBody,
  IUrlDetailsResponseBody,
} from '../../services/DataService/types';
import { IProject } from '../../services/ProjectService/types';

import { metricsActions } from './actions';
import {
  selectFeature,
  selectUI,
  selectUrlGroupsQuantifier,
  selectUrlsQuantifier,
  selectUrlsSortOrder,
  selectUrlsSortKey,
  selectUrlSearch,
} from './selectors';
import { selectActiveProjectID, selectActiveProjectGroupID, selectActiveProjectDomain, selectProjectByID, selectLatestCrawlID } from '../projects/selectors';

function* rankingCorrelationReload(action: Action<MetricsActionTypes.RANKING_CORRELATION_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const feature = yield select(selectFeature);

  if (!projectID || !feature) {
    return;
  }

  yield put(metricsActions.uiMerge({ loading: true }));

  const requestBody: IMetricsRankingCorrelationRequestBody = {
    feature,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IMetricsRankingCorrelationResponseBody> = yield call(DataService.metricsRankingCorrelation, projectID, requestBody, options);
  if (!result?.data) {
    yield put(metricsActions.uiMerge({ loading: false }));
    yield put(metricsActions.rankingCorrelationRefresh([]));
    return;
  }

  const response: IMetricsRankingCorrelationResponseBody = result?.data;

  const { data } = response;

  yield put(metricsActions.rankingCorrelationRefresh(data));
  yield put(metricsActions.uiMerge({ loading: false }));
}

function* urlGroupsReload(action: Action<MetricsActionTypes.URL_GROUPS_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const feature = yield select(selectFeature);
  const quantifier = yield select(selectUrlGroupsQuantifier);

  if (!projectID || !feature) {
    return;
  }

  yield put(metricsActions.uiMerge({ urlGroupsLoading: true }));

  const requestBody: IMetricsUrlGroupsRequestBody = {
    feature,
    quantifier,
    crawl_id: isCrawlID(quantifier) ? quantifier : null,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IMetricsUrlGroupsResponseBody> = yield call(DataService.metricsUrlGroups, projectID, requestBody, options);
  if (!result?.data) {
    yield put(metricsActions.uiMerge({ urlGroupsLoading: false }));
    yield put(metricsActions.urlGroupsRefresh([]));
    return;
  }

  const response: IMetricsUrlGroupsResponseBody = result?.data;

  const { data } = response;

  yield put(metricsActions.urlGroupsRefresh(data));
  yield put(metricsActions.uiMerge({ urlGroupsLoading: false }));
}

function* urlsReload(action: Action<MetricsActionTypes.URLS_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const groupID = yield select(selectActiveProjectGroupID);
  const activeProjectDomain = yield select(selectActiveProjectDomain);
  const feature = yield select(selectFeature);
  const quantifier = yield select(selectUrlsQuantifier);
  const sortKey = yield select(selectUrlsSortKey);
  const sortOrder = yield select(selectUrlsSortOrder);
  const urlSearch = yield select(selectUrlSearch);
  const { offset, limit } = yield select(selectUI);

  const projectByID: IProject = yield select(selectProjectByID(quantifier));

  if (!projectID || !feature) {
    return;
  }

  yield put(metricsActions.uiMerge({ urlsLoading: true }));

  const requestBody: IMetricsUrlsRequestBody = {
    feature,
    quantifier: projectByID ? projectByID.domain : isCrawlID(quantifier) ? groupID || activeProjectDomain : quantifier,
    crawl_id: isCrawlID(quantifier) ? quantifier : null,
    limit,
    offset,
    url_filter: urlSearch,
    sort: sortKey,
    sort_direction: sortOrder,
  };

  if (groupID) {
    requestBody.comparator = groupID;
  }

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IMetricsUrlsResponseBody> = yield call(DataService.metricsUrls, projectID, requestBody, options);
  if (!result?.data) {
    yield put(metricsActions.uiMerge({ urlsLoading: false }));
    yield put(metricsActions.urlsRefresh([]));
    return;
  }

  const response: IMetricsUrlsResponseBody = result?.data;

  const { data, count } = response;

  const countToSet = count === 0 ? null : count;

  yield put(metricsActions.urlsRefresh(data));
  yield put(metricsActions.uiMerge({ count: countToSet, urlsLoading: false }));
}

function* urlsFullReload(action: Action<MetricsActionTypes.URLS_FULL_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const groupID = yield select(selectActiveProjectGroupID);
  const activeProjectDomain = yield select(selectActiveProjectDomain);
  const feature = yield select(selectFeature);
  const quantifier = yield select(selectUrlsQuantifier);
  const sortKey = yield select(selectUrlsSortKey);
  const sortOrder = yield select(selectUrlsSortOrder);
  const urlSearch = yield select(selectUrlSearch);
  const { count: total } = yield select(selectUI);

  const projectByID: IProject = yield select(selectProjectByID(quantifier));

  if (!projectID || !feature) {
    return;
  }

  yield put(metricsActions.uiMerge({ urlsFullLoading: true }));

  const requestBody: IMetricsUrlsRequestBody = {
    feature,
    quantifier: projectByID ? projectByID.domain : isCrawlID(quantifier) ? groupID || activeProjectDomain : quantifier,
    crawl_id: isCrawlID(quantifier) ? quantifier : null,
    limit: total || 0,
    offset: 0,
    url_filter: urlSearch,
    sort: sortKey,
    ['sort_direction']: sortOrder,
  };

  if (groupID) {
    requestBody.comparator = groupID;
  }

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IMetricsUrlsResponseBody> = yield call(DataService.metricsUrls, projectID, requestBody, options);
  if (!result?.data) {
    yield put(metricsActions.uiMerge({ urlsFullLoading: false }));
    yield put(metricsActions.urlsFullRefresh([]));
    return;
  }

  const response: IMetricsUrlsResponseBody = result?.data;

  const { data, count } = response;

  const countToSet = count === 0 ? null : count;

  yield put(metricsActions.urlsFullRefresh(data));
  yield put(metricsActions.uiMerge({ count: countToSet, urlsFullLoading: false }));
}

function* urlDetailsReload(action: Action<MetricsActionTypes.URL_DETAILS_RELOAD, string>): any {
  const urlID = action.payload;
  const projectID = yield select(selectActiveProjectID);
  const crawlID = yield select(selectLatestCrawlID);
  const feature = yield select(selectFeature);

  if (!projectID || !crawlID || !feature || !urlID) {
    return;
  }

  yield put(metricsActions.uiMerge({ urlDetailsLoading: true }));

  const requestBody: IUrlDetailsRequestBody = {
    feature,
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IUrlDetailsResponseBody> = yield call(DataService.urlDetails, projectID, crawlID, urlID, requestBody, options);
  if (!result?.data) {
    yield put(metricsActions.uiMerge({ urlDetailsLoading: false }));
    yield put(metricsActions.urlDetailsRefresh([]));
    return;
  }

  const response: IUrlDetailsResponseBody = result?.data;

  const { data } = response;

  yield put(metricsActions.urlDetailsRefresh(data));
  yield put(metricsActions.uiMerge({ urlDetailsLoading: false }));
}

export default function* metricsSaga() {
  yield all([
    takeLatest(MetricsActionTypes.RANKING_CORRELATION_RELOAD, rankingCorrelationReload),
    takeLatest(MetricsActionTypes.URL_GROUPS_RELOAD, urlGroupsReload),
    takeLatest(MetricsActionTypes.URLS_RELOAD, urlsReload),
    takeLatest(MetricsActionTypes.URLS_FULL_RELOAD, urlsFullReload),
    takeLatest(MetricsActionTypes.URL_DETAILS_RELOAD, urlDetailsReload),
  ]);
}

import styled from "styled-components";
import { IProps } from "./SimpleList";

export const Wrapper = styled.div<Pick<IProps, 'error'> & { showSuggestions: boolean }>`
  display: flex;
  width: 100%;

  .main-list {
    display: flex;
    flex-direction: column;
    width: ${({ showSuggestions }) => showSuggestions ? 60 : 100}%;
    padding: 20px;
    border: 2px solid ${({ theme }) => theme.colors.grey};
    border-radius: 18px;

    .icon {
      max-width: none;
    }

    .icon-container {
      width: auto;
      height: 32px;
      top: 7px;
      right: 7px;
    }

    .add-input {
      height: 46px;
      border-radius: 12px;
      padding-right: 160px;
      line-height: 18px;
      font-size: 12px;
      padding-left: 15px;
    }

    button {
      text-transform: uppercase;
      height: 32px;
      padding: 0 30px;
      border-radius: 8px;

      .title {
        font-size: 13px!important;
      }
    }

    .list-container {
      padding-left: 17px;
      margin-top: 28px;

      .list-header {
        font-size: 14px;
        font-weight: bold;
      }

      .list-items {
        padding-top: 30px;

        .item {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 20px;

          svg {
            cursor: pointer;
            padding-bottom: 2px;
            margin-right: 18px;
          }
        }
      }
    }
  }

  .suggestions {
    display: flex;
    width: 40%;
    padding: 30px;
    border: 2px solid ${({ theme }) => theme.colors.grey};
    border-radius: 18px;
    margin-left: 16px;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    .main-list {
      width: 100%;
      margin-bottom: 20px;
    }
    .suggestions {
      width: 100%;
      margin-left: 0px;
    }
  }
`;
import styled from "styled-components";
import LibSelect from "antd/lib/select";
import { THEME } from "../../../constants/theme";
import { IProps } from "./Select";

const { colors } = THEME;

export const StyledSelect = styled(LibSelect) <IProps>`
  background: #fff;
  border-radius: 10px!important;
  width: ${({ width }) => width ? `${width}px` : '100%'}!important;
  max-width: ${({ theme }) => theme.maxWidth.inputs};
  height: ${({ height }) => height ? height : 40}px!important;

  .ant-select-selector {
    border: 2px solid ${({ error }) => error ? colors.inputs.border.error : colors.grey}!important;
    background: #fff;
    border-radius: 10px!important;
    height: ${({ height }) => height ? height : 40}px!important;
    width: ${({ width }) => width ? `${width}px` : '100%'}!important;
    padding: 0 0.6rem!important;
  }

  .ant-select-selection-item {
    line-height: 36px!important;
  }

  .ant-select-arrow {
    width: 18px;
    height: 18px;
    margin-top: -9px;
  }
`;
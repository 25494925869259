import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import omit from "lodash/omit";

import { Action } from '../commonTypes';
import { IChangePassword, ProfileSettingsActionTypes } from './types';
import { IProfileRequestBody, IRefreshTokensOptions, IResetPasswordStartBody, IUploadRequest, IUserSimple } from '../../services/AuthService/types';
import { modalsActions } from '../modals/actions';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { AuthService } from '../../services/AuthService';
import { profileSettingsActions } from './actions';
import { selectUser } from '../auth/selectors';
import { selectCompany, selectLicenseByID, selectUsers, selectPersonal, selectUI } from './selectors';
import { authActions } from '../auth/actions';
import { AppContext } from '../../enums/auth';
import { LoaderModalMessageKeys, ProjectSettingsKeys } from '../../enums/locales/modals';
import { ExternalService } from '../../services/ExternalService';
import { LicensesService } from '../../services/LicensesService';
import { selectMainLicense } from '../licenses/selectors';
import { ILicense, ILicenseCompany, ILicenseResponseBody, ILicenseUser, ILicenseUserRequestBody } from '../../services/LicensesService/types';
import { licensesActions } from '../licenses/actions';
import { selectAccountByID } from '../accounts/selectors';
import { AdminService } from '../../services/AdminService';
import { accountsActions } from '../accounts/actions';
import { selectUserByID } from '../users/selectors';
import { ISingleUserResponseBody } from '../../services/AdminService/types';
import { usersActions } from '../users/actions';
import { selectAccountLicense } from '../account-details/selectors';
import { selectUserEmail } from '../auth/selectors';

function* init(): any {
  const user = yield select(selectUser);
  const license = yield select(selectMainLicense);

  if (!user || !license?.company) {
    return;
  }

  const { primary_email, profile: { display_name } } = user;
  const { company, user: licenseUsers } = license;

  yield put(profileSettingsActions.usersRefresh(licenseUsers.filter(({ email }: ILicenseUser) => primary_email !== email).map(({ email }: ILicenseUser) => email)));
  yield put(profileSettingsActions.companyRefresh(company));
  yield put(profileSettingsActions.personalRefresh({ email: primary_email, first_name: display_name?.split(' ')[0] || '', last_name: display_name?.split(' ')[1] || '', }));
  yield put(modalsActions.profileSettingsShow());
}

function* adminInit(action: Action<ProfileSettingsActionTypes.ADMIN_INIT, string>): any {
  const licenseID = action.payload;
  const account = yield select(selectAccountByID(licenseID));
  const license = yield select(selectAccountLicense);

  const isAccountAvailable = !!account?.company && !!account?.user;
  const isLicenseAvailable = !!license?.company && !!license?.user;

  if (!isLicenseAvailable && !isAccountAvailable) {
    return;
  }

  const { company, user: licenseUsers, creator_name, creator_email } = isAccountAvailable ? account : license;

  const users = licenseUsers.filter(({ email }: ILicenseUser) => creator_email !== email).map(({ email }: ILicenseUser) => email) || [];

  yield put(profileSettingsActions.uiMerge({ editingLicenseID: licenseID }));
  yield put(profileSettingsActions.usersRefresh(users));
  yield put(profileSettingsActions.companyRefresh(company));
  yield put(profileSettingsActions.personalRefresh({ email: creator_email, first_name: creator_name?.split(' ')[0] || '', last_name: creator_name?.split(' ')[1] || '', }));
  yield put(modalsActions.accountSettingsShow());
}

function* adminUserInit(action: Action<ProfileSettingsActionTypes.ADMIN_USER_INIT, string>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };
  const userID = action.payload;
  const user = yield select(selectUserByID(userID));

  if (!user) {
    return;
  }


  const { primary_email, not_verified_email, profile: { display_name } } = user;

  yield put(profileSettingsActions.uiMerge({ editingUserID: userID }));
  yield put(profileSettingsActions.personalRefresh({ email: primary_email || not_verified_email, first_name: display_name?.split(' ')[0] || '', last_name: display_name?.split(' ')[1] || '', }));
  yield put(modalsActions.userSettingsShow());

  yield put(profileSettingsActions.uiMerge({ licensesLoading: true }));

  const result: IBaseResponse<ISingleUserResponseBody> = yield call(AdminService.getUser, userID, options);
  if (!result?.data) {
    yield put(profileSettingsActions.uiMerge({ licensesLoading: false }));
    return;
  }

  const licenses = result.data.licenses;
  yield put(profileSettingsActions.licensesRefresh(licenses));
  yield put(profileSettingsActions.uiMerge({ licensesLoading: false }));
}

function* personalSave(action: Action<ProfileSettingsActionTypes.PERSONAL_SAVE, never>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };
  const { first_name, last_name } = yield select(selectPersonal);

  if (!first_name) {
    return;
  }

  yield put(profileSettingsActions.uiMerge({ personalLoading: true }));

  const requestBody: IProfileRequestBody = {
    profile: {
      display_name: `${first_name} ${last_name}`,
    }
  };

  const result: IBaseResponse<IProfileRequestBody> = yield call(AuthService.updateUser, requestBody, options);
  if (!result?.data) {
    yield put(profileSettingsActions.uiMerge({ personalLoading: false }));
    return;
  }

  yield put(authActions.userReload());
  yield put(profileSettingsActions.uiMerge({ personalLoading: false }));
}

function* adminPersonalSave(action: Action<ProfileSettingsActionTypes.ADMIN_PERSONAL_SAVE, never>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };
  const { first_name, last_name } = yield select(selectPersonal);
  const { editingLicenseID } = yield select(selectUI);
  const account = yield select(selectAccountByID(editingLicenseID));

  if (!first_name) {
    return;
  }

  yield put(profileSettingsActions.uiMerge({ personalLoading: true }));

  const requestBody: IProfileRequestBody = {
    profile: {
      display_name: `${first_name} ${last_name}`,
    }
  };

  const result: IBaseResponse<IProfileRequestBody> = yield call(AdminService.updateUser, requestBody, account.creator, options);

  if (!result?.data) {
    yield put(profileSettingsActions.uiMerge({ personalLoading: false }));
    return;
  }

  yield put(accountsActions.accountsReload());
  yield put(profileSettingsActions.uiMerge({ personalLoading: false }));
}

function* adminUserPersonalSave(action: Action<ProfileSettingsActionTypes.ADMIN_USER_PERSONAL_SAVE, never>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };
  const { first_name, last_name } = yield select(selectPersonal);
  const { editingUserID } = yield select(selectUI);

  if (!first_name) {
    return;
  }

  yield put(profileSettingsActions.uiMerge({ personalLoading: true }));

  const requestBody: IProfileRequestBody = {
    profile: {
      display_name: `${first_name} ${last_name}`,
    }
  };

  const result: IBaseResponse<IProfileRequestBody> = yield call(AdminService.updateUser, requestBody, editingUserID, options);

  if (!result?.data) {
    yield put(profileSettingsActions.uiMerge({ personalLoading: false }));
    return;
  }

  yield put(usersActions.usersReload());
  yield put(profileSettingsActions.uiMerge({ personalLoading: false }));
}

function* companySave(action: Action<ProfileSettingsActionTypes.COMPANY_SAVE, never>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };
  const company = yield select(selectCompany);
  const mainLicense = yield select(selectMainLicense);
  const licenseID = mainLicense?.license_id || '';

  yield put(profileSettingsActions.uiMerge({ companyLoading: true }));

  const requestBody: ILicenseCompany = company;

  const result: IBaseResponse<ILicense> = yield call(LicensesService.updateCompany, licenseID, requestBody, options);
  if (!result?.data) {
    yield put(profileSettingsActions.uiMerge({ companyLoading: false }));
    return;
  }

  yield put(licensesActions.licensesReload());
  yield put(profileSettingsActions.uiMerge({ companyLoading: false }));
}

function* adminCompanySave(action: Action<ProfileSettingsActionTypes.ADMIN_COMPANY_SAVE, never>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };
  const company = yield select(selectCompany);
  const { editingLicenseID } = yield select(selectUI);

  yield put(profileSettingsActions.uiMerge({ companyLoading: true }));

  const requestBody: ILicenseCompany = company;

  const result: IBaseResponse<ILicenseResponseBody> = yield call(AdminService.updateLicenseCompany, requestBody, editingLicenseID, options);

  if (!result?.data) {
    yield put(profileSettingsActions.uiMerge({ companyLoading: false }));
    return;
  }

  yield put(accountsActions.accountsReload());
  yield put(profileSettingsActions.uiMerge({ companyLoading: false }));
}

function* usersUpdate(action: Action<ProfileSettingsActionTypes.USERS_UPDATE, string[]>): any {
  const newUsersList = action.payload;
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const mainLicense = yield select(selectMainLicense);
  const licenseID = mainLicense?.license_id || '';
  const user = yield select(selectUser);

  if (!user) {
    return;
  }

  yield put(profileSettingsActions.usersRefresh(newUsersList));

  const requestBody: ILicenseUserRequestBody = {
    user_list: [...newUsersList.map((email) => ({ email, read_only: false } as ILicenseUser)), { email: user.primary_email, read_only: false } as ILicenseUser]
  };

  const result: IBaseResponse<ILicense> = yield call(LicensesService.updateUsers, licenseID, requestBody, options);
  if (!result?.data) {
    return;
  }

  yield put(licensesActions.licensesReload());
}

function* adminUsersUpdate(action: Action<ProfileSettingsActionTypes.USERS_UPDATE, string[]>): any {
  const newUsersList = action.payload;
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const { editingLicenseID } = yield select(selectUI);
  const { email } = yield select(selectPersonal);
  const oldUserList = yield select(selectUsers);
  const activeAccountEmail = yield select(selectUserEmail);

  yield put(profileSettingsActions.usersRefresh(newUsersList));

  const requestBody: ILicenseUserRequestBody = {
    user_list: [...newUsersList.map((email) => ({ email, read_only: false } as ILicenseUser)), { email: email, read_only: false } as ILicenseUser]
  };

  const result: IBaseResponse<ILicense> = yield call(AdminService.updateLicenseUser, requestBody, editingLicenseID, options);
  if (!result?.data) {
    return;
  }

  const isActiveAccountEmailAdded = newUsersList.includes(activeAccountEmail as string);
  const isActiveAccountEmailRemoved = !newUsersList.includes(activeAccountEmail as string) && oldUserList.includes(activeAccountEmail as string);

  if (isActiveAccountEmailAdded || isActiveAccountEmailRemoved) {
    yield put(licensesActions.licensesReload());
  }

  yield put(accountsActions.accountsReload());
}

function* adminLicensesUpdate(action: Action<ProfileSettingsActionTypes.LICENSES_UPDATE, string>): any {
  const licenseIDToRemoveFrom = action.payload;
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  yield put(profileSettingsActions.uiMerge({ licensesLoading: true }));

  const { editingUserID } = yield select(selectUI);
  const { user: usersList } = yield select(selectLicenseByID(licenseIDToRemoveFrom));
  const { email: userEmail } = yield select(selectPersonal);

  const newUsers = usersList.filter(({ email }: ILicenseUser) => email !== userEmail).map(({ email, read_only }: ILicenseUser) => ({ email, read_only }));

  const requestBody: ILicenseUserRequestBody = {
    user_list: newUsers,
  };

  const result: IBaseResponse<ILicense> = yield call(AdminService.updateLicenseUser, requestBody, licenseIDToRemoveFrom, options);
  if (!result?.data) {
    return;
  }

  const licensesResult: IBaseResponse<ISingleUserResponseBody> = yield call(AdminService.getUser, editingUserID, options);
  if (!licensesResult?.data) {
    yield put(profileSettingsActions.uiMerge({ licensesLoading: false }));
    return;
  }

  const licenses = licensesResult.data.licenses;
  yield put(profileSettingsActions.licensesRefresh(licenses));
  yield put(profileSettingsActions.uiMerge({ licensesLoading: false }));
}

function* resetPasswordStart(action: Action<ProfileSettingsActionTypes.RESET_PASSWORD_START, never>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };
  const { email } = yield select(selectPersonal);

  if (!email) {
    return;
  }

  yield put(profileSettingsActions.uiMerge({ passwordReseting: true }));

  const requestBody: IResetPasswordStartBody = {
    email,
    app: AppContext.Cwv,
  };

  const result: IBaseResponse<string> = yield call(AuthService.resetPasswordStart, requestBody, options);
  if (result?.data !== '') {
    yield put(profileSettingsActions.uiMerge({ passwordReseted: false, passwordReseting: false }));
    return;
  }

  yield put(profileSettingsActions.uiMerge({ passwordReseted: true, passwordReseting: false }));
}

function* changePassword(action: Action<ProfileSettingsActionTypes.CHANGE_PASSWORD, IChangePassword>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  yield put(profileSettingsActions.uiMerge({ changeMessage: LoaderModalMessageKeys.Default }));

  const result: IBaseResponse<string> = yield call(AuthService.changePassword, action.payload, options);
  if (result?.data !== '') {
    yield put(profileSettingsActions.uiMerge({ changeMessage: ProjectSettingsKeys.ChangeMessageError }));
    return;
  }

  yield put(profileSettingsActions.uiMerge({ changeMessage: ProjectSettingsKeys.ChangeMessageSuccess }));
}

function* avatarUpload(action: Action<ProfileSettingsActionTypes.AVATAR_UPLOAD, File>): any {
  yield put(profileSettingsActions.uiMerge({ loading: true }));

  const uploadResult: IBaseResponse<IUploadRequest> = yield call(AuthService.getProfilePicture);
  if (!uploadResult?.data) {
    yield put(profileSettingsActions.uiMerge({ loading: false }));
    return;
  }

  const response: IUploadRequest = uploadResult?.data;
  const { upload_request: { url } } = response;

  const result: IBaseResponse<string> = yield call(ExternalService.imageUpload, url, action.payload);
  if (result?.data !== '') {
    yield put(profileSettingsActions.uiMerge({ loading: false }));
    return;
  }

  const newUser = yield select(selectUser);

  yield put(authActions.userRefresh({ ...newUser, profile: { ...omit(newUser.profile, 'profile_picture') } }));
  yield put(authActions.userReload());
  yield put(profileSettingsActions.uiMerge({ loading: false }));
}


export default function* profileSettingsSaga() {
  yield all([
    takeLatest(ProfileSettingsActionTypes.INIT, init),
    takeLatest(ProfileSettingsActionTypes.ADMIN_INIT, adminInit),
    takeLatest(ProfileSettingsActionTypes.ADMIN_USER_INIT, adminUserInit),
    takeLatest(ProfileSettingsActionTypes.AVATAR_UPLOAD, avatarUpload),
    takeLatest(ProfileSettingsActionTypes.PERSONAL_SAVE, personalSave),
    takeLatest(ProfileSettingsActionTypes.COMPANY_SAVE, companySave),
    takeLatest(ProfileSettingsActionTypes.ADMIN_PERSONAL_SAVE, adminPersonalSave),
    takeLatest(ProfileSettingsActionTypes.ADMIN_USER_PERSONAL_SAVE, adminUserPersonalSave),
    takeLatest(ProfileSettingsActionTypes.ADMIN_COMPANY_SAVE, adminCompanySave),
    takeLatest(ProfileSettingsActionTypes.USERS_UPDATE, usersUpdate),
    takeLatest(ProfileSettingsActionTypes.ADMIN_USERS_UPDATE, adminUsersUpdate),
    takeLatest(ProfileSettingsActionTypes.LICENSES_UPDATE, adminLicensesUpdate),
    takeLatest(ProfileSettingsActionTypes.RESET_PASSWORD_START, resetPasswordStart),
    takeLatest(ProfileSettingsActionTypes.CHANGE_PASSWORD, changePassword),
  ]);
}

import React, { useCallback, useMemo } from 'react';

import { Select } from "../styled/Select";
import { IPagination } from '../../types/common.types';
import { paginationLimitOptions } from '../../constants/pagination';

import { Wrapper } from './Pagination.styled';
import { useTranslation } from 'react-i18next';
import { PaginationKeys } from '../../enums/locales/pagination';
import clsx from 'clsx';

const Pagination: React.FC<IPagination> = ({
  offset,
  total,
  limit,
  limitOptions = paginationLimitOptions,
  className,
  onLimitChange,
  onOffsetChange,
}) => {
  const { t } = useTranslation();
  const currentPage = useMemo(() => (offset / limit) + 1, [limit, offset]);
  const lastPage = useMemo(() => Math.ceil(total / limit), [total, limit]);

  const previousPages = useMemo(() => Array.from({ length: 3 }, (_, index) => currentPage - index).reverse(), [currentPage]);
  const nextPages = useMemo(() => Array.from({ length: 2 }, (_, index) => currentPage + (index + 1)), [currentPage]);

  const pagesToRender = useMemo(() => {
    const filteredNextPages = nextPages.filter((item) => item <= lastPage);
    if (currentPage > 2) {
      return [...previousPages, ...filteredNextPages];
    }
    const filteredPreviousPages = previousPages.filter((item) => item > 0);
    return [...filteredPreviousPages, ...filteredNextPages];
  }, [previousPages, nextPages, currentPage, lastPage]);

  const onLimit = useCallback((newLimit: number) => {
    onLimitChange(newLimit);
    onOffsetChange(0);
  }, [onLimitChange, onOffsetChange]);

  const onOffsetClick = useCallback((page: number) => () => {
    onOffsetChange((page - 1) * limit);
  }, [onOffsetChange, limit]);

  return (
    <Wrapper className={className}>
      <div className="show-rows-select">
        <Select
          value={limit}
          onChange={onLimit}
          options={limitOptions}
        />
      </div>
      <div className="pages-switcher">
        <div
          className={clsx('first-page-block', { disabled: currentPage === 1 })}
          onClick={onOffsetClick(1)}
        >
          {t(PaginationKeys.First)}
        </div>
        {pagesToRender.map((page) => (
          <div
            key={page}
            onClick={onOffsetClick(page)}
            className={clsx("page-block", {
              current: currentPage === page
            })}
          >
            {page}
          </div>
        ))}
        <div
          className={clsx('last-page-block', { disabled: currentPage === lastPage })}
          onClick={onOffsetClick(lastPage)}
        >
          {t(PaginationKeys.Last)}
        </div>
      </div>
    </Wrapper>
  );
};

export default Pagination;
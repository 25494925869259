import { AxiosError } from 'axios';
import { BaseAPIService } from '../BaseAPIService';
import { IBaseResponse } from '../BaseAPIService/types';
import { IRefreshTokensOptions } from '../AuthService/types';
import { IProjectsResponseBody, ICrawlLatestResponseBody, ICrawlsResponseBody, IProject, IBenchmarksSuggestionsRequestBody, IKeywordsSuggestionsRequestBody, IKeywordsSuggestionsResponseBody, IAnnotationsBody } from './types';
import { dataServiceApiUrl } from '../../config';
import { API_PROJECT_ROUTES } from '../../constants/routes';
import { RouteParamPlaceholder } from '../../enums/routes';

class ProjectService extends BaseAPIService {
  constructor(url: string) {
    super(url);
    this.createEntry = this.createEntry.bind(this);
    this.updateEntry = this.updateEntry.bind(this);
    this.updateAnnotations = this.updateAnnotations.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.latestCrawl = this.latestCrawl.bind(this);
    this.crawls = this.crawls.bind(this);
    this.startCrawls = this.startCrawls.bind(this);
    this.crawlsActive = this.crawlsActive.bind(this);
    this.benchmarksSuggestions = this.benchmarksSuggestions.bind(this);
    this.keywordsSuggestions = this.keywordsSuggestions.bind(this);
  }

  async createEntry(body: Partial<IProject>, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProject>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProject> = await this.post<IProject, Partial<IProject>>(
        `${API_PROJECT_ROUTES.projects}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProject> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateEntry(projectID: string, body: Partial<IProject>, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProject>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProject> = await this.patch<IProject, Partial<IProject>>(
        `${API_PROJECT_ROUTES.projects}/${projectID}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProject> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateAnnotations(projectID: string, body: IAnnotationsBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProject>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProject> = await this.patch<IProject, IAnnotationsBody>(
        `${API_PROJECT_ROUTES.projects}/${projectID}/annotations`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProject> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async deleteEntry(projectID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<unknown>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<unknown> = await this.delete<unknown>(
        `${API_PROJECT_ROUTES.projects}/${projectID}`,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<unknown> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async crawls(projectID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICrawlsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICrawlsResponseBody> = await this.get<ICrawlsResponseBody>(
        API_PROJECT_ROUTES.crawls.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICrawlsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async benchmarksSuggestions(body: IBenchmarksSuggestionsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<{ domain: string; }[]>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<{ domain: string; }[]> = await this.post<{ domain: string; }[], IBenchmarksSuggestionsRequestBody>(
        API_PROJECT_ROUTES.benchmarksSuggestions,
        { ...body },
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<{ domain: string; }[]> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async keywordsSuggestions(body: IKeywordsSuggestionsRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<IKeywordsSuggestionsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IKeywordsSuggestionsResponseBody> = await this.post<IKeywordsSuggestionsResponseBody, IKeywordsSuggestionsRequestBody>(
        API_PROJECT_ROUTES.keywordsSuggestions,
        { ...body },
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IKeywordsSuggestionsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async startCrawls(projectID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICrawlsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICrawlsResponseBody> = await this.post<ICrawlsResponseBody>(
        API_PROJECT_ROUTES.startCrawls.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICrawlsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async latestCrawl(projectID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICrawlLatestResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICrawlLatestResponseBody> = await this.get<ICrawlLatestResponseBody>(
        API_PROJECT_ROUTES.latestCrawl.replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICrawlLatestResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async crawlsActive(options?: IRefreshTokensOptions): Promise<IBaseResponse<ICrawlsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICrawlsResponseBody> = await this.get<ICrawlsResponseBody>(
        API_PROJECT_ROUTES.crawlsActive,
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICrawlsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }
}

const ServiceInstance = new ProjectService(dataServiceApiUrl);

export default ServiceInstance;
export { ServiceInstance as ProjectService };

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccountsKeys } from "../../../../enums/locales/accounts";
import { FieldButtonTextKeys } from "../../../../enums/locales/forms";
import { profileSettingsActions } from "../../../../redux/profile-settings/actions";
import { selectLicenses } from "../../../../redux/profile-settings/selectors";

import { Wrapper } from "./LicensesTable.styled";

const LicensesTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const licenses = useSelector(selectLicenses);

  const onUserDelete = useCallback((licenseID: string) => () => {
    dispatch(profileSettingsActions.adminLicensesUpdate(licenseID))
  }, [dispatch]);

  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th>{t(AccountsKeys.AccountLabel)}</th>
            <th>{t(AccountsKeys.CompanyLabel)}</th>
            <th>{t(FieldButtonTextKeys.EndUsers)}</th>
            <th>{t(AccountsKeys.ProjectsLabel)}</th>
          </tr>
        </thead>
        <tbody>
          {licenses.map(({ license_id, creator_email, creator_name, company: { name }, user_slots_used, project_slots_used }) => (
            <React.Fragment key={license_id}>
              <tr>
                <td>{creator_name} ({creator_email})</td>
                <td>{name}</td>
                <td>{user_slots_used}</td>
                <td>{project_slots_used}</td>
              </tr>
              <tr>
                <td colSpan={4} onClick={onUserDelete(license_id)}>
                  {t(AccountsKeys.RemoveUserMessage)}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Wrapper>
  )
}

export default LicensesTable;
import { createSelector } from 'reselect';
import { RootState } from '../store';

import { Feature } from '../../enums/data';
import { FeaturesData } from '../../services/DataService/types';

const activeFeatures = (state: RootState) => state.KPI.activeFeatures;
const data = (state: RootState) => state.KPI.data;
const loading = (state: RootState) => state.KPI.loading;
const UI = (state: RootState) => state.KPI.UI;

export const selectActiveFeatures = createSelector(
	[activeFeatures],
	(activeFeatures) => activeFeatures,
);

export const selectKpiData = createSelector(
	[data],
	(data) => data,
);

export const selectKpiDomainData = createSelector(
	[data],
	(data) => {
		return Object.keys(data).reduce((res, featureKey) => {
			res[featureKey as Feature] = data[featureKey as Feature]?.project_domain;
			return res;
		}, {} as FeaturesData);
	},
);

export const selectKpiDataByFeature = (featureKey: Feature) => createSelector(
	[data],
	(data) => data[featureKey],
);

export const selectMultipleKpiDataByFeature = (featureKeys: Feature[]) => createSelector(
	[data],
	(data) => featureKeys.map((featureKey) => data[featureKey]),
);

export const selectLoadingByFeature = (featureKey: Feature) => createSelector(
	[loading],
	(loading) => loading[featureKey],
);

export const selectTotalLoadingByFeatures = (featureKeys: Feature[]) => createSelector(
	[loading],
	(loading) => featureKeys.some((featureKey) => !!loading[featureKey]),
);

export const selectTotalLoading = createSelector(
	[loading],
	(loading) => Object.keys(loading).some((featureKey) => !!loading[featureKey as Feature]),
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

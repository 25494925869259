import { IGoogleSignInBody, IPasswordSignInBody, IUserSimple, IRefreshTokensOptions } from '../../services/AuthService/types';

export enum AuthActionTypes {
  SIGN_IN_WITH_GOOGLE = '[Auth] sign-in-with-google',
  SIGN_IN_WITH_PASSWORD = '[Auth] sign-in-with-password',
  USER_RELOAD = '[Auth] user-reload',
  USER_REFRESH = '[Auth] user-refresh',
  USER_RESET = '[Auth] user-reset',
  TOKENS_REFRESH = '[Auth] tokens-refresh',
  SIGN_OUT = '[Auth] sign-out',
	UI_MERGE = '[Auth] ui-merge',
}

export interface IGoogleSignInPayload extends IGoogleSignInBody {}

export interface IPasswordSignInPayload extends IPasswordSignInBody {}

export interface IUserPayload extends IUserSimple {}

export interface IUIPayload {
  loading?: boolean;
}

export interface IRefreshTokensOptionsPayload extends IRefreshTokensOptions {}

export interface AuthState {
  // TODO: replace user type with actual type
  user: IUserPayload | null;
  UI: IUIPayload,
}

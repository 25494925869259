import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AntdModal from 'antd/lib/modal';

import { modalsActions } from '../../../redux/modals/actions';
import { selectLicenseVisible } from '../../../redux/modals/selectors';
import { IconClose } from '../../../components/styled/icons';
import { InputNumber } from '../../../components/styled/InputNumber';
import { Input } from '../../../components/styled/Input';
import { Button, ButtonType } from '../../../components/styled/Button';
import { StyledBodyWrapper } from './LicenseModal.styled';
import { SiderHeading, SiderSteps } from '../../../enums/locales/modals';
import { AccountDetailsKeys, AccountsKeys } from '../../../enums/locales/accounts';
import { ButtonTextKeys, FieldButtonTextKeys, FieldLabelKeys } from '../../../enums/locales/forms';
import { accountsActions } from '../../../redux/accounts/actions';
import { validateEmail } from '../../../utils/common';

const initValues = {
  creator_email: '',
  user_slots: 0,
  project_slots: 0,
  keyword_slots: 0,
  url_slots: 0,
};

const LicenseModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectLicenseVisible);
  const [resources, setResources] = useState(initValues);

  useEffect(() => {
    if (isVisible) {
      setResources(initValues);
    }
  }, [isVisible, setResources]);

  const isEmailDisabled = useMemo(() => {
    return !validateEmail(resources.creator_email);
  }, [resources.creator_email]);

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.licenseHide());
  }, [dispatch]);

  const onChange = useCallback((name: string) => (value: number) => {
    setResources({ ...resources, [name]: value > 0 ? value : 0 });
  }, [resources, setResources]);

  const onEmailChange = useCallback((e) => {
    setResources({ ...resources, creator_email: e.target.value });
  }, [resources, setResources]);

  const onResourceUpdate = useCallback(() => {
    dispatch(accountsActions.accountCreate(resources));
    dispatch(modalsActions.licenseHide());
  }, [dispatch, resources]);

  return (
    <AntdModal
      centered
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="limit-modal-container"
      maskStyle={{ zIndex: 10001 }}
      width={'500px'}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className="limit-modal-title">
          {t(ButtonTextKeys.NewLicense)}
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(FieldLabelKeys.Email)}:
          </div>
          <div className="email-input">
            <Input value={resources.creator_email} onChange={onEmailChange} />
          </div>
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(FieldButtonTextKeys.EndUsers) })}:
          </div>
          <InputNumber value={resources.user_slots} onChange={onChange('user_slots')} />
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(AccountsKeys.ProjectsLabel) })}:
          </div>
          <InputNumber value={resources.project_slots} onChange={onChange('project_slots')} />
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(FieldButtonTextKeys.EndKeywords) })}:
          </div>
          <InputNumber value={resources.keyword_slots} onChange={onChange('keyword_slots')} />
        </div>
        <div className="inputs-container">
          <div className="input-label">
            {t(AccountsKeys.LimitLabel, { resource: t(SiderSteps.Urls) })}:
          </div>
          <InputNumber value={resources.url_slots} onChange={onChange('url_slots')} />
        </div>
        <div className="update-btn-container">
          <Button
            type={ButtonType.Primary}
            onClick={onResourceUpdate}
            disabled={isEmailDisabled}
            title={t(ButtonTextKeys.Create)}
          />
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default LicenseModal;

import styled from 'styled-components';

export const StyledButton = styled.button`
  height: ${({ theme }) => theme.height.buttons.standard};
  max-width: ${({ theme }) => theme.maxWidth.buttons.standard};
  width: 100%;

  padding: 0;
  border-radius: 10px;
  opacity: 1;
  outline: none;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    .title-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
      }

      .title {
        letter-spacing: 0px;
        opacity: 1;
        font-style: normal;
      }
    }

    .suffix-wrapper,
    .prefix-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .suffix-wrapper {
      margin-right: 20px;
    }

    .prefix-wrapper {
      margin-left: 20px;
    }
  }

  &.primary,
  &.secondary,
  &.remove {
    .content {
      .title-wrapper {
        .title {
          font-weight: bold;
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }

  &.small,
  &.outline {
    .content {
      .title-wrapper {
        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }

  &.primary {
    color: ${({ theme }) => theme.colors.buttons.font.primary};
    background: ${({ theme }) => theme.colors.buttons.background.primary} 0% 0% no-repeat padding-box;
    border: none;
  }

  &.secondary {
    color: ${({ theme }) => theme.colors.buttons.font.secondary};
    background: ${({ theme }) => theme.colors.buttons.background.secondary} 0% 0% no-repeat padding-box;
    border: none;

    &.prefix:not(.disabled),
    &.suffix:not(.disabled) {
      .content {
        .prefix-wrapper,
        .suffix-wrapper {
          svg {
            .b {
              stroke: ${({ theme }) => theme.colors.buttons.font.secondary};
            }
          }
        }
      }
    }
  }

  &.outline {
    color: ${({ theme }) => theme.colors.buttons.font.outline};
    background: ${({ theme }) => theme.colors.buttons.background.outline} 0% 0% no-repeat padding-box;
    border: 2px solid ${({ theme }) => theme.colors.buttons.border.outline};
  }

  &.disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.buttons.font.disabled};
    background: ${({ theme }) => theme.colors.buttons.background.disabled} 0% 0% no-repeat padding-box;
    border: none;
  }

  &.remove {
    color: ${({ theme }) => theme.colors.buttons.font.remove};
    background: ${({ theme }) => theme.colors.buttons.background.remove} 0% 0% no-repeat padding-box;
    border: 2px solid ${({ theme }) => theme.colors.buttons.border.remove};

    .content {
      .title-wrapper {
        .icon {
          svg {
            .b {
              fill: ${({ theme }) => theme.colors.buttons.font.remove};
            }
          }
        }
      }
    }
  }

  &.small {
    height: ${({ theme }) => theme.height.buttons.small};
    max-width: ${({ theme }) => theme.maxWidth.buttons.small};

    .content {
      .title-wrapper {
        .icon {
          margin-right: 7px;
        }
      }

      .suffix-wrapper,
      .prefix-wrapper {
        svg {
          transform: scale(calc(14 / 18));
        }
      }

      .suffix-wrapper {
        margin-right: 12px;
      }

      .prefix-wrapper {
        margin-left: 12px;
      }
    }
  }

  &.prefix:not(.small),
  &.suffix:not(.small) {
    .content {
      .title-wrapper {
        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }

  &.rounded {
    border-radius: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;

import styled from 'styled-components';
import { ReactComponent as RawGlobeSVG } from '../../../../assets/icons/icon_globe.svg';

export const IconGlobe = styled(RawGlobeSVG)`
  .a {
    fill:none;
  }

  .b {
    fill:#222a41;
  }
`;

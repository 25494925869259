import { FieldTypes } from "../../../enums/forms";
import { ButtonTextKeys, FieldButtonTextKeys, FieldLabelKeys, FieldListTitleKeys, FieldPlaceholderKeys, FieldSubLabelKeys, FieldTableTitleKeys } from "../../../enums/locales/forms";
import { SiderSteps, SiderStepsSuffixes } from "../../../enums/locales/modals";
import { FIELD_NAMES } from "../../../enums/projectSettings";

import { recrawlsItems, strategyItems } from "../../../constants/projectSettings";
import { RadioType } from "../../../components/styled/Radio/Radio";

export const formSteps = [
  [FIELD_NAMES.Domain, FIELD_NAMES.Name],
  [FIELD_NAMES.Strategy],
  [FIELD_NAMES.Urls],
  [FIELD_NAMES.UrlGroups],
  [FIELD_NAMES.Invites],
  [FIELD_NAMES.Goals],
  [FIELD_NAMES.WarmUpUrls],
];

export const WarmUpUrlsFields = [
  {
    fieldType: FieldTypes.Switch,
    name: FIELD_NAMES.WarmUpUrls,
    labelKey: FieldLabelKeys.WarmUpUrls,
  },
];


export const GoalsFields = [
  {
    fieldType: FieldTypes.GoalsList,
    name: FIELD_NAMES.Goals,
    labelKey: FieldLabelKeys.Goals,
  },
];

export const UrlGroupsFields = [
  {
    fieldType: FieldTypes.GroupList,
    name: FIELD_NAMES.UrlGroups,
    labelKey: FieldLabelKeys.UrlGroups,
  },
];

export const InvitesFields = [
  {
    fieldType: FieldTypes.SimpleList,
    name: FIELD_NAMES.Invites,
    labelKey: FieldLabelKeys.Invites,
    addPlaceholder: FieldPlaceholderKeys.AddUsers,
    listTitle: FieldListTitleKeys.Invites,
    sufBtnText: ButtonTextKeys.Add,
    affBtnText: FieldButtonTextKeys.EndUsers,
  },
];

export const UrlsFields = [
  {
    fieldType: FieldTypes.CheckableList,
    name: FIELD_NAMES.Urls,
    labelKey: FieldLabelKeys.Urls,
    placeholder: FieldPlaceholderKeys.Urls,
    sufBtnText: ButtonTextKeys.Add,
    affBtnText: FieldTableTitleKeys.HeaderUrls,
    tableTitle: FieldTableTitleKeys.Urls,
    searchPlaceholder: FieldPlaceholderKeys.SearchUrls,
    headerTitle: FieldTableTitleKeys.HeaderUrls,
  },
];

export const TypeAndFrequencyFields = [
  {
    fieldType: FieldTypes.Radio,
    name: FIELD_NAMES.Strategy,
    labelKey: FieldLabelKeys.Strategy,
    items: strategyItems,
    radioType: RadioType.Large,
  },
  {
    fieldType: FieldTypes.Radio,
    name: FIELD_NAMES.RecrawlInterval,
    labelKey: FieldLabelKeys.Recrawl,
    items: recrawlsItems,
    subLabel: FieldSubLabelKeys.Recrawl,
  },
];

export const domainFields = [
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Domain,
    labelKey: FieldLabelKeys.Domain,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Name,
    labelKey: FieldLabelKeys.ProjectName,
  },
];


export const projectSettingsSteps = [
  {
    id: 'step-0',
    className: 'domain-and-project',
    title: SiderSteps.DomainAndProjectName,
    fields: domainFields,
  },
  {
    id: 'step-1',
    className: '',
    title: SiderSteps.TypeAndFrequency,
    fields: TypeAndFrequencyFields,
  },
  {
    id: 'step-2',
    className: '',
    title: SiderSteps.Urls,
    fields: UrlsFields,
  },
  {
    id: 'step-3',
    className: '',
    title: SiderSteps.URLGroups,
    suffix: SiderStepsSuffixes.Optional,
    fields: UrlGroupsFields,
  },
  {
    id: 'step-4',
    className: '',
    title: SiderSteps.InviteUsers,
    suffix: SiderStepsSuffixes.Optional,
    fields: InvitesFields,
  },
  {
    id: 'step-5',
    className: '',
    title: SiderSteps.Goals,
    suffix: SiderStepsSuffixes.Optional,
    fields: GoalsFields,
  },
  {
    id: 'step-6',
    className: 'warm-up-urls-switcher',
    title: SiderSteps.WarmUpUrls,
    suffix: SiderStepsSuffixes.Optional,
    fields: WarmUpUrlsFields,
  },
]
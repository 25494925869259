import yup from '../../../lib/yup';
import { FieldLabelKeys, FieldValidationMessageKeys } from '../../../enums/locales/forms';

export const string = (label: string) => {
  return yup
    .string()
    .label(label)
    .strict(false)
    .trim();
}

export const stringRequired = (label: string) => {
  return yup
    .string()
    .label(label)
    .strict(false)
    .trim()
    .nullable()
    .required();
}

export const domainRequired = (label: string) => {
  return yup
    .string()
    .label(label)
    .strict(false)
    .trim()
    .nullable()
    .matches(
      (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g),
      () => ({ key: FieldValidationMessageKeys.DomainStrong }),
    )
    .required();
}

export const arrayOfBenchmarks = () => {
  return yup
    .array()
    .test('', () => ({ key: FieldValidationMessageKeys.BenchmarksStrong }), (benchmarks = []) => {
      const result = benchmarks?.every(benchmark => {
        return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(benchmark);
      });
      return result;
    });
}

export const arrayRequired = (label: string) => {
  return yup
    .array()
    .label(label)
    .min(1, { key: FieldValidationMessageKeys.ArrayMin })
    .required();
}

export const arrayEmail = (label: string) => {
  return yup
    .array()
    .label(label)
    .test('', () => ({ key: FieldValidationMessageKeys.Email }), (invites = []) => {
      const result = invites?.every(invite => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g.test(invite);
      });
      return result;
    });
}

export const arrayObject = (label: string) => {
  return yup
    .array()
    .label(label)
    .of(yup.object().shape({
      regex: string(label),
      name: string(label)
    }));
}

export const arrayGoals = (label: string) => {
  return yup
    .array()
    .label(label)
    .of(yup.object().shape({
      name: string(label),
      condition: string(label),
      value: string(label),
      is_notify: yup.bool().label(label),
    }));
}

export const confirm = (
  field: string,
  label: string,
  message: string,
) => {
  return yup
    .string()
    .label(label)
    .min(8)
    .max(255)
    .oneOf([yup.ref(field), null], message)
    .required();
}

export const passwordRequired = (label = FieldLabelKeys.Password) => {
  return yup
    .string()
    .label(label)
    .strict(false)
    .trim()
    .max(120)
    .required();
}

export const passwordStrong = (label = FieldLabelKeys.Password) => {
  return yup
    .string()
    .label(label)
    .strict(false)
    .min(8)
    .max(120)
    .matches(
      (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/),
      () => ({ key: FieldValidationMessageKeys.PasswordStrong }),
    )
    .required();
}

export const email = (label = FieldLabelKeys.Email, message?: { key: FieldValidationMessageKeys }) => {
  return yup
    .string()
    .label(label)
    .strict(false)
    .trim()
    .email(message);
}

export const emailRequired = (label = FieldLabelKeys.Email) => {
  return yup
    .string()
    .label(label)
    .strict(false)
    .trim()
    .email()
    .required();
}

import styled from "styled-components";

export const Wrapper = styled.thead`
  th {
    position: sticky;
    z-index: 2;
    top: 44px;
    background: white;
  }

  @media screen and (max-width: 1420px) {
    th {
      top: 54px;
    }
  }
`;

export const StyledTH = styled.th<{ width?: string }>`
  width: ${({ width }) => width || 'auto'};
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  text-align: start;
`;
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import AntdModal from 'antd/lib/modal';

import { hexToRGBA } from '../../../utils/theme';
import { THEME } from '../../../constants/theme';

import { selectOnboardVisible } from '../../../redux/modals/selectors';

import { StyledBodyWrapper } from './OnboardModal.styled';
import { OnboardModalKeys } from '../../../enums/locales/modals';
import { selectProfileName } from '../../../redux/auth/selectors';
import { IconCheckmarkSmall, IconLive, IconTest } from '../../../components/styled/icons';
import { ProjectTypesKeys } from '../../../enums/locales/projects';
import { selectUI } from '../../../redux/project-settings/selectors';
import { projectSettingsActions } from '../../../redux/project-settings/actions';
import { Button, ButtonType } from '../../../components/styled/Button';
import { modalsActions } from '../../../redux/modals/actions';

const OnboardModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visible = useSelector(selectOnboardVisible);
  const profileName = useSelector(selectProfileName);
  const { isTest } = useSelector(selectUI);

  const onTypeClick = useCallback((flag) => () => {
    dispatch(projectSettingsActions.uiMerge({ isTest: flag }));
  }, [dispatch]);

  const onCreate = useCallback(() => {
    dispatch(modalsActions.onboardHide());
    dispatch(modalsActions.siderShow());
  }, [dispatch]);

  const displayName = profileName?.split(' ')[0] || '';
  const currentType = isTest ? ProjectTypesKeys.TestProject : ProjectTypesKeys.LiveProject;

  return (
    <AntdModal
      centered
      title={null}
      footer={null}
      closable={false}
      visible={visible}
      style={{ animationDuration: '0s' }}
      maskStyle={{
        background: `${hexToRGBA(
          THEME.colors.modals.background.loader,
          THEME.colors.modals.background.opacity.loader,
        )}`,
      }}
      wrapClassName="onboard-modal-container"
    >
      <StyledBodyWrapper>
        <div className="heading">
          <div className="title">{t(OnboardModalKeys.Title)} {displayName}!</div>
          <div className="description">{t(OnboardModalKeys.Description)}</div>
        </div>
        <div className="types-container">
          <div className="subtitle">
            {t(OnboardModalKeys.TypesSubtitle)}
          </div>
          <div className="types-cards">
            <div onClick={onTypeClick(false)} className={clsx("card", { active: !isTest })}>
              <div className="check">
                <IconCheckmarkSmall />
              </div>
              <div className="card-icon">
                <IconLive />
              </div>
              <div className="card-title">{t(ProjectTypesKeys.LiveProject)}</div>
              <div className="card-description">{t(OnboardModalKeys.LiveDescPart1)}</div>
              <div className="card-description">{t(OnboardModalKeys.LiveDescPart2)}</div>
            </div>
            <div onClick={onTypeClick(true)} className={clsx("card", { active: isTest })}>
              <div className="check">
                <IconCheckmarkSmall />
              </div>
              <div className="card-icon">
                <IconTest />
              </div>
              <div className="card-title">{t(ProjectTypesKeys.TestProject)}</div>
              <div className="card-description">{t(OnboardModalKeys.TestDescPart1)}</div>
              <div className="card-description">{t(OnboardModalKeys.TestDescPart2)}</div>
            </div>
          </div>
          <Button
            type={ButtonType.Primary}
            onClick={onCreate}
            title={t(OnboardModalKeys.ButtonTitle, { type: t(currentType) })}
          />
          <div className="support-message">{t(OnboardModalKeys.SupportMessage)}</div>
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
}

export default OnboardModal;


import { IDirectory } from "../../services/DataService/types";

export const setDirectoryNextLevel = (data: IDirectory[], nextLevel: IDirectory[], root: string, level: number): IDirectory[] => {
  let isFoundResult = false;

  data.forEach((item) => {
    const isFound = root === item.root && level == item.level && !item.nextLevel;
    if (isFound) {
      item.nextLevel = nextLevel;
      isFoundResult = true;
    }
  });

  if (isFoundResult) {
    return data;
  }

  return data.map((item) => {
    if (item.nextLevel) {
      return { ...item, nextLevel: setDirectoryNextLevel(item.nextLevel, nextLevel, root, level) };
    }
    return item;
  });
}
import { createSelector } from 'reselect';
import { RootState } from '../store';

const currentFeature = (state: RootState) => state.History.currentFeature;
const currentHistoryInterval = (state: RootState) => state.History.currentHistoryInterval;
const data = (state: RootState) => state.History.data;
const UI = (state: RootState) => state.History.UI;

export const selectCurrentFeature = createSelector(
	[currentFeature],
	(currentFeature) => currentFeature,
);

export const selectCurrentHistoryInterval = createSelector(
	[currentHistoryInterval],
	(currentHistoryInterval) => currentHistoryInterval,
);

export const selectHistoryData = createSelector(
	[data],
	(data) => data,
);

export const selectHistoryFeatureData = createSelector(
	[data, currentFeature],
	(data, currentFeature) => data[currentFeature],
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

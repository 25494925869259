import styled from 'styled-components';

import { IStyledWrapperProps } from './ProfileAvatar';

export const StyledWrapper = styled.div<IStyledWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  .profile-avatar-image,
  .profile-avatar-template {
    border-radius: 50%;
  }
  
  .profile-avatar-image {
  	width: ${({ size, theme }) => theme.width.profileAvatar || `${size}px`};
  	height: ${({ size, theme }) => theme.height.profileAvatar || `${size}px`};
    object-fit: cover;
  }
  
  .profile-avatar-template {
    display: flex;
    justify-content: center;
    align-items: center;
  	width: ${({ size, theme }) => theme.width.profileAvatar || `${size}px`};
  	height: ${({ size, theme }) => theme.height.profileAvatar || `${size}px`};
    background-color: ${({ templateBackgroundColor, theme }) => theme.colors.profileAvatar?.background || templateBackgroundColor};
    color: ${({ templateFontColor, theme }) => theme.colors.profileAvatar?.font || templateFontColor};
  }
`;

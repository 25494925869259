import { ILicense } from '../../services/LicensesService/types';
import { LicensesActions } from './actions';
import { LicensesActionTypes, LicensesState } from './types';
import { IUIPayload } from './types';

const initState: LicensesState = {
  licenses: [],
  activeLicenseID: '',
  UI: {
    loading: false,
  },
};

export function licensesReducer(state: LicensesState = initState, action: LicensesActions): LicensesState {
  switch (action.type) {
    case LicensesActionTypes.LICENSES_REFRESH: {
      return {
        ...state,
        licenses: action.payload as ILicense[],
      };
    }
    case LicensesActionTypes.ACTIVE_LICENSE_ID_REFRESH: {
      return {
        ...state,
        activeLicenseID: action.payload as string,
      };
    }
    case LicensesActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case LicensesActionTypes.RESET_ALL: {
      return initState;
    }
    default: {
      return state;
    }
  }
}

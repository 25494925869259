import { takeLatest, all, put, call, select } from 'redux-saga/effects';

import { AdminService } from '../../services/AdminService';
import { ProjectService } from '../../services/ProjectService';
import { LicensesService } from '../../services/LicensesService';

import { Action } from '../commonTypes';
import { ProjectSettingsActionTypes } from './types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IAdminCrawlsRequestBody } from '../../services/AdminService/types'
import { IBenchmarksSuggestionsRequestBody, ICrawlsResponseBody, IKeywordsSuggestionsRequestBody, IKeywordsSuggestionsResponseBody, IProject, IProjectResponseBody } from '../../services/ProjectService/types';
import { IProjectSettingsData } from './types';
import { EmptyObject } from '../../types/common.types';

import { projectSettingsActions } from './actions';
import { projectsActions } from '../projects/actions';
import { modalsActions } from '../modals/actions';
import { accountDetailsActions } from '../account-details/actions';
import { selectData, selectProjectID, selectUI } from './selectors';
import { selectProjectByID, selectUI as selectProjectsUI } from '../projects/selectors';
import { selectActiveLicenseID } from '../licenses/selectors';
import { selectAccountLicenseID, selectAccountProjectByID, selectCrawlsSearch } from '../account-details/selectors';
import { licensesActions } from '../licenses/actions';

function* updateInit(action: Action<ProjectSettingsActionTypes.UPDATE_INIT, string>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  yield put(projectSettingsActions.uiMerge({ isNew: false }));

  const projectID = action.payload;
  const project: IProject = yield select(selectProjectByID(projectID));

  const projectSettingsData: IProjectSettingsData = {
    domain: project.domain,
    name: project.name,
    country_code: project.country_code,
    strategy: project.strategy,
    recrawl_interval: project.recrawl_interval,
    keywords: project.keywords || [],
    benchmarks: project.benchmarks || [],
    url_groups: project.url_groups || [],
    invites: project.invites || [],
    goals: project.goals || [],
    urls: project.urls || [],
    is_warmup_urls: !!project.is_warmup_urls,
  };

  const result: IBaseResponse<ICrawlsResponseBody> = yield call(ProjectService.crawls, projectID, options);
  if (!result?.data) {
    yield put(projectsActions.uiMerge({ loading: false }));
    return;
  }

  const response: ICrawlsResponseBody = result?.data;

  const { crawls } = response;

  yield put(projectSettingsActions.uiMerge({
    isTest: project.is_test_project,
    isCrawled: !!crawls.length,
    keywordsInitCount: project?.keywords?.length || 0,
    urlsInitCount: project?.urls?.length || 0,
    initRecrawlInterval: project?.recrawl_interval || null,
  }));
  yield put(projectSettingsActions.projectIDSet(projectID));
  yield put(projectSettingsActions.dataRefresh(projectSettingsData));
  yield put(modalsActions.siderShow());
}

function* adminUpdateInit(action: Action<ProjectSettingsActionTypes.ADMIN_UPDATE_INIT, string>): any {
  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  yield put(projectSettingsActions.uiMerge({ isNew: false }));

  const projectID = action.payload;
  const project: IProject = yield select(selectAccountProjectByID(projectID));
  const licenseID = yield select(selectAccountLicenseID);

  if (!project || !licenseID) {
    return;
  }

  const projectSettingsData: IProjectSettingsData = {
    domain: project.domain,
    name: project.name,
    country_code: project.country_code,
    strategy: project.strategy,
    recrawl_interval: project.recrawl_interval,
    keywords: project.keywords || [],
    benchmarks: project.benchmarks || [],
    url_groups: project.url_groups || [],
    invites: project.invites || [],
    goals: project.goals || [],
    urls: project.urls || [],
    is_warmup_urls: !!project.is_warmup_urls,
  };

  const crawlsSearch = yield select(selectCrawlsSearch);

  const crawlsRequestBody: IAdminCrawlsRequestBody = {
    filter: crawlsSearch,
  }

  const result: IBaseResponse<ICrawlsResponseBody> = yield call(AdminService.getLicenseProjectCrawls, projectID, licenseID, crawlsRequestBody, options);
  if (!result?.data) {
    yield put(projectsActions.uiMerge({ loading: false }));
    return;
  }

  const response: ICrawlsResponseBody = result?.data;

  const { crawls } = response;

  yield put(projectSettingsActions.uiMerge({
    isTest: project.is_test_project,
    isCrawled: !!crawls.length,
    keywordsInitCount: project?.keywords?.length || 0,
    urlsInitCount: project?.urls?.length || 0,
    initRecrawlInterval: project?.recrawl_interval || null,
  }));
  yield put(projectSettingsActions.projectIDSet(projectID));
  yield put(projectSettingsActions.dataRefresh(projectSettingsData));
  yield put(modalsActions.adminSiderShow());
}

function* dataSave(action: Action<ProjectSettingsActionTypes.DATA_SAVE, EmptyObject>): any {
  yield put(projectSettingsActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const projectID = yield select(selectProjectID);
  const licenseID = yield select(selectActiveLicenseID);
  const project = yield select(selectProjectByID(projectID));
  const body: Partial<IProject> = yield select(selectData);
  const { isTest, isCrawlNow, isCrawled } = yield select(selectUI);

  const requestBody = {
    ...body,
    invites: !!body.invites?.length ? body.invites : null,
    urls: !!body.urls?.length ? body.urls : null,
    url_groups: !!body.url_groups?.length ? body.url_groups : null,
    searchmetrics_project_id: project?.searchmetrics_project_id || null,
    license_id: licenseID,
    is_test_project: isTest,
    is_warmup_urls: !!body?.is_warmup_urls,
  }

  let result: IBaseResponse<IProjectResponseBody>;

  if (projectID) {
    result = yield call(LicensesService.updateProject, requestBody, projectID, licenseID, options);
  } else {
    result = yield call(LicensesService.createProject, requestBody, licenseID, options);
  }

  if (!result?.data) {
    yield put(projectSettingsActions.uiMerge({ loading: false }));
    return;
  }

  const currentProjectID = projectID || result.data.project.project_id;
  const isCrawlNeeded = currentProjectID && isCrawlNow && (!isCrawled || !body.recrawl_interval);

  if (isCrawlNeeded) {
    yield call(LicensesService.crawlsStart, currentProjectID, licenseID, options);
    yield put(projectsActions.activeCrawlsInit());
  }

  yield put(projectsActions.listReload());
  yield put(licensesActions.licensesReload());
  yield put(projectSettingsActions.uiMerge({ loading: false }));
  yield put(modalsActions.siderHide());
  yield put(projectSettingsActions.reset());
}

function* adminDataSave(action: Action<ProjectSettingsActionTypes.DATA_SAVE, EmptyObject>): any {
  yield put(projectSettingsActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const projectID = yield select(selectProjectID);
  const project: IProject = yield select(selectAccountProjectByID(projectID));
  const licenseID = yield select(selectAccountLicenseID);
  const activeLicenseID = yield select(selectActiveLicenseID);

  if (!project || !licenseID) {
    return;
  }

  const body: Partial<IProject> = yield select(selectData);
  const { isTest, isCrawlNow, isCrawled } = yield select(selectUI);

  const requestBody = {
    ...body,
    invites: !!body.invites?.length ? body.invites : null,
    urls: !!body.urls?.length ? body.urls : null,
    url_groups: !!body.url_groups?.length ? body.url_groups : null,
    searchmetrics_project_id: project?.searchmetrics_project_id || null,
    license_id: licenseID,
    is_test_project: isTest,
    is_warmup_urls: !!body?.is_warmup_urls,
  }

  const result: IBaseResponse<IProjectResponseBody> = yield call(AdminService.updateLicenseProject, requestBody, projectID, licenseID, options);

  if (!result?.data) {
    yield put(projectSettingsActions.uiMerge({ loading: false }));
    return;
  }

  const currentProjectID = projectID || result.data.project.project_id;
  const isCrawlNeeded = currentProjectID && isCrawlNow && (!isCrawled || !body.recrawl_interval);

  const { projectsMenuOpen } = yield select(selectProjectsUI);

  if (isCrawlNeeded) {
    yield call(LicensesService.crawlsStart, currentProjectID, licenseID, options);
  }

  if (isCrawlNeeded && projectsMenuOpen) {
    yield put(projectsActions.activeCrawlsCheckStart());
  }

  yield put(accountDetailsActions.projectsReload());
  yield put(accountDetailsActions.licenseReload());

  if (activeLicenseID === licenseID) {
    yield put(projectsActions.listReload());
    yield put(licensesActions.licensesReload());
  }

  yield put(projectSettingsActions.uiMerge({ loading: false }));
  yield put(modalsActions.adminSiderHide());
  yield put(projectSettingsActions.reset());
}

function* benchmarksSuggestionsReload(action: Action<ProjectSettingsActionTypes.BENCHMARKS_SUGGESTIONS_RELOAD, EmptyObject>): any {
  const { domain = '', country_code = '' } = yield select(selectData);

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestBody: IBenchmarksSuggestionsRequestBody = {
    domain,
    country_code,
  }

  const result: IBaseResponse<{ domain: string; }[]> = yield call(ProjectService.benchmarksSuggestions, requestBody, options);

  if (!result?.data) {
    yield put(projectSettingsActions.uiMerge({ loading: false }));
    return;
  }

  const { data } = result;
  const list = data.map(({ domain }) => domain);

  yield put(projectSettingsActions.benchmarksSuggestionsRefresh(list));
}

function* keywordsSuggestionsReload(action: Action<ProjectSettingsActionTypes.BENCHMARKS_SUGGESTIONS_RELOAD, EmptyObject>): any {
  const { domain = '', country_code = '' } = yield select(selectData);
  const { keywordsOffset, keywordsLimit } = yield select(selectUI);
  yield put(projectSettingsActions.uiMerge({ keywordsLoading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestBody: IKeywordsSuggestionsRequestBody = {
    domain,
    country_code,
    offset: keywordsOffset,
    limit: keywordsLimit,
  }

  const result: IBaseResponse<IKeywordsSuggestionsResponseBody> = yield call(ProjectService.keywordsSuggestions, requestBody, options);

  if (!result?.data) {
    yield put(projectSettingsActions.uiMerge({ keywordsLoading: false }));
    return;
  }

  const response = result.data;
  const { data, count } = response;

  yield put(projectSettingsActions.keywordsSuggestionsRefresh(data));
  yield put(projectSettingsActions.uiMerge({ keywordsCount: count, keywordsLoading: false }));
}

export default function* projectSettingsSaga() {
  yield all([
    takeLatest(ProjectSettingsActionTypes.UPDATE_INIT, updateInit),
    takeLatest(ProjectSettingsActionTypes.ADMIN_UPDATE_INIT, adminUpdateInit),
    takeLatest(ProjectSettingsActionTypes.DATA_SAVE, dataSave),
    takeLatest(ProjectSettingsActionTypes.ADMIN_DATA_SAVE, adminDataSave),
    takeLatest(ProjectSettingsActionTypes.BENCHMARKS_SUGGESTIONS_RELOAD, benchmarksSuggestionsReload),
    takeLatest(ProjectSettingsActionTypes.KEYWORDS_SUGGESTIONS_RELOAD, keywordsSuggestionsReload),
  ]);
}

import { createSelector } from 'reselect';
import { RootState } from '../store';

const data = (state: RootState) => state.Directory.data;
const openedFolders = (state: RootState) => state.Directory.openedFolders;
const sort = (state: RootState) => state.Directory.sort;
const summaryDataRestrictions = (state: RootState) => state.Directory.summaryDataRestrictions;
const UI = (state: RootState) => state.Directory.UI;

export const selectData = createSelector(
  [data],
  (data) => data,
);

export const selectOpenedFolders = createSelector(
  [openedFolders],
  (openedFolders) => openedFolders,
);

export const selectSortFeature = createSelector(
  [sort],
  (sort) => sort.feature,
);

export const selectSortOrder = createSelector(
  [sort],
  (sort) => sort.order,
);

export const selectSummaryDataRestrictions = createSelector(
  [summaryDataRestrictions],
  (summaryDataRestrictions) => summaryDataRestrictions,
);

export const selectUI = createSelector(
  [UI],
  (UI) => UI,
);

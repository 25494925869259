import { EmptyObject } from '../../types/common.types';
import { ActionUnion, createAction } from '../commonTypes';
import { IKeyword, IProjectSettingsData, IUIPayload, ProjectSettingsActionTypes } from './types';

export const projectSettingsActions = {
  updateInit: (payload: string): { type: ProjectSettingsActionTypes, payload: string } => createAction(
    ProjectSettingsActionTypes.UPDATE_INIT,
    payload,
  ),
  adminUpdateInit: (payload: string): { type: ProjectSettingsActionTypes, payload: string } => createAction(
    ProjectSettingsActionTypes.ADMIN_UPDATE_INIT,
    payload,
  ),
  dataSave: (): { type: ProjectSettingsActionTypes, payload: EmptyObject } => createAction(
    ProjectSettingsActionTypes.DATA_SAVE,
  ),
  adminDataSave: (): { type: ProjectSettingsActionTypes, payload: EmptyObject } => createAction(
    ProjectSettingsActionTypes.ADMIN_DATA_SAVE,
  ),
  dataRefresh: (payload: IProjectSettingsData): { type: ProjectSettingsActionTypes, payload: IProjectSettingsData } => createAction(
    ProjectSettingsActionTypes.DATA_REFRESH,
    payload,
  ),
  projectIDSet: (payload: string): { type: ProjectSettingsActionTypes, payload: string } => createAction(
    ProjectSettingsActionTypes.PROJECT_ID_SET,
    payload,
  ),
  projectIDReset: (payload: string): { type: ProjectSettingsActionTypes, payload: EmptyObject } => createAction(
    ProjectSettingsActionTypes.PROJECT_ID_RESET,
  ),
  benchmarksSuggestionsReload: (): { type: ProjectSettingsActionTypes, payload: EmptyObject } => createAction(
    ProjectSettingsActionTypes.BENCHMARKS_SUGGESTIONS_RELOAD,
  ),
  benchmarksSuggestionsRefresh: (payload: string[]): { type: ProjectSettingsActionTypes, payload: string[] } => createAction(
    ProjectSettingsActionTypes.BENCHMARKS_SUGGESTIONS_REFRESH,
    payload,
  ),
  keywordsSuggestionsReload: (): { type: ProjectSettingsActionTypes, payload: EmptyObject } => createAction(
    ProjectSettingsActionTypes.KEYWORDS_SUGGESTIONS_RELOAD,
  ),
  keywordsSuggestionsRefresh: (payload: IKeyword[]): { type: ProjectSettingsActionTypes, payload: IKeyword[] } => createAction(
    ProjectSettingsActionTypes.KEYWORDS_SUGGESTIONS_REFRESH,
    payload,
  ),
  finishedStepsRefresh: (payload: number[]): { type: ProjectSettingsActionTypes, payload: number[] } => createAction(
    ProjectSettingsActionTypes.FINISHED_STEPS_REFRESH,
    payload,
  ),
  reset: (): { type: ProjectSettingsActionTypes, payload: EmptyObject } => createAction(
    ProjectSettingsActionTypes.RESET,
  ),
  uiMerge: (payload: IUIPayload): { type: ProjectSettingsActionTypes, payload: IUIPayload } => createAction(
    ProjectSettingsActionTypes.UI_MERGE,
    payload,
  ),
}

export type ProjectSettingsActions = ActionUnion<typeof projectSettingsActions>;

import { createSelector } from 'reselect';
import { RootState } from '../store';

const urls = (state: RootState) => state.CompoundAnalysis.urls;
const allUrls = (state: RootState) => state.CompoundAnalysis.allUrls;
const networkDetails = (state: RootState) => state.CompoundAnalysis.networkDetails;
const thumbnails = (state: RootState) => state.CompoundAnalysis.thumbnails;
const activeUrlID = (state: RootState) => state.CompoundAnalysis.activeUrlID;
const resourceTypeFilter = (state: RootState) => state.CompoundAnalysis.resourceTypeFilter;
const disabledResourceTypes = (state: RootState) => state.CompoundAnalysis.disabledResourceTypes;
const urlsSortOrder = (state: RootState) => state.CompoundAnalysis.urlsSortOrder;
const urlsSortKey = (state: RootState) => state.CompoundAnalysis.urlsSortKey;
const urlSearch = (state: RootState) => state.CompoundAnalysis.urlSearch;
const networkDetailsSortOrder = (state: RootState) => state.CompoundAnalysis.networkDetailsSortOrder;
const networkDetailsSortKey = (state: RootState) => state.CompoundAnalysis.networkDetailsSortKey;
const UI = (state: RootState) => state.CompoundAnalysis.UI;

export const selectUrls = createSelector(
  [urls],
  (urls) => urls,
);

export const selectAllUrls = createSelector(
  [allUrls],
  (allUrls) => allUrls,
);

export const selectNetworkDetails = createSelector(
  [networkDetails],
  (networkDetails) => networkDetails,
);

export const selectThumbnails = createSelector(
  [thumbnails],
  (thumbnails) => thumbnails,
);

export const selectThumbnailsFCPValue = createSelector(
  [thumbnails],
  (thumbnails) => thumbnails['first-contentful-paint:numericValue'],
);

export const selectThumbnailsFMPValue = createSelector(
  [thumbnails],
  (thumbnails) => thumbnails['first-meaningful-paint:numericValue'],
);

export const selectThumbnailsLCPValue = createSelector(
  [thumbnails],
  (thumbnails) => thumbnails['largest-contentful-paint:numericValue'],
);

export const selectThumbnailsLCPElementLabel = createSelector(
  [thumbnails],
  (thumbnails) => {
    if (Array.isArray(thumbnails?.largest_contentful_paint_element)) {
      if (Array.isArray(thumbnails?.largest_contentful_paint_element[0]?.items)) {
        return thumbnails?.largest_contentful_paint_element[0]?.items[0]?.node?.nodeLabel;
      }
    }
    return undefined;
  },
);

export const selectThumbnailsLCPElementSnippet = createSelector(
  [thumbnails],
  (thumbnails) => {
    if (Array.isArray(thumbnails?.largest_contentful_paint_element)) {
      if (Array.isArray(thumbnails?.largest_contentful_paint_element[0]?.items)) {
        return thumbnails?.largest_contentful_paint_element[0]?.items[0]?.node?.snippet;
      }
    }
    return undefined;
  },
);

export const selectScreenshotThumbnails = createSelector(
  [thumbnails],
  (thumbnails) => Array.isArray(thumbnails?.screenshot_thumbnails) ? thumbnails?.screenshot_thumbnails[0] : [],
);

export const selectFinalScreenshot = createSelector(
  [thumbnails],
  (thumbnails) => Array.isArray(thumbnails?.final_screenshot) ? thumbnails?.final_screenshot[0] : undefined,
);

export const selectNetworkResourceTypes = createSelector(
  [networkDetails],
  (networkDetails) => Array.from(new Set(networkDetails.map(({ resource_type }) => resource_type))),
);

export const selectNetworkResourceTypesByQuantity = createSelector(
  [networkDetails, selectNetworkResourceTypes],
  (networkDetails, selectNetworkResourceTypes) => {
    return selectNetworkResourceTypes.map((resource_type) => {
      const filteredNetworkDetails = networkDetails.filter((item) => item.resource_type === resource_type) || [];
      return {
        resource_type,
        quantity: filteredNetworkDetails.length,
      };
    });
  },
);

export const selectNetworkResourceTypesBySize = createSelector(
  [networkDetails, selectNetworkResourceTypes],
  (networkDetails, selectNetworkResourceTypes) => {
    return selectNetworkResourceTypes.map((resource_type) => {
      const filteredNetworkDetails = networkDetails.filter((item) => item.resource_type === resource_type) || [];
      return {
        resource_type,
        size: filteredNetworkDetails.reduce((acc, { resource_size }) => {
          acc += resource_size;
          return acc;
        }, 0),
      };
    });
  },
);

export const selectActiveUrlID = createSelector(
  [activeUrlID],
  (activeUrlID) => activeUrlID,
);

export const selectResourceTypeFilter = createSelector(
  [resourceTypeFilter],
  (resourceTypeFilter) => resourceTypeFilter,
);

export const selectDisabledResourceTypes = createSelector(
  [disabledResourceTypes],
  (disabledResourceTypes) => disabledResourceTypes,
);

export const selectActiveUrlData = createSelector(
  [urls, activeUrlID],
  (urls, activeUrlID) => urls.find(({ url_id }) => url_id === activeUrlID),
);

export const selectUrlsSortOrder = createSelector(
  [urlsSortOrder],
  (urlsSortOrder) => urlsSortOrder,
);

export const selectUrlsSortKey = createSelector(
  [urlsSortKey],
  (urlsSortKey) => urlsSortKey,
);

export const selectUrlSearch = createSelector(
  [urlSearch],
  (urlSearch) => urlSearch,
);

export const selectNetworkDetailsSortOrder = createSelector(
  [networkDetailsSortOrder],
  (networkDetailsSortOrder) => networkDetailsSortOrder,
);

export const selectNetworkDetailsSortKey = createSelector(
  [networkDetailsSortKey],
  (networkDetailsSortKey) => networkDetailsSortKey,
);

export const selectUI = createSelector(
  [UI],
  (UI) => UI,
);

import { takeLatest, takeEvery, all, call, put, select } from 'redux-saga/effects';

import { AccountsActionTypes } from './types'
import { Action } from '../commonTypes';
import { EmptyObject } from '../../types/common.types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { accountsActions } from './actions';
import { AdminService } from '../../services/AdminService';
import { IAdminLicenseRequestBody, IAdminLicenseResponseBody } from '../../services/AdminService/types';
import {
  selectAccountByID,
  selectAccountsSearch,
  selectAccountsSortKey,
  selectAccountsSortOrder,
  selectAccountCreatorEmailByID,
  selectUI,
} from './selectors';
import { selectUI as selectProfileUI } from '../profile-settings/selectors';
import { ILicense } from '../../services/LicensesService/types';
import { selectActiveLicenseID, selectActiveLicenseUsersEmails } from '../licenses/selectors';
import { selectAccountLicenseID, selectAccountLicense } from '../account-details/selectors';
import { accountDetailsActions } from '../account-details/actions';
import { licensesActions } from '../licenses/actions';

function* accountsReload(action: Action<AccountsActionTypes.ACCOUNTS_RELOAD, EmptyObject>): any {
  yield put(accountsActions.uiMerge({ loading: true }));
  const accountsSearch = yield select(selectAccountsSearch);
  const sortKey = yield select(selectAccountsSortKey);
  const sortOrder = yield select(selectAccountsSortOrder);
  const { offset, limit } = yield select(selectUI);

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestBody: IAdminLicenseRequestBody = {
    filter: accountsSearch,
    offset,
    limit,
    sort: sortKey,
    sort_direction: sortOrder,
  }

  const result: IBaseResponse<IAdminLicenseResponseBody> = yield call(AdminService.getLicenses, requestBody, options);
  if (!result?.data) {
    yield put(accountsActions.uiMerge({ loading: false }));
    return;
  }

  const response: IAdminLicenseResponseBody = result?.data;
  const licensesList = response.licenses;

  yield put(accountsActions.accountsRefresh(licensesList));
  yield put(accountsActions.uiMerge({ loading: false, count: response.count }));
}

function* accountsDelete(action: Action<AccountsActionTypes.ACCOUNT_DELETE, string>): any {
  yield put(accountsActions.uiMerge({ loading: true }));
  const accountID = action.payload;

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<IAdminLicenseResponseBody> = yield call(AdminService.deleteLicense, accountID, options);
  if (!result) {
    yield put(accountsActions.uiMerge({ loading: false }));
    return;
  }

  const deletedAccountEmail = yield select(selectAccountCreatorEmailByID(accountID));
  const activeAccountUsersEmails = yield select(selectActiveLicenseUsersEmails);

  if (activeAccountUsersEmails?.includes(deletedAccountEmail)) {
    yield put(licensesActions.licensesReload());
  }

  yield put(accountsActions.accountsReload());
}

function* accountResourceUpdate(action: Action<AccountsActionTypes.ACCOUNTS_RESOURCES_UPDATE, Partial<ILicense>>): any {
  yield put(accountsActions.uiMerge({ loading: true }));
  const activeLicenseID = yield select(selectActiveLicenseID);
  const { editingLicenseID } = yield select(selectProfileUI);
  const accountLicenseID = yield select(selectAccountLicenseID);
  const detailsAccount = yield select(selectAccountLicense);
  const editingAccount = yield select(selectAccountByID(editingLicenseID || ''));

  const account: ILicense = accountLicenseID ? detailsAccount : editingAccount;

  if (!account) {
    yield put(accountsActions.uiMerge({ loading: false }));
    return;
  }

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestPayload = {
    ...action.payload,
    creator_id: account.creator,
  };

  const result: IBaseResponse<IAdminLicenseResponseBody> = yield call(AdminService.updateLicense, requestPayload, editingLicenseID || accountLicenseID, options);
  if (!result) {
    yield put(accountsActions.uiMerge({ loading: false }));
    return;
  }

  yield put(accountsActions.accountsReload());
  if (activeLicenseID === account?.license_id) {
    yield put(licensesActions.licensesReload());
  }
  if (accountLicenseID) {
    yield put(accountDetailsActions.licenseReload());
  }
}

function* accountCreate(action: Action<AccountsActionTypes.ACCOUNT_CREATE, Partial<ILicense>>): any {
  yield put(accountsActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestPayload = {
    ...action.payload,
  };

  const result: IBaseResponse<IAdminLicenseResponseBody> = yield call(AdminService.createLicense, requestPayload, options);
  if (!result) {
    yield put(accountsActions.uiMerge({ loading: false }));
    return;
  }

  yield put(accountsActions.accountsReload());
}

export default function* accountsSaga() {
  yield all([
    takeLatest(AccountsActionTypes.ACCOUNTS_RELOAD, accountsReload),
    takeEvery(AccountsActionTypes.ACCOUNTS_RESOURCES_UPDATE, accountResourceUpdate),
    takeEvery(AccountsActionTypes.ACCOUNT_CREATE, accountCreate),
    takeEvery(AccountsActionTypes.ACCOUNT_DELETE, accountsDelete),
  ]);
}

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding-top: 36px;
  padding-left: 1%;

  &.hidden {
    display: none;
  }

  .ant-breadcrumb {
    > span:first-child {
      cursor: pointer;
    }
  }
`;

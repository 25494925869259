import React, { useEffect } from "react";
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { FieldLabelKeys } from '../../../enums/locales/forms';

import { Field } from '../Field';
import { Checkbox as StyledCheckbox } from '../../styled/Checkbox';
import { IProps as IStyledProps } from '../../styled/Checkbox/Checkbox';

export interface IPartialStyledProps extends Pick<IStyledProps, 'small'> {}
 
export interface IProps extends FieldProps, IPartialStyledProps {
  className?: string;
  labelKey?: FieldLabelKeys;
  noBottomMargin?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  field,
  form,
  className = '',
  labelKey,
  noBottomMargin,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { name } = field;
	const { touched, isSubmitting, setFieldTouched } = form;

  const splitedName = name.split('.')[0];
  const isTouched = Boolean(touched[splitedName]);

  useEffect(() => {
    if (isSubmitting && !isTouched) {
      setFieldTouched(splitedName, true);
    }
  }, [isSubmitting, isTouched, splitedName, setFieldTouched]);

  const label = labelKey ? t(labelKey) : '';

  return (
    <Field
      className={className}
      noBottomMargin={noBottomMargin}
    >
      <StyledCheckbox
        {...field}
        {...restProps}
        label={label}
      />
    </Field>
  );
};

export default Checkbox;

import { ProjectsOverviewActions } from './actions';
import {
  ProjectsOverviewActionTypes,
  ProjectsOverviewState,
  IProjectsOverviewSortRefreshPayload,
  ProjectsOverviewDataPayload,
} from './types';
import { SortOrder } from '../../enums/data';
import { ProjectsOverviewDataKeys } from '../../enums/projects';

const initState: ProjectsOverviewState = {
  sort: {
    dataKey: ProjectsOverviewDataKeys.LatestCrawl,
    order: SortOrder.Desc,
  },
  data: [],
};

export function projectsOverviewReducer(state: ProjectsOverviewState = initState, action: ProjectsOverviewActions): ProjectsOverviewState {
  switch (action.type) {
    case ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_SORT_REFRESH: {
      return {
        ...state,
        sort: {
          ...action.payload as IProjectsOverviewSortRefreshPayload,
        },
      };
    }
    case ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_DATA_REFRESH: {
      return {
        ...state,
        data: [
          ...action.payload as ProjectsOverviewDataPayload,
        ],
      };
    }
    case ProjectsOverviewActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

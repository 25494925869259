export enum MetricsKeys {
  URLGroup = 'metrics_url_group',
  YourURLs = 'metrics_your_urls',
  LiveCheck = 'metrics_live_check',
  ShowLCP = 'metrics_show_lcp',
  ShowDetails = 'metrics_show_details',
  CurrentKPI = 'metrics_current_kpi',
  Export = 'metrics_export',
  HistoricalDevelopment = 'metrics_historical_development',
  CorrelationVsRankings = 'metrics_correlation_vs_rankings',
  DetailsTitle = 'metrics_details_title',
  FilterUrls = 'metrics_filter_urls',
}
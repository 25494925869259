import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";

import { ButtonTextKeys, UrlGroupKeys } from "../../../enums/locales/forms";
import { IUrlGroup } from "../../../redux/project-settings/types";

import { Button, ButtonType } from "../Button";
import { IconDelete } from "../icons";
import { Input } from "../Input";
import { EditableTable } from "./EditableTable";

import { Wrapper } from './GroupList.styled';

export enum GroupType {
  UrlGroups = 'url-groups',
}

export interface IProps {
  listType?: GroupType;
  value?: IUrlGroup[];
  error?: boolean;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
}

const GroupList: React.FC<IProps> = ({
  value = [],
  error,
  onChange = () => { },
  onBlur = () => { },
}) => {
  const { t } = useTranslation();
  const [newItem, setNewItem] = useState<IUrlGroup>({ name: '', regex: '' });
  const [checked, setChecked] = useState<string[]>([]);
  const [oldValue, setOldValue] = useState(value);

  useEffect(() => {
    if (!isEqual(oldValue, value)) {
      onBlur();
      setOldValue(value);
    }
  }, [value, onBlur, oldValue]);

  const onNewItemChange = useCallback((e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  }, [newItem, setNewItem]);

  const onCheck = useCallback((newChecked) => {
    setChecked(newChecked);
  }, [setChecked, checked]);

  const onAdd = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onChange([...value, newItem]);
    setNewItem({ name: '', regex: '' });
  }, [onChange, value, setNewItem, newItem]);

  const onDataChange = useCallback((newData) => {
    onChange(newData);
  }, [onChange]);

  const onDelete = useCallback(() => {
    onChange(value.filter((item) => !checked.includes(item.regex)));
    setChecked([]);
  }, [setChecked, onChange, checked, value]);

  const isDisabled = useMemo(() => !newItem.regex || !newItem.name || !!value.find(({ regex, name }) => regex === newItem.regex || name === newItem.name), [value, newItem]);

  const showTable = !!value.length;
  const showDeleteBtn = !!checked.length;

  return (
    <Wrapper error={error}>
      <div className="data-input">
        <div className="label">{t(UrlGroupKeys.UrlRegex)}</div>
        <div className="input">
          <Input
            name="regex"
            value={newItem.regex}
            onChange={onNewItemChange}
            placeholder={t(UrlGroupKeys.RegexPlaceholder)}
          />
        </div>
      </div>
      <div className="data-input">
        <div className="label">{t(UrlGroupKeys.Name)}</div>
        <div className="input">
          <Input
            name="name"
            value={newItem.name}
            onChange={onNewItemChange}
            placeholder={`${t(UrlGroupKeys.NamePlaceholder)}...`}
          />
        </div>
      </div>
      <Button
        type={ButtonType.Primary}
        onClick={onAdd}
        disabled={isDisabled}
        title={t(ButtonTextKeys.Add)}
      />
      {showTable && (
        <div className="table-container">
          <EditableTable
            data={value}
            onDataChange={onDataChange}
            onCheck={onCheck}
            checked={checked}
          />
          {showDeleteBtn && <Button
            type={ButtonType.Secondary}
            onClick={onDelete}
            icon={<IconDelete />}
            title={t(ButtonTextKeys.DeleteSelected)}
          />}
        </div>
      )}
    </Wrapper>
  );
};

export default GroupList;

import styled from "styled-components";
import { hexToRGBA } from "../../../utils/theme";

import { IProps } from "./GoalsList";

export const Wrapper = styled.div<Pick<IProps, 'error'>>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  border: 2px solid ${({ theme }) => theme.colors.grey};
  border-radius: 18px;
  padding: 20px;

  .data-input {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 16px;
    }

    & > div {
      height: 42px;
      max-width: none;

      &:nth-child(1) {
        width: 40%!important;
      }

      &:nth-child(2) {
        width: 15%!important;
      }

      &:nth-child(3) {
        width: 25%!important;
      }
    }
  }

  button {
    max-width: none;
    width: 20%;
    padding: 0;
    height: 40px;
    text-transform: uppercase;

    .content .title-wrapper .title {
      font-size: 13px!important;
    }
  }

  .goals-list {
    margin-top: 30px;
    .goals-list-title {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primaryDark};
      font-weight: bold;
      margin-bottom: 28px;
    }
    .goals-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .feature {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #24242A;
        
        svg {
          margin-top: -2px;
          margin-right: 18px;
          cursor: pointer;
        }
      }

      .notify {
        .notify-switch {
          font-size: 12px;
          font-weight: 400;
          color: ${hexToRGBA('#24242A', 0.5)};
          &.checked {
            font-weight: 600;
            color: #24242A;
          }
        }
      }
    }
  }
`;
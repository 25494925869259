import { ProjectsActions } from './actions';
import {
  ProjectsActionTypes,
  ProjectsState,
  ProjectsListPayload,
  LatestCrawlPayload,
  ActiveProjectIDPayload,
  ActiveProjectGroupIDPayload,
  IUIPayload,
  CrawlsPayload,
  ICrawlsInProgressPayload,
} from './types';

const initState: ProjectsState = {
  activeProjectID: null,
  activeProjectGroupID: null,
  latestCrawl: null,
  crawls: [],
  list: [],
  crawlsInProgress: {},
  UI: {
    loading: false,
    editingAnnotationDate: null,
    crawlsChecking: false,
    projectsMenuOpen: false,
    testMode: false,
    restoringProject: true,
    restoringGroup: true,
  },
};

export function projectsReducer(state: ProjectsState = initState, action: ProjectsActions): ProjectsState {
  switch (action.type) {
    case ProjectsActionTypes.LIST_REFRESH: {
      return {
        ...state,
        list: [...action.payload as ProjectsListPayload],
      };
    }
    case ProjectsActionTypes.LATEST_CRAWL_REFRESH: {
      return {
        ...state,
        latestCrawl: action.payload as LatestCrawlPayload,
      };
    }
    case ProjectsActionTypes.CRAWLS_REFRESH: {
      return {
        ...state,
        crawls: action.payload as CrawlsPayload,
      };
    }
    case ProjectsActionTypes.ACTIVE_PROJECT_ID_REFRESH: {
      return {
        ...state,
        activeProjectID: action.payload as ActiveProjectIDPayload,
      };
    }
    case ProjectsActionTypes.ACTIVE_PROJECT_ID_RESET: {
      return {
        ...state,
        activeProjectID: null,
      };
    }
    case ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_REFRESH: {
      return {
        ...state,
        activeProjectGroupID: action.payload as ActiveProjectGroupIDPayload,
      };
    }
    case ProjectsActionTypes.ACTIVE_PROJECT_GROUP_ID_RESET: {
      return {
        ...state,
        activeProjectGroupID: null,
      };
    }
    case ProjectsActionTypes.ACTIVE_CRAWLS_CHECK_STOP: {
      return {
        ...state,
        UI: {
          ...state.UI,
          crawlsChecking: false,
        },
      };
    }
    case ProjectsActionTypes.CRAWLS_IN_PROGRESS_MERGE: {
      return {
        ...state,
        crawlsInProgress: {
          ...state.crawlsInProgress,
          ...action.payload as ICrawlsInProgressPayload,
        },
      };
    }
    case ProjectsActionTypes.CRAWLS_IN_PROGRESS_RESET: {
      return {
        ...state,
        crawlsInProgress: {},
      };
    }
    case ProjectsActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case ProjectsActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

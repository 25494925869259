import { ROWS_COUNTS } from '../../enums/projectSettings';
import { ProjectSettingsActions } from './actions';
import {
  IKeyword,
  IProjectSettingsData,
  ProjectSettingsActionTypes,
  ProjectSettingsState,
  IUIPayload,
} from './types';

const initState: ProjectSettingsState = {
  data: {
    domain: '',
    name: '',
    country_code: '',
    strategy: '',
    recrawl_interval: null,
    keywords: [],
    benchmarks: [],
    url_groups: [],
    invites: [],
    urls: [],
    goals: [],
  },
  projectID: null,
  benchmarksSuggestions: [],
  keywordsSuggestions: [],
  finishedSteps: [],
  UI: {
    keywordsCount: null,
    keywordsOffset: 0,
    keywordsLimit: ROWS_COUNTS.TwentyFive,
    keywordsLoading: false,
    isNew: true,
    isCrawled: false,
    isCrawlNow: true,
    isTest: false,
    loading: false,
    keywordsInitCount: 0,
    urlsInitCount: 0,
    initRecrawlInterval: null,
  },
};

export function projectSettingsReducer(state: ProjectSettingsState = initState, action: ProjectSettingsActions): ProjectSettingsState {
  switch (action.type) {
    case ProjectSettingsActionTypes.DATA_REFRESH: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload as IProjectSettingsData,
        },
      }
    }
    case ProjectSettingsActionTypes.PROJECT_ID_SET: {
      return {
        ...state,
        projectID: action.payload as string,
      }
    }
    case ProjectSettingsActionTypes.PROJECT_ID_RESET: {
      return {
        ...state,
        projectID: null,
      }
    }
    case ProjectSettingsActionTypes.BENCHMARKS_SUGGESTIONS_REFRESH: {
      return {
        ...state,
        benchmarksSuggestions: action.payload as string[],
      }
    }
    case ProjectSettingsActionTypes.KEYWORDS_SUGGESTIONS_REFRESH: {
      return {
        ...state,
        keywordsSuggestions: action.payload as IKeyword[],
      }
    }
    case ProjectSettingsActionTypes.FINISHED_STEPS_REFRESH: {
      return {
        ...state,
        finishedSteps: action.payload as number[],
      }
    }
    case ProjectSettingsActionTypes.RESET: {
      return initState;
    }
    case ProjectSettingsActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getAccountsColumns } from './assets';

import { accountDetailsActions } from '../../../../redux/account-details/actions';
import { selectAccounts, selectAccountLicenseID, selectTargetLicenseID, selectUI } from '../../../../redux/account-details/selectors';

import { Table } from '../../../../components/Table';
import { StyledWrapper as Wrapper } from './AccountsTable.styled';

const AccountsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accounts = useSelector(selectAccounts);
  const accountLicenseID = useSelector(selectAccountLicenseID);
  const targetLicenseID = useSelector(selectTargetLicenseID);
  const { accountsLoading } = useSelector(selectUI);

  const onAccountSelect = useCallback((licenseID: string) => () => {
    dispatch(accountDetailsActions.targetLicenseIDRefresh(licenseID));
  }, [dispatch]);

  const columns = useMemo(() => getAccountsColumns(t, onAccountSelect), [t, onAccountSelect]);
  const preparedAccounts = useMemo(() => accounts.map(account => {
    const classes = clsx({
      current: account.license_id === accountLicenseID,
      selected: account.license_id === targetLicenseID,
    });

    return {
      ...account,
      classes,
    };
  }), [accounts, accountLicenseID, targetLicenseID]);

  return (
    <Wrapper>
      <Table
        columns={columns}
        loading={accountsLoading}
        data={preparedAccounts}
      />
    </Wrapper>
  );
};

export default AccountsTable;

import { IDomainHistory } from '../../services/DataService/types';
import { DomainHistoryInterval, Feature } from '../../enums/data';

export enum HistoryActionTypes {
  DOMAIN_HISTORY_RELOAD = '[History] domain-history-reload',
  DOMAIN_HISTORY_REFRESH = '[History] domain-history-refresh',

  CURRENT_HISTORY_INTERVAL_REFRESH = '[History] current-history-interval-refresh',
  CURRENT_FEATURE_REFRESH = '[History] current-feature-refresh',

  UI_MERGE = '[History] ui-merge',

  RESET_ALL = '[History] reset-all',
}

export type HistoryPayload = {
  [key in Feature]?: IDomainHistory;
}

export interface IUIPayload {
  showAnnotations?: boolean;
  loading?: boolean;
}

export interface HistoryState {
  currentFeature: Feature;
  currentHistoryInterval: DomainHistoryInterval;
  data: HistoryPayload;
  UI: IUIPayload;
}

import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 60px;

  .fields-group-title {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    padding-top: 60px;
    padding-bottom: 6px;
    color: ${({ theme }) => theme.colors.lightBlack};

    .suffix {
      margin-left: 10px;
      color: ${({ theme }) => theme.colors.darkGrey};
      font-size: 14px;
      font-weight: 400;
    }
  }

  button {
    margin-top: 30px;
    max-width: 150px;
  }

  .success-indicator {
    display: none!important;
  }

  .label {
    margin-bottom: 0;
    margin-top: 16px;
    .label {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 10px;
      margin-top: 0;
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  input {
    caret-color: ${({ theme }) => theme.colors.secondaryDark};
  }
  
  .control > div {
    height: 42px;
    max-width: 430px;
  }
  
`;
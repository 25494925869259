import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonType } from "../../../../components/styled/Button";
import { StackedIndicator } from '../../../../components/StackedIndicator';
import { IconEdit } from '../../../../components/styled/icons';
import { THEME } from '../../../../constants/theme';
import { getResourceUtilizationColor } from '../../../../utils/resources';
import { AccountDetailsKeys } from '../../../../enums/locales/accounts';
import { SiderHeading, SiderSteps } from '../../../../enums/locales/modals';
import { ProjectsOverviewFieldsKeys, ProjectsOverviewKeys } from '../../../../enums/locales/projects';
import { selectAccountByID } from '../../../../redux/accounts/selectors';
import { selectUI } from '../../../../redux/profile-settings/selectors';

import { Wrapper } from './ResourceCard.styled';
import { modalsActions } from '../../../../redux/modals/actions';

const ResourceCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, editingLicenseID } = useSelector(selectUI);
  const account = useSelector(selectAccountByID(editingLicenseID || ''));

  const onAccountEdit = useCallback(() => {
    dispatch(modalsActions.limitShow());
  }, [dispatch]);

  return (
    <Wrapper>
      <div className="title">{t(SiderHeading.AccountResources)}:</div>
      <div className="count-indicator">
        <StackedIndicator
          title={t(ProjectsOverviewKeys.UsersCountIndicatorTitle)}
          value={account?.user_slots_used || 0}
          maxValue={account?.user_slots}
          color={getResourceUtilizationColor(account?.user_slots_used || 0, account?.user_slots || 0)}
          reset={loading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={t(ProjectsOverviewKeys.ProjectsCountIndicatorTitle)}
          value={account?.project_slots_used || 0}
          maxValue={account?.project_slots}
          color={getResourceUtilizationColor(account?.project_slots_used || 0, account?.project_slots || 0)}
          reset={loading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={`${t(ProjectsOverviewFieldsKeys.Keywords)}:`}
          value={account?.keyword_slots_used || 0}
          maxValue={account?.keyword_slots}
          color={getResourceUtilizationColor(account?.keyword_slots_used || 0, account?.keyword_slots || 0)}
          reset={loading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={`${t(SiderSteps.Urls)}:`}
          value={account?.url_slots_used || 0}
          maxValue={account?.url_slots}
          color={getResourceUtilizationColor(account?.url_slots_used || 0, account?.url_slots || 0)}
          reset={loading}
        />
      </div>
      <div className="edit-btn-container">
        <Button
          rounded
          small
          type={ButtonType.Outline}
          icon={<IconEdit />}
          title={t(AccountDetailsKeys.EditAccountButton)}
          onClick={onAccountEdit}
        />
      </div>
    </Wrapper>
  )
}

export default ResourceCard;


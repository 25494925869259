import { OverviewActions } from './actions';
import { OverviewActionTypes, OverviewState, OverviewPayload, IUIPayload } from './types';

const initState: OverviewState = {
  data: [],
  UI: {
    loading: false,
  },
};

export function overviewReducer(state: OverviewState = initState, action: OverviewActions): OverviewState {
  switch (action.type) {
    case OverviewActionTypes.FEATURE_OVERVIEW_REFRESH: {
      return {
        ...state,
        data: [...action.payload as OverviewPayload],
      };
    }
    case OverviewActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case OverviewActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

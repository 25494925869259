import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

export class FingerprintService {
  static async getFingerprint(): Promise<GetResult | null> {
    try {
      const agent = await FingerprintJS.load();
      const fingerprint = await agent.get();
      return fingerprint;
    } catch(e) {
      return null;
    }
  }
}

import { createSelector } from 'reselect';
import { format, parseISO } from 'date-fns';
import { RootState } from '../store';
import { CRAWL_DATE_FORMAT } from '../../constants/formats';
import { Feature } from '../../enums/data';

const activeProjectID = (state: RootState) => state.Projects.activeProjectID;
const activeProjectGroupID = (state: RootState) => state.Projects.activeProjectGroupID;
const list = (state: RootState) => state.Projects.list;
const latestCrawl = (state: RootState) => state.Projects.latestCrawl;
const crawls = (state: RootState) => state.Projects.crawls;
const UI = (state: RootState) => state.Projects.UI;
const crawlsInProgress = (state: RootState) => state.Projects.crawlsInProgress;

export const selectActiveProjectID = createSelector(
	[activeProjectID],
	(activeProjectID) => activeProjectID,
);

export const selectActiveProjectGroupID = createSelector(
	[activeProjectGroupID],
	(activeProjectGroupID) => activeProjectGroupID,
);

export const selectProjects = createSelector(
	[list],
	(list) => list,
);

export const selectLatestCrawl = createSelector(
	[latestCrawl],
	(latestCrawl) => latestCrawl,
);

export const selectLatestCrawlID = createSelector(
	[latestCrawl],
	(latestCrawl) => latestCrawl?.crawl_id,
);

export const selectLatestCrawlCreated = createSelector(
	[latestCrawl],
	(latestCrawl) => latestCrawl?.created_at ? format(parseISO(latestCrawl?.created_at), 'dd.MM.yyyy') : '',
);

export const selectCrawlsDomains = createSelector(
	[list, crawls, activeProjectGroupID],
	(list, crawls, activeProjectGroupID) => crawls.map(({ project_id, crawl_id, created_at }) => {
		const project = list.find(({ project_id: projectID }) => project_id === projectID);
		const group = project?.url_groups?.find(({ url_group_id }) => url_group_id === activeProjectGroupID)

		return ({ id: crawl_id, domain: group?.name || project?.domain || '', date: format(parseISO(created_at), CRAWL_DATE_FORMAT) });
	}),
);

export const selectProjectByID = (id: string | null) => createSelector(
	[list],
	(list) => list.find(({ project_id }) => project_id === id),
);

export const selectActiveProjectAnnotations = createSelector(
	[list, activeProjectID],
	(list, activeProjectID) => list.find(({ project_id }) => project_id === activeProjectID)?.annotations,
);

export const selectProjectByGroupID = (id: string | null) => createSelector(
	[list],
	(list) => list.find(({ url_groups }) => url_groups?.some(({ url_group_id }) => url_group_id === id)),
);

export const selectActiveProjectTestMode = createSelector(
	[list, activeProjectID],
	(list, activeProjectID) => Boolean(list.find(({ project_id }) => project_id === activeProjectID)?.is_test_project),
);

export const selectActiveProjectDomain = createSelector(
	[list, activeProjectID],
	(list, activeProjectID) => list.find(({ project_id }) => project_id === activeProjectID)?.domain || '',
);

export const selectActiveProjectBenchmarks = createSelector(
	[list, activeProjectID],
	(list, activeProjectID) => list.find(({ project_id }) => project_id === activeProjectID)?.benchmarks || [],
);

export const selectActiveProjectGroups = createSelector(
	[list, activeProjectID],
	(list, activeProjectID) => list.find(({ project_id }) => project_id === activeProjectID)?.url_groups || [],
);

export const selectActiveProjectGoalByFeature = (feature: Feature) => createSelector(
	[list, activeProjectID],
	(list, activeProjectID) => list.find(({ project_id }) => project_id === activeProjectID)?.goals?.find(({ name }) => name === feature) || null,
);

export const selectActiveGroup = createSelector(
	[list, activeProjectID, activeProjectGroupID],
	(list, activeProjectID, activeProjectGroupID) => list.find(({ project_id }) => project_id === activeProjectID)?.url_groups?.find(({ url_group_id }) => url_group_id === activeProjectGroupID),
);

export const selectGroupByID = (groupID: string) => createSelector(
	[list, activeProjectID],
	(list, activeProjectID) => list.find(({ project_id }) => project_id === activeProjectID)?.url_groups?.find(({ url_group_id }) => url_group_id === groupID),
);

export const selectCrawlsInProgress = createSelector(
	[crawlsInProgress],
	(crawlsInProgress) => crawlsInProgress,
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

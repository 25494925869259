import { ActionUnion, createAction } from '../commonTypes';
import { AppActionTypes, DeviceIdentifierPayload, LanguagePayload, IUIPayload } from './types';
import { EmptyObject } from '../../types/common.types';

export const appActions = {
  appStart: (): { type: AppActionTypes, payload: EmptyObject } => createAction(
    AppActionTypes.APP_START,
  ),
  appStarted: (): { type: AppActionTypes, payload: EmptyObject } => createAction(
    AppActionTypes.APP_STARTED,
  ),
  languageRefresh: (payload: LanguagePayload): { type: AppActionTypes, payload: LanguagePayload } => createAction(
    AppActionTypes.LANGUAGE_REFRESH,
    payload,
  ),
  deviceIdentifierUpdate: (): { type: AppActionTypes, payload: EmptyObject } => createAction(
    AppActionTypes.DEVICE_IDENTIFIER_UPDATE,
  ),
  deviceIdentifierRefresh: (payload: DeviceIdentifierPayload): { type: AppActionTypes, payload: DeviceIdentifierPayload } => createAction(
    AppActionTypes.DEVICE_IDENTIFIER_REFRESH,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: AppActionTypes, payload: IUIPayload } => createAction(
    AppActionTypes.UI_MERGE,
    payload,
  ),
}

export type AppActions = ActionUnion<typeof appActions>;

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  margin-top: 20px;

  user-select: none;
  
  .show-rows-select {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;

    .ant-select {
      height: 30px!important;
      width: 70px!important;
      /* margin-left: 20px; */

      .ant-select-selector {
        height: 30px!important;
        padding-left: 12px!important;
      }

      .ant-select-selection-item {
        padding-right: 9px!important;
        line-height: 27px!important;
        font-weight: 600!important;
      }
    }
  }

  .pages-switcher {
    display: flex;
    align-items: center;

    .page-block {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
      height: 30px;
      background: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.grey};
      border-radius: 10px;
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondaryDark};
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.current {
        color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.primaryBlue};
        background: ${({ theme }) => theme.colors.primaryBlue};
      }
    }

    .first-page-block,
    .last-page-block {
      cursor: pointer;
      font-size: 14px;

      &.disabled {
        pointer-events: none;
        color: #171C2833;
      }
    }

    .first-page-block {
      margin-right: 16px;
    }

    .last-page-block {
      margin-left: 8px;
    }
  }
`;
import { takeLatest, all, call, put, select } from 'redux-saga/effects';

import { AccountDetailsActionTypes } from './types'
import { Action } from '../commonTypes';
import { EmptyObject } from '../../types/common.types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { ILicenseEntryResponseBody } from '../../services/LicensesService/types';
import { ICrawlsResponseBody } from '../../services/ProjectService/types';
import {
  IAdminLicenseRequestBody,
  IAdminLicenseResponseBody,
  IAdminProjectsRequestBody,
  IAdminProjectMoveRequestBody,
  IAdminCrawlsRequestBody,
  IAdminProjectsResponseBody,
  ISingleUserResponseBody,
} from '../../services/AdminService/types';
import { AdminService } from '../../services/AdminService';
import { accountDetailsActions } from './actions';
import { modalsActions } from '../modals/actions';
import { projectsActions } from '../../redux/projects/actions';
import {
  selectAccountLicenseID,
  selectAccountLicenseCreatorID,
  selectProjectID,
  selectTargetLicenseID,
  selectAccountsSearch,
  selectProjectsSearch,
  selectProjectsSortKey,
  selectProjectsSortOrder,
  selectCrawlsSearch,
  selectCrawlsSortKey,
  selectCrawlsSortOrder,
  selectUI,
} from './selectors';
import { selectProjectByID } from '../../redux/projects/selectors';

function* accountsReload(action: Action<AccountDetailsActionTypes.ACCOUNTS_DATA_RELOAD, EmptyObject>): any {
  yield put(accountDetailsActions.uiMerge({ accountsLoading: true }));
  const accountsSearch = yield select(selectAccountsSearch);

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const requestBody: IAdminLicenseRequestBody = {
    filter: accountsSearch,
  }

  const result: IBaseResponse<IAdminLicenseResponseBody> = yield call(AdminService.getLicenses, requestBody, options);
  if (!result?.data) {
    yield put(accountDetailsActions.uiMerge({ accountsLoading: false }));
    return;
  }

  const response: IAdminLicenseResponseBody = result?.data;
  const licensesList = response.licenses;

  yield put(accountDetailsActions.accountsRefresh(licensesList));
  yield put(accountDetailsActions.uiMerge({ accountsLoading: false }));
}

function* licenseReload(action: Action<AccountDetailsActionTypes.LICENSE_DATA_RELOAD, EmptyObject>): any {
  yield put(accountDetailsActions.uiMerge({ licenseLoading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const licenseID = yield select(selectAccountLicenseID);
  if (!licenseID) {
    return;
  }

  const result: IBaseResponse<ILicenseEntryResponseBody> = yield call(AdminService.getLicense, licenseID, options);
  if (!result?.data) {
    yield put(accountDetailsActions.uiMerge({ licenseLoading: false }));
    return;
  }

  const response: ILicenseEntryResponseBody = result?.data;
  const { license } = response;

  yield put(accountDetailsActions.licenseRefresh(license));
  yield put(accountDetailsActions.uiMerge({ licenseLoading: false }));
}

function* creatorReload(action: Action<AccountDetailsActionTypes.CREATOR_DATA_RELOAD, EmptyObject>): any {
  yield put(accountDetailsActions.uiMerge({ creatorLoading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const creatorID = yield select(selectAccountLicenseCreatorID);
  if (!creatorID) {
    return;
  }

  const result: IBaseResponse<ISingleUserResponseBody> = yield call(AdminService.getUser, creatorID, options);
  if (!result?.data) {
    yield put(accountDetailsActions.uiMerge({ creatorLoading: false }));
    return;
  }

  const response: ISingleUserResponseBody = result?.data;
  const { user } = response;

  yield put(accountDetailsActions.creatorRefresh(user));
  yield put(accountDetailsActions.uiMerge({ creatorLoading: false }));
}

function* projectsReload(action: Action<AccountDetailsActionTypes.PROJECTS_DATA_RELOAD, EmptyObject>): any {
  yield put(accountDetailsActions.uiMerge({ projectsLoading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const licenseID = yield select(selectAccountLicenseID);
  if (!licenseID) {
    return;
  }

  const projectsSearch = yield select(selectProjectsSearch);
  const sortKey = yield select(selectProjectsSortKey);
  const sortOrder = yield select(selectProjectsSortOrder);
  const { offset, limit } = yield select(selectUI);

  const requestBody: IAdminProjectsRequestBody = {
    filter: projectsSearch,
    offset,
    limit,
    sort: sortKey,
    sort_direction: sortOrder,
  }

  const result: IBaseResponse<IAdminProjectsResponseBody> = yield call(AdminService.getLicenseProjects, licenseID, requestBody, options);
  if (!result?.data) {
    yield put(accountDetailsActions.uiMerge({ projectsLoading: false }));
    return;
  }

  const response: IAdminProjectsResponseBody = result?.data;
  const { license: projects, count } = response;

  yield put(accountDetailsActions.projectsRefresh(projects));
  yield put(accountDetailsActions.uiMerge({ projectsLoading: false, count }));
}

function* crawlsReload(action: Action<AccountDetailsActionTypes.CRAWLS_DATA_RELOAD, EmptyObject>): any {
  yield put(accountDetailsActions.uiMerge({ crawlsLoading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const licenseID = yield select(selectAccountLicenseID);
  const projectID = yield select(selectProjectID);

  if (!licenseID || !projectID) {
    return;
  }

  const crawlsSearch = yield select(selectCrawlsSearch);
  const sortKey = yield select(selectCrawlsSortKey);
  const sortOrder = yield select(selectCrawlsSortOrder);

  const requestBody: IAdminCrawlsRequestBody = {
    filter: crawlsSearch,
    sort: sortKey,
    sort_direction: sortOrder,
  }

  const result: IBaseResponse<ICrawlsResponseBody> = yield call(AdminService.getLicenseProjectCrawls, projectID, licenseID, requestBody, options);
  if (!result?.data) {
    yield put(accountDetailsActions.uiMerge({ crawlsLoading: false }));
    return;
  }

  const response: ICrawlsResponseBody = result?.data;
  const { crawls } = response;

  yield put(accountDetailsActions.crawlsRefresh(crawls));
  yield put(accountDetailsActions.uiMerge({ crawlsLoading: false }));
}

function* projectMove(action: Action<AccountDetailsActionTypes.PROJECT_MOVE, EmptyObject>): any {
  yield put(accountDetailsActions.uiMerge({ projectMoving: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const licenseID = yield select(selectAccountLicenseID);
  const projectID = yield select(selectProjectID);
  const targetLicenseID = yield select(selectTargetLicenseID);

  if (!licenseID || !projectID || !targetLicenseID) {
    return;
  }

  const requestBody: IAdminProjectMoveRequestBody = {
    target_license_id: targetLicenseID,
  }

  const result: IBaseResponse<unknown> = yield call(AdminService.moveLicenseProject, requestBody, projectID, licenseID, options);
  if (!result) {
    yield put(accountDetailsActions.uiMerge({ projectMoving: false }));
    return;
  }

  yield put(modalsActions.projectMoveHide());
  yield put(accountDetailsActions.targetLicenseIDRefresh(null));
  yield put(accountDetailsActions.accountsRefresh([]));
  yield put(accountDetailsActions.crawlsRefresh([]));

  const projectInActiveLicense = yield select(selectProjectByID(projectID));
  if (!!projectInActiveLicense) {
    yield put(projectsActions.listReload());
  }

  yield put(accountDetailsActions.projectsReload());
  yield put(accountDetailsActions.licenseReload());
  yield put(accountDetailsActions.uiMerge({ projectMoving: false }));
}

function* projectRemove(action: Action<AccountDetailsActionTypes.PROJECT_REMOVE, string>): any {
  yield put(accountDetailsActions.uiMerge({ projectRemoving: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const projectID = action.payload;
  const licenseID = yield select(selectAccountLicenseID);
  if (!licenseID) {
    return;
  }

  const result: IBaseResponse<unknown> = yield call(AdminService.deleteLicenseProject, projectID, licenseID, options);
  if (!result) {
    yield put(accountDetailsActions.uiMerge({ projectRemoving: false }));
    return;
  }

  const projectInActiveLicense = yield select(selectProjectByID(projectID));
  if (!!projectInActiveLicense) {
    yield put(projectsActions.listReload());
  }

  yield put(accountDetailsActions.projectsReload());
  yield put(accountDetailsActions.licenseReload());
  yield put(modalsActions.projectMoveHide());
  yield put(modalsActions.projectCrawlsHide());
  yield put(accountDetailsActions.uiMerge({ projectRemoving: false }));
}

function* crawlRemove(action: Action<AccountDetailsActionTypes.CRAWL_REMOVE, string>): any {
  yield put(accountDetailsActions.uiMerge({ crawlRemoving: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const crawlID = action.payload;

  const licenseID = yield select(selectAccountLicenseID);
  const projectID = yield select(selectProjectID);

  if (!licenseID || !projectID) {
    return;
  }

  const result: IBaseResponse<unknown> = yield call(AdminService.deleteLicenseProjectCrawl, crawlID, projectID, licenseID, options);
  if (!result) {
    yield put(accountDetailsActions.uiMerge({ crawlRemoving: false }));
    return;
  }

  yield put(accountDetailsActions.crawlsReload());
  yield put(accountDetailsActions.uiMerge({ crawlRemoving: false }));
}

export default function* accountDetailsSaga() {
  yield all([
    takeLatest(AccountDetailsActionTypes.ACCOUNTS_DATA_RELOAD, accountsReload),
    takeLatest(AccountDetailsActionTypes.CREATOR_DATA_RELOAD, creatorReload),
    takeLatest(AccountDetailsActionTypes.LICENSE_DATA_RELOAD, licenseReload),
    takeLatest(AccountDetailsActionTypes.PROJECTS_DATA_RELOAD, projectsReload),
    takeLatest(AccountDetailsActionTypes.CRAWLS_DATA_RELOAD, crawlsReload),
    takeLatest(AccountDetailsActionTypes.PROJECT_MOVE, projectMove),
    takeLatest(AccountDetailsActionTypes.PROJECT_REMOVE, projectRemove),
    takeLatest(AccountDetailsActionTypes.CRAWL_REMOVE, crawlRemove),
  ]);
}

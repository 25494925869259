import { takeLatest, all, call, put, select } from 'redux-saga/effects';

import * as LocaleStorageUtils from '../../utils/storage';

import { LicensesActionTypes } from './types'
import { Action } from '../commonTypes';
import { EmptyObject } from '../../types/common.types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { ILicenseResponseBody } from '../../services/LicensesService/types';
import { licensesActions } from './actions';
import { LicensesService } from '../../services/LicensesService';
import { selectActiveLicenseID } from './selectors';
import { selectUser } from '../auth/selectors';

function* licensesReload(action: Action<LicensesActionTypes.LICENSES_RELOAD, EmptyObject>): any {
  yield put(licensesActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ILicenseResponseBody> = yield call(LicensesService.getLicenses, options);
  if (!result?.data) {
    yield put(licensesActions.uiMerge({ loading: false }));
    return;
  }

  const response: ILicenseResponseBody = result?.data;
  const licensesList = response.licenses;

  const activeLicenseID = yield select(selectActiveLicenseID);

  const isValidLicense = !!licensesList.find(({ license_id }) => activeLicenseID === license_id);

  yield put(licensesActions.licensesRefresh(response.licenses));

  if (!isValidLicense) {
    const user = yield select(selectUser);
    const currentActiveLicenses = yield call(LocaleStorageUtils.restoreActiveLicenses);
    const activeLicenses = currentActiveLicenses || {};
    const activeLicenseID = user ? activeLicenses[user.id] : licensesList[0]?.license_id || '';

    const isValid = !!licensesList.find(({ license_id }: any) => license_id === activeLicenseID);
    if (isValid) {
      yield put(licensesActions.activeLicenseIDRefresh(activeLicenseID));
    } else {
      yield put(licensesActions.activeLicenseIDRefresh(licensesList[0]?.license_id || ''));
    }
  }
  yield put(licensesActions.uiMerge({ loading: false }));
}

function* activeLicenseIDStore(): any {
  const licenseID = yield select(selectActiveLicenseID);
  const user = yield select(selectUser);

  if (!user || !licenseID) {
    return;
  }

  const currentActiveLicenses = yield call(LocaleStorageUtils.restoreActiveLicenses);
  const activeLicenses = currentActiveLicenses || {};

  yield call(LocaleStorageUtils.storeActiveLicenses, { ...activeLicenses, [user.id]: licenseID });
}

export default function* licensesSaga() {
  yield all([
    takeLatest(LicensesActionTypes.LICENSES_RELOAD, licensesReload),
    takeLatest(LicensesActionTypes.ACTIVE_LICENSE_ID_STORE, activeLicenseIDStore),
  ]);
}

export enum NavigationMenuKeys {
  Dashboard = 'side_bar_navigation_menu_dashboard_title',
  PagespeedMetrics = 'side_bar_navigation_menu_pagespeed_metrics_title',
  Leaderboards = 'side_bar_navigation_menu_leaderboards_title',
  PerformanceScore = 'side_bar_navigation_menu_performance_score_title',
  PerformanceScorePM = 'side_bar_navigation_menu_performance_score_pm_title',
  LargestContentfulPaint = 'side_bar_navigation_menu_largest_contentful_paint_title',
  LargestContentfulPaintPM = 'side_bar_navigation_menu_largest_contentful_paint_pm_title',
  FirstInputDelay = 'side_bar_navigation_menu_first_input_delay_title',
  FirstInputDelayPM = 'side_bar_navigation_menu_first_input_delay_pm_title',
  TotalBlockingTime = 'side_bar_navigation_menu_total_blocking_time_title',
  TotalBlockingTimePM = 'side_bar_navigation_menu_total_blocking_time_pm_title',
  TimeToInteractive = 'side_bar_navigation_menu_time_to_interactive_title',
  TimeToInteractivePM = 'side_bar_navigation_menu_time_to_interactive_pm_title',
  DOMSize = 'side_bar_navigation_menu_dom_size_title',
  DOMSizePM = 'side_bar_navigation_menu_dom_size_pm_title',
  ModernImages = 'side_bar_navigation_menu_modern_images_title',
  ModernImagesPM = 'side_bar_navigation_menu_modern_images_pm_title',
  ServerResponseTime = 'side_bar_navigation_menu_server_response_time_title',
  ServerResponseTimePM = 'side_bar_navigation_menu_server_response_time_pm_title',
  FirstContentfulPaint = 'side_bar_navigation_menu_first_contentful_paint_title',
  FirstContentfulPaintPM = 'side_bar_navigation_menu_first_contentful_paint_pm_title',
  FirstMeaningfulPaint = 'side_bar_navigation_menu_first_meaningful_paint_title',
  FirstMeaningfulPaintPM = 'side_bar_navigation_menu_first_meaningful_paint_pm_title',
  CumulativeLayoutShift = 'side_bar_navigation_menu_cumulative_layout_shift_title',
  CumulativeLayoutShiftPM = 'side_bar_navigation_menu_cumulative_layout_shift_pm_title',

  InteractionToNextPaint = 'side_bar_navigation_menu_experimental_interaction_to_next_paint_title',
  InteractionToNextPaintPM = 'side_bar_navigation_menu_experimental_interaction_to_next_paint_pm_title',
  ExperimentalTimeToFirstByte = 'side_bar_navigation_menu_experimental_time_to_first_byte_title',
  ExperimentalTimeToFirstBytePM = 'side_bar_navigation_menu_experimental_time_to_first_byte_pm_title',

  UnminifiedJSPM = 'side_bar_navigation_menu_unminified_js_pm_title',
  UnusedJSPM = 'side_bar_navigation_menu_unused_js_pm_title',
  UnusedCSSPM = 'side_bar_navigation_menu_unused_css_pm_title',
  UnminifiedCSSPM = 'side_bar_navigation_menu_unminified_css_pm_title',
  UnminifiedJS = 'side_bar_navigation_menu_unminified_js_title',
  UnusedJS = 'side_bar_navigation_menu_unused_js_title',
  UnusedCSS = 'side_bar_navigation_menu_unused_css_title',
  UnminifiedCSS = 'side_bar_navigation_menu_unminified_css_title',
  OptimizedImagesPM = 'side_bar_navigation_menu_optimized_images_pm_title',
  OptimizedImages = 'side_bar_navigation_menu_optimized_images_title',
  NumTasksOver50msPM = 'side_bar_navigation_menu_num_tasks_over_50_ms_pm_title',
  NumTasksOver100msPM = 'side_bar_navigation_menu_num_tasks_over_100_ms_pm_title',
  NumTasksOver500msPM = 'side_bar_navigation_menu_num_tasks_over_500_ms_pm_title',
  NumTasksOver50ms = 'side_bar_navigation_menu_num_tasks_over_50_ms_title',
  NumTasksOver100ms = 'side_bar_navigation_menu_num_tasks_over_100_ms_title',
  NumTasksOver500ms = 'side_bar_navigation_menu_num_tasks_over_500_ms_title',
  RenderBlockingResourcesPM = 'side_bar_navigation_menu_render_blocking_resources_pm_title',
  RenderBlockingResources = 'side_bar_navigation_menu_render_blocking_resources_title',
  SpeedIndexPM = 'side_bar_navigation_menu_speed_index_pm_title',
  SpeedIndex = 'side_bar_navigation_menu_speed_index_title',
  UsesTextCompressionPM = 'side_bar_navigation_menu_uses_text_compression_pm_title',
  UsesTextCompression = 'side_bar_navigation_menu_uses_text_compression_title',
  OffscreenImagesPM = 'side_bar_navigation_menu_offscreen_images_pm_title',
  OffscreenImages = 'side_bar_navigation_menu_offscreen_images_title',
  NumFontsPM = 'side_bar_navigation_menu_num_fonts_pm_title',
  NumFonts = 'side_bar_navigation_menu_num_fonts_title',
  NumRequestsPM = 'side_bar_navigation_menu_num_requests_pm_title',
  NumRequests = 'side_bar_navigation_menu_num_requests_title',
  NumTasksPM = 'side_bar_navigation_menu_num_tasks_pm_title',
  NumTasks = 'side_bar_navigation_menu_num_tasks_title',
  NumScriptsPM = 'side_bar_navigation_menu_num_scripts_pm_title',
  NumScripts = 'side_bar_navigation_menu_num_scripts_title',
  NumStylesheetsPM = 'side_bar_navigation_menu_num_stylesheets_pm_title',
  NumStylesheets = 'side_bar_navigation_menu_num_stylesheets_title',
  TotalTaskTimePM = 'side_bar_navigation_menu_total_task_time_pm_title',
  TotalTaskTime = 'side_bar_navigation_menu_total_task_time_title',
  TotalByteWeightPM = 'side_bar_navigation_menu_total_byte_weight_pm_title',
  TotalByteWeight = 'side_bar_navigation_menu_total_byte_weight_title',
  Summary = 'side_bar_navigation_menu_summary_title',
  CompoundAnalysis = 'side_bar_navigation_menu_compound_analysis_title',
  NetworkRequestsAnalysis = 'side_bar_navigation_menu_network_requests_analysis_title',
  AnomalyAnalysis = 'side_bar_navigation_menu_anomaly_analysis_title',
  CrossCorrelationMatrix = 'side_bar_navigation_menu_cross_correlation_matrix_title',
  DirectoryAnalysis = 'side_bar_navigation_menu_directory_analysis_title',
  BestWorstPractice = 'side_bar_navigation_menu_best_worst_practice_title',
  GlobalStatistics = 'side_bar_navigation_menu_global_statistics_title',
  CoreWebVitals = 'side_bar_navigation_menu_core_web_vitals_title',
  CountryLeaderboards = 'side_bar_navigation_menu_country_leaderboards_title',
  ThirdPartyScriptsAnalysis = 'side_bar_navigation_menu_third_party_scripts_analysis_title',
  Accounts = 'side_bar_navigation_menu_accounts_title',
  AccountDetails = 'side_bar_navigation_menu_account_details_title',
}

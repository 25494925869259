import { ResourceTypes } from '../enums/compoundAnalysis';
import { Feature } from '../enums/data';
import { DashboardKpiSummaryHeaderKeys } from '../enums/locales/dashboard';
import { StaticResourceTypes } from '../enums/networkDetails';

export const URL_FEATURE_FIELDS = {
  [DashboardKpiSummaryHeaderKeys.PerformanceScore]: {
    labDataKey: Feature.PerformanceScore,
  },
  [DashboardKpiSummaryHeaderKeys.LargestContentfulPaint]: {
    labDataKey: Feature.LargestContentfulPaintLab,
  },
  [DashboardKpiSummaryHeaderKeys.TotalBlockingTime]: {
    labDataKey: Feature.TotalBlockingTimeLab,
  },
  [DashboardKpiSummaryHeaderKeys.TimeToInteractive]: {
    labDataKey: Feature.TimeToInteractiveLab,
  },
};

export const RESOURCE_COLORS_BY_TYPE = {
  [StaticResourceTypes.All]: 'white',
  [ResourceTypes.Document]: '#5789FF',
  [ResourceTypes.Fetch]: '#4658a3',
  [ResourceTypes.Font]: '#a361e2',
  [ResourceTypes.Stylesheet]: '#ffce4a',
  [ResourceTypes.Script]: '#90cd5b',
  [ResourceTypes.Preflight]: '#8798ae',
  [ResourceTypes.Other]: '#cbcbcb',
  [ResourceTypes.Image]: '#f7954f',
  [ResourceTypes.Video]: '#ef614a',
  [ResourceTypes.XHR]: '#3db7e2',
}
import React, { useCallback } from 'react';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import keys from "lodash/keys";

import { Button, ButtonType } from "../../../components/styled/Button";

import * as FormikUtils from '../../../utils/formik';
import { profileSchema } from '../../../utils/validation/schemas';
import { profileSettingsSteps } from './assets';

import { Wrapper } from './ProfileForm.styled';
import { selectPersonal, selectUI as selectProfileUI } from '../../../redux/profile-settings/selectors';
import { profileSettingsActions } from '../../../redux/profile-settings/actions';
import { SiderButtomTextKeys } from '../../../enums/locales/modals';
import { selectUI } from '../../../redux/auth/selectors';

export const ProfileFormBag = FormikUtils.createFormBag();

export interface IProps {
  onSubmit: (values: FormikValues) => void;
}

const ProfileForm: React.FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectPersonal);
  const { personalLoading } = useSelector(selectProfileUI);
  const { loading } = useSelector(selectUI);

  const onBlur = useCallback((e) => {
    dispatch(profileSettingsActions.personalRefresh({ [e.target.name]: e.target.value }));
  }, [ProfileFormBag.touched, ProfileFormBag.values, ProfileFormBag.errors]);

  const isDisabled = keys(ProfileFormBag.errors).length !== 0
    || personalLoading
    || loading;

  return (
    <Wrapper>
      <Formik
        initialValues={data}
        validationSchema={profileSchema}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          FormikUtils.fillFormBag(ProfileFormBag, formikProps);

          return (
            <Form>
              {profileSettingsSteps.map(({ title, suffix = '', fields, id, className }) =>
                <React.Fragment key={id}>
                  <div id={id} className="fields-group-title">
                    <span>{t(title)}</span>
                    {suffix && <span className="suffix">({t(suffix)})</span>}
                  </div>
                  <div className={className}>
                    {fields.map(fieldConfig => {
                      const { name } = fieldConfig;
                      const dynamicFieldConfig: Record<string, unknown> = { ...fieldConfig, onBlur: onBlur };

                      return (
                        <Field name={name} key={name}>
                          {(fieldProps: FieldProps) => FormikUtils.createField(fieldProps, dynamicFieldConfig)}
                        </Field>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
              <Button
                type={ButtonType.Primary}
                disabled={isDisabled}
                icon={personalLoading ? <Spin size="small" /> : null}
                title={t(SiderButtomTextKeys.Save)}
              />
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
}

export default ProfileForm;
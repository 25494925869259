import { ActionUnion, createAction } from '../commonTypes';
import { ModalsActionTypes, IRequestResultModalPayload } from './types';
import { EmptyObject } from '../../types/common.types';
import { LoaderModalMessageKeys } from '../../enums/locales/modals';

export const modalsActions = {
  loaderShow: (payload?: LoaderModalMessageKeys): { type: ModalsActionTypes, payload?: LoaderModalMessageKeys } => createAction(
    ModalsActionTypes.LOADER_SHOW,
    payload,
  ),
  loaderHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.LOADER_HIDE,
  ),
  onboardShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ONBOARD_SHOW,
  ),
  onboardHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ONBOARD_HIDE,
  ),
  siderShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.SIDER_SHOW,
  ),
  siderHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.SIDER_HIDE,
  ),
  adminSiderShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ADMIN_SIDER_SHOW,
  ),
  adminSiderHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ADMIN_SIDER_HIDE,
  ),
  profileSettingsShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.PROFILE_SETTINGS_SHOW,
  ),
  profileSettingsHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.PROFILE_SETTINGS_HIDE,
  ),
  accountSettingsShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ACCOUNT_SETTINGS_SHOW,
  ),
  accountSettingsHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ACCOUNT_SETTINGS_HIDE,
  ),
  userSettingsShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.USER_SETTINGS_SHOW,
  ),
  userSettingsHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.USER_SETTINGS_HIDE,
  ),
  annotationShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ANNOTATION_SHOW,
  ),
  annotationHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.ANNOTATION_HIDE,
  ),
  requestResultShow: (payload: IRequestResultModalPayload): { type: ModalsActionTypes, payload: IRequestResultModalPayload } => createAction(
    ModalsActionTypes.REQUEST_RESULT_SHOW,
    payload,
  ),
  requestResultHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.REQUEST_RESULT_HIDE,
  ),
  urlDetailsShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.URL_DETAILS_SHOW,
  ),
  urlDetailsHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.URL_DETAILS_HIDE,
  ),
  networkDetailsShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.NETWORK_DETAILS_SHOW,
  ),
  networkDetailsHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.NETWORK_DETAILS_HIDE,
  ),
  limitShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.LIMIT_SHOW,
  ),
  limitHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.LIMIT_HIDE,
  ),
  licenseShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.LICENSE_SHOW,
  ),
  licenseHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.LICENSE_HIDE,
  ),
  thumbnailsShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.THUMBNAILS_SHOW,
  ),
  thumbnailsHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.THUMBNAILS_HIDE,
  ),
  projectCrawlsShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.PROJECT_CRAWLS_SHOW,
  ),
  projectCrawlsHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.PROJECT_CRAWLS_HIDE,
  ),
  projectMoveShow: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.PROJECT_MOVE_SHOW,
  ),
  projectMoveHide: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.PROJECT_MOVE_HIDE,
  ),
  resetAll: (): { type: ModalsActionTypes, payload: EmptyObject } => createAction(
    ModalsActionTypes.RESET_ALL,
  ),
}

export type ModalsActions = ActionUnion<typeof modalsActions>;

import { ILicense, ILicenseCompany } from '../../services/LicensesService/types';
import { EmptyObject } from '../../types/common.types';
import { ActionUnion, createAction } from '../commonTypes';
import { IChangePassword, IProfilePersonal, IUIPayload, ProfileSettingsActionTypes } from './types';

export const profileSettingsActions = {
  init: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.INIT,
  ),
  adminInit: (payload: string): { type: ProfileSettingsActionTypes, payload: string } => createAction(
    ProfileSettingsActionTypes.ADMIN_INIT,
    payload,
  ),
  adminUserInit: (payload: string): { type: ProfileSettingsActionTypes, payload: string } => createAction(
    ProfileSettingsActionTypes.ADMIN_USER_INIT,
    payload,
  ),
  resetPasswordStart: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.RESET_PASSWORD_START,
  ),
  changePassword: (payload: IChangePassword): { type: ProfileSettingsActionTypes, payload: IChangePassword } => createAction(
    ProfileSettingsActionTypes.CHANGE_PASSWORD,
    payload,
  ),
  avatarUpload: (payload: File): { type: ProfileSettingsActionTypes, payload: File } => createAction(
    ProfileSettingsActionTypes.AVATAR_UPLOAD,
    payload,
  ),
  personalSave: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.PERSONAL_SAVE,
  ),
  personalRefresh: (payload: IProfilePersonal): { type: ProfileSettingsActionTypes, payload: IProfilePersonal } => createAction(
    ProfileSettingsActionTypes.PERSONAL_REFRESH,
    payload,
  ),
  adminPersonalSave: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.ADMIN_PERSONAL_SAVE,
  ),
  adminUserPersonalSave: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.ADMIN_USER_PERSONAL_SAVE,
  ),
  companySave: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.COMPANY_SAVE,
  ),
  companyRefresh: (payload: Partial<ILicenseCompany>): { type: ProfileSettingsActionTypes, payload: Partial<ILicenseCompany> } => createAction(
    ProfileSettingsActionTypes.COMPANY_REFRESH,
    payload,
  ),
  adminCompanySave: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.ADMIN_COMPANY_SAVE,
  ),
  usersUpdate: (payload: string[]): { type: ProfileSettingsActionTypes, payload: string[] } => createAction(
    ProfileSettingsActionTypes.USERS_UPDATE,
    payload,
  ),
  usersRefresh: (payload: string[]): { type: ProfileSettingsActionTypes, payload: string[] } => createAction(
    ProfileSettingsActionTypes.USERS_REFRESH,
    payload,
  ),
  licensesRefresh: (payload: ILicense[]): { type: ProfileSettingsActionTypes, payload: ILicense[] } => createAction(
    ProfileSettingsActionTypes.LICENSES_REFRESH,
    payload,
  ),
  adminLicensesUpdate: (payload: string): { type: ProfileSettingsActionTypes, payload: string } => createAction(
    ProfileSettingsActionTypes.LICENSES_UPDATE,
    payload,
  ),
  adminUsersUpdate: (payload: string[]): { type: ProfileSettingsActionTypes, payload: string[] } => createAction(
    ProfileSettingsActionTypes.ADMIN_USERS_UPDATE,
    payload,
  ),
  reset: (): { type: ProfileSettingsActionTypes, payload: EmptyObject } => createAction(
    ProfileSettingsActionTypes.RESET,
  ),
  uiMerge: (payload: IUIPayload): { type: ProfileSettingsActionTypes, payload: IUIPayload } => createAction(
    ProfileSettingsActionTypes.UI_MERGE,
    payload,
  ),
}

export type ProfileSettingsActions = ActionUnion<typeof profileSettingsActions>;

import { FieldTypes } from "../../../enums/forms";
import { FieldLabelKeys } from "../../../enums/locales/forms";
import { ProjectSettingsKeys } from "../../../enums/locales/modals";
import { FIELD_NAMES } from "../../../enums/profileSettings";

export const personalFields = [
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.FirstName,
    autoComplete: 'off',
    labelKey: FieldLabelKeys.FirstName,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.LastName,
    autoComplete: 'off',
    labelKey: FieldLabelKeys.LastName,
  },
  {
    fieldType: FieldTypes.Input,
    type: 'text',
    name: FIELD_NAMES.Email,
    disabled: true,
    labelKey: FieldLabelKeys.Email,
  },
];


export const profileSettingsSteps = [
  {
    id: 'step-0',
    className: 'personal-info',
    title: ProjectSettingsKeys.PersonalDetails,
    suffix: '',
    fields: personalFields,
  },
]
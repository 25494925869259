import styled from 'styled-components';
import { ReactComponent as RawCheckmarkSmallSVG } from '../../../../assets/icons/checkmark_small.svg';

export const IconCheckmarkSmall = styled(RawCheckmarkSmallSVG)`
 .a, .b {
   fill: none;
  }

  .b {
    stroke: #171c28;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`;

export const hexToRGBA = (hex: string, a = 1) => {
  let r: number | string = 0;
  let g: number | string = 0;
  let b: number | string = 0;

  // 3 digits
  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;

    // 6 digits
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }

  return `rgba(${+r}, ${+g}, ${+b}, ${+a})`;
};

export const getColor = (index: number) => {
  const staticColors = [
    '#5e8cca',
    '#85a24b',
    '#d15330',
    '#ce9c5f',
    '#52b9e2',
    '#53ad88',
    '#987ced',
    '#cddc3f',
    '#e47432',
    '#de7f77',
  ]
  return staticColors[index % staticColors.length];
};

import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import JSONTree from 'react-json-tree'

import AntdModal from 'antd/lib/modal';

import { THEME } from '../../../constants/theme';
import { ANIMATION_CLASSES } from '../../../constants/animations';
import { MetricsKeys } from '../../../enums/locales/metrics';
import { isUrl } from '../../../utils/common';

import { IMetricsUrl } from '../../../services/DataService/types';
import { metricsActions } from '../../../redux/metrics/actions';
import { modalsActions } from '../../../redux/modals/actions';
import { selectActiveUrlData, selectUrlDetails, selectUI } from '../../../redux/metrics/selectors';
import { selectUrlDetailsVisible } from '../../../redux/modals/selectors';

import { IconClose, IconExternalLink } from '../../../components/styled/icons';
import { StyledBodyWrapper } from './UrlDetailsModal.styled';

const getSkeleton = () => (
  <div className={clsx('url-details-skeleton', { [ANIMATION_CLASSES.animatedSkeleton]: true })}>
    {Array.from({ length: 11 }, (_, i) => <div key={i} style={{ width: `${100 - Math.ceil(30 * Math.random())}%` }} />)}
  </div>
);

const UrlDetailsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch =  useDispatch();
  const isVisible = useSelector(selectUrlDetailsVisible);
  const { urlDetailsLoading } = useSelector(selectUI);
  const activeUrlData = useSelector(selectActiveUrlData) as IMetricsUrl;
  const details = useSelector(selectUrlDetails);

  const openUrl = useCallback((url: string) => () => {
    window.open(url, '_blank');
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.urlDetailsHide());
    dispatch(metricsActions.activeUrlIDRefresh(null));
    dispatch(metricsActions.urlDetailsRefresh([]));
  }, [dispatch]);

  return (
    <AntdModal
      centered
      title={(
        <>
          <div className="url-details-modal-title">
            {t(MetricsKeys.DetailsTitle)}
          </div>
          <div className="url-details-modal-link">
            <IconExternalLink onClick={openUrl(activeUrlData?.url)} />
            <a href={activeUrlData?.url} target="_blank" rel="noreferrer"><span>{activeUrlData?.url}</span></a>
          </div>
        </>
      )}
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="url-details-modal-container"
      width={'70%'}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        {urlDetailsLoading && getSkeleton()}
        {!urlDetailsLoading && (
          <JSONTree
            theme={THEME.jsonTree}
            data={details}
            valueRenderer={(raw) => {
              if (typeof raw !== 'string') {
                return raw;
              }
              const sanitizedRaw = raw.replace(/"/g,"");
              return isUrl(sanitizedRaw) ? <a href={sanitizedRaw} target="_blank" rel="noreferrer">{sanitizedRaw}</a> : raw;
            }}
            shouldExpandNode={() => true}
          />
        )}
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default UrlDetailsModal;

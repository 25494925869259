import styled from "styled-components";
import { hexToRGBA } from "../../../utils/theme";
import { IProps } from "./CheckableList";

export const Wrapper = styled.div<Pick<IProps, 'error'>>`
  width: 100%;

  .tabs {
    width: fit-content;
    height: 40px;
    margin-bottom: 20px;

    .tab {
      height: 40px;
    }
  }
  
  .textarea-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    border: 2px solid ${({ theme }) => theme.colors.grey};
    border-radius: 18px;
    padding: 20px 15px 15px 30px;
    margin-bottom: 18px;

    .placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #171C2880;
      margin-bottom: 18px;
    }

    textarea {
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      border: none;
      outline: none;
	    appearance: none;
    }
  }

  button {
    max-width: none;
    margin-bottom: 30px;

    .title {
      font-weight: 600!important;
    }
  }

  .table-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    border: 2px solid ${({ theme }) => theme.colors.grey};
    border-radius: 18px;

    .table-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      .header-title {
        font-size: 18px;
        font-weight: 600;
        span {
          opacity: 0.4;
          margin-left: 10px;
        }
      }

      .header-search {
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
        }
        .search-input {
          height: 36px;
          max-width: 212px;
          font-size: 12px;
          line-height: 17px;
        }
        .icon-container {
          top: 7px;
          right: 8px;
          width: 22px;
          height: 22px;
        }
      }
    }

    .table-list-content {
      .label {
        margin-left: 12px;
        margin-bottom: 14px;
        font-size: 12px;
        font-weight: 400;
      }
      .ant-checkbox {
        top: 0.05rem;
      }

      .table-list-content-header {
        padding-left: 8px;
        .label {
          font-size: 12px;
          font-weight: 600;
        } 
      }

      .divider {
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.primaryDark};
        border-radius: 1px;
      }

      .table-list-content-items {
        padding-top: 11px;

        .list-item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 28px;
          border-radius: 8px;
          padding-left: 8px;
          margin-bottom: 12px;

          .small {
            width: 100%;
            .ant-checkbox-wrapper {
              width: 100%;
            }
          }

          .label {
            width: 100%;
            margin-bottom: 0;
          }

          &.item-checked {
            background: ${({ theme }) => hexToRGBA(theme.colors.linkBlue, 0.1)};
            .label {
              color: ${({ theme }) => theme.colors.linkBlue};
              font-weight: 600;
            }
          } 
        }
      }

      .outline {
        margin-bottom: 0;
      }

      .secondary {
        margin-top: 12px;
        margin-bottom: 0;
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.red};
        font-size: 16px;

        .title {
          font-weight: 500!important;
        }

        .b {
          fill:  ${({ theme }) => theme.colors.red};
        }
      }

      .pagination {
        padding: 0;
      }
    }
  }  
`;
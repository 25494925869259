import { CoreWebVitalsValues } from "../../enums/coreWebVitals";
import { FeatureComparison } from "../../services/DataService/types";

export enum VitalsActionTypes {
  VITALS_RELOAD = '[Vitals] vitals-reload',
  VITALS_REFRESH = '[Vitals] vitals-refresh',

  QUANTIFIER_REFRESH = '[Vitals] quantifier-refresh',
  COMPARATOR_REFRESH = '[Vitals] comparator-refresh',

  UI_MERGE = '[Vitals] ui-merge',

  RESET_ALL = '[Vitals] reset-all',
}

export interface IUIPayload {
  loading?: boolean;
}

export interface VitalsState {
  data: FeatureComparison;
  quantifier: CoreWebVitalsValues | string;
  comparator: string;  
  UI: IUIPayload;
}

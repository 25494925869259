import { Languages } from '../../enums/locales/languages';
import { QUERY_PARAMS_KEYS } from '../../enums/queryParams';
import { history } from '../../redux/store';

export const validateLanguage = (lang: Languages): boolean => {
  return Object.values(Languages).includes(lang);
}

export const setQueryParam = (key: QUERY_PARAMS_KEYS, value: any, query: any) => {
  const queryParams = new URLSearchParams(query);
  queryParams.set(key, value);
  history.replace({ search: queryParams.toString() });
}

export const getQueryParam = (key: QUERY_PARAMS_KEYS, query: any) => {
  const queryParams = new URLSearchParams(query);
  return queryParams.get(key);
}

export const removeQueryParam = (key: QUERY_PARAMS_KEYS, query: any) => {
  const queryParams = new URLSearchParams(query);
  queryParams.delete(key);
  history.replace({ search: queryParams.toString() });
}

export const resetQueryParams = () => {
  history.replace({ search: '' });
}
import React, { Suspense, lazy, useMemo } from 'react';
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';

import { UI_ROUTES, UI_ACTUAL_ROUTES } from '../../constants/routes';
import { selectLanguage } from '../../redux/app/selectors';
import { selectIsLoggedIn, selectUser } from '../../redux/auth/selectors';
import { Languages } from '../../enums/locales/languages';

const SignInPage = lazy(() => import(/* webpackChunkName: "SignInPage" */ '../../pages/SignInPage'));
const ResetPasswordPage = lazy(() => import(/* webpackChunkName: "ResetPasswordPage" */ '../../pages/ResetPasswordPage'));
const SignUpPage = lazy(() => import(/* webpackChunkName: "SignUpPage" */ '../../pages/SignUpPage'));

const DashboardPage = lazy(() => import(/* webpackChunkName: "DashboardPage" */ '../../pages/DashboardPage'));

const MetricsPSPage = lazy(() => import(/* webpackChunkName: "MetricsPSPage" */ '../../pages/Metrics/MetricsPSPage'));
const MetricsCLSPage = lazy(() => import(/* webpackChunkName: "MetricsCLSPage" */ '../../pages/Metrics/MetricsCLSPage'));

const MetricsINPPage = lazy(() => import(/* webpackChunkName: "MetricsINPPage" */ '../../pages/Metrics/MetricsINPPage/MetricsINPPage'));
const MetricsTTFBPage = lazy(() => import(/* webpackChunkName: "MetricsTTFBPage" */ '../../pages/Metrics/MetricsTTFBPage/MetricsTTFBPage'));

const MetricsLCPPage = lazy(() => import(/* webpackChunkName: "MetricsLCPPage" */ '../../pages/Metrics/MetricsLCPPage'));
const MetricsFIDPage = lazy(() => import(/* webpackChunkName: "MetricsFIDPage" */ '../../pages/Metrics/MetricsFIDPage'));
const MetricsTBTPage = lazy(() => import(/* webpackChunkName: "MetricsTBTPage" */ '../../pages/Metrics/MetricsTBTPage'));
const MetricsTTIPage = lazy(() => import(/* webpackChunkName: "MetricsTTIPage" */ '../../pages/Metrics/MetricsTTIPage'));
const MetricsFCPPage = lazy(() => import(/* webpackChunkName: "MetricsFCPPage" */ '../../pages/Metrics/MetricsFCPPage'));
const MetricsFMPPage = lazy(() => import(/* webpackChunkName: "MetricsFMPPage" */ '../../pages/Metrics/MetricsFMPPage'));
const MetricsSRTPage = lazy(() => import(/* webpackChunkName: "MetricsSRTPage" */ '../../pages/Metrics/MetricsSRTPage'));
const MetricsDOMSizePage = lazy(() => import(/* webpackChunkName: "MetricsDOMSizePage" */ '../../pages/Metrics/MetricsDOMSizePage'));
const MetricsMIPage = lazy(() => import(/* webpackChunkName: "MetricsMIPage" */ '../../pages/Metrics/MetricsMIPage'));
const MetricsUnminJSPage = lazy(() => import(/* webpackChunkName: "MetricsUnminJSPage" */ '../../pages/Metrics/MetricsUnminJSPage'));
const MetricsUnminCSSPage = lazy(() => import(/* webpackChunkName: "MetricsUnminCSSPage" */ '../../pages/Metrics/MetricsUnminCSSPage'));
const MetricsUnusedJSPage = lazy(() => import(/* webpackChunkName: "MetricsUnusedJSPage" */ '../../pages/Metrics/MetricsUnusedJSPage'));
const MetricsUnusedCSSPage = lazy(() => import(/* webpackChunkName: "MetricsUnusedCSSPage" */ '../../pages/Metrics/MetricsUnusedCSSPage'));
const MetricsOIPage = lazy(() => import(/* webpackChunkName: "MetricsOIPage" */ '../../pages/Metrics/MetricsOIPage'));
const MetricsRBRPage = lazy(() => import(/* webpackChunkName: "MetricsRBRPage" */ '../../pages/Metrics/MetricsRBRPage'));
const MetricsNTO50Page = lazy(() => import(/* webpackChunkName: "MetricsNTO50Page" */ '../../pages/Metrics/MetricsNTO50Page'));
const MetricsNTO100Page = lazy(() => import(/* webpackChunkName: "MetricsNTO100Page" */ '../../pages/Metrics/MetricsNTO100Page'));
const MetricsNTO500Page = lazy(() => import(/* webpackChunkName: "MetricsNTO500Page" */ '../../pages/Metrics/MetricsNTO500Page'));
const MetricsSIPage = lazy(() => import(/* webpackChunkName: "MetricsSIPage" */ '../../pages/Metrics/MetricsSIPage'));
const MetricsUTCPage = lazy(() => import(/* webpackChunkName: "MetricsUTCPage" */ '../../pages/Metrics/MetricsUTCPage'));
const MetricsOFIPage = lazy(() => import(/* webpackChunkName: "MetricsOFIPage" */ '../../pages/Metrics/MetricsOFIPage'));
const MetricsNFPage = lazy(() => import(/* webpackChunkName: "MetricsNFPage" */ '../../pages/Metrics/MetricsNFPage'));
const MetricsNRPage = lazy(() => import(/* webpackChunkName: "MetricsNRPage" */ '../../pages/Metrics/MetricsNRPage'));
const MetricsNTPage = lazy(() => import(/* webpackChunkName: "MetricsNTPage" */ '../../pages/Metrics/MetricsNTPage'));
const MetricsNSSPage = lazy(() => import(/* webpackChunkName: "MetricsNSSPage" */ '../../pages/Metrics/MetricsNSSPage'));
const MetricsNSCRPage = lazy(() => import(/* webpackChunkName: "MetricsNSCRPage" */ '../../pages/Metrics/MetricsNSCRPage'));
const MetricsTBWPage = lazy(() => import(/* webpackChunkName: "MetricsTBWPage" */ '../../pages/Metrics/MetricsTBWPage'));
const MetricsTTTPage = lazy(() => import(/* webpackChunkName: "MetricsTTTPage" */ '../../pages/Metrics/MetricsTTTPage'));

const LeaderboardPSPage = lazy(() => import(/* webpackChunkName: "LeaderboardPSPage" */ '../../pages/Leaderboard/LeaderboardPSPage'));
const LeaderboardFCPPage = lazy(() => import(/* webpackChunkName: "LeaderboardFCPPage" */ '../../pages/Leaderboard/LeaderboardFCPPage'));
const LeaderboardLCPPage = lazy(() => import(/* webpackChunkName: "LeaderboardLCPPage" */ '../../pages/Leaderboard/LeaderboardLCPPage'));
const LeaderboardFIDPage = lazy(() => import(/* webpackChunkName: "LeaderboardFIDPage" */ '../../pages/Leaderboard/LeaderboardFIDPage'));
const LeaderboardCLSPage = lazy(() => import(/* webpackChunkName: "LeaderboardCLSPage" */ '../../pages/Leaderboard/LeaderboardCLSPage'));
const LeaderboardFMPPage = lazy(() => import(/* webpackChunkName: "LeaderboardFMPPage" */ '../../pages/Leaderboard/LeaderboardFMPPage'));
const LeaderboardSRTPage = lazy(() => import(/* webpackChunkName: "LeaderboardSRTPage" */ '../../pages/Leaderboard/LeaderboardSRTPage'));
const LeaderboardDOMSizePage = lazy(() => import(/* webpackChunkName: "LeaderboardDOMSizePage" */ '../../pages/Leaderboard/LeaderboardDOMSizePage'));
const LeaderboardMIPage = lazy(() => import(/* webpackChunkName: "LeaderboardMIPage" */ '../../pages/Leaderboard/LeaderboardMIPage'));
const LeaderboardTTIPage = lazy(() => import(/* webpackChunkName: "LeaderboardTTIPage" */ '../../pages/Leaderboard/LeaderboardTTIPage'));
const LeaderboardTBTPage = lazy(() => import(/* webpackChunkName: "LeaderboardTBTPage" */ '../../pages/Leaderboard/LeaderboardTBTPage'));
const LeaderboardUnminJSPage = lazy(() => import(/* webpackChunkName: "LeaderboardUnminJSPage" */ '../../pages/Leaderboard/LeaderboardUnminJSPage'));
const LeaderboardUnminCSSPage = lazy(() => import(/* webpackChunkName: "LeaderboardUnminCSSPage" */ '../../pages/Leaderboard/LeaderboardUnminCSSPage'));
const LeaderboardUnusedJSPage = lazy(() => import(/* webpackChunkName: "LeaderboardUnusedJSPage" */ '../../pages/Leaderboard/LeaderboardUnusedJSPage'));
const LeaderboardUnusedCSSPage = lazy(() => import(/* webpackChunkName: "LeaderboardUnusedCSSPage" */ '../../pages/Leaderboard/LeaderboardUnusedCSSPage'));
const LeaderboardNTO50Page = lazy(() => import(/* webpackChunkName: "LeaderboardNTO50Page" */ '../../pages/Leaderboard/LeaderboardNTO50Page'));
const LeaderboardNTO100Page = lazy(() => import(/* webpackChunkName: "LeaderboardNTO100Page" */ '../../pages/Leaderboard/LeaderboardNTO100Page'));
const LeaderboardNTO500Page = lazy(() => import(/* webpackChunkName: "LeaderboardNTO500Page" */ '../../pages/Leaderboard/LeaderboardNTO500Page'));
const LeaderboardOIPage = lazy(() => import(/* webpackChunkName: "LeaderboardOIPage" */ '../../pages/Leaderboard/LeaderboardOIPage'));
const LeaderboardRBRPage = lazy(() => import(/* webpackChunkName: "LeaderboardRBRPage" */ '../../pages/Leaderboard/LeaderboardRBRPage'));
const LeaderboardSIPage = lazy(() => import(/* webpackChunkName: "LeaderboardSIPage" */ '../../pages/Leaderboard/LeaderboardSIPage'));
const LeaderboardUTCPage = lazy(() => import(/* webpackChunkName: "LeaderboardUTCPage" */ '../../pages/Leaderboard/LeaderboardUTCPage'));
const LeaderboardOFIPage = lazy(() => import(/* webpackChunkName: "LeaderboardOFIPage" */ '../../pages/Leaderboard/LeaderboardOFIPage'));
const LeaderboardNFPage = lazy(() => import(/* webpackChunkName: "LeaderboardNFPage" */ '../../pages/Leaderboard/LeaderboardNFPage'));
const LeaderboardNRPage = lazy(() => import(/* webpackChunkName: "LeaderboardNRPage" */ '../../pages/Leaderboard/LeaderboardNRPage'));
const LeaderboardNTPage = lazy(() => import(/* webpackChunkName: "LeaderboardNTPage" */ '../../pages/Leaderboard/LeaderboardNTPage'));
const LeaderboardNSSPage = lazy(() => import(/* webpackChunkName: "LeaderboardNSSPage" */ '../../pages/Leaderboard/LeaderboardNSSPage'));
const LeaderboardNSCRPage = lazy(() => import(/* webpackChunkName: "LeaderboardNSCRPage" */ '../../pages/Leaderboard/LeaderboardNSCRPage'));
const LeaderboardTBWPage = lazy(() => import(/* webpackChunkName: "LeaderboardTBWPage" */ '../../pages/Leaderboard/LeaderboardTBWPage'));
const LeaderboardTTTPage = lazy(() => import(/* webpackChunkName: "LeaderboardTTTPage" */ '../../pages/Leaderboard/LeaderboardTTTPage'));

const NetworkRequestsAnalysisPage = lazy(() => import(/* webpackChunkName: "NetworkRequestsAnalysisPage" */ '../../pages/CompoundAnalysis/NetworkRequestsAnalysisPage'));

const DirectoryAnalysisPage = lazy(() => import(/* webpackChunkName: "DirectoryAnalysisPage" */ '../../pages/CompoundAnalysis/DirectoryAnalysisPage'));
const ProjectsOverviewPage = lazy(() => import(/* webpackChunkName: "ProjectsOverviewPage" */ '../../pages/ProjectsOverviewPage'));

const AccountsPage = lazy(() => import(/* webpackChunkName: "AccountsPage" */ '../../pages/AccountsPage'));
const AccountDetailsPage = lazy(() => import(/* webpackChunkName: "AccountDetailsPage" */ '../../pages/AccountDetailsPage'));
const UsersPage = lazy(() => import(/* webpackChunkName: "UsersPage" */ '../../pages/UsersPage'));

const getPublicRoutes = (lang: Languages) => ([
  <Route key={UI_ROUTES.signIn} path={UI_ROUTES.signIn} component={SignInPage} />,
  <Route key={UI_ROUTES.signUp} path={UI_ROUTES.signUp} component={SignUpPage} />,

  <Redirect key={UI_ACTUAL_ROUTES.signIn} from={UI_ACTUAL_ROUTES.signIn} to={generatePath(UI_ROUTES.signIn, { lang })} />,
  <Redirect key={UI_ACTUAL_ROUTES.signUp} from={UI_ACTUAL_ROUTES.signUp} to={generatePath(UI_ROUTES.signUp, { lang })} />,

  <Redirect key="redirect" to={generatePath(UI_ROUTES.signIn, { lang })} />,
]);

const getRestrictedRoutes = (lang: Languages) => ([
  <Route
    key={UI_ROUTES.dashboard}
    path={UI_ROUTES.dashboard}
    component={DashboardPage}
  />,

  <Route
    key={UI_ROUTES.metricsPerformanceScore}
    path={UI_ROUTES.metricsPerformanceScore}
    component={MetricsPSPage}
  />,

  <Route
    key={UI_ROUTES.metricsCumulativeLayoutShift}
    path={UI_ROUTES.metricsCumulativeLayoutShift}
    component={MetricsCLSPage}
  />,

  <Route
    key={UI_ROUTES.metricsInteractionToNextPaint}
    path={UI_ROUTES.metricsInteractionToNextPaint}
    component={MetricsINPPage}
  />,

  <Route
    key={UI_ROUTES.metricsExperimentalTimeToFirstByte}
    path={UI_ROUTES.metricsExperimentalTimeToFirstByte}
    component={MetricsTTFBPage}
  />,

  <Route
    key={UI_ROUTES.metricsLargestContentfulPaint}
    path={UI_ROUTES.metricsLargestContentfulPaint}
    component={MetricsLCPPage}
  />,

  <Route
    key={UI_ROUTES.metricsFirstInputDelay}
    path={UI_ROUTES.metricsFirstInputDelay}
    component={MetricsFIDPage}
  />,

  <Route
    key={UI_ROUTES.metricsTotalBlockingTime}
    path={UI_ROUTES.metricsTotalBlockingTime}
    component={MetricsTBTPage}
  />,

  <Route
    key={UI_ROUTES.metricsTimeToInteractive}
    path={UI_ROUTES.metricsTimeToInteractive}
    component={MetricsTTIPage}
  />,

  <Route
    key={UI_ROUTES.metricsFirstContentfulPaint}
    path={UI_ROUTES.metricsFirstContentfulPaint}
    component={MetricsFCPPage}
  />,

  <Route
    key={UI_ROUTES.metricsServerResponseTime}
    path={UI_ROUTES.metricsServerResponseTime}
    component={MetricsSRTPage}
  />,

  <Route
    key={UI_ROUTES.metricsDOMSize}
    path={UI_ROUTES.metricsDOMSize}
    component={MetricsDOMSizePage}
  />,

  <Route
    key={UI_ROUTES.metricsFirstMeaningfulPaint}
    path={UI_ROUTES.metricsFirstMeaningfulPaint}
    component={MetricsFMPPage}
  />,

  <Route
    key={UI_ROUTES.metricsModernImages}
    path={UI_ROUTES.metricsModernImages}
    component={MetricsMIPage}
  />,

  <Route
    key={UI_ROUTES.metricsUnminifiedJS}
    path={UI_ROUTES.metricsUnminifiedJS}
    component={MetricsUnminJSPage}
  />,

  <Route
    key={UI_ROUTES.metricsUnminifiedCSS}
    path={UI_ROUTES.metricsUnminifiedCSS}
    component={MetricsUnminCSSPage}
  />,

  <Route
    key={UI_ROUTES.metricsUnusedJS}
    path={UI_ROUTES.metricsUnusedJS}
    component={MetricsUnusedJSPage}
  />,

  <Route
    key={UI_ROUTES.metricsUnusedCSS}
    path={UI_ROUTES.metricsUnusedCSS}
    component={MetricsUnusedCSSPage}
  />,

  <Route
    key={UI_ROUTES.metricsOptimizedImages}
    path={UI_ROUTES.metricsOptimizedImages}
    component={MetricsOIPage}
  />,

  <Route
    key={UI_ROUTES.metricsRenderBlockingResources}
    path={UI_ROUTES.metricsRenderBlockingResources}
    component={MetricsRBRPage}
  />,

  <Route
    key={UI_ROUTES.metricsNumTasksOver50ms}
    path={UI_ROUTES.metricsNumTasksOver50ms}
    component={MetricsNTO50Page}
  />,

  <Route
    key={UI_ROUTES.metricsNumTasksOver100ms}
    path={UI_ROUTES.metricsNumTasksOver100ms}
    component={MetricsNTO100Page}
  />,

  <Route
    key={UI_ROUTES.metricsNumTasksOver500ms}
    path={UI_ROUTES.metricsNumTasksOver500ms}
    component={MetricsNTO500Page}
  />,

  <Route
    key={UI_ROUTES.metricsSpeedIndex}
    path={UI_ROUTES.metricsSpeedIndex}
    component={MetricsSIPage}
  />,

  <Route
    key={UI_ROUTES.metricsUsesTextCompression}
    path={UI_ROUTES.metricsUsesTextCompression}
    component={MetricsUTCPage}
  />,

  <Route
    key={UI_ROUTES.metricsOffscreenImages}
    path={UI_ROUTES.metricsOffscreenImages}
    component={MetricsOFIPage}
  />,

  <Route
    key={UI_ROUTES.metricsNumFonts}
    path={UI_ROUTES.metricsNumFonts}
    component={MetricsNFPage}
  />,

  <Route
    key={UI_ROUTES.metricsNumRequests}
    path={UI_ROUTES.metricsNumRequests}
    component={MetricsNRPage}
  />,

  <Route
    key={UI_ROUTES.metricsNumScripts}
    path={UI_ROUTES.metricsNumScripts}
    component={MetricsNSCRPage}
  />,

  <Route
    key={UI_ROUTES.metricsNumStylesheets}
    path={UI_ROUTES.metricsNumStylesheets}
    component={MetricsNSSPage}
  />,

  <Route
    key={UI_ROUTES.metricsNumTasks}
    path={UI_ROUTES.metricsNumTasks}
    component={MetricsNTPage}
  />,

  <Route
    key={UI_ROUTES.metricsTotalByteWeight}
    path={UI_ROUTES.metricsTotalByteWeight}
    component={MetricsTBWPage}
  />,

  <Route
    key={UI_ROUTES.metricsTotalTaskTime}
    path={UI_ROUTES.metricsTotalTaskTime}
    component={MetricsTTTPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsPerformanceScore}
    path={UI_ROUTES.leaderboardsPerformanceScore}
    component={LeaderboardPSPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsFirstContentfulPaint}
    path={UI_ROUTES.leaderboardsFirstContentfulPaint}
    component={LeaderboardFCPPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsLargestContentfulPaint}
    path={UI_ROUTES.leaderboardsLargestContentfulPaint}
    component={LeaderboardLCPPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsFirstInputDelay}
    path={UI_ROUTES.leaderboardsFirstInputDelay}
    component={LeaderboardFIDPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsTimeToInteractive}
    path={UI_ROUTES.leaderboardsTimeToInteractive}
    component={LeaderboardTTIPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsTotalBlockingTime}
    path={UI_ROUTES.leaderboardsTotalBlockingTime}
    component={LeaderboardTBTPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsCumulativeLayoutShift}
    path={UI_ROUTES.leaderboardsCumulativeLayoutShift}
    component={LeaderboardCLSPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsFirstMeaningfulPaint}
    path={UI_ROUTES.leaderboardsFirstMeaningfulPaint}
    component={LeaderboardFMPPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsDOMSize}
    path={UI_ROUTES.leaderboardsDOMSize}
    component={LeaderboardDOMSizePage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsModernImages}
    path={UI_ROUTES.leaderboardsModernImages}
    component={LeaderboardMIPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsServerResponseTime}
    path={UI_ROUTES.leaderboardsServerResponseTime}
    component={LeaderboardSRTPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsUnminifiedCSS}
    path={UI_ROUTES.leaderboardsUnminifiedCSS}
    component={LeaderboardUnminCSSPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsUnminifiedJS}
    path={UI_ROUTES.leaderboardsUnminifiedJS}
    component={LeaderboardUnminJSPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsUnusedCSS}
    path={UI_ROUTES.leaderboardsUnusedCSS}
    component={LeaderboardUnusedCSSPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsUnusedJS}
    path={UI_ROUTES.leaderboardsUnusedJS}
    component={LeaderboardUnusedJSPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumTasksOver50ms}
    path={UI_ROUTES.leaderboardsNumTasksOver50ms}
    component={LeaderboardNTO50Page}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumTasksOver100ms}
    path={UI_ROUTES.leaderboardsNumTasksOver100ms}
    component={LeaderboardNTO100Page}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumTasksOver500ms}
    path={UI_ROUTES.leaderboardsNumTasksOver500ms}
    component={LeaderboardNTO500Page}
  />,

  <Route
    key={UI_ROUTES.leaderboardsOptimizedImages}
    path={UI_ROUTES.leaderboardsOptimizedImages}
    component={LeaderboardOIPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsRenderBlockingResources}
    path={UI_ROUTES.leaderboardsRenderBlockingResources}
    component={LeaderboardRBRPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsSpeedIndex}
    path={UI_ROUTES.leaderboardsSpeedIndex}
    component={LeaderboardSIPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsUsesTextCompression}
    path={UI_ROUTES.leaderboardsUsesTextCompression}
    component={LeaderboardUTCPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsOffscreenImages}
    path={UI_ROUTES.leaderboardsOffscreenImages}
    component={LeaderboardOFIPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumFonts}
    path={UI_ROUTES.leaderboardsNumFonts}
    component={LeaderboardNFPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumRequests}
    path={UI_ROUTES.leaderboardsNumRequests}
    component={LeaderboardNRPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumTasks}
    path={UI_ROUTES.leaderboardsNumTasks}
    component={LeaderboardNTPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumScripts}
    path={UI_ROUTES.leaderboardsNumScripts}
    component={LeaderboardNSCRPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsNumStylesheets}
    path={UI_ROUTES.leaderboardsNumStylesheets}
    component={LeaderboardNSSPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsTotalTaskTime}
    path={UI_ROUTES.leaderboardsTotalTaskTime}
    component={LeaderboardTTTPage}
  />,

  <Route
    key={UI_ROUTES.leaderboardsTotalByteWeight}
    path={UI_ROUTES.leaderboardsTotalByteWeight}
    component={LeaderboardTBWPage}
  />,

  <Route
    key={UI_ROUTES.networkRequestsAnalysis}
    path={UI_ROUTES.networkRequestsAnalysis}
    component={NetworkRequestsAnalysisPage}
  />,

  <Route
    key={UI_ROUTES.analysisDirectoryAnalysis}
    path={UI_ROUTES.analysisDirectoryAnalysis}
    component={DirectoryAnalysisPage}
  />,

  <Route
    key={UI_ROUTES.projectsOverview}
    path={UI_ROUTES.projectsOverview}
    component={ProjectsOverviewPage}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.dashboard}
    from={UI_ACTUAL_ROUTES.dashboard}
    to={generatePath(UI_ROUTES.dashboard, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsPerformanceScore}
    from={UI_ACTUAL_ROUTES.metricsPerformanceScore}
    to={generatePath(UI_ROUTES.metricsPerformanceScore, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsCumulativeLayoutShift}
    from={UI_ACTUAL_ROUTES.metricsCumulativeLayoutShift}
    to={generatePath(UI_ROUTES.metricsCumulativeLayoutShift, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsLargestContentfulPaint}
    from={UI_ACTUAL_ROUTES.metricsLargestContentfulPaint}
    to={generatePath(UI_ROUTES.metricsLargestContentfulPaint, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsFirstInputDelay}
    from={UI_ACTUAL_ROUTES.metricsFirstInputDelay}
    to={generatePath(UI_ROUTES.metricsFirstInputDelay, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsTotalBlockingTime}
    from={UI_ACTUAL_ROUTES.metricsTotalBlockingTime}
    to={generatePath(UI_ROUTES.metricsTotalBlockingTime, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsTimeToInteractive}
    from={UI_ACTUAL_ROUTES.metricsTimeToInteractive}
    to={generatePath(UI_ROUTES.metricsTimeToInteractive, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsFirstContentfulPaint}
    from={UI_ACTUAL_ROUTES.metricsFirstContentfulPaint}
    to={generatePath(UI_ROUTES.metricsFirstContentfulPaint, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsFirstMeaningfulPaint}
    from={UI_ACTUAL_ROUTES.metricsFirstMeaningfulPaint}
    to={generatePath(UI_ROUTES.metricsFirstMeaningfulPaint, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsServerResponseTime}
    from={UI_ACTUAL_ROUTES.metricsServerResponseTime}
    to={generatePath(UI_ROUTES.metricsServerResponseTime, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsDOMSize}
    from={UI_ACTUAL_ROUTES.metricsDOMSize}
    to={generatePath(UI_ROUTES.metricsDOMSize, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsModernImages}
    from={UI_ACTUAL_ROUTES.metricsModernImages}
    to={generatePath(UI_ROUTES.metricsModernImages, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsUnminifiedJS}
    from={UI_ACTUAL_ROUTES.metricsUnminifiedJS}
    to={generatePath(UI_ROUTES.metricsUnminifiedJS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsUnminifiedCSS}
    from={UI_ACTUAL_ROUTES.metricsUnminifiedCSS}
    to={generatePath(UI_ROUTES.metricsUnminifiedCSS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsUnusedJS}
    from={UI_ACTUAL_ROUTES.metricsUnusedJS}
    to={generatePath(UI_ROUTES.metricsUnusedJS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsUnusedCSS}
    from={UI_ACTUAL_ROUTES.metricsUnusedCSS}
    to={generatePath(UI_ROUTES.metricsUnusedCSS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsOptimizedImages}
    from={UI_ACTUAL_ROUTES.metricsOptimizedImages}
    to={generatePath(UI_ROUTES.metricsOptimizedImages, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsRenderBlockingResources}
    from={UI_ACTUAL_ROUTES.metricsRenderBlockingResources}
    to={generatePath(UI_ROUTES.metricsRenderBlockingResources, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumTasksOver50ms}
    from={UI_ACTUAL_ROUTES.metricsNumTasksOver50ms}
    to={generatePath(UI_ROUTES.metricsNumTasksOver50ms, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumTasksOver100ms}
    from={UI_ACTUAL_ROUTES.metricsNumTasksOver100ms}
    to={generatePath(UI_ROUTES.metricsNumTasksOver100ms, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumTasksOver500ms}
    from={UI_ACTUAL_ROUTES.metricsNumTasksOver500ms}
    to={generatePath(UI_ROUTES.metricsNumTasksOver500ms, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsSpeedIndex}
    from={UI_ACTUAL_ROUTES.metricsSpeedIndex}
    to={generatePath(UI_ROUTES.metricsSpeedIndex, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsUsesTextCompression}
    from={UI_ACTUAL_ROUTES.metricsUsesTextCompression}
    to={generatePath(UI_ROUTES.metricsUsesTextCompression, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsOffscreenImages}
    from={UI_ACTUAL_ROUTES.metricsOffscreenImages}
    to={generatePath(UI_ROUTES.metricsOffscreenImages, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumFonts}
    from={UI_ACTUAL_ROUTES.metricsNumFonts}
    to={generatePath(UI_ROUTES.metricsNumFonts, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumRequests}
    from={UI_ACTUAL_ROUTES.metricsNumRequests}
    to={generatePath(UI_ROUTES.metricsNumRequests, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumScripts}
    from={UI_ACTUAL_ROUTES.metricsNumScripts}
    to={generatePath(UI_ROUTES.metricsNumScripts, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumStylesheets}
    from={UI_ACTUAL_ROUTES.metricsNumStylesheets}
    to={generatePath(UI_ROUTES.metricsNumStylesheets, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsNumTasks}
    from={UI_ACTUAL_ROUTES.metricsNumTasks}
    to={generatePath(UI_ROUTES.metricsNumTasks, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsTotalByteWeight}
    from={UI_ACTUAL_ROUTES.metricsTotalByteWeight}
    to={generatePath(UI_ROUTES.metricsTotalByteWeight, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.metricsTotalTaskTime}
    from={UI_ACTUAL_ROUTES.metricsTotalTaskTime}
    to={generatePath(UI_ROUTES.metricsTotalTaskTime, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsPerformanceScore}
    from={UI_ACTUAL_ROUTES.leaderboardsPerformanceScore}
    to={generatePath(UI_ROUTES.leaderboardsPerformanceScore, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsFirstContentfulPaint}
    from={UI_ACTUAL_ROUTES.leaderboardsFirstContentfulPaint}
    to={generatePath(UI_ROUTES.leaderboardsFirstContentfulPaint, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsLargestContentfulPaint}
    from={UI_ACTUAL_ROUTES.leaderboardsLargestContentfulPaint}
    to={generatePath(UI_ROUTES.leaderboardsLargestContentfulPaint, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsFirstInputDelay}
    from={UI_ACTUAL_ROUTES.leaderboardsFirstInputDelay}
    to={generatePath(UI_ROUTES.leaderboardsFirstInputDelay, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsTimeToInteractive}
    from={UI_ACTUAL_ROUTES.leaderboardsTimeToInteractive}
    to={generatePath(UI_ROUTES.leaderboardsTimeToInteractive, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsTotalBlockingTime}
    from={UI_ACTUAL_ROUTES.leaderboardsTotalBlockingTime}
    to={generatePath(UI_ROUTES.leaderboardsTotalBlockingTime, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsCumulativeLayoutShift}
    from={UI_ACTUAL_ROUTES.leaderboardsCumulativeLayoutShift}
    to={generatePath(UI_ROUTES.leaderboardsCumulativeLayoutShift, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsFirstMeaningfulPaint}
    from={UI_ACTUAL_ROUTES.leaderboardsFirstMeaningfulPaint}
    to={generatePath(UI_ROUTES.leaderboardsFirstMeaningfulPaint, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsServerResponseTime}
    from={UI_ACTUAL_ROUTES.leaderboardsServerResponseTime}
    to={generatePath(UI_ROUTES.leaderboardsServerResponseTime, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsDOMSize}
    from={UI_ACTUAL_ROUTES.leaderboardsDOMSize}
    to={generatePath(UI_ROUTES.leaderboardsDOMSize, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsModernImages}
    from={UI_ACTUAL_ROUTES.leaderboardsModernImages}
    to={generatePath(UI_ROUTES.leaderboardsModernImages, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsUnminifiedCSS}
    from={UI_ACTUAL_ROUTES.leaderboardsUnminifiedCSS}
    to={generatePath(UI_ROUTES.leaderboardsUnminifiedCSS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsUnminifiedJS}
    from={UI_ACTUAL_ROUTES.leaderboardsUnminifiedJS}
    to={generatePath(UI_ROUTES.leaderboardsUnminifiedJS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsUnusedCSS}
    from={UI_ACTUAL_ROUTES.leaderboardsUnusedCSS}
    to={generatePath(UI_ROUTES.leaderboardsUnusedCSS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsUnusedJS}
    from={UI_ACTUAL_ROUTES.leaderboardsUnusedJS}
    to={generatePath(UI_ROUTES.leaderboardsUnusedJS, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumTasksOver50ms}
    from={UI_ACTUAL_ROUTES.leaderboardsNumTasksOver50ms}
    to={generatePath(UI_ROUTES.leaderboardsNumTasksOver50ms, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumTasksOver100ms}
    from={UI_ACTUAL_ROUTES.leaderboardsNumTasksOver100ms}
    to={generatePath(UI_ROUTES.leaderboardsNumTasksOver100ms, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumTasksOver500ms}
    from={UI_ACTUAL_ROUTES.leaderboardsNumTasksOver500ms}
    to={generatePath(UI_ROUTES.leaderboardsNumTasksOver500ms, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsOptimizedImages}
    from={UI_ACTUAL_ROUTES.leaderboardsOptimizedImages}
    to={generatePath(UI_ROUTES.leaderboardsOptimizedImages, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsRenderBlockingResources}
    from={UI_ACTUAL_ROUTES.leaderboardsRenderBlockingResources}
    to={generatePath(UI_ROUTES.leaderboardsRenderBlockingResources, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsSpeedIndex}
    from={UI_ACTUAL_ROUTES.leaderboardsSpeedIndex}
    to={generatePath(UI_ROUTES.leaderboardsSpeedIndex, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsUsesTextCompression}
    from={UI_ACTUAL_ROUTES.leaderboardsUsesTextCompression}
    to={generatePath(UI_ROUTES.leaderboardsUsesTextCompression, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsOffscreenImages}
    from={UI_ACTUAL_ROUTES.leaderboardsOffscreenImages}
    to={generatePath(UI_ROUTES.leaderboardsOffscreenImages, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumFonts}
    from={UI_ACTUAL_ROUTES.leaderboardsNumFonts}
    to={generatePath(UI_ROUTES.leaderboardsNumFonts, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumRequests}
    from={UI_ACTUAL_ROUTES.leaderboardsNumRequests}
    to={generatePath(UI_ROUTES.leaderboardsNumRequests, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumTasks}
    from={UI_ACTUAL_ROUTES.leaderboardsNumTasks}
    to={generatePath(UI_ROUTES.leaderboardsNumTasks, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumScripts}
    from={UI_ACTUAL_ROUTES.leaderboardsNumScripts}
    to={generatePath(UI_ROUTES.leaderboardsNumScripts, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsNumStylesheets}
    from={UI_ACTUAL_ROUTES.leaderboardsNumStylesheets}
    to={generatePath(UI_ROUTES.leaderboardsNumStylesheets, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsTotalByteWeight}
    from={UI_ACTUAL_ROUTES.leaderboardsTotalByteWeight}
    to={generatePath(UI_ROUTES.leaderboardsTotalByteWeight, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.leaderboardsTotalTaskTime}
    from={UI_ACTUAL_ROUTES.leaderboardsTotalTaskTime}
    to={generatePath(UI_ROUTES.leaderboardsTotalTaskTime, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.networkRequestsAnalysis}
    from={UI_ACTUAL_ROUTES.networkRequestsAnalysis}
    to={generatePath(UI_ROUTES.networkRequestsAnalysis, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.analysisDirectoryAnalysis}
    from={UI_ACTUAL_ROUTES.analysisDirectoryAnalysis}
    to={generatePath(UI_ROUTES.analysisDirectoryAnalysis, { lang })}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.projectsOverview}
    from={UI_ACTUAL_ROUTES.projectsOverview}
    to={generatePath(UI_ROUTES.projectsOverview, { lang })}
  />,

  <Redirect key="redirect" to={generatePath(UI_ROUTES.dashboard, { lang })} />,
]);

const getAdminRoutes = (lang: Languages) => ([
  <Route
    key={UI_ROUTES.adminAccountDetails}
    path={UI_ROUTES.adminAccountDetails}
    component={AccountDetailsPage}
  />,
  <Route
    key={UI_ROUTES.adminAccounts}
    path={UI_ROUTES.adminAccounts}
    component={AccountsPage}
  />,
  <Route
    key={UI_ROUTES.adminUsers}
    path={UI_ROUTES.adminUsers}
    component={UsersPage}
  />,

  <Redirect
    key={UI_ACTUAL_ROUTES.adminAccountDetails}
    from={UI_ACTUAL_ROUTES.adminAccountDetails}
    to={`${generatePath(UI_ROUTES.adminAccountDetails, { lang })}`}
  />,
  <Redirect
    key={UI_ACTUAL_ROUTES.adminAccounts}
    from={UI_ACTUAL_ROUTES.adminAccounts}
    to={`${generatePath(UI_ROUTES.adminAccounts, { lang })}`}
  />,
  <Redirect
    key={UI_ACTUAL_ROUTES.adminUsers}
    from={UI_ACTUAL_ROUTES.adminUsers}
    to={`${generatePath(UI_ROUTES.adminUsers, { lang })}`}
  />,
]);

const Routes: React.FC = () => {
  const currentLanguage = useSelector(selectLanguage);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  const publicRoutes = useMemo(() => getPublicRoutes(currentLanguage), [currentLanguage]);
  const restrictedRoutes = useMemo(() => getRestrictedRoutes(currentLanguage), [currentLanguage]);
  const adminRoutes = useMemo(() => getAdminRoutes(currentLanguage), [currentLanguage]);

  const isAdmin = useMemo(() => isLoggedIn && !!user?.is_admin, [isLoggedIn, user]);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route key={UI_ROUTES.resetPassword} path={UI_ROUTES.resetPassword} component={ResetPasswordPage} />
        <Redirect key={UI_ACTUAL_ROUTES.resetPassword} from={UI_ACTUAL_ROUTES.resetPassword} to={`${generatePath(UI_ROUTES.resetPassword, { lang: currentLanguage, id: ':id' })}`} />

        {isAdmin && adminRoutes}

        {!isLoggedIn && publicRoutes}
        {isLoggedIn && restrictedRoutes}

        {/*consider adding some 404 page*/}
      </Switch>
    </Suspense>
  );
};

export default Routes;

import { takeLatest, all, call, put, select } from 'redux-saga/effects';

import { Action } from '../commonTypes';
import { ProjectsOverviewActionTypes, IProjectsOverviewSortChangePayload } from './types';
import { SortOrder } from '../../enums/data';

import { projectsOverviewActions } from './actions';
import { selectSortDataKey, selectSortOrder } from './selectors';
import { selectProjects } from '../projects/selectors';

import { createProjectOverviewData, sortProjectsOverviewDataByKey } from '../../utils/projects';

// import { mockedProjectOverviewData } from '../../utils/mocks/projects';

function* sortChange(action: Action<ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_SORT_CHANGE, IProjectsOverviewSortChangePayload>): any {
  const { dataKey, order } = action.payload;

  if (order !== undefined) {
    yield put(projectsOverviewActions.sortRefresh({ dataKey, order }));
    return;
  }

  const currentDataKey = yield select(selectSortDataKey);
  const currentOrder = yield select(selectSortOrder);

  if (currentDataKey !== dataKey) {
    yield put(projectsOverviewActions.sortRefresh({ dataKey, order: SortOrder.Asc }));
    return;
  }

  if (currentOrder === SortOrder.Desc) {
    yield put(projectsOverviewActions.sortRefresh({ dataKey, order: SortOrder.Asc }));
    return;
  }

  yield put(projectsOverviewActions.sortRefresh({ dataKey, order: SortOrder.Desc }));
}

function* dataUpdate(): any {
  const projects =  yield select(selectProjects);
  const currentDataKey = yield select(selectSortDataKey);
  const currentOrder = yield select(selectSortOrder);

  const data = createProjectOverviewData(projects);
  const sortedData = yield call(sortProjectsOverviewDataByKey, data, currentDataKey, currentOrder);
  yield put(projectsOverviewActions.dataRefresh(sortedData));
}

export default function* projectsOverviewSaga() {
  yield all([
    takeLatest(ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_SORT_CHANGE, sortChange),
    takeLatest(ProjectsOverviewActionTypes.PROJECTS_OVERVIEW_DATA_UPDATE, dataUpdate),
  ]);
}

import { CompoundAnalysisActions } from './actions';
import { CompoundAnalysisActionTypes, CompoundAnalysisState, IUIPayload } from './types';
import { CompoundAnalysisUrl, ICompoundAnalysisNetworkDetail, ICompoundAnalysisThumbnails } from "../../services/DataService/types";
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { StaticResourceTypes } from '../../enums/networkDetails';
import { URLsDataKeys, NetworkDetailsDataKeys } from '../../enums/compoundAnalysis';
import { SortOrder, Feature } from '../../enums/data';

const initState: CompoundAnalysisState = {
  urls: [],
  allUrls: [],
  networkDetails: [],
  thumbnails: {} as ICompoundAnalysisThumbnails,
  activeUrlID: null,
  resourceTypeFilter: StaticResourceTypes.All,
  disabledResourceTypes: [],
  urlsSortKey: Feature.PerformanceScore,
  urlsSortOrder: SortOrder.Desc,
  urlSearch: '',
  networkDetailsSortKey: NetworkDetailsDataKeys.Position,
  networkDetailsSortOrder: SortOrder.Asc,
  UI: {
    offset: 0,
    limit: ROWS_COUNTS.TwentyFive,
    count: null,
    loading: false,
    urlsLoading: false,
    networkDetailsLoading: false,
  },
};

export function compoundAnalysisReducer(state: CompoundAnalysisState = initState, action: CompoundAnalysisActions): CompoundAnalysisState {
  switch (action.type) {
    case CompoundAnalysisActionTypes.URLS_REFRESH: {
      return {
        ...state,
        urls: [...action.payload as CompoundAnalysisUrl[]],
      }
    }
    case CompoundAnalysisActionTypes.ALL_URLS_REFRESH: {
      return {
        ...state,
        allUrls: [...action.payload as CompoundAnalysisUrl[]],
      }
    }
    case CompoundAnalysisActionTypes.NETWORK_DETAILS_REFRESH: {
      return {
        ...state,
        networkDetails: [...action.payload as ICompoundAnalysisNetworkDetail[]],
      }
    }
    case CompoundAnalysisActionTypes.THUMBNAILS_REFRESH: {
      return {
        ...state,
        thumbnails: { ...action.payload as ICompoundAnalysisThumbnails },
      }
    }
    case CompoundAnalysisActionTypes.ACTIVE_URL_ID_REFRESH: {
      return {
        ...state,
        activeUrlID: action.payload as string | null,
      }
    }
    case CompoundAnalysisActionTypes.RESOURCE_TYPE_FILTER_REFRESH: {
      return {
        ...state,
        resourceTypeFilter: action.payload as string,
      }
    }
    case CompoundAnalysisActionTypes.DISABLED_RESOURCE_TYPES_REFRESH: {
      return {
        ...state,
        disabledResourceTypes: [...action.payload as string[]],
      }
    }
    case CompoundAnalysisActionTypes.URL_SEARCH_REFRESH: {
      return {
        ...state,
        urlSearch: action.payload as string,
      }
    }
    case CompoundAnalysisActionTypes.URLS_SORT_ORDER_REFRESH: {
      return {
        ...state,
        urlsSortOrder: action.payload as SortOrder,
      }
    }
    case CompoundAnalysisActionTypes.URLS_SORT_KEY_REFRESH: {
      return {
        ...state,
        urlsSortKey: action.payload as URLsDataKeys | Feature,
      }
    }
    case CompoundAnalysisActionTypes.NETWORK_DETAILS_SORT_ORDER_REFRESH: {
      return {
        ...state,
        networkDetailsSortOrder: action.payload as SortOrder,
      }
    }
    case CompoundAnalysisActionTypes.NETWORK_DETAILS_SORT_KEY_REFRESH: {
      return {
        ...state,
        networkDetailsSortKey: action.payload as NetworkDetailsDataKeys,
      }
    }
    case CompoundAnalysisActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case CompoundAnalysisActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

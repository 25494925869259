import { CoreWebVitalsValues } from '../../enums/coreWebVitals';
import { IMetricsRankingCorrelation, IMetricsUrlGroup, IMetricsUrl } from "../../services/DataService/types";
import { MetricsActions } from './actions';
import { IUIPayload, MetricsActionTypes, MetricsState } from './types';
import { Feature, SortOrder } from '../../enums/data';
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { MetricsDataKeys } from '../../enums/metrics';
import { IUrlDetails } from '../../services/DataService/types';

const initState: MetricsState = {
  rankingCorrelationData: [],
  urlGroupsData: [],
  urlsData: [],
  urlsFullData: [],
  feature: Feature.LargestContentfulPaintFD,
  urlGroupsQuantifier: CoreWebVitalsValues.Top5,
  urlsQuantifier: CoreWebVitalsValues.Top5,
  urlsSortOrder: SortOrder.Asc,
  urlsSortKey: MetricsDataKeys.Comparison,
  urlDetails: [],
  activeUrlID: null,
  urlSearch: '',
  UI: {
    offset: 0,
    limit: ROWS_COUNTS.TwentyFive,
    count: null,
    loading: false,
    urlGroupsLoading: false,
    urlsLoading: false,
    urlsFullLoading: false,
    urlDetailsLoading: false,
  },
};

export function metricsReducer(state: MetricsState = initState, action: MetricsActions): MetricsState {
  switch (action.type) {
    case MetricsActionTypes.RANKING_CORRELATION_REFRESH: {
      return {
        ...state,
        rankingCorrelationData: [...action.payload as IMetricsRankingCorrelation[]],
      }
    }
    case MetricsActionTypes.URL_GROUPS_REFRESH: {
      return {
        ...state,
        urlGroupsData: [...action.payload as IMetricsUrlGroup[]],
      }
    }
    case MetricsActionTypes.URLS_REFRESH: {
      return {
        ...state,
        urlsData: [...action.payload as IMetricsUrl[]],
      }
    }
    case MetricsActionTypes.URLS_FULL_REFRESH: {
      return {
        ...state,
        urlsFullData: [...action.payload as IMetricsUrl[]],
      }
    }
    case MetricsActionTypes.URLS_SORT_ORDER_REFRESH: {
      return {
        ...state,
        urlsSortOrder: action.payload as SortOrder,
      }
    }
    case MetricsActionTypes.URLS_SORT_KEY_REFRESH: {
      return {
        ...state,
        urlsSortKey: action.payload as MetricsDataKeys,
      }
    }
    case MetricsActionTypes.URL_GROUPS_QUANTIFIER_REFRESH: {
      return {
        ...state,
        urlGroupsQuantifier: action.payload as CoreWebVitalsValues | string,
      }
    }
    case MetricsActionTypes.URLS_QUANTIFIER_REFRESH: {
      return {
        ...state,
        urlsQuantifier: action.payload as CoreWebVitalsValues | string,
      }
    }
    case MetricsActionTypes.FEATURE_REFRESH: {
      return {
        ...state,
        feature: action.payload as Feature,
      }
    }
    case MetricsActionTypes.URL_DETAILS_REFRESH: {
      return {
        ...state,
        urlDetails: [...action.payload as IUrlDetails[]],
      }
    }
    case MetricsActionTypes.ACTIVE_URL_ID_REFRESH: {
      return {
        ...state,
        activeUrlID: action.payload as string | null,
      }
    }
    case MetricsActionTypes.URL_SEARCH_REFRESH: {
      return {
        ...state,
        urlSearch: action.payload as string,
      }
    }
    case MetricsActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case MetricsActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

import styled from 'styled-components';

import { hexToRGBA } from '../../../utils/theme';

export const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 600px;

  overflow: auto;

  border-radius: 24px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #171C2810 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin-bottom: 24px;
  }

  &::-webkit-scrollbar-thumb {
    background: #555BF0 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  .search-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin-top: 0px;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 8px;

    .left-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-style: normal;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      color: #181818;
    }

    .right-section {
      display: flex;
      align-items: center;

      .search-input-container {
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          width: 290px;
        }
        .search-input {
          height: 34px;
          max-width: 290px;
          font-size: 12px;
          line-height: 17px;
        }
        .icon-container {
          top: 6px;
          right: 7px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
`;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

import AntdModal from 'antd/lib/modal';

import { modalsActions } from '../../../redux/modals/actions';
import { selectAnnotationVisible } from '../../../redux/modals/selectors';
import { IconClose } from '../../../components/styled/icons';
import { Button, ButtonType } from '../../../components/styled/Button';
import { StyledBodyWrapper } from './AnnotationModal.styled';
import { ButtonTextKeys, FieldPlaceholderKeys } from '../../../enums/locales/forms';
import { DashboardLabelKeys } from '../../../enums/locales/dashboard';
import { selectActiveProjectAnnotations, selectUI } from '../../../redux/projects/selectors';
import { projectsActions } from '../../../redux/projects/actions';

const AnnotationModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectAnnotationVisible);
  const annotations = useSelector(selectActiveProjectAnnotations);
  const { editingAnnotationDate } = useSelector(selectUI);
  const [date, setDate] = useState<Date>(new Date());
  const [name, setName] = useState<string>('');

  const isEdit = !!editingAnnotationDate;

  useEffect(() => {
    if (!!editingAnnotationDate && annotations) {
      setDate(new Date(editingAnnotationDate));
      const nameByDate = annotations.find(({ date }) => date === format(new Date(editingAnnotationDate), 'yyyy-MM-dd'))?.name;
      if (nameByDate) {
        setName(nameByDate);
      }
    }
  }, [editingAnnotationDate, annotations]);

  useEffect(() => {
    const existedAnnotation = annotations?.find(({ date: annotationDate }) => format(date, 'yyyy-MM-dd') === annotationDate);
    if (!!existedAnnotation && (editingAnnotationDate ? existedAnnotation.date === format(new Date(editingAnnotationDate), 'yyyy-MM-dd') : true)) {
      setName(existedAnnotation.name);
    }
  }, [date, annotations, setName, editingAnnotationDate]);

  const handleCancel = useCallback(() => {
    dispatch(projectsActions.uiMerge({ editingAnnotationDate: null }));
    setName('');
    setDate(new Date());
    dispatch(modalsActions.annotationHide());
  }, [dispatch]);

  const onAnnotationsUpdate = useCallback(() => {
    if (annotations) {
      const oldAnnotations = annotations.filter(({ date: annotationDate }) => format(date, 'yyyy-MM-dd') !== annotationDate && (editingAnnotationDate ? (format(new Date(editingAnnotationDate), 'yyyy-MM-dd') !== annotationDate) : true));
      const newAnnotations = [...oldAnnotations];
      if (name) {
        newAnnotations.push({ date: format(date, 'yyyy-MM-dd'), name });
      }
      dispatch(projectsActions.annotationsUpdate(newAnnotations));
    }
    setName('');
    setDate(new Date());
    dispatch(projectsActions.uiMerge({ editingAnnotationDate: null }));
    dispatch(modalsActions.annotationHide());
  }, [dispatch, setName, setDate, date, annotations, name, editingAnnotationDate]);

  const onChange = useCallback((newDate) => setDate(newDate as Date), [setDate]);
  const onChangeTextarea = useCallback((e) => {
    setName(e.target.value);
  }, [setName]);

  const isSaveDisabled = !date;

  return (
    <AntdModal
      centered
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="limit-modal-container"
      maskStyle={{ zIndex: 10001 }}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className="limit-modal-title">
          {isEdit ? t(DashboardLabelKeys.EditAnnotationLabel) : t(DashboardLabelKeys.CreateAnnotationLabel)}
        </div>
        <div className="inputs-container">
          <DatePicker
            calendarClassName="annotation-date"
            selected={date}
            placeholderText={t(FieldPlaceholderKeys.AnnotationDatePlaceholder)}
            dateFormat="MMM dd, yyyy"
            onChange={onChange}
            popperPlacement="bottom"
          />
        </div>
        <div className="inputs-container">
          <textarea
            placeholder={`${t(FieldPlaceholderKeys.AnnotationPlaceholder)}...`}
            value={name}
            onChange={onChangeTextarea}
            rows={6}
          />
        </div>
        <div className="update-btn-container">
          <Button
            type={ButtonType.Primary}
            disabled={isSaveDisabled}
            onClick={onAnnotationsUpdate}
            title={t(ButtonTextKeys.SaveAnnotation)}
          />
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default AnnotationModal;

import { takeLatest, all, put, call, select } from 'redux-saga/effects';

import { DataService } from '../../services/DataService';

import { Action } from '../commonTypes';
import { OverviewActionTypes } from './types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import { FeatureOverviewResponseBody } from '../../services/DataService/types';
import { EmptyObject } from '../../types/common.types';

import { overviewActions } from './actions';
import { selectActiveProjectID } from '../projects/selectors';

function* overviewReload(action: Action<OverviewActionTypes.FEATURE_OVERVIEW_RELOAD, EmptyObject>): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

	yield put(overviewActions.uiMerge({ loading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<FeatureOverviewResponseBody> = yield call(DataService.featureOverview, projectID, options);
  if (!result?.data) {
    yield put(overviewActions.uiMerge({ loading: false }));
    return;
  }

  const response: FeatureOverviewResponseBody = result?.data;

  const { data } = response;

  yield put(overviewActions.overviewRefresh(data));
  yield put(overviewActions.uiMerge({ loading: false }));
}

export default function* overviewSaga() {
  yield all([
    takeLatest(OverviewActionTypes.FEATURE_OVERVIEW_RELOAD, overviewReload),
  ]);
}

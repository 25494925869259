import styled from "styled-components";

import { hexToRGBA } from "../../utils/theme";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => hexToRGBA(theme.colors.primaryBg, 0.5)};
  border-radius: 30px;
  padding: 0 20px 20px;
  font-size: 14px;

  table {
    position: relative;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tbody {
      border-radius: 12px;

      ::after {
        content: '';
        position: absolute;
        box-shadow: 0px 2px 10px #0000000F;
        left: 0;
        right: 0;
        top: 83px;
        bottom: 0;
        z-index: -1;
        border-radius: 12px;
      }

      tr {
        background: ${({ theme }) => theme.colors.white};
        height: 66px;

        td {
          vertical-align: middle;
          padding: 0 25px;
          @media (max-width: 1440px) {
            &:not(:first-child) {
              padding: 0 18px;
            }
          }
          @media (max-width: 1280px) {
            &:not(:first-child) {
              padding: 0 6px;
            }
          }
          &:not(:first-child) {
            text-align: center;
          }
          &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:nth-child(2n+1) {
          background: #fdfdfd;
        }

        &:not(:first-child) {
          border-top: 2px solid ${({ theme }) => theme.colors.tableRowBorder};
        }

        &:first-child td:first-child {
          border-top-left-radius: 12px;
        }

        &:first-child td:last-child {
          border-top-right-radius: 12px;
        }

        &:last-child td:first-child {
          border-bottom-left-radius: 12px;
        }

        &:last-child td:last-child {
          border-bottom-right-radius: 12px;
        }
      }
    }
  }

  .loading-placeholder {
    display: flex;
    height: 30px;
    border-radius: 10px;
    line-height: 30px;
    background: ${({ theme }) => theme.colors.primaryBg};
    width: 100%;
  }
`;
export enum FIELD_NAMES {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
  Name = 'name',
  Address = 'address',
  Zip = 'zip',
  City = 'city',
  Country = 'country',
  Vat = 'vat',
}
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';

import AntdModal from 'antd/lib/modal';

import { MetricsKeys } from '../../../enums/locales/metrics';

import { CompoundAnalysisUrl } from '../../../services/DataService/types';
import { compoundAnalysisActions } from '../../../redux/compound-analysis/actions';
import { modalsActions } from '../../../redux/modals/actions';
import {
  selectActiveUrlData,
  selectNetworkDetails,
  selectNetworkResourceTypesByQuantity,
  selectResourceTypeFilter,
  selectNetworkDetailsSortKey,
  selectNetworkDetailsSortOrder,
  selectUI,
} from '../../../redux/compound-analysis/selectors';
import { selectNetworkDetailsVisible } from '../../../redux/modals/selectors';
import { selectActiveProjectDomain, selectLatestCrawlCreated } from '../../../redux/projects/selectors';

import { LoaderModalMessageKeys } from '../../../enums/locales/modals';
import { NetworkRequestsAnalysisKeys } from '../../../enums/locales/compoundAnalysis';
import { SortOrder } from '../../../enums/data';
import { NetworkDetailsDataKeys } from '../../../enums/compoundAnalysis';
import { StaticResourceTypes } from '../../../enums/networkDetails';
import { ISortParams } from './types';
import { getColumns } from './assets';

import { Select } from '../../../components/styled/Select';
import { IconClose, IconExternalLink, IconExport, IconSearch } from '../../../components/styled/icons';
import { DetailsTable } from '../../../components/DetailsTable';
import { Input } from '../../../components/styled/Input';
import { TreeMap } from '../../../components/charts/TreeMap';
import { createMonitorData, createTreeQuantityData, createTreeSizeData } from '../../../utils/charts';
import { StyledBodyWrapper, ColoredTD } from './NetworkDetailsModal.styled';
import { ANIMATION_CLASSES } from '../../../constants/animations';

const NetworkDetailsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectNetworkDetailsVisible);
  const projectDomain = useSelector(selectActiveProjectDomain);
  const activeUrlData = useSelector(selectActiveUrlData) as CompoundAnalysisUrl;
  const networkDetails = useSelector(selectNetworkDetails);
  const networkResourceTypesByQuantity = useSelector(selectNetworkResourceTypesByQuantity);
  const resourceTypeFilter = useSelector(selectResourceTypeFilter);
  const { networkDetailsLoading } = useSelector(selectUI);
  const sortKey = useSelector(selectNetworkDetailsSortKey);
  const sortOrder = useSelector(selectNetworkDetailsSortOrder);
  const crawlDate = useSelector(selectLatestCrawlCreated);
  const [search, setSearch] = useState('');

  const openUrl = useCallback((url: string) => () => {
    window.open(url, '_blank');
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.networkDetailsHide());
    dispatch(compoundAnalysisActions.activeUrlIDRefresh(null));
    dispatch(compoundAnalysisActions.networkDetailsRefresh([]));
  }, [dispatch]);

  const onFilter = useCallback((filter) => {
    dispatch(compoundAnalysisActions.resourceTypeFilterRefresh(filter));
  }, [dispatch]);

  const onSortChange = useCallback((key: NetworkDetailsDataKeys) => () => {
    if (key !== sortKey) {
      dispatch(compoundAnalysisActions.networkDetailsSortKeyRefresh(key));
    }
    dispatch(compoundAnalysisActions.networkDetailsSortOrderRefresh(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc));
  }, [dispatch, sortOrder, sortKey]);

  useEffect(() => {
    dispatch(compoundAnalysisActions.networkDetailsSortApply());
  }, [sortOrder, sortKey]);

  const onSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, [setSearch]);

  const allResourceTypesQuantity = useMemo(() => networkResourceTypesByQuantity.reduce((acc, { quantity }) => acc += quantity, 0), [networkResourceTypesByQuantity]);
  const preparedQuantityData = useMemo(() => createTreeQuantityData(networkDetails), [networkDetails]);
  const preparedSizeData = useMemo(() => createTreeSizeData(networkDetails), [networkDetails]);
  const preparedMotitorData = useMemo(() => createMonitorData(networkDetails), [networkDetails]);
  const columns = useMemo(() => {
    const sortParams: ISortParams = { sortKey, sortOrder, onSortChange };
    return getColumns(t, openUrl, sortParams)
  }, [t, openUrl, sortKey, sortOrder, onSortChange]);

  const filteredDetails = useMemo(() => networkDetails.filter(({ resource_type, url }) => {
    const isCurrentResource = resourceTypeFilter === StaticResourceTypes.All || resource_type === resourceTypeFilter;
    const isSearchedUrl = url.includes(search);

    return isSearchedUrl && isCurrentResource;
  }), [networkDetails, resourceTypeFilter, search]);

  const emptyFeatures = useMemo(() => Array.from({ length: 9 }, () => ({})), []);
  const tdClassName = clsx('loading-placeholder', { [ANIMATION_CLASSES.animatedSkeleton]: networkDetailsLoading });

  const resourceTypeOptions = useMemo(() => {
    return [
      { value: StaticResourceTypes.All, label: <span>All&nbsp;&bull;&nbsp;{`${allResourceTypesQuantity}`}</span> },
      ...networkResourceTypesByQuantity.map(({ resource_type, quantity }) => ({ value: resource_type, label: <span>{`${resource_type}`}&nbsp;&bull;&nbsp;{`${quantity}`}</span>})),
    ];
  }, [networkResourceTypesByQuantity]);

  return (
    <AntdModal
      centered
      title={(
        <>
          <div className="network-details-modal-title">
            {t(MetricsKeys.DetailsTitle)}
          </div>
          <div className="network-details-modal-link">
            <IconExternalLink onClick={openUrl(activeUrlData?.url)} />
            <a href={activeUrlData?.url} target="_blank" rel="noreferrer"><span>{activeUrlData?.url}</span></a>
          </div>
          <div className="network-details-modal-crawl-date">
            ({crawlDate})
          </div>
        </>
      )}
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      wrapClassName="network-details-modal-container"
      width={'90%'}
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className="resource-monitor-container">
          <div className="resource-monitor">
            <table>
              <thead>
                <tr>
                  <th>{t(NetworkRequestsAnalysisKeys.ResourceMonitorTypeLabel)}</th>
                  <th>{t(NetworkRequestsAnalysisKeys.ResourceMonitorQuantityLabel)}</th>
                  <th>{t(NetworkRequestsAnalysisKeys.ResourceMonitorSizeLabel)}</th>
                </tr>
              </thead>
              <tbody>
                {networkDetailsLoading && emptyFeatures.map((_, index) => (
                  <tr key={index}>
                    <td>
                      <div className={tdClassName}></div>
                    </td>
                    <td>
                      <div className={tdClassName}></div>
                    </td>
                    <td>
                      <div className={tdClassName}></div>
                    </td>
                  </tr>
                ))}
                {!networkDetailsLoading && preparedMotitorData.map(({ name, color, quantity, size }) => (
                  <tr key={name}>
                    <td>
                      <ColoredTD color={color}>{name}</ColoredTD>
                    </td>
                    <td>{quantity}</td>
                    <td>{size}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="tree-map-container">
            <TreeMap title={t(NetworkRequestsAnalysisKeys.QuantityTreeTitle)} data={preparedQuantityData} loading={networkDetailsLoading} />
          </div>
          <div className="tree-map-container">
            <TreeMap title={t(NetworkRequestsAnalysisKeys.SizeTreeTitle)} data={preparedSizeData} loading={networkDetailsLoading} />
          </div>
        </div>
        <div className="resource-type-filters">
          <Select
            width={180}
            onChange={onFilter}
            value={resourceTypeFilter}
            options={resourceTypeOptions}
          />
          <div className="export">
            <Input
              className="search-input"
              value={search}
              onChange={onSearch}
              placeholder={`${t(MetricsKeys.FilterUrls)}...`}
              icon={<IconSearch />}
            />
            <CSVLink
              data={filteredDetails}
              filename={`network-requests-analysis-${projectDomain}.csv`}
              className={clsx("download-csv", { loading: networkDetailsLoading })}
              target="_blank"
            >
              <div>{networkDetailsLoading ? t(LoaderModalMessageKeys.Default) : t(NetworkRequestsAnalysisKeys.Export)}</div>
              <IconExport />
            </CSVLink>
          </div>
        </div>
        <DetailsTable
          columns={columns}
          loading={networkDetailsLoading}
          placeholdersCount={21}
          data={filteredDetails}
        />
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default NetworkDetailsModal;

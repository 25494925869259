import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  width: 100%;

  .project-item-title,
  .project-item-groups {
    width: 100%;
  }

  .project-item-title,
  .project-item-group {
    height: 34px;
  }

  &:not(:first-child) {
    .project-item-title {
      margin-top: 15px;
    }
  }

  .project-item-title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 40px;

    cursor: pointer;

    .project-item-domain-picture {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    .project-item-domain-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-style: normal;
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
      letter-spacing: 0px;
      color: #171C28;
      width: 170px;

      span {
        display: block;
        max-width: 165px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .project-item-test-mode-label {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 24px;
        width: 24px;

        margin-left: 10px;

        border-radius: 50%;
        background: #181818 0% 0% no-repeat padding-box;

        span {
          font-size: 14px;
          font-weight: 600;
          color: white;
        }
      }

    &.active {
      background: #EDEEFE 0% 0% no-repeat padding-box;
      border-radius: 6px;

      .project-item-domain-name {
        position: relative;
        color: #555BF0;
        border-radius: 6px;
        height: 34px;
        width: 170px;
      }
    }
  }

  &.test-mode {
    .project-item-title {
      .project-item-test-mode-label {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 24px;
        width: 24px;

        margin-left: 10px;
        margin-right: 5px;

        border-radius: 50%;
        background: #181818 0% 0% no-repeat padding-box;

        span {
          font-size: 14px;
          font-weight: 600;
          color: white;
        }
      }
    }
  }

  .project-item-groups {
    margin-top: 5px;
    .project-item-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 35px;
      padding-left: 5px;
      margin-right: 34px;

      cursor: pointer;

      font-style: normal;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      color: #171C28;

      &.active {
        background: #EDEEFE 0% 0% no-repeat padding-box;
        border-radius: 6px;
        color: #555BF0;
      }
    }
  }

  .project-item-edit-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;

    height: 54px;
    margin-bottom: 6px;
  
    button {
      height: 34px;
      width: 146px;

      .content {
        .title-wrapper {
          .title {
            font-style: normal;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            color: #181818;
          }
        }
      }
    }

    &.disabled {
      cursor: initial;
  
      button {
        pointer-events: none;
        
        svg {
          path {
            fill: #858585;
          }
        }

        .content {
          .title-wrapper {
            .title {
              color: #858585;
            }
          }
        }
      }
    }
  }

  .project-item-crawl-in-progress-indicator {
      width: calc(100%);
      margin-top: 1px;
      margin-bottom: 6px;

      .stacked-indicator-statistics {
        display: none;
      }

      .stacked-indicator-statistics + div {
        min-height: 4px;
        border-radius: 100px;
        background:#dbdbdb;

        &::before {
          border-radius: 100px;
        }
      }
      &.hidden {
        display: none;
      }
    }
`;

import axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { EmptyObject } from '../../types/common.types';
import { IBaseResponse, IBaseConfig, IBasicHeaders, IProcessError, IProcessOptions } from './types';
import { RequestResultModalType } from '../../enums/modals';
import * as LocaleStorageUtils from '../../utils/storage';
import { store } from '../../redux/store';
import { authActions } from '../../redux/auth/actions';
import { modalsActions } from '../../redux/modals/actions';

export class BaseAPIService {
	baseServiceURL = '';

	constructor(url = '') {
		if (url) {
			this.baseServiceURL = url;
		}

		this.retryAllActions = this.retryAllActions.bind(this);
		this.processError = this.processError.bind(this);
		this.processResponse = this.processResponse.bind(this);

		this.get = this.get.bind(this);
		this.post = this.post.bind(this);
		this.patch = this.patch.bind(this);
		this.delete = this.delete.bind(this);
	}

	get baseURL(): string {
		return this.baseServiceURL;
	}

	get agent(): AxiosInstance {
		const headers: IBasicHeaders = {};

		const accessToken = LocaleStorageUtils.restoreAccessToken();

		if (accessToken) {
			headers.Authorization = `Bearer ${accessToken}`;
		}

		return axios.create({
			baseURL: this.baseURL,
			headers,
		});
	}

	retryAllActions() {
		LocaleStorageUtils.storeValue('isRefreshingTokens', '');
		const actionsToRetry = LocaleStorageUtils.restoreValue('actionsToRetry', []);
		actionsToRetry.forEach((action: any) => {
			store.dispatch(action);
		});
		LocaleStorageUtils.storeValue('actionsToRetry', []);
	}

	processError(error: AxiosError, options?: IProcessOptions): IProcessError {
		const accessToken = LocaleStorageUtils.restoreAccessToken();
		const { retryAction } = options || {};
		const status = error?.response?.status || null;
		const axiosError = error?.response?.data?.error;

		if (status === 401 && accessToken && retryAction) {
			const isRefreshingTokens = LocaleStorageUtils.restoreValue('isRefreshingTokens', '');
			if (!isRefreshingTokens) {
				store.dispatch(authActions.tokensRefresh());
				LocaleStorageUtils.storeValue('isRefreshingTokens', true);
			}
			if (retryAction) {
				const actionsToRetry = LocaleStorageUtils.restoreValue('actionsToRetry', []);
				LocaleStorageUtils.storeValue('actionsToRetry', [...actionsToRetry, retryAction]);
			}
		}

		if (status === 401 && !accessToken && !retryAction) {
			store.dispatch(modalsActions.requestResultShow({ type: RequestResultModalType.AuthError }));
		}

		if (status === 418) {
			store.dispatch(modalsActions.requestResultShow({ type: RequestResultModalType.NotEnoughResources }));
		}

		if (status !== 401 && status !== 418 && status !== 500) {
			store.dispatch(modalsActions.requestResultShow({ type: RequestResultModalType.ConnectionError, retryActions: [retryAction] }));
		}

		return {
			errorMessage: axiosError,
			status,
		};
	}

	processResponse<T>(response: AxiosResponse): T {
		const axiosData = response?.data;

		return axiosData;
	}

	async get<T, P = EmptyObject>(url: string, params?: P, config: IBaseConfig = {}, options: IProcessOptions = {}): Promise<IBaseResponse<T>> {
		try {
			const response = await this.agent.get(url, {
				params,
				...config,
			});

			const result: IBaseResponse<T> = {
				data: this.processResponse<T>(response),
			};
			return Promise.resolve(result);
		} catch (error) {
			const result: IBaseResponse<T> = { error: this.processError(error as AxiosError, options) };
			return Promise.resolve(result);
		}
	}

	async post<T, D = EmptyObject>(url: string, data: D, config: IBaseConfig = {}, options: IProcessOptions = {}): Promise<IBaseResponse<T>> {
		try {
			const response = await this.agent.post(url, { ...data }, { ...config });

			const result: IBaseResponse<T> = {
				data: this.processResponse<T>(response),
			};

			return Promise.resolve(result);
		} catch (error) {
			const result = { error: this.processError(error as AxiosError, options) };
			return Promise.resolve(result);
		}
	}

	async put<T, D = EmptyObject>(url: string, data: D, config: IBaseConfig = {}, options: IProcessOptions = {}): Promise<IBaseResponse<T>> {
		try {
			const response = await this.agent.put(url, data, { ...config });

			const result: IBaseResponse<T> = {
				data: this.processResponse<T>(response),
			};

			return Promise.resolve(result);
		} catch (error) {
			const result = { error: this.processError(error as AxiosError, options) };
			return Promise.resolve(result);
		}
	}

	async patch<T, D = EmptyObject>(url: string, data: D, config: IBaseConfig = {}, options: IProcessOptions = {}): Promise<IBaseResponse<T>> {
		try {
			const response = await this.agent.patch(url, { ...data }, { ...config });

			const result: IBaseResponse<T> = {
				data: this.processResponse<T>(response),
			};

			return Promise.resolve(result);
		} catch (error) {
			const result = { error: this.processError(error as AxiosError, options) };
			return Promise.resolve(result);
		}
	}

	async delete<T>(url: string, config: IBaseConfig = {}, options: IProcessOptions = {}): Promise<IBaseResponse<T>> {
		try {
			const response = await this.agent.delete(url, { ...config });

			const result: IBaseResponse<T> = {
				data: this.processResponse<T>(response),
			};
			return Promise.resolve(result);
		} catch (error) {
			const result = { error: this.processError(error as AxiosError, options) };
			return Promise.resolve(result);
		}
	}
}

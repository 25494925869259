
import React from 'react';
import { format } from 'date-fns';
import { TFunction } from 'react-i18next';

import { ACCOUNT_DETAILS_ACCOUNT_DATE_FORMAT } from '../../../../constants/formats';
import { AccountsKeys } from '../../../../enums/locales/accounts';
import { FieldButtonTextKeys, FieldLabelKeys } from '../../../../enums/locales/forms';
import { ProjectsOverviewFieldsKeys, ProjectsOverviewKeys } from '../../../../enums/locales/projects';

export const getAccountsColumns = (t: TFunction, onAccountSelect: (id: string) => () => void) => {
  return [
    {
      dataIndex: 'license_id',
      title: t(AccountsKeys.LicenseIDLabel),
      width: '14%',
      render: (item: any) => <span onClick={onAccountSelect(item.license_id)}>{item.license_id}</span>,
    },
    {
      dataIndex: 'creator_name',
      title: t(ProjectsOverviewFieldsKeys.Name),
      width: '14%',
      render: (item: any) => <span onClick={onAccountSelect(item.license_id)}>{item.creator_name}</span>,
    },
    {
      dataIndex: 'creator_email',
      title: t(FieldLabelKeys.Email),
      width: '20%',
      render: (item: any) => <span onClick={onAccountSelect(item.license_id)}>{item.creator_email}</span>,
    },
    {
      dataIndex: 'company.name',
      title: t(AccountsKeys.CompanyLabel),
      width: '10%',
      render: (item: any) => <span onClick={onAccountSelect(item.license_id)}>{item.company.name}</span>,
    },
    {
      dataIndex: 'user_slots_used',
      title: t(FieldButtonTextKeys.EndUsers),
      width: '10%',
    },
    {
      dataIndex: 'project_slots_used',
      title: t(ProjectsOverviewKeys.HeaderTitle),
      width: '10%',
    },
    {
      dataIndex: 'keyword_slots_used',
      title: t(ProjectsOverviewFieldsKeys.Keywords),
      width: '10%',
    },
    {
      dataIndex: 'created_at',
      title: t(AccountsKeys.CreatedAtLabel),
      render: (item: any) => <>{format(new Date(item.created_at), ACCOUNT_DETAILS_ACCOUNT_DATE_FORMAT)}</>,
      width: '12%',
    },
  ];
};

import { AuthActions } from './actions';
import { AuthActionTypes, AuthState, IUserPayload } from './types';

const initState: AuthState = {
  user: null,
  UI: {
    loading: false,
  },
};

export function authReducer(state: AuthState = initState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.USER_REFRESH: {
      return {
        ...state,
        user: {
          ...action.payload as IUserPayload,
        },
      };
    }
    case AuthActionTypes.USER_RESET: {
      return {
        ...state,
        user: null,
      };
    }
    case AuthActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

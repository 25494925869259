import { ActionUnion, createAction } from '../commonTypes';
import { AccountDetailsActionTypes, IUIPayload } from './types';
import { IProject, ICrawl } from '../../services/ProjectService/types';
import { ILicense } from '../../services/LicensesService/types';
import { IUserSimple } from '../../services/AuthService/types';
import { EmptyObject } from '../../types/common.types';
import { ProjectsDataKeys, CrawlsDataKeys } from '../../enums/projects';
import { SortOrder } from '../../enums/data';

export const accountDetailsActions = {
  accountsReload: (): { type: AccountDetailsActionTypes, payload: EmptyObject } => createAction(
    AccountDetailsActionTypes.ACCOUNTS_DATA_RELOAD,
  ),
  accountsRefresh: (payload: ILicense[]): { type: AccountDetailsActionTypes, payload: ILicense[] } => createAction(
    AccountDetailsActionTypes.ACCOUNTS_DATA_REFRESH,
    payload,
  ),
  creatorReload: (): { type: AccountDetailsActionTypes, payload: EmptyObject } => createAction(
    AccountDetailsActionTypes.CREATOR_DATA_RELOAD,
  ),
  creatorRefresh: (payload: IUserSimple): { type: AccountDetailsActionTypes, payload: IUserSimple } => createAction(
    AccountDetailsActionTypes.CREATOR_DATA_REFRESH,
    payload,
  ),
  licenseReload: (): { type: AccountDetailsActionTypes, payload: EmptyObject } => createAction(
    AccountDetailsActionTypes.LICENSE_DATA_RELOAD,
  ),
  licenseRefresh: (payload: ILicense): { type: AccountDetailsActionTypes, payload: ILicense } => createAction(
    AccountDetailsActionTypes.LICENSE_DATA_REFRESH,
    payload,
  ),
  projectsReload: (): { type: AccountDetailsActionTypes, payload: EmptyObject } => createAction(
    AccountDetailsActionTypes.PROJECTS_DATA_RELOAD,
  ),
  projectsRefresh: (payload: IProject[]): { type: AccountDetailsActionTypes, payload: IProject[] } => createAction(
    AccountDetailsActionTypes.PROJECTS_DATA_REFRESH,
    payload,
  ),
  projectsSortKeyRefresh: (payload: ProjectsDataKeys): { type: AccountDetailsActionTypes, payload: ProjectsDataKeys } => createAction(
    AccountDetailsActionTypes.PROJECTS_SORT_KEY_REFRESH,
    payload,
  ),
  projectsSortOrderRefresh: (payload: SortOrder): { type: AccountDetailsActionTypes, payload: SortOrder } => createAction(
    AccountDetailsActionTypes.PROJECTS_SORT_ORDER_REFRESH,
    payload,
  ),
  crawlsReload: (): { type: AccountDetailsActionTypes, payload: EmptyObject } => createAction(
    AccountDetailsActionTypes.CRAWLS_DATA_RELOAD,
  ),
  crawlsRefresh: (payload: ICrawl[]): { type: AccountDetailsActionTypes, payload: ICrawl[] } => createAction(
    AccountDetailsActionTypes.CRAWLS_DATA_REFRESH,
    payload,
  ),
  crawlsSortKeyRefresh: (payload: CrawlsDataKeys): { type: AccountDetailsActionTypes, payload: CrawlsDataKeys } => createAction(
    AccountDetailsActionTypes.CRAWLS_SORT_KEY_REFRESH,
    payload,
  ),
  crawlsSortOrderRefresh: (payload: SortOrder): { type: AccountDetailsActionTypes, payload: SortOrder } => createAction(
    AccountDetailsActionTypes.CRAWLS_SORT_ORDER_REFRESH,
    payload,
  ),
  accountLicenseIDRefresh: (payload: string | null): { type: AccountDetailsActionTypes, payload: string | null } => createAction(
    AccountDetailsActionTypes.ACCOUNT_LICENSE_ID_REFRESH,
    payload,
  ),
  targetLicenseIDRefresh: (payload: string | null): { type: AccountDetailsActionTypes, payload: string | null } => createAction(
    AccountDetailsActionTypes.TARGET_LICENSE_ID_REFRESH,
    payload,
  ),
  projectIDRefresh: (payload: string | null): { type: AccountDetailsActionTypes, payload: string | null } => createAction(
    AccountDetailsActionTypes.PROJECT_ID_REFRESH,
    payload,
  ),
  accountsSearchRefresh: (payload: string): { type: AccountDetailsActionTypes, payload: string } => createAction(
    AccountDetailsActionTypes.ACCOUNTS_SEARCH_REFRESH,
    payload,
  ),
  projectsSearchRefresh: (payload: string): { type: AccountDetailsActionTypes, payload: string } => createAction(
    AccountDetailsActionTypes.PROJECTS_SEARCH_REFRESH,
    payload,
  ),
  crawlsSearchRefresh: (payload: string): { type: AccountDetailsActionTypes, payload: string } => createAction(
    AccountDetailsActionTypes.CRAWLS_SEARCH_REFRESH,
    payload,
  ),
  projectMove: (): { type: AccountDetailsActionTypes, payload: EmptyObject } => createAction(
    AccountDetailsActionTypes.PROJECT_MOVE,
  ),
  projectRemove: (payload: string): { type: AccountDetailsActionTypes, payload: string } => createAction(
    AccountDetailsActionTypes.PROJECT_REMOVE,
    payload,
  ),
  crawlRemove: (payload: string): { type: AccountDetailsActionTypes, payload: string } => createAction(
    AccountDetailsActionTypes.CRAWL_REMOVE,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: AccountDetailsActionTypes, payload: IUIPayload } => createAction(
    AccountDetailsActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: AccountDetailsActionTypes, payload: EmptyObject } => createAction(
    AccountDetailsActionTypes.RESET_ALL,
  ),
}

export type AccountDetailsActions = ActionUnion<typeof accountDetailsActions>;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import sortBy from "lodash/sortBy";

import * as FormikUtils from '../../../utils/formik';
import * as ProjectSettingsUtils from '../../../utils/projectSettings';
import { projectSchema } from '../../../utils/validation/schemas';
import { formSteps, projectSettingsSteps } from './assets';

import { Wrapper } from './ProjectForm.styled';
import { useDispatch, useSelector } from 'react-redux';
import { projectSettingsActions } from '../../../redux/project-settings/actions';
import { selectLanguage } from '../../../redux/app/selectors';
import isoCountries from '../../../lib/i18n-iso-countries';
import { FIELD_NAMES } from '../../../enums/projectSettings';
import { selectBenchmarksSuggestions, selectData, selectFinishedSteps, selectKeywordsSuggestions, selectUI } from '../../../redux/project-settings/selectors';
import { IProjectSettingsData } from '../../../redux/project-settings/types';
import { countriesCodes } from '../../../constants/projectSettings';

const initValues: IProjectSettingsData = {
  domain: '',
  name: '',
  country_code: '',
  strategy: '',
  recrawl_interval: null,
  keywords: [],
  benchmarks: [],
  url_groups: [],
  invites: [],
  goals: [],
};

export const ProjectFormBag = FormikUtils.createFormBag();

export interface IProps {
  onSubmit: (values: FormikValues) => void;
}

const ProjectForm: React.FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);
  const data = useSelector(selectData);
  const benchmarksSuggestions = useSelector(selectBenchmarksSuggestions);
  const keywordsSuggestions = useSelector(selectKeywordsSuggestions);
  const originalFinishedSteps = useSelector(selectFinishedSteps);
  const { isNew, keywordsCount, keywordsOffset, keywordsLimit, keywordsLoading } = useSelector(selectUI);
  const [mounted, setMounted] = useState(false);
  const [initErrors, setInitErrors] = useState<any>({});

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data.domain && data.country_code && originalFinishedSteps.includes(0) && originalFinishedSteps.includes(1)) {
      dispatch(projectSettingsActions.benchmarksSuggestionsReload());
      dispatch(projectSettingsActions.keywordsSuggestionsReload());
    }
  }, [dispatch, data.domain, data.country_code, originalFinishedSteps.includes(0), originalFinishedSteps.includes(1)]);

  useEffect(() => {
    if (data.domain && data.country_code && originalFinishedSteps.includes(0) && originalFinishedSteps.includes(1)) {
      dispatch(projectSettingsActions.keywordsSuggestionsReload());
    }
  }, [dispatch, data.domain, data.country_code, originalFinishedSteps.includes(0), originalFinishedSteps.includes(1), keywordsOffset, keywordsLimit]);

  const countriesOptions = useMemo(() => {
    return sortBy(countriesCodes.map((countryCode) => ({
      value: countryCode.toLowerCase(),
      label: isoCountries.getName(countryCode, lang, { select: 'official' }),
    })), ['label']);
  }, [lang]);

  useEffect(() => {
    const finishedSteps: number[] = mounted ? ProjectSettingsUtils.createFinishedSteps(formSteps, ProjectFormBag.errors, ProjectFormBag.touched, ProjectFormBag.values) : [];
    dispatch(projectSettingsActions.finishedStepsRefresh(finishedSteps));
  }, [data, ProjectFormBag.values, ProjectFormBag.touched, ProjectFormBag.errors]);

  useEffect(() => {
    ProjectFormBag.validateForm().then((result: any) => {
      setInitErrors(result);
    });
  }, []);

  const onBlur = useCallback((e) => {
    dispatch(projectSettingsActions.dataRefresh({ [e.target.name]: e.target.value }));
  }, [ProjectFormBag.touched, ProjectFormBag.values, ProjectFormBag.errors, mounted]);

  const initTouched = useMemo(() => {
    return !isNew ? Object.keys(initValues).reduce((acc, key) => ({ ...acc, [key]: true }), {}) : {};
  }, [isNew]);

  const onLimitChange = useCallback((newLimit) => {
    dispatch(projectSettingsActions.uiMerge({ keywordsLimit: newLimit }));
  }, [dispatch]);

  const onOffsetChange = useCallback((newOffset) => {
    dispatch(projectSettingsActions.uiMerge({ keywordsOffset: newOffset }));
  }, [dispatch]);

  return (
    <Wrapper>
      <Formik
        initialValues={data}
        initialTouched={initTouched}
        initialErrors={initErrors}
        validationSchema={projectSchema}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          FormikUtils.fillFormBag(ProjectFormBag, formikProps);

          return (
            <Form>
              {projectSettingsSteps.map(({ title, suffix = '', fields, id, className }) =>
                <React.Fragment key={id}>
                  <div id={id} className="fields-group-title">
                    <span>{t(title)}</span>
                    {suffix && <span className="suffix">({t(suffix)})</span>}
                  </div>
                  <div className={className}>
                    {fields.map(fieldConfig => {
                      const { name } = fieldConfig;
                      const dynamicFieldConfig: Record<string, unknown> = { ...fieldConfig, onBlur: onBlur };

                      if (name === FIELD_NAMES.CountryCode) {
                        dynamicFieldConfig.options = countriesOptions;
                      }

                      if (name === FIELD_NAMES.Benchmarks) {
                        dynamicFieldConfig.suggestions = benchmarksSuggestions;
                      }

                      if (name === FIELD_NAMES.Keywords) {
                        dynamicFieldConfig.suggestions = keywordsSuggestions;
                        dynamicFieldConfig.keywordsCount = keywordsCount;
                        dynamicFieldConfig.limit = keywordsLimit;
                        dynamicFieldConfig.offset = keywordsOffset;
                        dynamicFieldConfig.onLimitChange = onLimitChange;
                        dynamicFieldConfig.onOffsetChange = onOffsetChange;
                        dynamicFieldConfig.loading = keywordsLoading;
                      }

                      return (
                        <Field name={name} key={name}>
                          {(fieldProps: FieldProps) => FormikUtils.createField(fieldProps, dynamicFieldConfig)}
                        </Field>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
}

export default ProjectForm;
import React, { useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';

import { IProps as IProfileCardProps } from './ProfileCard/ProfileCard';

import { ProfileCard } from './ProfileCard';
import { StyledWrapper as Wrapper } from './ProfileMenu.styled';

export interface IProps extends Omit<IProfileCardProps, 'collapsed' | 'onClickDropIcon' | 'onClickProfile'> { }

const ProfileMenu: React.FC<IProps> = ({
  onClickSignOut,
  onClickProfileSettings,
  ...restProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickProfile = useCallback(() => {
    setOpen(prevState => !prevState);
  }, [setOpen]);

  const handleClickOutsideProfile = useCallback((e) => {
    if (ref?.current && !ref?.current?.contains(e.target)) {
      setOpen(false);
    }
  }, [ref, setOpen]);

  const handleClickSignOut = useCallback(() => {
    onClickSignOut && onClickSignOut();
    handleClickProfile();
  }, [onClickSignOut, handleClickProfile]);

  const handleClickProfileSettings = useCallback(() => {
    onClickProfileSettings && onClickProfileSettings();
    handleClickProfile();
  }, [onClickProfileSettings, handleClickProfile]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideProfile, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideProfile, true);
    };
  }, []);

  const className = clsx({
    open,
  });

  return (
    <Wrapper className={className}>
      <div ref={ref} className="profile-container">
        <ProfileCard
          {...restProps}
          collapsed={!open}
          onClickProfile={handleClickProfile}
          onClickSignOut={handleClickSignOut}
          onClickProfileSettings={handleClickProfileSettings}
        />
      </div>
    </Wrapper>
  );
};

export default ProfileMenu;

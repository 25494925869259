import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AntdModal from 'antd/lib/modal';

import {
  RequestResultModalTitleKeys,
  RequestResultModalMessageKeys,
  ModalsActionsKeys,
} from '../../../enums/locales/modals';
import { RequestResultModalType } from '../../../enums/modals';

import {
  selectRequestResultType,
  selectRequestResultVisible,
  selectRequestResultRetryActions,
} from '../../../redux/modals/selectors';
import { modalsActions } from '../../../redux/modals/actions';

import { IconClose, IconCloud } from '../../../components/styled/icons';
import { Button, ButtonType } from '../../../components/styled/Button';
import { StyledBodyWrapper } from './RequestResultModal.styled';

const MODAL_TITLES = {
  [RequestResultModalType.AuthError]: RequestResultModalTitleKeys.AuthError,
  [RequestResultModalType.ConnectionError]: RequestResultModalTitleKeys.ConnectionError,
  [RequestResultModalType.NotEnoughResources]: RequestResultModalTitleKeys.NotEnoughResources,
};

const MODAL_MESSAGES = {
  [RequestResultModalType.AuthError]: RequestResultModalMessageKeys.AuthError,
  [RequestResultModalType.ConnectionError]: RequestResultModalMessageKeys.ConnectionError,
  [RequestResultModalType.NotEnoughResources]: RequestResultModalMessageKeys.NotEnoughResources,
};

const MODAL_ICONS = {
  [RequestResultModalType.AuthError]: null,
  [RequestResultModalType.ConnectionError]: <IconCloud />,
  [RequestResultModalType.NotEnoughResources]: null,
};

const RequestResultModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const type = useSelector(selectRequestResultType) as RequestResultModalType;
  const isVisible = useSelector(selectRequestResultVisible);
  const retryActions = useSelector(selectRequestResultRetryActions);

  const handleClickTryAgainButton = useCallback(() => {
    if (!retryActions.length) {
      dispatch(modalsActions.requestResultHide());
    } else {
      retryActions.forEach((action) => {
        dispatch(action);
      });
      dispatch(modalsActions.requestResultHide());
    }
  }, [dispatch, retryActions]);

  const handleCancel = useCallback(() => {
    dispatch(modalsActions.requestResultHide());
  }, [dispatch]);

  return (
    <AntdModal
      centered
      title={null}
      footer={null}
      closeIcon={<IconClose />}
      visible={isVisible}
      style={{ animationDuration: '0s' }}
      maskStyle={{ zIndex: 10099, background: '#000000B2 0% 0% no-repeat padding-box' }}
      wrapClassName="request-result-modal-container"
      onCancel={handleCancel}
    >
      <StyledBodyWrapper>
        <div className={clsx('icon', { hidden: !MODAL_ICONS[type] })}>
          {MODAL_ICONS[type]}
        </div>
        <div className="body-title">{t(MODAL_TITLES[type])}</div>
        <div className="body-message">{t(MODAL_MESSAGES[type])}</div>
        <div className="actions-button">
          <Button
            type={ButtonType.Primary}
            title={t(ModalsActionsKeys.TryAgain)}
            onClick={handleClickTryAgainButton}
          />
        </div>
      </StyledBodyWrapper>
    </AntdModal>
  );
};

export default RequestResultModal;

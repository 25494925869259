import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, ButtonType } from "../../../../components/styled/Button";
import { StackedIndicator } from '../../../../components/StackedIndicator';
import { getResourceUtilizationColor } from '../../../../utils/resources';
import { SiderHeading, SiderSteps } from '../../../../enums/locales/modals';
import { ProjectsOverviewFieldsKeys, ProjectsOverviewKeys } from '../../../../enums/locales/projects';

import { Wrapper } from './ResourceCard.styled';
import { ButtonTextKeys } from '../../../../enums/locales/forms';
import { selectMainLisenseCounts, selectUI } from '../../../../redux/licenses/selectors';

const ResourceCard: React.FC = () => {
  const { t } = useTranslation();
  const { loading } = useSelector(selectUI);
  const {
    user_slots,
    user_slots_used,
    url_slots,
    url_slots_used,
    project_slots,
    project_slots_used,
    keyword_slots,
    keyword_slots_used,
  } = useSelector(selectMainLisenseCounts);


  const onRequest = useCallback((e) => {
    e.preventDefault();
    window.location.href = "mailto:support@webexactly.com?subject=Lets add some resources";
  }, []);

  return (
    <Wrapper>
      <div className="title">{t(SiderHeading.YourResources)}:</div>
      <div className="count-indicator">
        <StackedIndicator
          title={t(ProjectsOverviewKeys.UsersCountIndicatorTitle)}
          value={user_slots_used}
          maxValue={user_slots}
          color={getResourceUtilizationColor(user_slots_used, user_slots)}
          reset={loading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={t(ProjectsOverviewKeys.ProjectsCountIndicatorTitle)}
          value={project_slots_used}
          maxValue={project_slots}
          color={getResourceUtilizationColor(project_slots_used, project_slots)}
          reset={loading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={`${t(ProjectsOverviewFieldsKeys.Keywords)}:`}
          value={keyword_slots_used}
          maxValue={keyword_slots}
          color={getResourceUtilizationColor(keyword_slots_used, keyword_slots)}
          reset={loading}
        />
      </div>
      <div className="count-indicator">
        <StackedIndicator
          title={`${t(SiderSteps.Urls)}:`}
          value={url_slots_used}
          maxValue={url_slots}
          color={getResourceUtilizationColor(url_slots_used, url_slots)}
          reset={loading}
        />
      </div>
      <Button
        type={ButtonType.Primary}
        onClick={onRequest}
        title={t(ButtonTextKeys.RequestMore)}
      />
    </Wrapper>
  )
}

export default ResourceCard;


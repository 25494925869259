import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .inner-tree-map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 270px;
  }

  .tree-map-title {
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  .tree-map-legends {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
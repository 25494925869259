export enum LoaderModalMessageKeys {
  Default = 'loader_modal_default_message',
  Auth = 'loader_modal_auth_message',
}

export enum RequestResultModalTitleKeys {
  AuthError = 'loader_modal_auth_error_title',
  ConnectionError = 'loader_modal_connection_error_title',
  NotEnoughResources = 'loader_modal_not_enough_resources_title',
}

export enum RequestResultModalMessageKeys {
  AuthError = 'loader_modal_auth_error_message',
  ConnectionError = 'loader_modal_connection_error_message',
  NotEnoughResources = 'loader_modal_not_enough_resources_message',
}

export enum ModalsActionsKeys {
  TryAgain = 'modal_action_try_again_title',
}

export enum SiderHeading {
  SetupNewProject = 'sider_heading_setup_new_project',
  ProjectSettings = 'sider_heading_project_settings',
  YourResources = 'sider_heading_your_resources',
  AccountResources = 'sider_heading_account_resources',
  LimitMessage = 'sider_heading_limit_message',
}

export enum SiderButtomTextKeys {
  Save = 'setup_new_project_save',
  StartCrawl = 'setup_new_project_start_crawl',
}

export enum SiderStepsSuffixes {
  Optional = 'setup_new_project_optional',
}

export enum SiderSteps {
  DomainAndProjectName = 'setup_new_project_domain_and_project_name',
  Country = 'setup_new_project_country',
  TypeAndFrequency = 'setup_new_project_type_and_frequency',
  Keywords = 'setup_new_project_keywords',
  Urls = 'setup_new_project_urls',
  Benchmarks = 'setup_new_project_benchmarks',
  URLGroups = 'setup_new_project_url_groups',
  InviteUsers = 'setup_new_project_invite_users',
  Goals = 'setup_new_project_goals',
  WarmUpUrls = 'setup_new_project_warm_up_urls',
}

export enum TabsKeys {
  Manual = 'tabs_manual_label',
  Suggested = 'tabs_suggested_label',
}

export enum ProjectSettingsKeys {
  ProfileSettings = 'profile_settings_sider_title',
  EditAccount = 'edit_account_sider_title',
  EditUser = 'edit_user_sider_title',
  CreateAccount = 'create_account_sider_title',
  PersonalDetails = 'profile_settings_personal_details_title',
  CompanyDetails = 'profile_settings_company_details_title',
  Security = 'profile_settings_security_title',
  Licenses = 'profile_settings_licenses_title',
  AvatarDescription = 'profile_settings_avatar_description',
  ResetPassword = 'profile_settings_reset_password',
  PasswordLabel = 'profile_settings_password_label',
  ResetedSuccessful = 'profile_settings_password_reseted_successful',
  ResetedError = 'profile_settings_password_reseted_error',
  ResetResend = 'profile_settings_password_reset_resend',
  ChangeMessageError = 'profile_settings_change_message_error',
  ChangeMessageSuccess = 'profile_settings_change_message_success',
  ManageUsers = 'profile_settings_manage_users',
  ManageUsersSubtitle = 'profile_settings_manage_users_subtitle',
}

export enum OnboardModalKeys {
  Title = 'onboard_modal_title',
  Description = 'onboard_modal_description',
  TypesSubtitle = 'onboard_modal_type_subtitle',
  LiveDescPart1 = 'onboard_modal_live_description_part_1',
  LiveDescPart2 = 'onboard_modal_live_description_part_2',
  TestDescPart1 = 'onboard_modal_test_description_part_1',
  TestDescPart2 = 'onboard_modal_test_description_part_2',
  ButtonTitle = 'onboard_modal_button_title',
  SupportMessage = 'onboard_modal_support_message',
}
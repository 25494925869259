import styled from 'styled-components';

export const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .message {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 26px;

    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    color: #222A41;
  }

  .loader {
    position: relative;
  
    height: 10px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    border-radius: 5px;

    ::after {
      content: '';
      display: block;

      position: absolute;

      width: 20%;
      height: 100%;

      top: 0;
      left: calc(50% - 20% / 2);

      background: #555BF0 0% 0% no-repeat padding-box;
      border-radius: 5px;

      transition: all 0.3s;
    }

    &.finalized {
      ::after {
        left: 0;
        width: 100%;
      }
    }
  }
`;

import styled from "styled-components";
import { THEME } from "../../../../constants/theme";

const { colors } = THEME;

export const Wrapper = styled.div`
  width: 100%;
  padding: 1rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #0000000F;
  border-radius: 12px;
  margin-bottom: 40px;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: ${colors.lightBlack};
  }

  .count-indicator {
    margin-top: 20px;
    .stacked-indicator-title {
      font-size: 12px;
      font-weight: 400;
    }
  }

  button {
    margin-top: 30px;
    .title {
      color: ${colors.white};
    }
  }
`;
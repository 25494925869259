import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import { DataService } from '../../services/DataService';

import { Action } from '../commonTypes';
import { CompoundAnalysisActionTypes } from './types';
import { IBaseResponse } from '../../services/BaseAPIService/types';
import { IRefreshTokensOptions } from '../../services/AuthService/types';
import {
  ICompoundAnalysisUrlsRequestBody,
  ICompoundAnalysisUrlsResponseBody,
  ICompoundAnalysisThumbnailsResponseBody,
  ICompoundAnalysisThumbnails,
  ICompoundAnalysisNetworkDetailsResponseBody,
  ICompoundAnalysisNetworkDetail,
} from '../../services/DataService/types';

import { compoundAnalysisActions } from './actions';
import {
  selectUI,
  selectActiveUrlID,
  selectUrlsSortOrder,
  selectUrlsSortKey,
  selectUrlSearch,
  selectNetworkDetailsSortOrder,
  selectNetworkDetailsSortKey,
  selectNetworkDetails,
} from './selectors';
import {
  selectActiveProjectID,
  selectActiveProjectGroupID,
  selectLatestCrawlID,
} from '../projects/selectors';

function* urlsReload(action: Action<CompoundAnalysisActionTypes.URLS_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  yield put(compoundAnalysisActions.uiMerge({ urlsLoading: true }));

  const groupID = yield select(selectActiveProjectGroupID);
  const sortKey = yield select(selectUrlsSortKey);
  const sortOrder = yield select(selectUrlsSortOrder);
  const urlSearch = yield select(selectUrlSearch);
  const { offset, limit } = yield select(selectUI);

  const requestBody: ICompoundAnalysisUrlsRequestBody = {
    limit,
    offset,
    url_filter: urlSearch,
    sort: sortKey,
    ['sort_direction']: sortOrder.toUpperCase(),
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ICompoundAnalysisUrlsResponseBody> = yield call(DataService.compoundAnalysisUrls, projectID, groupID, requestBody, options);
  if (!result?.data) {
    yield put(compoundAnalysisActions.uiMerge({ urlsLoading: false }));
    return;
  }

  const response: ICompoundAnalysisUrlsResponseBody = result?.data;

  const { data, count } = response;

   if (!data) {
    yield put(compoundAnalysisActions.urlsRefresh([]));
    yield put(compoundAnalysisActions.uiMerge({ count: null, urlsLoading: false }));
     return;
   }

  const countToSet = count === 0 ? null : count;

  yield put(compoundAnalysisActions.urlsRefresh(data));
  yield put(compoundAnalysisActions.uiMerge({ count: countToSet, urlsLoading: false }));
}

function* allUrlsReload(action: Action<CompoundAnalysisActionTypes.URLS_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);

  if (!projectID) {
    return;
  }

  yield put(compoundAnalysisActions.uiMerge({ allUrlsLoading: true }));

  const groupID = yield select(selectActiveProjectGroupID);
  const sortKey = yield select(selectUrlsSortKey);
  const sortOrder = yield select(selectUrlsSortOrder);
  const urlSearch = yield select(selectUrlSearch);
  const { count: total } = yield select(selectUI);

  const requestBody: ICompoundAnalysisUrlsRequestBody = {
    limit: total || 0,
    offset: 0,
    url_filter: urlSearch,
    sort: sortKey,
    ['sort_direction']: sortOrder.toUpperCase(),
  };

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ICompoundAnalysisUrlsResponseBody> = yield call(DataService.compoundAnalysisUrls, projectID, groupID, requestBody, options);
  if (!result?.data) {
    yield put(compoundAnalysisActions.uiMerge({ allUrlsLoading: false }));
    return;
  }

  const response: ICompoundAnalysisUrlsResponseBody = result?.data;

  const { data, count } = response;

  const countToSet = count === 0 ? null : count;

  yield put(compoundAnalysisActions.allUrlsRefresh(data));
  yield put(compoundAnalysisActions.uiMerge({ count: countToSet, allUrlsLoading: false }));
}

function* networkDetailsReload(action: Action<CompoundAnalysisActionTypes.NETWORK_DETAILS_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const crawlID = yield select(selectLatestCrawlID);
  const urlID = yield select(selectActiveUrlID);
  const sortOrder = yield select(selectNetworkDetailsSortOrder);
  const sortKey = yield select(selectNetworkDetailsSortKey);

  if (!projectID || !crawlID || !urlID) {
    return;
  }

  yield put(compoundAnalysisActions.uiMerge({ networkDetailsLoading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ICompoundAnalysisNetworkDetailsResponseBody> = yield call(DataService.compoundAnalysisNetworkDetails, projectID, crawlID, urlID, options);
  if (!result?.data) {
    yield put(compoundAnalysisActions.uiMerge({ networkDetailsLoading: false }));
    return;
  }

  const response: ICompoundAnalysisNetworkDetailsResponseBody = result?.data;

  const { data } = response;

  const maxTime = maxBy(data, 'time')?.time as number;
  const maxResourceSize = maxBy(data, 'resource_size')?.resource_size as number;
  const maxTransferSize = maxBy(data, 'transfer_size')?.transfer_size as number;

  const preparedData = data.map((item, i) => ({
    ...item,
    time_percentage: Math.floor((item.time / maxTime) * 100),
    resource_size_percentage: Math.floor((item.resource_size / maxResourceSize) * 100),
    transfer_size_percentage: Math.floor((item.transfer_size / maxTransferSize) * 100),
    position: i + 1,
  }));

  const sortedData = yield call(orderBy, preparedData, [sortKey], [sortOrder]);

  yield put(compoundAnalysisActions.networkDetailsRefresh(sortedData));
  yield put(compoundAnalysisActions.uiMerge({ networkDetailsLoading: false }));
}

function* thumbnailsReload(action: Action<CompoundAnalysisActionTypes.THUMBNAILS_RELOAD, never>): any {
  const projectID = yield select(selectActiveProjectID);
  const crawlID = yield select(selectLatestCrawlID);
  const urlID = yield select(selectActiveUrlID);

  if (!projectID || !crawlID || !urlID) {
    return;
  }

  yield put(compoundAnalysisActions.uiMerge({ thumbnailsLoading: true }));

  const options: IRefreshTokensOptions = {
    retryAction: action,
  };

  const result: IBaseResponse<ICompoundAnalysisThumbnailsResponseBody> = yield call(DataService.compoundAnalysisThumbnails, projectID, crawlID, urlID, options);
  if (!result?.data) {
    yield put(compoundAnalysisActions.uiMerge({ thumbnailsLoading: false }));
    return;
  }

  const response: ICompoundAnalysisThumbnailsResponseBody = result?.data;

  const { data } = response;

  yield put(compoundAnalysisActions.thumbnailsRefresh(data));
  yield put(compoundAnalysisActions.uiMerge({ thumbnailsLoading: false }));
}

function* networkDetailsSortApply(action: Action<CompoundAnalysisActionTypes.NETWORK_DETAILS_SORT_APPLY, never>): any {
  const networkDetails: ICompoundAnalysisNetworkDetail[] = yield select(selectNetworkDetails);
  const sortOrder = yield select(selectNetworkDetailsSortOrder);
  const sortKey = yield select(selectNetworkDetailsSortKey);

  if (!networkDetails.length) {
    return;
  }

  const sortedData = yield call(orderBy, networkDetails, [sortKey], [sortOrder]);

  yield put(compoundAnalysisActions.networkDetailsRefresh(sortedData));
}

export default function* compoundAnalysisSaga() {
  yield all([
    takeLatest(CompoundAnalysisActionTypes.URLS_RELOAD, urlsReload),
    takeLatest(CompoundAnalysisActionTypes.ALL_URLS_RELOAD, allUrlsReload),
    takeLatest(CompoundAnalysisActionTypes.THUMBNAILS_RELOAD, thumbnailsReload),
    takeLatest(CompoundAnalysisActionTypes.NETWORK_DETAILS_RELOAD, networkDetailsReload),
    takeLatest(CompoundAnalysisActionTypes.NETWORK_DETAILS_SORT_APPLY, networkDetailsSortApply),
  ]);
}

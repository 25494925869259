import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/lib/tooltip';

import { THEME } from '../../../constants/theme';
import { hexToRGBA } from '../../../utils/theme';
import { ResourceTypes } from '../../../enums/compoundAnalysis';
import { DashboardLabelKeys } from '../../../enums/locales/dashboard';
import { NetworkRequestsAnalysisKeys } from '../../../enums/locales/compoundAnalysis';
import { ICompoundAnalysisNetworkDetail } from '../../../services/DataService/types';
import { RESOURCE_COLORS_BY_TYPE } from '../../../constants/compoundAnalysis';

import { ScreenshotCard } from '../ScreenshotCard';
import { IconExternalLink } from '../../../components/styled/icons';
import { StyledWrapper as Wrapper } from './Filmstrip.styled';

export interface IFilmstripDataItem {
  src: string;
  timing: number;
  timestamp: number;
  nextTiming: number;
  fcpValue?: number;
  fmpValue?: number;
  lcpValue?: number;
}

export interface IProps {
  currentFCPValue: number;
  currentFMPValue: number;
  currentLCPValue: number;
  filmstripData: IFilmstripDataItem[];
  networkDetailsData: ICompoundAnalysisNetworkDetail[];
  networkResourceTypes: string[];
  disabledResourceTypes: string[];
  onClickLegendItem: (key: string) => () => void;
}

const Filmstrip: React.FC<IProps> = ({
  currentFCPValue,
  currentFMPValue,
  currentLCPValue,
  filmstripData,
  networkDetailsData,
  networkResourceTypes,
  disabledResourceTypes,
  onClickLegendItem,
}) => {
  const { t } = useTranslation();

  const openUrl = useCallback((url: string) => () => {
    window.open(url, '_blank');
  }, []);

  const filteredNetworkResourceTypes = useMemo(() => {
    return networkResourceTypes.filter(type => !disabledResourceTypes.includes(type));
  }, [networkResourceTypes, disabledResourceTypes]);

  return (
    <Wrapper>
      <div className="filmstrip-screenshots">
        {filmstripData.map(({ src, timing }, i) => (
          <ScreenshotCard key={`screenshot:${i}`} src={src} timing={timing} />
        ))}
      </div>
      <div className="filmstrip-chart">
        <div className="header-layer">
          <div className="chart-title">
            <span>{t(NetworkRequestsAnalysisKeys.LoadingTimes)}</span>
          </div>
          <div className="resource-type-legend">
            {networkResourceTypes.map((type) => {
              const disabled = disabledResourceTypes.includes(type);
              return (
                <div key={`type-item:${type}`} className="type-item" onClick={onClickLegendItem(type)}>
                  <span style={{ background: disabled ? hexToRGBA(THEME.colors.grey, 0.6) : RESOURCE_COLORS_BY_TYPE[type as ResourceTypes] }} />
                  <span>{type}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid-layer">
          {filmstripData.map(({ timing, nextTiming, fcpValue, fmpValue, lcpValue }, i, arr) => {
            const step = arr[arr.length - 1].timing / arr.length;
            const isTail = (i + 1) === arr.length;

            const fcpShift = isTail && (currentFCPValue > (fcpValue as number)) ? 100 : (fcpValue as number - timing) * 100 / (nextTiming - timing);
            const fmpShift = isTail && (currentFMPValue > (fmpValue as number)) ? 100 : (fmpValue as number - timing) * 100 / (nextTiming - timing);
            const lcpShift = isTail && (currentLCPValue > (lcpValue as number)) ? 100 : (lcpValue as number - timing) * 100 / (nextTiming - timing);

            const isFCPOverlapped = Math.abs((fcpValue as number) - currentFMPValue) < step || Math.abs((fcpValue as number) - currentLCPValue) < step;
            const isLCPOverlapped = Math.abs((lcpValue as number) - currentFCPValue) < step || Math.abs((lcpValue as number) - currentFMPValue) < step;
 
            return (
              <div key={`grid-layer-block:${i}`} className="grid-layer-block" style={{ width: `${100 / arr.length}%` }}>
                <div className={clsx('grid-layer-block-label fcp', { hidden: fcpValue === undefined, overlapped: isFCPOverlapped })} style={{ left: `${fcpShift}%` }}>
                  <span>FCP:</span>
                  <span>{fcpValue}</span>
                  <span>{t(DashboardLabelKeys.Milliseconds)}</span>
                </div>
                <div className={clsx('grid-layer-block-label fmp', { hidden: fmpValue === undefined })} style={{ left: `${fmpShift}%` }}>
                  <span>FMP:</span>
                  <span>{fmpValue}</span>
                  <span>{t(DashboardLabelKeys.Milliseconds)}</span>
                </div>
                <div className={clsx('grid-layer-block-label lcp', { hidden: lcpValue === undefined, overlapped: isLCPOverlapped })} style={{ left: `${lcpShift}%` }}>
                  <span>LCP:</span>
                  <span>{currentLCPValue}</span>
                  <span>{t(DashboardLabelKeys.Milliseconds)}</span>
                </div>
                <div className={clsx('grid-layer-block-pointer fcp', { hidden: fcpValue === undefined, overlapped: isFCPOverlapped })} style={{ left: `${fcpShift}%` }} />
                <div className={clsx('grid-layer-block-pointer fmp', { hidden: fmpValue === undefined })} style={{ left: `${fmpShift}%` }} />
                <div className={clsx('grid-layer-block-pointer lcp', { hidden: lcpValue === undefined, overlapped: isLCPOverlapped })} style={{ left: `${lcpShift}%` }} />
              </div>
            );
          })}
        </div>
        <div className="bars-layer">
          <div className="bars-layer-container">
            {filteredNetworkResourceTypes.map((type, i) => {
              const networkDetails = networkDetailsData.filter(({ resource_type }) => resource_type === type);

              return (
                <div
                  key={`bars-layer-row:${i}`}
                  className="bars-layer-row"
                >
                  {networkDetails.map(({ url, start_time, end_time, time }, i) => {
                    const firstTiming = filmstripData[0]?.timing;
                    const lastTiming = filmstripData[filmstripData.length - 1]?.timing;
                    const left  = `${start_time < firstTiming ? 0 : 100 * (start_time - firstTiming) / lastTiming}%`;
                    const width = `${end_time > lastTiming 
                      ? 100 * (lastTiming - start_time + firstTiming) / lastTiming 
                      : start_time < firstTiming ? 100 * (time + start_time - firstTiming) / lastTiming  : 100 * time / lastTiming}%`;

                    return (
                      <Tooltip
                        key={`bars-layer-block:${i}`}
                        title={(
                          <div className="bars-layer-tip">
                            <div className="bars-layer-tip-link">
                              <span><IconExternalLink onClick={openUrl(url)} /></span>
                              <span>{url}</span>
                            </div>
                            <div className="bars-layer-tip-timing">
                              <span>{`${Math.round(start_time)}-${Math.round(end_time)}`}</span>
                              <span>{t(DashboardLabelKeys.Milliseconds)}</span>
                            </div>
                          </div>
                        )}
                      >
                        <div
                          className="bars-layer-block"
                          style={{ left, width, background: RESOURCE_COLORS_BY_TYPE[type as ResourceTypes] }}
                        />
                      </Tooltip>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Filmstrip;

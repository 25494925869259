import { createSelector } from 'reselect';
import { RootState } from '../store';

const data = (state: RootState) => state.ProjectSettings.data;
const projectID = (state: RootState) => state.ProjectSettings.projectID;
const benchmarksSuggestions = (state: RootState) => state.ProjectSettings.benchmarksSuggestions;
const keywordsSuggestions = (state: RootState) => state.ProjectSettings.keywordsSuggestions;
const finishedSteps = (state: RootState) => state.ProjectSettings.finishedSteps;
const UI = (state: RootState) => state.ProjectSettings.UI;

export const selectData = createSelector(
  [data],
  (data) => data,
);

export const selectKeywordsCount = createSelector(
  [data],
  (data) => data?.keywords?.length || 0,
);

export const selectUrlsCount = createSelector(
  [data],
  (data) => data?.urls?.length || 0,
);

export const selectRecrawlInterval = createSelector(
  [data],
  (data) => data?.recrawl_interval,
);

export const selectProjectID = createSelector(
  [projectID],
  (projectID) => projectID,
);

export const selectBenchmarksSuggestions = createSelector(
  [benchmarksSuggestions],
  (benchmarksSuggestions) => benchmarksSuggestions,
);

export const selectKeywordsSuggestions = createSelector(
  [keywordsSuggestions],
  (keywordsSuggestions) => keywordsSuggestions,
);

export const selectFinishedSteps = createSelector(
  [finishedSteps],
  (finishedSteps) => finishedSteps,
);

export const selectUI = createSelector(
  [UI],
  (UI) => UI,
);

import * as yup from 'yup';

import { FieldValidationMessageKeys } from '../../enums/locales/forms';
import { IFieldErrorMessage } from '../../types/forms.types';

const { setLocale } = yup;

interface IMin {
  min: number;
}

interface IMax {
  max: number;
}

setLocale({
  mixed: {
    required: (): IFieldErrorMessage => ({ key: FieldValidationMessageKeys.Required }),
  },
  string: {
    email: (): IFieldErrorMessage => ({ key: FieldValidationMessageKeys.Email }),
    min: ({ min }: IMin): IFieldErrorMessage => ({ key: FieldValidationMessageKeys.StringMin, value: min }),
    max: ({ max }: IMax): IFieldErrorMessage => ({ key: FieldValidationMessageKeys.StringMax, value: max }),
  },
});

export default yup;

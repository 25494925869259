import orderBy from 'lodash/orderBy';
import { IProject } from '../../services/ProjectService/types';
import { IProjectListOption } from '../../containers/TopBar/TopBarMenu/ProjectMenu/ProjectList/ProjectList';
import { IProjectOverviewDataItem } from '../../types/projects.types';
import { ProjectsOverviewDataKeys, RecrawlInterval } from '../../enums/projects';
import { SortOrder } from '../../enums/data';
import { IOption } from '../../types/common.types';
import { beautifyUrl } from '../../utils/common';

export const createProjectOptions: IProjectListOption[] | any = (rawProjects: IProject[] = []) => {
  return rawProjects.map(item => {
    return {
      projectID: item.project_id,
      domainName: item.domain,
      projectName: item.name,
      domainPictureUrl: item?.domain_picture,
      urlGroups: item.url_groups,
      isTestMode: item.is_test_project,
    };
  });
};

export const sortProjectsOverviewDataByKey = (data: IProjectOverviewDataItem[], key: ProjectsOverviewDataKeys, order: SortOrder): IProjectOverviewDataItem[] => {
  const sortedData = orderBy(data, [key], [order]);
  return sortedData;
};

export const createProjectOverviewData = (data: IProject[]): IProjectOverviewDataItem[] => {
  return data.map(({ project_id, name, domain, strategy, country_code, keywords, latest_crawl, recrawl_interval }) => {
    return {
      [ProjectsOverviewDataKeys.ID]: project_id,
      [ProjectsOverviewDataKeys.Name]: name,
      [ProjectsOverviewDataKeys.Domain]: beautifyUrl(domain),
      [ProjectsOverviewDataKeys.LatestCrawl]: latest_crawl as string,
      [ProjectsOverviewDataKeys.Type]: strategy,
      [ProjectsOverviewDataKeys.Frequency]: recrawl_interval as RecrawlInterval,
      [ProjectsOverviewDataKeys.Country]: country_code || '',
      [ProjectsOverviewDataKeys.Keywords]: keywords.length,
    }
  });
};

export const createProjectsOptions = (project: IProject) => {
  const resultArray: IOption[] = [];
  if (project?.domain) {
    resultArray.push({
      value: project?.project_id || '',
      label: project?.name || project?.domain || '',
      extraLabel: project?.domain || '',
      withPicture: true
    });
  }

  const urlGroups = (project?.url_groups || []).map(({ url_group_id, name }) => ({ value: url_group_id, label: name, withPicture: false }));

  return [
    ...resultArray,
    ...urlGroups,
  ];
};
import { DomainHistoryInterval, Feature } from '../enums/data';
import { Strategy } from '../enums/projects';
import { DashboardTopMenuKeys, DashboardKpiKeys, DashboardKpiSummaryHeaderKeys, DashboardIntervalKeys, DashboardKpiHintKeys, DashboardKpiSubtitleKeys } from '../enums/locales/dashboard';

export const DASHBOARD_MENU_STRATEGIES_MAP = {
  [Strategy.Desktop]: DashboardTopMenuKeys.PCTitle,
  [Strategy.Mobile]: DashboardTopMenuKeys.MobileTitle,
};

export const KPI = [
  {
    kpiKey: DashboardKpiKeys.PerformanceScore,
    hintKey: DashboardKpiHintKeys.PerformanceScore,
    labDataKey: Feature.PerformanceScore,
  },
  {
    kpiKey: DashboardKpiKeys.FirstContentfulPaint,
    hintKey: DashboardKpiHintKeys.FirstContentfulPaint,
    fieldDataKey: Feature.FirstContentfulPaintFD,
    labDataKey: Feature.FirstContentfulPaintLab,
  },
  {
    kpiKey: DashboardKpiKeys.LargestContentfulPaint,
    hintKey: DashboardKpiHintKeys.LargestContentfulPaint,
    fieldDataKey: Feature.LargestContentfulPaintFD,
    labDataKey: Feature.LargestContentfulPaintLab,
  },
  {
    kpiKey: DashboardKpiKeys.CumulativeLayoutShift,
    hintKey: DashboardKpiHintKeys.CumulativeLayoutShift,
    fieldDataKey: Feature.CumulativeLayoutShiftFD,
    labDataKey: Feature.CumulativeLayoutShiftLab,
  },
  {
    kpiKey: DashboardKpiKeys.FirstInputDelay,
    hintKey: DashboardKpiHintKeys.FirstInputDelay,
    fieldDataKey: Feature.FirstInputDelayFD,
  },
  {
    kpiKey: DashboardKpiKeys.TotalBlockingTime,
    hintKey: DashboardKpiHintKeys.TotalBlockingTime,
    labDataKey: Feature.TotalBlockingTimeLab,
  },
  {
    kpiKey: DashboardKpiKeys.TimeToInteractive,
    hintKey: DashboardKpiHintKeys.TimeToInteractive,
    labDataKey: Feature.TimeToInteractiveLab,
  },
  {
    kpiKey: DashboardKpiKeys.UnusedCssTitle,
    hintKey: DashboardKpiHintKeys.UnusedCss,
    labDataKey: Feature.UnusedCss,
  },
];

export const KPI_SUMMARY_FIELDS = [
  {
    kpiKey: DashboardKpiKeys.PerformanceScore,
    headerKey: DashboardKpiSummaryHeaderKeys.PerformanceScore,
    labDataKey: Feature.PerformanceScore,
  },
  {
    kpiKey: DashboardKpiKeys.CumulativeLayoutShift,
    headerKey: DashboardKpiSummaryHeaderKeys.CumulativeLayoutShift,
    fieldDataKey: Feature.CumulativeLayoutShiftFD,
    labDataKey: Feature.CumulativeLayoutShiftLab,
  },
  {
    kpiKey: DashboardKpiKeys.FirstContentfulPaint,
    headerKey: DashboardKpiSummaryHeaderKeys.FirstContentfulPaint,
    fieldDataKey: Feature.FirstContentfulPaintFD,
    labDataKey: Feature.FirstContentfulPaintLab,
  },
  {
    kpiKey: DashboardKpiKeys.LargestContentfulPaint,
    headerKey: DashboardKpiSummaryHeaderKeys.LargestContentfulPaint,
    fieldDataKey: Feature.LargestContentfulPaintFD,
    labDataKey: Feature.LargestContentfulPaintLab,
  },
  {
    kpiKey: DashboardKpiKeys.TotalBlockingTime,
    headerKey: DashboardKpiSummaryHeaderKeys.TotalBlockingTime,
    labDataKey: Feature.TotalBlockingTimeLab,
  },
  {
    kpiKey: DashboardKpiKeys.FirstInputDelay,
    headerKey: DashboardKpiSummaryHeaderKeys.FirstInputDelay,
    fieldDataKey: Feature.FirstInputDelayFD,
  },
  {
    kpiKey: DashboardKpiKeys.TimeToInteractive,
    headerKey: DashboardKpiSummaryHeaderKeys.TimeToInteractive,
    labDataKey: Feature.TimeToInteractiveLab,
  },
];

export const INTERVAL_OPTIONS = [
  {
    value: DomainHistoryInterval.LastNinetyDays,
    label: DashboardIntervalKeys.LastNinetyDays,
  },
  {
    value: DomainHistoryInterval.LastOneHundredAndEightyDays,
    label: DashboardIntervalKeys.LastOneHundredAndEightyDays,
  },
  {
    value: DomainHistoryInterval.LastThreeHundredAndSixtyDays,
    label: DashboardIntervalKeys.LastThreeHundredAndSixtyDays,
  }
]

export const METRIC_OPTIONS = [
  {
    value: Feature.PerformanceScore,
    label: DashboardKpiKeys.PerformanceScore,
  },
  {
    value: Feature.FirstContentfulPaintFD,
    label: DashboardKpiKeys.FirstContentfulPaint,
    subtitle: DashboardKpiSubtitleKeys.FD,
  },
  {
    value: Feature.LargestContentfulPaintFD,
    label: DashboardKpiKeys.LargestContentfulPaint,
    subtitle: DashboardKpiSubtitleKeys.FD,
  },
  {
    value: Feature.FirstInputDelayFD,
    label: DashboardKpiKeys.FirstInputDelay,
    subtitle: DashboardKpiSubtitleKeys.FD,
  },
  {
    value: Feature.CumulativeLayoutShiftFD,
    label: DashboardKpiKeys.CumulativeLayoutShift,
    subtitle: DashboardKpiSubtitleKeys.FD,
  },
  {
    value: Feature.InteractionToNextPaint,
    label: DashboardKpiKeys.InteractionToNextPaint,
  },
  {
    value: Feature.ExperimentalTimeToFirstByte,
    label: DashboardKpiKeys.ExperimentalTimeToFirstByte,
  },
  {
    value: Feature.SpeedIndex,
    label: DashboardKpiKeys.SpeedIndex,
  },
  {
    value: Feature.TotalBlockingTimeLab,
    label: DashboardKpiKeys.TotalBlockingTime,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.FirstMeaningfulPaintLab,
    label: DashboardKpiKeys.FirstMeaningfulPaint,
    subtitle: DashboardKpiSubtitleKeys.Lab,
  },
  {
    value: Feature.FirstContentfulPaintLab,
    label: DashboardKpiKeys.FirstContentfulPaint,
    subtitle: DashboardKpiSubtitleKeys.Lab,
  },
  {
    value: Feature.LargestContentfulPaintLab,
    label: DashboardKpiKeys.LargestContentfulPaint,
    subtitle: DashboardKpiSubtitleKeys.Lab,
  },
  {
    value: Feature.TimeToInteractiveLab,
    label: DashboardKpiKeys.TimeToInteractive,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.CumulativeLayoutShiftLab,
    label: DashboardKpiKeys.CumulativeLayoutShift,
    subtitle: DashboardKpiSubtitleKeys.Lab,
  },
  {
    value: Feature.ServerResponseTime,
    label: DashboardKpiKeys.ServerResponseTime,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.DOMSize,
    label: DashboardKpiKeys.DOMSize,
  },
  {
    value: Feature.NumTasksOver50ms,
    label: DashboardKpiKeys.NumTasksOver50ms,
  },
  {
    value: Feature.NumTasksOver100ms,
    label: DashboardKpiKeys.NumTasksOver100ms,
  },
  {
    value: Feature.NumTasksOver500ms,
    label: DashboardKpiKeys.NumTasksOver500ms,
  },
  {
    value: Feature.UnusedCssLab,
    label: DashboardKpiKeys.UnusedCss,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.UnusedJsLab,
    label: DashboardKpiKeys.UnusedJs,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.UsesTextCompression,
    label: DashboardKpiKeys.UsesTextCompression,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.UnminifiedJsLab,
    label: DashboardKpiKeys.UnminifiedJs,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.UnminifiedCssLab,
    label: DashboardKpiKeys.UnminifiedCss,
    subtitle: DashboardKpiSubtitleKeys.Ms,
  },
  {
    value: Feature.ModernImage,
    label: DashboardKpiKeys.ModernImage,
    subtitle: DashboardKpiSubtitleKeys.SavingsInBytes,
  },
  {
    value: Feature.OffscreenImage,
    label: DashboardKpiKeys.OffscreenImage,
    subtitle: DashboardKpiSubtitleKeys.SavingsInBytes,
  },
  {
    value: Feature.NumTasks,
    label: DashboardKpiKeys.NumTasks,
    subtitle: DashboardKpiSubtitleKeys.Diagnostics,
  },
  {
    value: Feature.TotalTaskTime,
    label: DashboardKpiKeys.TotalTaskTime,
    subtitle: DashboardKpiSubtitleKeys.Diagnostics,
  },
  {
    value: Feature.TotalByteWeight,
    label: DashboardKpiKeys.TotalByteWeight,
    subtitle: DashboardKpiSubtitleKeys.Diagnostics,
  },
  {
    value: Feature.NumFonts,
    label: DashboardKpiKeys.NumFonts,
    subtitle: DashboardKpiSubtitleKeys.Diagnostics,
  },
  {
    value: Feature.NumRequests,
    label: DashboardKpiKeys.NumRequests,
    subtitle: DashboardKpiSubtitleKeys.Diagnostics,
  },
  {
    value: Feature.NumScripts,
    label: DashboardKpiKeys.NumScripts,
    subtitle: DashboardKpiSubtitleKeys.Diagnostics,
  },
  {
    value: Feature.NumStylesheets,
    label: DashboardKpiKeys.NumStylesheets,
    subtitle: DashboardKpiSubtitleKeys.Diagnostics,
  },
];

export const fieldNamesToLabExport = [
  'domain',
  Feature.PerformanceScore,
  Feature.CumulativeLayoutShiftLab,
  Feature.FirstContentfulPaintLab,
  Feature.LargestContentfulPaintLab,
  Feature.TotalBlockingTimeLab,
  Feature.TimeToInteractiveLab,
]

export const fieldNamesToFDExport = [
  'domain',
  Feature.PerformanceScore,
  Feature.CumulativeLayoutShiftFD,
  Feature.FirstContentfulPaintFD,
  Feature.LargestContentfulPaintFD,
  Feature.TotalBlockingTimeLab,
  Feature.FirstInputDelayFD,
  Feature.TimeToInteractiveLab,
]
import styled from 'styled-components';
import { ReactComponent as RawCheckmarkGreenSVG } from '../../../../assets/icons/green_checkmark.svg';

export const IconCheckmarkGreen = styled(RawCheckmarkGreenSVG)`
  .a, .b {
    fill: none;
  }
  
  .b {
    stroke: #35c996;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
  }
`;

import { takeLatest, all, call, put, select } from 'redux-saga/effects';

import { sortOverviewDataByKey, sanitizeData } from '../../utils/kpi/data';

import { Action } from '../commonTypes';
import { KpiSummaryActionTypes, IKpiSummarySortChangePayload, IKpiSummaryDataRestrictionsPayload } from './types';
import { FeatureOverview } from '../../services/DataService/types';
import { SortOrder } from '../../enums/data';

import { kpiSummaryActions } from './actions';
import { selectSortFeature, selectSortOrder, selectSummaryDataRestrictions } from './selectors';
import { selectOverviewData } from '../overview/selectors';
import { selectActiveProjectDomain, selectActiveProjectGroupID } from '../projects/selectors';

function* sortChange(action: Action<KpiSummaryActionTypes.KPI_SUMMARY_SORT_CHANGE, IKpiSummarySortChangePayload>): any {
  const { feature, order } = action.payload;

  if (order !== undefined) {
    yield put(kpiSummaryActions.sortRefresh({ feature, order }));
    return;
  }

  const currentFeature = yield select(selectSortFeature);
  const currentOrder = yield select(selectSortOrder);

  if (currentFeature !== feature) {
    yield put(kpiSummaryActions.sortRefresh({ feature, order: SortOrder.Desc }));
    return;
  }

  if (currentOrder === SortOrder.Asc) {
    yield put(kpiSummaryActions.sortRefresh({ feature, order: SortOrder.Desc }));
    return;
  }

  yield put(kpiSummaryActions.sortRefresh({ feature, order: SortOrder.Asc }));
}

function* summaryDataUpdate(): any {
  const activeDomainName = yield select(selectActiveProjectDomain);
  const activeGroupID = yield select(selectActiveProjectGroupID);
  const overviewData: FeatureOverview[] = yield select(selectOverviewData);
  const { sortBy, order, count }: IKpiSummaryDataRestrictionsPayload = yield select(selectSummaryDataRestrictions);
  const filteredOverviewData = overviewData.filter(({ domain }) => activeGroupID ? domain !== activeGroupID : !domain?.includes(activeDomainName));
  const sanitizedData = sanitizeData(filteredOverviewData);
  const sortedData = yield call(sortOverviewDataByKey, sanitizedData, sortBy as string, order as SortOrder, count);
  
  yield put(kpiSummaryActions.dataRefresh(sortedData));
}

export default function* kpiSummarySaga() {
  yield all([
    takeLatest(KpiSummaryActionTypes.KPI_SUMMARY_SORT_CHANGE, sortChange),
    takeLatest(KpiSummaryActionTypes.KPI_SUMMARY_DATA_UPDATE, summaryDataUpdate),
  ]);
}

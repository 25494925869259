export enum URLsDataKeys {
  UrlID = 'url_id',
  Url = 'url',
}

export enum NetworkDetailsDataKeys {
  Url = 'url',
  ResourceType = 'resource_type',
  StartTime = 'start_time',
  EndTime = 'end_time',
  Time = 'time',
  ResourceSize = 'resource_size',
  TransferSize = 'transfer_size',
  Efficiency = 'efficiency',
  Position = 'position',
}

export enum ResourceTypes {
  Document = 'Document',
  Script = 'Script',
  Stylesheet = 'Stylesheet',
  Font = 'Font',
  Image = 'Image',
  Video = 'Video',
  XHR = 'XHR',
  Fetch = 'Fetch',
  Preflight = 'Preflight',
  Other = 'Other',
}

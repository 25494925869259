export enum Feature {
  PerformanceScore = 'performance_score',
  FirstContentfulPaintFD = 'first_contentful_paint_ms',
  LargestContentfulPaintFD = 'largest_contentful_paint_ms',
  FirstInputDelayFD = 'first_input_delay_ms',
  CumulativeLayoutShiftFD = 'cumulative_layout_shift_score',

  // experimental features
  InteractionToNextPaint = 'interaction_to_next_paint',
  ExperimentalTimeToFirstByte = 'experimental_time_to_first_byte',

  SpeedIndex = 'speed-index:numericValue',
  ServerResponseTime = 'server-response-time:numericValue',
  DOMSize = 'dom-size:numericValue',
  NumTasksOver50ms = 'diagnostics:numTasksOver50ms',
  NumTasksOver100ms = 'diagnostics:numTasksOver100ms',
  NumTasksOver500ms = 'diagnostics:numTasksOver500ms',
  NumTasks = 'diagnostics:numTasks',
  TotalTaskTime = 'diagnostics:totalTaskTime',
  TotalByteWeight = 'diagnostics:totalByteWeight',
  NumFonts = 'diagnostics:numFonts',
  NumRequests = 'diagnostics:numRequests',
  NumScripts = 'diagnostics:numScripts',
  NumStylesheets = 'diagnostics:numStylesheets',

  FirstMeaningfulPaintLab = 'first-meaningful-paint:numericValue',
  FirstContentfulPaintLab = 'first-contentful-paint:numericValue',
  LargestContentfulPaintLab = 'largest-contentful-paint:numericValue',
  CumulativeLayoutShiftLab = 'cumulative-layout-shift:numericValue',

  TotalBlockingTimeLab = 'total-blocking-time:numericValue',
  TimeToInteractiveLab = 'interactive:numericValue',

  UnusedCss = 'unused-css-rules:details_overallSavingsBytes',
  UnusedJs = 'unused-javascript:details_overallSavingsBytes',

  UnusedCssLab = 'unused-css-rules:numericValue',
  UnusedJsLab = 'unused-javascript:numericValue',

  UsesTextCompression = 'uses-text-compression:numericValue',
  UnminifiedCss = 'unminified-css:details_overallSavingsBytes',
  UnminifiedJs = 'unminified-javascript:details_overallSavingsBytes',

  UnminifiedCssLab = 'unminified-css:numericValue',
  UnminifiedJsLab = 'unminified-javascript:numericValue',

  ModernImage = 'modern-image-formats:details_overallSavingsBytes',
  OffscreenImage = 'offscreen-images:details_overallSavingsBytes',

  OptimizedImages = 'uses-optimized-images:numericValue',
  RenderBlockingResources = 'render-blocking-resources:numericValue',
}

export enum Quantifier {
  Top5 = 'TOP_5',
  Top10 = 'TOP_10',
}

export enum DomainHistoryInterval {
  LastNinetyDays = 90,
  LastOneHundredAndEightyDays = 180,
  LastThreeHundredAndSixtyDays = 360,
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

import { Serie } from "@nivo/line";
import { intervalToDuration, max, min, eachDayOfInterval } from "date-fns";
import entries from "lodash/entries";
import { TFunction } from 'react-i18next';
import { RESOURCE_COLORS_BY_TYPE } from "../../constants/compoundAnalysis";

import { THEME } from "../../constants/theme";
import { ResourceTypes } from "../../enums/compoundAnalysis";
import { Feature } from "../../enums/data";
import { DashboardLegendsKeys } from "../../enums/locales/dashboard";
import { LeaderboardKeys } from "../../enums/locales/leaderboard";
import { StaticResourceTypes } from "../../enums/networkDetails";
import { ICompoundAnalysisNetworkDetail, IDomainHistory, ILeaderboardHistoryData, IMetricsRankingCorrelation } from "../../services/DataService/types";
import { IUrlGroup } from "../../services/ProjectService/types";
import { roundNumber } from "../common";
import { getColor } from "../theme";

const { colors } = THEME;

export const createSmallData = (data: IDomainHistory, t: TFunction, domain: string, groupID?: string, groupName?: string) => {
  const dates = Object.keys(data).map((key) => new Date(key));

  const wholeInterval = { start: min(dates), end: max(dates) };
  const daysCount = !!dates.length ? (eachDayOfInterval(wholeInterval).length) : 0;

  const series = [
    {
      id: t(DashboardLegendsKeys.BenchmarkAvg),
      color: colors.chart.benchmark,
      data: Object.keys(data).map((key) => ({ x: key, y: roundNumber(data[key].benchmark_avg) })),
    },
    {
      id: t(DashboardLegendsKeys.MarketAvg),
      color: colors.chart.market,
      data: Object.keys(data).map((key) => ({ x: key, y: roundNumber(data[key].market_avg) })),
    },
  ];

  if (groupID && groupName) {
    series.push({
      id: groupName,
      color: colors.chart.group,
      data: Object.keys(data).map((key) => ({ x: key, y: roundNumber(data[key][groupID] as number || 0) })),
    })
  }

  series.push({
    id: domain,
    color: colors.chart.domain,
    data: Object.keys(data).map((key) => ({ x: key, y: roundNumber(data[key].project_domain) })),
  });

  return { series, tickValues: (daysCount / 6).toFixed(0) };
};

export const createMsData = (data: IDomainHistory, t: TFunction, domain: string, groupID?: string, groupName?: string) => {
  const dates = Object.keys(data).map((key) => new Date(key));
  const wholeInterval = { start: min(dates), end: max(dates) };
  const daysCount = !!dates.length ? (eachDayOfInterval(wholeInterval).length) : 0;

  const series = [
    {
      id: t(DashboardLegendsKeys.BenchmarkAvg),
      color: colors.chart.benchmark,
      data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].benchmark_avg)).toFixed(2) })),
    },
    {
      id: t(DashboardLegendsKeys.MarketAvg),
      color: colors.chart.market,
      data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].market_avg)).toFixed(2) })),
    },
  ];

  if (groupID && groupName) {
    series.push({
      id: groupName,
      color: colors.chart.group,
      data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key][groupID] as number || 0)).toFixed(2) })),
    })
  }

  series.push({
    id: domain,
    color: colors.chart.domain,
    data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].project_domain)).toFixed(2) })),
  });

  return { series, tickValues: (daysCount / 6).toFixed(0) };
};

export const createScoreData = (data: IDomainHistory, t: TFunction, domain: string, groupID?: string, groupName?: string) => {
  const dates = Object.keys(data).map((key) => new Date(key));
  const wholeInterval = { start: min(dates), end: max(dates) };
  const daysCount = !!dates.length ? (eachDayOfInterval(wholeInterval).length) : 0;

  const series = [
    {
      id: t(DashboardLegendsKeys.BenchmarkAvg),
      color: colors.chart.benchmark,
      data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].benchmark_avg) * 100).toFixed(0) })),
    },
    {
      id: t(DashboardLegendsKeys.MarketAvg),
      color: colors.chart.market,
      data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].market_avg) * 100).toFixed(0) })),
    },
  ];

  if (groupID && groupName) {
    series.push({
      id: groupName,
      color: colors.chart.group,
      data: Object.keys(data).map((key) => ({ x: key, y: ((Number(data[key][groupID] as number || 0)) * 100).toFixed(0) })),
    })
  }

  series.push({
    id: domain,
    color: colors.chart.domain,
    data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].project_domain) * 100).toFixed(0) })),
  });

  return { series, tickValues: (daysCount / 6).toFixed(0) };
};

export const createSmallTestData = (data: IDomainHistory, domain: string, urlGroups: IUrlGroup[]) => {
  const dates = Object.keys(data).map((key) => new Date(key));
  const wholeInterval = { start: min(dates), end: max(dates) };
  const daysCount = !!dates.length ? (eachDayOfInterval(wholeInterval).length) : 0;

  const series: Serie[] = [];

  urlGroups.forEach(({ name, url_group_id }, index) => {
    series.push({
      id: name,
      color: getColor(index),
      data: Object.keys(data).map((key) => ({ x: key, y: roundNumber(data[key][url_group_id] as number || 0) })),
    });
  });

  series.push({
    id: domain,
    color: colors.chart.domain,
    data: Object.keys(data).map((key) => ({ x: key, y: roundNumber(data[key].project_domain) })),
  });

  return { series, tickValues: (daysCount / 6).toFixed(0) };
};

export const createMsTestData = (data: IDomainHistory, domain: string, urlGroups: IUrlGroup[]) => {
  const dates = Object.keys(data).map((key) => new Date(key));
  const wholeInterval = { start: min(dates), end: max(dates) };
  const daysCount = !!dates.length ? (eachDayOfInterval(wholeInterval).length) : 0;

  const series: Serie[] = [];

  urlGroups.forEach(({ name, url_group_id }, index) => {
    series.push({
      id: name,
      color: getColor(index),
      data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key][url_group_id] as number || 0)).toFixed(2) })),
    });
  });

  series.push({
    id: domain,
    color: colors.chart.domain,
    data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].project_domain)).toFixed(2) })),
  });

  return { series, tickValues: (daysCount / 6).toFixed(0) };
};

export const createScoreTestData = (data: IDomainHistory, domain: string, urlGroups: IUrlGroup[]) => {
  const dates = Object.keys(data).map((key) => new Date(key));
  const wholeInterval = { start: min(dates), end: max(dates) };
  const daysCount = !!dates.length ? (eachDayOfInterval(wholeInterval).length) : 0;

  const series: Serie[] = [];

  urlGroups.forEach(({ name, url_group_id }, index) => {
    series.push({
      id: name,
      color: getColor(index),
      data: Object.keys(data).map((key) => ({ x: key, y: ((Number(data[key][url_group_id] as number || 0)) * 100).toFixed(0) })),
    })
  });

  series.push({
    id: domain,
    color: colors.chart.domain,
    data: Object.keys(data).map((key) => ({ x: key, y: (Number(data[key].project_domain) * 100).toFixed(0) })),
  });

  return { series, tickValues: (daysCount / 6).toFixed(0) };
};

export const createCorrelationData = (data: IMetricsRankingCorrelation[], feature: Feature): IMetricsRankingCorrelation[] => {
  return data.map((item) => {
    return {
      ...item,
      value: roundNumber(feature === Feature.PerformanceScore ? item.value * 100 : item.value),
      color: item.position < 11 ? colors.fast : colors.moderate,
    }
  });
}

export const createLCPTestData = (data: ILeaderboardHistoryData, t: TFunction, kpiKey: string, isInMS: boolean, multiplier: number) => {
  const sortedDataKeys = Object.keys(data).sort();
  const dates = sortedDataKeys.map((key) => new Date(key));
  const wholeInterval = { start: min(dates), end: max(dates) };
  const daysCount = !!dates.length ? (eachDayOfInterval(wholeInterval).length) : 0;

  const series = [
    {
      id: t(LeaderboardKeys.LeaderboardLCPRank),
      color: colors.chart.benchmark,
      data: sortedDataKeys.map((key) => ({ x: key, y: (Number(data[key].rank || 0)).toFixed(0) })),
    },
    {
      id: t(LeaderboardKeys.LeaderboardLCPAvg, { kpiKey: t(kpiKey) }),
      color: colors.chart.avg,
      data: sortedDataKeys.map((key) => {
        const yValue = (Number(data[key].market_avg as number || 0)) * multiplier;
        let resultYValue: number | string = yValue;

        if (yValue > 10) {
          resultYValue = yValue.toFixed(0);
        }

        if (yValue < 10) {
          resultYValue = yValue.toFixed(2);
        }

        if (yValue < 2) {
          resultYValue = yValue.toFixed(3);
        }

        return {
          x: key,
          y: resultYValue
        };
      }),
    },
    {
      id: t(isInMS ? LeaderboardKeys.LeaderboardLCPValue : LeaderboardKeys.LeaderboardLCPValueNotInMS, { kpiKey: t(kpiKey) }),
      color: colors.chart.domain,
      data: sortedDataKeys.map((key) => {
        const yValue = (Number(data[key].project_domain as number || 0)) * multiplier;
        let resultYValue: number | string = yValue;

        if (yValue > 10) {
          resultYValue = yValue.toFixed(0);
        }

        if (yValue < 10) {
          resultYValue = yValue.toFixed(2);
        }

        if (yValue < 2) {
          resultYValue = yValue.toFixed(3);
        }

        return {
          x: key,
          y: resultYValue
        };
      }),
    },
  ];

  return { series, tickValues: (daysCount / 6).toFixed(0) };
};

export const createTreeQuantityData = (data: ICompoundAnalysisNetworkDetail[]) => {
  const quantityMap = data.reduce((acc, item) => {
    if (acc[item.resource_type]) {
      return { ...acc, [item.resource_type]: acc[item.resource_type] + 1 };
    }
    return { ...acc, [item.resource_type]: 1 };
  }, {} as any);

  return { name: StaticResourceTypes.All, color: RESOURCE_COLORS_BY_TYPE[StaticResourceTypes.All], children: entries(quantityMap).map(([key, value]) => ({ name: key, color: RESOURCE_COLORS_BY_TYPE[key as ResourceTypes], children: [{ name: key, color: RESOURCE_COLORS_BY_TYPE[key as ResourceTypes], value }] })) };
};

export const createTreeSizeData = (data: ICompoundAnalysisNetworkDetail[]) => {
  const quantityMap = data.reduce((acc, item) => {
    if (!item.resource_size) {
      return acc;
    }
    if (acc[item.resource_type]) {
      return { ...acc, [item.resource_type]: Math.round(item.resource_size / 1000) + acc[item.resource_type] };
    }
    return { ...acc, [item.resource_type]: Math.round(item.resource_size / 1000) };
  }, {} as any);

  return { name: StaticResourceTypes.All, color: RESOURCE_COLORS_BY_TYPE[StaticResourceTypes.All], children: entries(quantityMap).map(([key, value]) => ({ name: key, color: RESOURCE_COLORS_BY_TYPE[key as ResourceTypes], children: [{ name: key, color: RESOURCE_COLORS_BY_TYPE[key as ResourceTypes], value }] })) };
};

export const createMonitorData = (data: ICompoundAnalysisNetworkDetail[]) => {
  const quantityMap = data.reduce((acc, item) => {
    if (acc[item.resource_type]) {
      return {
        ...acc, [item.resource_type]: {
          quantity: acc[item.resource_type].quantity + 1,
          size: acc[item.resource_type].size + Math.round(item.resource_size / 1000),
        }
      };
    }
    return {
      ...acc, [item.resource_type]: {
        quantity: 1,
        size: Math.round(item.resource_size / 1000),
      }
    };
  }, {} as any);

  return entries(quantityMap).map(([key, value]: [string, any]) => ({ ...value, name: key, color: RESOURCE_COLORS_BY_TYPE[key as ResourceTypes] }));
};
import styled from "styled-components";

export const Wrapper = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  padding: 20px;

  thead {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryDark};

    th {
      &:not(:first-child) {
        text-align: start;
      }

      &:nth-child(2) {
        width: 46%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 16%;
      }
    }
  }

  th {
    height: 30px;
    vertical-align: top;
    padding-top: 5px;
    &:first-child {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }
  }

  tbody {
    tr:not(:first-child) {
      border-top: 2px solid ${({ theme }) => theme.colors.grey};
    }

    td {
      height: 46px;
      vertical-align: middle;

      &:not(:first-child) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 5px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        & > div {
          height: 38px;
          width: 90%;
        }
      }

      svg {
        cursor: pointer;
      }

      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      #cancel {
        width: 26px;
        height: 26px;
        #icon_close {
          opacity: 1!important;
        }
      }

      #Rectangle_2116 {
        fill: none!important;
      }

      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 14px;
      }
    }
  }
`;
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';

import { NavigationMenuKeys } from '../../enums/locales/sideBar';
import { NAVIGATION_MAP as NavMap, NAVIGATION_MENU_GROUPS } from '../../constants/sideBar';

import { appActions } from '../../redux/app/actions';
import { selectLanguage, selectUI } from '../../redux/app/selectors';
import { selectPathPattern } from '../../redux/router/selectors';

import { IconMenu, IconMenuExpand } from '../../components/styled/icons';
import { NavigationMenu } from './NavigationMenu';
import { StyledWrapper as Wrapper } from './SideBar.styled';

const SideBar: React.FC = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectLanguage);
  const currentPathPattern = useSelector(selectPathPattern);
  const { isSideBarCollapsed } = useSelector(selectUI);

  const selectedKey = useMemo(() => NavMap.get(currentPathPattern) as NavigationMenuKeys, [
    currentPathPattern,
  ]);

  const handleNavigationMenuSelect = useCallback((key) => {
    const pathPattern = NavMap.get(key) as string;
    dispatch(push(generatePath(pathPattern, { lang: currentLanguage })));
  }, [dispatch, currentLanguage]);

  const handleClickExpandTrigger = useCallback(() => {
    dispatch(appActions.uiMerge({ isSideBarCollapsed: !isSideBarCollapsed }));
  }, [dispatch, isSideBarCollapsed]);

  const expandTriggerIcon = isSideBarCollapsed ? <IconMenu /> : <IconMenuExpand />;

  return (
    <Wrapper>
      <div className="navigation-menu-section">
        <NavigationMenu
          collapsed={isSideBarCollapsed}
          selectedKey={selectedKey}
          groups={NAVIGATION_MENU_GROUPS}
          onSelect={handleNavigationMenuSelect}
        />
      </div>
      <div className="bottom-menu-section">
        <div
          className="navigation-menu-expand-trigger"
          onClick={handleClickExpandTrigger}
        >
          {expandTriggerIcon}
        </div>
      </div>
    </Wrapper>
  );
};

export default SideBar;

import { ActionUnion, createAction } from '../commonTypes';
import { IUIPayload, UsersActionTypes } from './types';
import { EmptyObject } from '../../types/common.types';
import { IUserSimple } from '../../services/AuthService/types';
import { SortOrder } from '../../enums/data';
import { UsersDataKeys } from '../../enums/users';

export const usersActions = {
  usersReload: (): { type: UsersActionTypes, payload: EmptyObject } => createAction(
    UsersActionTypes.USERS_RELOAD,
  ),
  usersRefresh: (payload: IUserSimple[]): { type: UsersActionTypes, payload: IUserSimple[] } => createAction(
    UsersActionTypes.USERS_REFRESH,
    payload,
  ),
  usersSortKeyRefresh: (payload: UsersDataKeys): { type: UsersActionTypes, payload: UsersDataKeys } => createAction(
    UsersActionTypes.USERS_SORT_KEY_REFRESH,
    payload,
  ),
  usersSortOrderRefresh: (payload: SortOrder): { type: UsersActionTypes, payload: SortOrder } => createAction(
    UsersActionTypes.USERS_SORT_ORDER_REFRESH,
    payload,
  ),
  userDelete: (payload: string): { type: UsersActionTypes, payload: string } => createAction(
    UsersActionTypes.USER_DELETE,
    payload,
  ),
  usersSearchRefresh: (payload: string): { type: UsersActionTypes, payload: string } => createAction(
    UsersActionTypes.USERS_SEARCH_REFRESH,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: UsersActionTypes, payload: IUIPayload } => createAction(
    UsersActionTypes.UI_MERGE,
    payload,
  ),
}

export type UsersActions = ActionUnion<typeof usersActions>;

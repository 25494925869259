import styled from "styled-components";
import { THEME } from "../../../constants/theme";

const { colors } = THEME;

export const Wrapper = styled.div<{ width?: number, height?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3%;
  height: ${({ height }) => height ? `${height}px` : '100%'};
  width: ${({ width }) => width ? `${width}px` : '100%'};
  color: ${colors.primaryBg};

  svg:first-child {
    margin-left: auto;
    margin-right: auto;
  }
`;
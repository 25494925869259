import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import clsx from "clsx";

import { ButtonTextKeys, ConditionsKeys, GoalsKeys } from "../../../enums/locales/forms";
import { CONDITIONS } from "../../../enums/projectSettings";
import { IGoal } from "../../../services/ProjectService/types";

import { Button, ButtonType } from "../Button";
import { Input } from "../Input";
import { Select } from "../Select";
import { Switch } from "../Switch";

import { Wrapper } from './GoalsList.styled';
import { Feature } from "../../../enums/data";
import { METRIC_OPTIONS } from "../../../constants/dashboard";
import { IconDelete } from "../icons";

const conditions = [
  {
    value: CONDITIONS.Less,
    label: ConditionsKeys.Less,
  },
  {
    value: CONDITIONS.More,
    label: ConditionsKeys.More,
  },
];

const initItem = { name: Feature.PerformanceScore, condition: CONDITIONS.Less, value: 0, is_notify: false };

export interface IProps {
  value?: IGoal[];
  error?: boolean;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
}

const GoalsList: React.FC<IProps> = ({
  value = [],
  error,
  onChange = () => { },
  onBlur = () => { },
}) => {
  const { t } = useTranslation();
  const [newItem, setNewItem] = useState<IGoal>(initItem);
  const [oldValue, setOldValue] = useState(value);

  useEffect(() => {
    if (!isEqual(oldValue, value)) {
      onBlur();
      setOldValue(value);
    }
  }, [value, onBlur, oldValue]);

  const onNewItemChange = useCallback((e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  }, [newItem, setNewItem]);

  const onNewItemSelectChange = useCallback((name: string) => (value: string) => {
    setNewItem({ ...newItem, [name]: value });
  }, [newItem, setNewItem]);

  const translatedMetrics = useMemo(() => {
    return METRIC_OPTIONS.map((item) => {
      const resultLabel = item.subtitle ? `${t(item.label)} (${t(item.subtitle)})` : t(item.label);

      return ({ ...item, label: resultLabel });
    })
  }, [t]);

  const filteredMetrics = useMemo(() => {
    return translatedMetrics.filter(({ value: itemValue }) => !value.find(({ name }) => name === itemValue));
  }, [translatedMetrics, value]);

  const onAdd = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onChange([...value, newItem]);

    const filterNewName = filteredMetrics.filter(({ value: itemValue }) => itemValue !== newItem.name);
    setNewItem({ ...initItem, name: filterNewName[0]?.value || '' });
  }, [onChange, value, setNewItem, newItem, filteredMetrics]);

  const getMetricTitle = useCallback((feature) => {
    return translatedMetrics.find(({ value: itemValue }) => itemValue === feature)?.label;
  }, [translatedMetrics]);

  const translatedConditions = useMemo(() => {
    return conditions.map((item) => ({ ...item, label: t(item.label) }));
  }, [t]);

  const toggleNotify = useCallback((feature) => () => {
    const newValue = value.map((item) => item.name === feature ? { ...item, is_notify: !item.is_notify } : item);
    onChange(newValue);
  }, [onChange, value]);

  const onDelete = useCallback((feature) => () => {
    const newValue = value.filter((item) => item.name !== feature);
    onChange(newValue);
    if (!newItem.name) {
      setNewItem({ ...newItem, name: feature });
    }
  }, [onChange, value, newItem, setNewItem]);

  const showList = !!value.length;

  return (
    <Wrapper error={error}>
      <div className="data-input">
        <Select
          value={newItem.name}
          options={filteredMetrics}
          disabled={!newItem.name}
          onChange={onNewItemSelectChange('name')}
        />
        <Select
          value={newItem.condition}
          options={translatedConditions}
          onChange={onNewItemSelectChange('condition')}
        />
        <Input
          name="value"
          type="number"
          value={newItem.value}
          onChange={onNewItemChange}
        />
        <Button
          type={ButtonType.Primary}
          onClick={onAdd}
          disabled={!newItem.name}
          title={t(ButtonTextKeys.AddGoal)}
        />
      </div>
      {showList && (
        <div className="goals-list">
          <div className="goals-list-title">{t(GoalsKeys.ListHeader)}:</div>
          {value.map(({ name: feature, condition, is_notify: isNotify, value: itemValue }) => (
            <div key={feature} className="goals-list-item">
              <div className="feature">
                <IconDelete onClick={onDelete(feature)} /> {getMetricTitle(feature)} {condition === CONDITIONS.Less ? '<' : '>'} {itemValue}
              </div>
              <div className="notify">
                <Switch
                  className={clsx("notify-switch", { checked: isNotify })}
                  label={t(GoalsKeys.SwitchLabel)}
                  checked={isNotify}
                  onChange={toggleNotify(feature)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default GoalsList;

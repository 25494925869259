import { createSelector } from 'reselect';
import { RootState } from '../store';

const user = (state: RootState) => state.Auth.user;
const UI = (state: RootState) => state.Auth.UI;

export const selectUser = createSelector(
	[user],
	(user) => user,
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

export const selectIsLoggedIn = createSelector(
	[selectUser],
	(user) => Boolean(user),
);

export const selectUserEmail = createSelector(
	[user],
	(user) => user?.primary_email || user?.not_verified_email || undefined,
);

export const selectProfileName = createSelector(
	[user],
	(user) => user?.profile?.display_name || user?.primary_email.split('@')[0] || user?.not_verified_email?.split('@')[0] || undefined,
);

export const selectProfilePicture = createSelector(
	[user],
	(user) => user?.profile?.profile_picture?.download_url || '',
);

export const selectProfilePictureThumbnail = createSelector(
	[user],
	(user) => user?.profile?.profile_picture_thumbnail?.download_url || '',
);

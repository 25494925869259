import React, { useEffect, useState } from "react";
import LibSwitch, { SwitchProps } from "antd/lib/switch";

import { Wrapper } from './Switch.styled';

export interface IProps extends SwitchProps {
  value?: boolean;
  className?: string;
  label?: string;
  onBlur?: (...args: any) => void;
}

const Switch: React.FC<IProps> = ({ className = '', label = '', value, onBlur = () => { }, ...rest }) => {
  const [oldValue, setOldValue] = useState(value);

  useEffect(() => {
    if (oldValue !== value) {
      onBlur();
      setOldValue(value);
    }
  }, [value, onBlur, oldValue]);

  return (
    <Wrapper className={className}>
      {label} <LibSwitch checked={!!value} {...rest} />
    </Wrapper>
  );
};

export default Switch;

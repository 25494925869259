import { createSelector } from 'reselect';
import { RootState } from '../store';

const accountLicenseID = (state: RootState) => state.AccountDetails.accountLicenseID;
const targetLicenseID = (state: RootState) => state.AccountDetails.targetLicenseID;
const projectID = (state: RootState) => state.AccountDetails.projectID;
const accounts = (state: RootState) => state.AccountDetails.accounts;
const creator = (state: RootState) => state.AccountDetails.creator;
const license = (state: RootState) => state.AccountDetails.license;
const projects = (state: RootState) => state.AccountDetails.projects;
const crawls = (state: RootState) => state.AccountDetails.crawls;
const accountsSearch = (state: RootState) => state.AccountDetails.accountsSearch;
const projectsSearch = (state: RootState) => state.AccountDetails.projectsSearch;
const projectsSortOrder = (state: RootState) => state.AccountDetails.projectsSortOrder;
const projectsSortKey = (state: RootState) => state.AccountDetails.projectsSortKey;
const crawlsSearch = (state: RootState) => state.AccountDetails.crawlsSearch;
const crawlsSortOrder = (state: RootState) => state.AccountDetails.crawlsSortOrder;
const crawlsSortKey = (state: RootState) => state.AccountDetails.crawlsSortKey;
const UI = (state: RootState) => state.AccountDetails.UI;

export const selectAccountLicenseID = createSelector(
	[accountLicenseID],
	(accountLicenseID) => accountLicenseID,
);

export const selectTargetLicenseID = createSelector(
	[targetLicenseID],
	(targetLicenseID) => targetLicenseID,
);

export const selectProjectID = createSelector(
	[projectID],
	(projectID) => projectID,
);

export const selectCreatorProfilePicture = createSelector(
	[creator],
	(creator) => creator?.profile?.profile_picture?.download_url || '',
);

export const selectAccountLicense = createSelector(
	[license],
	(license) => license,
);

export const selectAccountLicenseCreatorID = createSelector(
	[license],
	(license) => license?.creator,
);

export const selectAccounts = createSelector(
	[accounts],
	(accounts) => accounts,
);

export const selectAccountProjects = createSelector(
	[projects],
	(projects) => projects,
);

export const selectAccountProjectByID = (id: string | null) => createSelector(
	[projects],
	(projects) => projects?.find(({ project_id}) => project_id === id),
);

export const selectAccountProjectCrawls = createSelector(
	[crawls],
	(crawls) => crawls,
);

export const selectAccountsSearch = createSelector(
	[accountsSearch],
	(accountsSearch) => accountsSearch,
);

export const selectProjectsSearch = createSelector(
	[projectsSearch],
	(projectsSearch) => projectsSearch,
);

export const selectProjectsSortOrder = createSelector(
	[projectsSortOrder],
	(projectsSortOrder) => projectsSortOrder,
);

export const selectProjectsSortKey = createSelector(
	[projectsSortKey],
	(projectsSortKey) => projectsSortKey,
);

export const selectCrawlsSearch = createSelector(
	[crawlsSearch],
	(crawlsSearch) => crawlsSearch,
);

export const selectCrawlsSortOrder = createSelector(
	[crawlsSortOrder],
	(crawlsSortOrder) => crawlsSortOrder,
);

export const selectCrawlsSortKey = createSelector(
	[crawlsSortKey],
	(crawlsSortKey) => crawlsSortKey,
);

export const selectAccountLicenseCounts = createSelector(
	[license],
	(license) => ({
		user_slots: license?.user_slots || 0,
		project_slots: license?.project_slots || 0,
		keyword_slots: license?.keyword_slots || 0,
		url_slots: license?.url_slots || 0,
		user_slots_used: license?.user_slots_used || 0,
		project_slots_used: license?.project_slots_used || 0,
		keyword_slots_used: license?.keyword_slots_used || 0,
		url_slots_used: license?.url_slots_used || 0
	})
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

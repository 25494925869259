import styled from "styled-components";

export const Wrapper = styled.div`
  counter-reset: child-number;
  margin-bottom: 60px;

  .fields-group-title {
    position: relative;
    counter-increment: child-number;
    font-size: 18px;
    font-weight: bold;
    padding-top: 40px;
    color: ${({ theme }) => theme.colors.lightBlack};

    ::before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      content: counter(child-number);
      font-size: 14px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.secondaryDark};
      top: 35px;
      left: -48px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 2px solid ${({ theme }) => theme.colors.secondaryDark};
    }

    .suffix {
      margin-left: 10px;
      color: ${({ theme }) => theme.colors.darkGrey};
      font-size: 14px;
      font-weight: 400;
    }
  }

  .success-indicator {
    display: none!important;
  }

  .label {
    margin-bottom: 0;
    margin-top: 30px;
    .label {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 16px;
      margin-top: 0;
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  input {
    caret-color: ${({ theme }) => theme.colors.secondaryDark};
  }

  .domain-and-project {
    .control > div {
      height: 42px;
    }
  }
`;
import styled from 'styled-components';
import { ReactComponent as RawDeleteSVG } from '../../../../assets/icons/delete-icon.svg';

export const IconDelete = styled(RawDeleteSVG)`
  .a {
    fill: none;
  }

  .b {
    fill: #171c28;
  }
`;

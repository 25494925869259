import React from 'react';
import { format } from 'date-fns';
import { TFunction } from 'react-i18next';
import AntdPopConfirm from 'antd/lib/popconfirm';

import { THEME } from '../../../../constants/theme';
import { ACCOUNT_DETAILS_PROJECT_DATE_FORMAT } from '../../../../constants/formats';
import { AccountsKeys } from '../../../../enums/locales/accounts';
import { ProjectsOverviewKeys } from '../../../../enums/locales/projects';
import { ISortParams } from '../../../../types/crawls.types';
import { CrawlsDataKeys } from '../../../../enums/projects';
import { SortOrder } from '../../../../enums/data';
import { StackedIndicator } from '../../../../components/StackedIndicator';
import { IconArrowDownward, IconArrowUpward, IconDelete } from '../../../../components/styled/icons';

export const getCrawlsColumns = (
  t: TFunction,
  onCrawlDelete: (id: string) => () => void,
  sortParams: ISortParams,
) => {
  const { sortOrder, sortKey, onSortChange } = sortParams;

  return [
    {
      dataIndex: CrawlsDataKeys.CrawlID,
      title: (
        <div
          className="cell"
          onClick={onSortChange(CrawlsDataKeys.CrawlID)}
        >
          {t(AccountsKeys.CrawlIDLabel)}
          {sortKey === CrawlsDataKeys.CrawlID && (
            <div className="sort-icon">
              {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
            </div>
          )}
        </div>
      ),
      width: '30%',
      render: (item: any) => <span title={item.crawl_id}>{item.crawl_id}</span>,
    },
    {
      dataIndex: CrawlsDataKeys.CreatedAt,
      title: (
        <div
          className="cell"
          onClick={onSortChange(CrawlsDataKeys.CreatedAt)}
        >
          {t(AccountsKeys.CreatedAtLabel)}
          {sortKey === CrawlsDataKeys.CreatedAt && (
            <div className="sort-icon">
              {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
            </div>
          )}
        </div>
      ),
      width: '30%',
      render: (item: any) => (
        <span title={format(new Date(item.created_at), ACCOUNT_DETAILS_PROJECT_DATE_FORMAT)}>
          {format(new Date(item.created_at), ACCOUNT_DETAILS_PROJECT_DATE_FORMAT)}
        </span>
      ),
    },
    {
      dataIndex: 'status',
      title: t(AccountsKeys.StatusLabel),
      width: '30%',
      render: (item: any) => (
        <div className="status">
          <StackedIndicator
            hideTitle
            value={item.no_jobs_done}
            maxValue={item.no_jobs}
            color={THEME.colors.fast}
            backColor={THEME.colors.slow}
          />
          <div className="total">
            <div className="total-top">
              <div className="total-all">
                <span>{t(AccountsKeys.CrawlTotalLabel)}:</span>
                <span>{item.no_jobs}</span>
              </div>
              <div className="total-done">
                <span>{t(AccountsKeys.CrawlDoneLabel)}:</span>
                <span>{item.no_jobs_done}</span>
              </div>
            </div>
            <div className="total-bottom">
              <div className="total-open">
                <span>{t(AccountsKeys.CrawlOpenLabel)}:</span>
                <span>{item.no_jobs - item.no_jobs_done - item.no_jobs_failed}</span>
              </div>
              <div className="total-failed">
                <span>{t(AccountsKeys.CrawlFailedLabel)}:</span>
                <span>{item.no_jobs_failed}</span>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      dataIndex: 'options',
      title: '',
      with: '10%',
      render: (item: any) => (
        <div className="option-buttons">
          <AntdPopConfirm
            getPopupContainer={(triggerNode: any) => triggerNode}
            icon={null}
            placement="left"
            title={t(AccountsKeys.DeleteCrawlMessage)}
            okText={t(ProjectsOverviewKeys.DeleteConfirmButtonTitle)}
            cancelText={t(ProjectsOverviewKeys.DeleteCancelButtonTitle)}
            onConfirm={onCrawlDelete(item.crawl_id)}
          >
            <div className="button-delete">
              <IconDelete />
            </div>
          </AntdPopConfirm>
        </div>
      ),
    },
  ];
};

import orderBy from 'lodash/orderBy';
import { SortOrder } from '../../enums/data';
import { IDomainHistory } from '../../services/DataService/types';

export const sortHistoryData = (data: IDomainHistory, order: SortOrder): IDomainHistory => {
  const dates = Object.keys(data);
  const sortedDates = orderBy(dates, (date) => new Date(date).valueOf(), [order]);

  return sortedDates.reduce((res, date) => {
    res[date] = data[date];
    return res;
  }, {} as IDomainHistory);
};

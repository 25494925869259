import styled from "styled-components";

export const StyledWrapper = styled.div`
  table {
    border-collapse: separate;
    border-spacing: 0 6px;
  }

  thead tr {
    height: 66px!important;
  }

  thead th,
  tbody tr td {
    padding: 0 12px!important;

    &:nth-child(1),
    &:nth-child(2) {
      text-align: start!important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .cell {
        justify-content: flex-start;
      }
    }

    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: #171C28;
  }

  .cell {
    display: flex;
    align-items: center;
    cursor: pointer;

    .sort-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      margin-left: 4px;

      svg {
        transform: scale(0.8);
      }
    }
  }

  tbody tr {
    position: relative;
    background: #ffffff!important;
    border-top: 0!important;
    border-radius: 12px!important;
    box-shadow: 0px 2px 10px #0000000F!important;

    td:first-child {
      border-top-left-radius: 12px;
    }
    td:last-child {
      border-top-right-radius: 12px;
    }
    td:first-child {
      border-bottom-left-radius: 12px;
    }
    td:last-child {
      border-bottom-right-radius: 12px;
    }
  }

  tbody::after {
    box-shadow: none!important;
  }

  .status {
    .total {
      display: flex;
      flex-direction: column;

      margin-top: 10px;

      font-size: 12px;
      font-weight: 500;
      line-height: 18px;

      .total-top,
      .total-bottom {
        display: flex;
        justify-content: space-between;

        > div {
          display: flex;
          justify-content: flex-start;
          width: 50%;

          span {
            display: inline-block;
          }

          span:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
  
  .option-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    div:first-child,
    div:nth-child(2) {
      margin-right: 10px;
    }

    svg {
      cursor: pointer;
      width: 18px; 
    }
  }
`;

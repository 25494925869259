import React from "react";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { FieldLabelKeys, FieldSubLabelKeys } from '../../../enums/locales/forms';
import { IFieldErrorMessage } from '../../../types/forms.types';
import { IconCheckmarkGreen } from '../../styled/icons';
import { StyledWrapper as Wrapper } from './Field.styled';
import { SiderStepsSuffixes } from "../../../enums/locales/modals";

export interface IProps {
  className?: string;
  labelKey?: FieldLabelKeys;
  subLabel?: FieldSubLabelKeys | SiderStepsSuffixes;
  error?: IFieldErrorMessage;
  touched?: boolean;
  noBottomMargin?: boolean;
}

const Field: React.FC<IProps> = ({
  className = '',
  labelKey = '',
  subLabel = '',
  error,
  touched,
  noBottomMargin,
  children,
}) => {
  const { t } = useTranslation();
  const isLabel = Boolean(labelKey);
  const isError = Boolean(error);

  const wrapperClassName = clsx({
    [className]: Boolean(className),
    label: isLabel,
    error: isError,
    touched,
    'no-bottom-margin': noBottomMargin,
  });

  const errorMessage = error
    ? error?.value ? t(error.key, { value: error.value })
      : t(error.key) : '';

  return (
    <Wrapper className={wrapperClassName}>
      <div className="label">
        <div>
          <span>{t(labelKey)}</span>
          {subLabel && <span className="sub-label">({t(subLabel)})</span>}
        </div>
      </div>
      <div className="control">
        {children}
      </div>
      <div className="error-message">
        {errorMessage}
      </div>
      <div className="success-indicator">
        <IconCheckmarkGreen />
      </div>
    </Wrapper>
  );
};

export default Field;

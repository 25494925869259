import { Languages } from '../enums/locales/languages';

export const I18NEXT_LOCALESTORAGE_PREFIX = 'i18next_res_';
export const I18NEXT_LOCALESTORAGE_CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours

export const SUPPORTED_LANGUAGES = [
  {
    key: Languages.En,
    title: 'English',
  },
  {
    key: Languages.De,
    title: 'Deutsch',
  },
];

import styled from "styled-components";

import { THEME } from "../../../constants/theme";

const { colors } = THEME;

export const Wrapper = styled.div`
  font-size: 13px;
  font-weight: 600;

  .ant-switch {
    margin-left: 14px;
    margin-right: 20px;
    height: 26px;
    width: 44px;
    background: #EAEAEA;

    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
      box-shadow: none;
    }

    .ant-switch-handle {
      top: 4px;
      left: 4px;
    }

    &.ant-switch-checked {
      background: ${colors.primaryBlue};

      .ant-switch-handle {
        left: calc(100% - 18px - 4px)
      }
    }
  }
`;
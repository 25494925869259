import { AxiosError } from 'axios';
import { BaseAPIService } from '../BaseAPIService';
import { IBaseResponse } from '../BaseAPIService/types';
import { dataServiceApiUrl } from '../../config';
import { API_LICENCE_ROUTES } from '../../constants/routes';
import { IRefreshTokensOptions } from '../AuthService/types';
import { IProject, IProjectsResponseBody, ICrawlsResponseBody } from '../ProjectService/types';
import { ILicense, ILicenseCompany, ILicenseResponseBody, ILicenseUserRequestBody } from './types';
import { RouteParamPlaceholder } from '../../enums/routes'

class LicensesService extends BaseAPIService {

  constructor(url: string) {
    super(url);
    this.getLicenses = this.getLicenses.bind(this);
    this.getProjects = this.getProjects.bind(this);
    this.createProject = this.createProject.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.crawlsStart = this.crawlsStart.bind(this);
    this.crawlsActive = this.crawlsActive.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.updateUsers = this.updateUsers.bind(this);
  }

  async getLicenses(options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicenseResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicenseResponseBody> = await this.get<ILicenseResponseBody>(
        `${API_LICENCE_ROUTES.licenses}`,
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicenseResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async getProjects(licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProjectsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProjectsResponseBody> = await this.get<IProjectsResponseBody>(
        API_LICENCE_ROUTES.projects.replace(RouteParamPlaceholder.LicenseID, `${licenseID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProjectsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async createProject(body: Partial<IProject>, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProject>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProject> = await this.post<IProject, Partial<IProject>>(
        API_LICENCE_ROUTES.projects.replace(RouteParamPlaceholder.LicenseID, `${licenseID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProject> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateProject(body: Partial<IProject>, projectID: string, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<IProject>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<IProject> = await this.patch<IProject, Partial<IProject>>(
        `${API_LICENCE_ROUTES.projects.replace(RouteParamPlaceholder.LicenseID, `${licenseID}`)}/${projectID}`,
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<IProject> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async crawlsActive(licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<ICrawlsResponseBody>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ICrawlsResponseBody> = await this.get<ICrawlsResponseBody>(
        API_LICENCE_ROUTES.crawlsActive.replace(RouteParamPlaceholder.LicenseID, `${licenseID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ICrawlsResponseBody> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async crawlsStart(projectID: string, licenseID: string, options?: IRefreshTokensOptions): Promise<IBaseResponse<unknown>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<unknown> = await this.post<unknown>(
        API_LICENCE_ROUTES.crawlsStart.replace(RouteParamPlaceholder.LicenseID, `${licenseID}`).replace(RouteParamPlaceholder.ProjectID, `${projectID}`),
        {},
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<unknown> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateCompany(licenseID: string, body: ILicenseCompany, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicense>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicense> = await this.patch<ILicense, ILicenseCompany>(
        API_LICENCE_ROUTES.company.replace(RouteParamPlaceholder.LicenseID, `${licenseID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicense> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }

  async updateUsers(licenseID: string, body: ILicenseUserRequestBody, options?: IRefreshTokensOptions): Promise<IBaseResponse<ILicense>> {
    const { retryAction = null, config = {} } = options || {};
    try {
      const response: IBaseResponse<ILicense> = await this.post<ILicense, ILicenseUserRequestBody>(
        API_LICENCE_ROUTES.user.replace(RouteParamPlaceholder.LicenseID, `${licenseID}`),
        body,
        { ...config },
        { retryAction }
      );

      const result: IBaseResponse<ILicense> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }
}

const ServiceInstance = new LicensesService(dataServiceApiUrl);

export default ServiceInstance;
export { ServiceInstance as LicensesService };

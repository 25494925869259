import axios, { AxiosError } from 'axios';
import { BaseAPIService } from '../BaseAPIService';
import { IBaseResponse } from '../BaseAPIService/types';

class ExternalService extends BaseAPIService {

  constructor(url?: string) {
    super(url);
    this.imageUpload = this.imageUpload.bind(this);
  }

  async imageUpload(url: string, file: File): Promise<IBaseResponse<string>> {
    try {
      const response = await axios.put(
        url,
        file,
        {}
      );

      const result: IBaseResponse<string> = { data: response?.data };
      return Promise.resolve(result);

    } catch (error) {
      return { error: this.processError(error as AxiosError) };
    }
  }
}

const ServiceInstance = new ExternalService();

export default ServiceInstance;
export { ServiceInstance as ExternalService };


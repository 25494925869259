import styled from "styled-components";

import { THEME } from "../../../constants/theme";
import { hexToRGBA } from "../../../utils/theme";

const { colors } = THEME;

export const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: ${({ visible }) => visible ? 'translate(0, 0)' : 'translate(100%, 0)'};
  background: ${({ visible }) => visible ? hexToRGBA(colors.sider.mask, colors.sider.maskOpacity) : hexToRGBA(colors.sider.mask, 0)};
  transition: ${({ visible }) => !visible ? 'background .5s, transform .1s .5s' : 'transform .1s, background .5s .1s'};
  
  .sider-body {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 70vw;

    @media (max-width: 1600px) {
      width: 75vw;
    }
    @media (max-width: 1440px) {
      width: 80vw;
    }
    @media (max-width: 1280px) {
      width: 90vw;
    }

    transform: ${({ visible }) => visible ? 'translate(0, 0)' : 'translate(100%, 0)'};
    background: white;
    transition: all .5s ease;

    .sider-header {
      display: flex;
      align-items: center;
      height: 100px;
      width: 100%;
      padding: 0 32px;
      background: ${colors.linkBlue};
      font-size: 28px;
      font-weight: 600;
      color: ${colors.white};

      .btn-back {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background: ${hexToRGBA(colors.black, 0.18)};
        margin-right: 18px;
        cursor: pointer;

        svg {
          transform: rotate(180deg);
        }
      }
    }

    .sider-body-content {
      display: flex;

      .sider-body-content-form {
        position: relative;
        overflow: auto;
        scroll-behavior: smooth;
        width: 75%;
        height: calc(100vh - 100px);
        padding-left: 8.5%;
        padding-right: 1.6%;
        padding-top: 40px;
      }

      .sider-body-content-meta {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: content-box;
        top: -130px;
        right: 0;
        width: 25%;
        height: calc(100vh - 40px);
        padding: 50px 3.77% 20px;

        .navigation {
          width: 100%;
          height: calc(100vh - 146px);
          display: flex;
          flex-direction: column;
        }

        .btn-container {
          width: 100%;
          margin-top: 20px;
          padding-left: 20px;
        }

        .limits-message {
          font-size: 12px;
          text-align: center;
          font-weight: 600;
          color: ${({ theme }) => theme.colors.red};
          margin-bottom: 16px;
          padding-right: 60px;
        }

        .switch-container {
            & > div {
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
            }
            margin-bottom: 10px;
          }

        .continue-btn {
          width: 100%;
          padding: 0 60px 0 0;
        }
      }
    }
  }
`;
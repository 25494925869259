import { CoreWebVitalsTypes, CoreWebVitalsValues } from '../enums/coreWebVitals';
import { Feature } from '../enums/data';
import { DashboardKpiKeys, DashboardKpiSubtitleKeys, DashboardWebVitalsFilters, DashboardWebVitalsKeys } from '../enums/locales/dashboard';
import { IOption } from '../types/common.types';

export const CORE_WEB_VITALS_RECOMMENDATIONS = {
  [DashboardKpiKeys.SpeedIndex]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 0,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 0,
      to: 0,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 0,
      to: 0,
    },
  },
  [DashboardKpiKeys.PerformanceScore]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 0,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 0,
      to: 0,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 0,
      to: 0,
    },
  },
  [DashboardKpiKeys.FirstMeaningfulPaint]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 2000,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 2000,
      to: 4000,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 4000,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.FirstContentfulPaint]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 1800,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 1800,
      to: 3000,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 3000,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.LargestContentfulPaint]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 2500,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 2500,
      to: 4000,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 4000,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.CumulativeLayoutShift]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 10
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 10,
      to: 25,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 25,
      to: Infinity,
    },
  },

  [DashboardKpiKeys.ExperimentalTimeToFirstByte]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 800
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 800,
      to: 1800,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 1800,
      to: Infinity,
    },
  },

  [DashboardKpiKeys.InteractionToNextPaint]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 500,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 500,
      to: Infinity,
    },
  },

  [DashboardKpiKeys.FirstInputDelay]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 99,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 99,
      to: 299,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 299,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.TimeToInteractive]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 3800,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 3800,
      to: 7300
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 7300,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.TotalBlockingTime]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.UnusedCss]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.UnusedCssTitle]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.UnusedJs]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.UnminifiedCss]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.UnminifiedJs]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.ModernImage]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.OptimizedImages]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.RenderBlockingResources]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.OffscreenImage]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.ServerResponseTime]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.DOMSize]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumTasksOver50ms]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumTasksOver100ms]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumTasksOver500ms]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumTasks]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.TotalTaskTime]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.TotalByteWeight]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.UsesTextCompression]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumFonts]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumRequests]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumScripts]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
  [DashboardKpiKeys.NumStylesheets]: {
    [CoreWebVitalsTypes.Fast]: {
      from: 0,
      to: 200,
    },
    [CoreWebVitalsTypes.Moderate]: {
      from: 200,
      to: 600,
    },
    [CoreWebVitalsTypes.Slow]: {
      from: 600,
      to: Infinity,
    },
  },
};

export const VITALS_OPTIONS: IOption[] = [
  { value: CoreWebVitalsValues.Top5, label: DashboardWebVitalsFilters.Top5Market },
  { value: CoreWebVitalsValues.Top10, label: DashboardWebVitalsFilters.Top10Market },
]

export const TITLES: { [key in Feature]?: DashboardKpiKeys } = {
  [Feature.PerformanceScore]: DashboardKpiKeys.PerformanceScore,
  [Feature.FirstContentfulPaintFD]: DashboardKpiKeys.FirstContentfulPaint,
  [Feature.LargestContentfulPaintFD]: DashboardKpiKeys.LargestContentfulPaint,
  [Feature.FirstInputDelayFD]: DashboardKpiKeys.FirstInputDelay,
  [Feature.CumulativeLayoutShiftFD]: DashboardKpiKeys.CumulativeLayoutShift,

  // experimetnal features
  [Feature.InteractionToNextPaint]: DashboardKpiKeys.InteractionToNextPaint,
  [Feature.ExperimentalTimeToFirstByte]: DashboardKpiKeys.ExperimentalTimeToFirstByte,

  [Feature.SpeedIndex]: DashboardKpiKeys.SpeedIndex,
  [Feature.TotalBlockingTimeLab]: DashboardKpiKeys.TotalBlockingTime,
  [Feature.FirstMeaningfulPaintLab]: DashboardKpiKeys.FirstMeaningfulPaint,
  [Feature.FirstContentfulPaintLab]: DashboardKpiKeys.FirstContentfulPaint,
  [Feature.LargestContentfulPaintLab]: DashboardKpiKeys.LargestContentfulPaint,
  [Feature.TimeToInteractiveLab]: DashboardKpiKeys.TimeToInteractive,
  [Feature.CumulativeLayoutShiftLab]: DashboardKpiKeys.CumulativeLayoutShift,
  [Feature.ServerResponseTime]: DashboardKpiKeys.ServerResponseTime,
  [Feature.DOMSize]: DashboardKpiKeys.DOMSize,
  [Feature.NumTasksOver50ms]: DashboardKpiKeys.NumTasksOver50ms,
  [Feature.NumTasksOver100ms]: DashboardKpiKeys.NumTasksOver100ms,
  [Feature.NumTasksOver500ms]: DashboardKpiKeys.NumTasksOver500ms,
  [Feature.UnusedCssLab]: DashboardKpiKeys.UnusedCss,
  [Feature.UnusedJsLab]: DashboardKpiKeys.UnusedJs,
  [Feature.UsesTextCompression]: DashboardKpiKeys.UsesTextCompression,
  [Feature.UnminifiedJsLab]: DashboardKpiKeys.UnminifiedJs,
  [Feature.UnminifiedCssLab]: DashboardKpiKeys.UnminifiedCss,
  [Feature.ModernImage]: DashboardKpiKeys.ModernImage,
  [Feature.OffscreenImage]: DashboardKpiKeys.OffscreenImage,
  [Feature.NumTasks]: DashboardKpiKeys.NumTasks,
  [Feature.TotalTaskTime]: DashboardKpiKeys.TotalTaskTime,
  [Feature.TotalByteWeight]: DashboardKpiKeys.TotalByteWeight,
  [Feature.NumFonts]: DashboardKpiKeys.NumFonts,
  [Feature.NumRequests]: DashboardKpiKeys.NumRequests,
  [Feature.NumScripts]: DashboardKpiKeys.NumScripts,
  [Feature.NumStylesheets]: DashboardKpiKeys.NumStylesheets,
}

export const SUB_TITLES: { [key in Feature]?: DashboardKpiSubtitleKeys } = {
  [Feature.FirstContentfulPaintFD]: DashboardKpiSubtitleKeys.FD,
  [Feature.LargestContentfulPaintFD]: DashboardKpiSubtitleKeys.FD,
  [Feature.FirstInputDelayFD]: DashboardKpiSubtitleKeys.FD,
  [Feature.CumulativeLayoutShiftFD]: DashboardKpiSubtitleKeys.FD,

  [Feature.TotalBlockingTimeLab]: DashboardKpiSubtitleKeys.Ms,
  [Feature.FirstMeaningfulPaintLab]: DashboardKpiSubtitleKeys.Lab,
  [Feature.FirstContentfulPaintLab]: DashboardKpiSubtitleKeys.Lab,
  [Feature.LargestContentfulPaintLab]: DashboardKpiSubtitleKeys.Lab,
  [Feature.TimeToInteractiveLab]: DashboardKpiSubtitleKeys.Ms,
  [Feature.CumulativeLayoutShiftLab]: DashboardKpiSubtitleKeys.Lab,
  [Feature.ServerResponseTime]: DashboardKpiSubtitleKeys.Ms,

  [Feature.UnusedCssLab]: DashboardKpiSubtitleKeys.Ms,
  [Feature.UnusedJsLab]: DashboardKpiSubtitleKeys.Ms,
  [Feature.UsesTextCompression]: DashboardKpiSubtitleKeys.Ms,
  [Feature.UnminifiedJsLab]: DashboardKpiSubtitleKeys.Ms,
  [Feature.UnminifiedCssLab]: DashboardKpiSubtitleKeys.Ms,
  [Feature.ModernImage]: DashboardKpiSubtitleKeys.SavingsInBytes,
  [Feature.OffscreenImage]: DashboardKpiSubtitleKeys.SavingsInBytes,
  [Feature.NumTasks]: DashboardKpiSubtitleKeys.Diagnostics,
  [Feature.TotalTaskTime]: DashboardKpiSubtitleKeys.Diagnostics,
  [Feature.TotalByteWeight]: DashboardKpiSubtitleKeys.Diagnostics,
  [Feature.NumFonts]: DashboardKpiSubtitleKeys.Diagnostics,
  [Feature.NumRequests]: DashboardKpiSubtitleKeys.Diagnostics,
  [Feature.NumScripts]: DashboardKpiSubtitleKeys.Diagnostics,
  [Feature.NumStylesheets]: DashboardKpiSubtitleKeys.Diagnostics,
}

export const COMPARISON_RULES: { [key in Feature]?: boolean } = {
  [Feature.PerformanceScore]: true,
}

export const CONDITIONAL_FORMATTING_RECOMMENDATIONS = {
  [CoreWebVitalsTypes.Slow]: {
    from: 0,
    to: 50,
  },
  [CoreWebVitalsTypes.Moderate]: {
    from: 50,
    to: 90,
  },
  [CoreWebVitalsTypes.Fast]: {
    from: 90,
    to: 100,
  },
}

export const CONDITIONAL_FORMATTING_RECOMMENDATIONS_BY_TOP_MARKET = {
  [CoreWebVitalsTypes.Fast]: {
    from: 0,
    to: 80,
  },
  [CoreWebVitalsTypes.Moderate]: {
    from: 80,
    to: 120,
  },
  [CoreWebVitalsTypes.Slow]: {
    from: 120,
    to: Infinity,
  },
}

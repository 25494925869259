import React from 'react';

import { THEME } from '../../constants/theme';
import { StyledWrapper as Wrapper } from './ProfileAvatar.styled';

const { colors } = THEME;

export interface IProps {
  url?: string;
  name?: string;
  templateBackgroundColor?: string;
  templateFontColor?: string;
  size?: number;
}

export interface IStyledWrapperProps extends Pick<IProps, 'templateBackgroundColor' | 'templateFontColor' | 'size'> { }

const ProfileAvatar: React.FC<IProps> = ({
  url = '',
  name = 'Guest',
  templateBackgroundColor = colors.primaryBlue,
  templateFontColor = colors.white,
  size = 40,
}) => {

  const avatar = url
    ? <img className="profile-avatar-image" src={`${url}?dummy=${Math.random()}`} />
    : <div className="profile-avatar-template">{name?.slice(0, 1).toUpperCase()}</div>

  return (
    <Wrapper
      templateBackgroundColor={templateBackgroundColor}
      templateFontColor={templateFontColor}
      size={size}
    >
      {avatar}
    </Wrapper>
  );
};

export default ProfileAvatar;

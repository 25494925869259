import { CoreWebVitalsValues } from '../../enums/coreWebVitals';
import { FeatureComparison } from '../../services/DataService/types';
import { VitalsActions } from './actions';
import { IUIPayload, VitalsActionTypes, VitalsState } from './types';

const initState: VitalsState = {
  data: {},
  quantifier: '',
  comparator: '',
  UI: {
    loading: false,
  },
};

export function vitalsReducer(state: VitalsState = initState, action: VitalsActions): VitalsState {
  switch (action.type) {
    case VitalsActionTypes.VITALS_REFRESH: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload as FeatureComparison,
        },
      }
    }
    case VitalsActionTypes.QUANTIFIER_REFRESH: {
      return {
        ...state,
        quantifier: action.payload as CoreWebVitalsValues | string,
      }
    }
    case VitalsActionTypes.COMPARATOR_REFRESH: {
      return {
        ...state,
        comparator: action.payload as string,
      }
    }
    case VitalsActionTypes.UI_MERGE: {
      return {
        ...state,
        UI: {
          ...state.UI,
          ...action.payload as IUIPayload,
        },
      };
    }
    case VitalsActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

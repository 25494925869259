import { createSelector } from 'reselect';
import { RootState } from '../store';
import { RouteParamPlaceholder } from '../../enums/routes';

const location = (state: RootState) => state.router.location;

export const selectPathname = createSelector(
	[location],
	(location) => location.pathname,
);

export const selectLangParam = createSelector(
	[location],
	(location) => location.pathname.split('/')[1],
);

export const selectQueryParams = createSelector(
	[location],
	(location) => location.query
)

export const selectActualRoute = createSelector(
	[location],
	(location) => `/${location.pathname.split('/').slice(2).join('/')}`,
);

export const selectPathPattern = createSelector(
	[selectActualRoute],
	(actualRoute) => `/${RouteParamPlaceholder.Lang}${actualRoute}`,
);

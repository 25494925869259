import { createSelector } from 'reselect';
import { RootState } from '../store';

const licenses = (state: RootState) => state.Licenses.licenses;
const activeLicenseID = (state: RootState) => state.Licenses.activeLicenseID;
const UI = (state: RootState) => state.Licenses.UI;

export const selectLicenses = createSelector(
	[licenses],
	(licenses) => licenses,
);

export const selectActiveLicenseID = createSelector(
	[activeLicenseID],
	(activeLicenseID) => activeLicenseID,
);

export const selectMainLicense = createSelector(
	[licenses],
	(licenses) => licenses[0],
);

export const selectActiveLicense = createSelector(
	[licenses, selectActiveLicenseID],
	(licenses, activeLicenseID) => licenses.find(({ license_id }) => license_id === activeLicenseID),
);

export const selectActiveLicenseUsersEmails = createSelector(
	[selectActiveLicense],
	(activeLicense) => activeLicense?.user.map(({ email }) => email),
);

export const selectActiveLisenseCounts = createSelector(
	[selectActiveLicense],
	(activeLicense) => ({
		user_slots: activeLicense?.user_slots || 0,
		project_slots: activeLicense?.project_slots || 0,
		keyword_slots: activeLicense?.keyword_slots || 0,
		url_slots: activeLicense?.url_slots || 0,
		user_slots_used: activeLicense?.user_slots_used || 0,
		project_slots_used: activeLicense?.project_slots_used || 0,
		keyword_slots_used: activeLicense?.keyword_slots_used || 0,
		url_slots_used: activeLicense?.url_slots_used || 0
	})
);

export const selectMainLisenseCounts = createSelector(
	[selectMainLicense],
	(activeLicense) => ({
		user_slots: activeLicense?.user_slots || 0,
		project_slots: activeLicense?.project_slots || 0,
		keyword_slots: activeLicense?.keyword_slots || 0,
		url_slots: activeLicense?.url_slots || 0,
		user_slots_used: activeLicense?.user_slots_used || 0,
		project_slots_used: activeLicense?.project_slots_used || 0,
		keyword_slots_used: activeLicense?.keyword_slots_used || 0,
		url_slots_used: activeLicense?.url_slots_used || 0
	})
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  padding-left: 21px;
  padding-top: 6px;
  padding-bottom: 6px;

  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  color: #171C28;

  cursor: pointer;

  &.active {
    position: relative;
    color: #5468F0;

    &::before {
      content: '';
      display: block;

      position: absolute;

      top: 12px;
      left: 8px;

      height: 5px;
      width: 5px;

      border-radius: 50%;
      background-color: #5468F0;
    }
  }
`;

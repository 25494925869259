import { ILicense } from '../../services/LicensesService/types';

export enum LicensesActionTypes {
  LICENSES_RELOAD = '[Licenses] licenses-reload',
  LICENSES_REFRESH = '[Licenses] licenses-refresh',

  ACTIVE_LICENSE_ID_REFRESH = '[Licenses] active-license-id-refresh',

  ACTIVE_LICENSE_ID_STORE = '[Licenses] active-license-id-store',

  UI_MERGE = '[Licenses] ui-merge',

  RESET_ALL = '[Licenses] reset-all',
}

export interface IUIPayload {
  loading?: boolean;
}

export interface LicensesState {
  licenses: ILicense[];
  activeLicenseID: string;
  UI: IUIPayload;
}

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  /* min-height: 100vh; */
  height: 100vh;
  width: 100vw;

  .topbar-container {
    display: none;
  }

  .sidebar-container {
    display: none;
  }

  &:not(.topbar):not(.sidebar) {
    .main-content {
      overflow: hidden;
      height: 100%;
      width: 100%;

      margin: 0;
      padding: 0;
    }
  }

  &.topbar {
    padding-top: ${({ theme }) => theme.height.topBarContainer};
    overflow: hidden;

    .topbar-container {
      display: block;
      position: fixed;
      z-index: 500;
      top: 0;

      width: 100%;
      height: ${({ theme }) => theme.height.topBarContainer};
      
      box-shadow: 0px 3px 12px #0000000D;
    }

    .main-content {
      overflow: hidden;
      transition: all 0.2s linear;
      height: 100%;
    }
  }

  &.sidebar {
    .sidebar-container {
      display: block;
      position: fixed;
      top: ${({ theme }) => theme.height.topBarContainer};
      left: 0;

      height: ${({ theme }) => `calc(100% - ${theme.height.topBarContainer})`};
      width: ${({ theme }) => theme.width.sideBarContainer};

      overflow-x: hidden;

      transition: width 0.5s linear;
    }

    .main-content {
      overflow: auto;
      margin-left: ${({ theme }) => theme.width.sideBarContainer};
      width: ${({ theme }) => `calc(100% - ${theme.width.sideBarContainer})`};
    }

    &.collapsed {
      .sidebar-container {
        width: ${({ theme }) => theme.width.sideBarContainerCollapsed};
      }
      .main-content {
        margin-left: ${({ theme }) => theme.width.sideBarContainerCollapsed};
        width: ${({ theme }) => `calc(100% - ${theme.width.sideBarContainerCollapsed})`}
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &.sidebar {
      .sidebar-container {
        width: ${({ theme }) => `calc(${theme.width.sideBarContainer} - 26px)`};
      }

      .main-content {
        margin-left: ${({ theme }) => `calc(${theme.width.sideBarContainer} - 26px)`};
        width: ${({ theme }) => `calc(100% - ${theme.width.sideBarContainer} + 26px)`};
      }

    }
    &.sidebar {
      &.collapsed {
      .sidebar-container {
        width: calc(${({ theme }) => theme.width.sideBarContainerCollapsed} / 2);
      }
      .main-content {
        margin-left: calc(${({ theme }) => theme.width.sideBarContainerCollapsed} / 2);
        width: ${({ theme }) => `calc(100% - ${theme.width.sideBarContainerCollapsed} / 2)`}
      }
    }
    }
  }
`;

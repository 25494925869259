import styled from 'styled-components';
import { hexToRGBA } from '../../utils/theme';

export interface IStyledBarProps {
  mounted: boolean;
  color: string;
  value: number;
  maxValue?: number;
  backColor?: string;
}

export const StyledBar = styled.div<IStyledBarProps>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  width: 100%;
  min-height: 6px;
  border-radius: 6px;

  background: ${({ theme, backColor }) => backColor || hexToRGBA(theme.colors.secondaryDark, 0.05)};

  &.reset {
    ::before {
      width: 0;
      transition: none;
    }
  }

  ::before {
    content: '';
    display: block;
    width: ${({ value, maxValue, mounted }) => mounted ? `${maxValue && (maxValue > value) ? value * 100 / maxValue : 100}%` : 0};
    background: ${({ color }) => color};
    border-radius: 6px;
    transition: all 1s ease-in-out;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stacked-indicator-statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin-bottom: 12px;

    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;

    .stacked-indicator-title {
      margin-right: 2em;
      font-weight: 500;
    }

    .stacked-indicator-counter {
      font-weight: 600;

      &.hide {
        display: none;
      }
    }
  }
`;

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  .filmstrip-screenshots {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    margin-bottom: 32px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 4px;

    background: #00000007 0% 0% no-repeat padding-box;

    > div {
      .image-holder {
        img {
          max-height: 160px;
        }
      }
    }
  }

  .filmstrip-chart {
    display: flex;
    flex-direction: column;

    width: 100%;

    position: relative;

    .header-layer {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      height: 25px;
      margin-bottom: 18px;

      position: relative;

      .chart-title {
        position: absolute;

        left: 0;

        font-style: normal;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 0px;
        color: #181818;
      }

      .resource-type-legend {
        display: flex;
        justify-content: space-around;
        align-items: center;

        width: 70%;

        margin-bottom: 3px;

        .type-item {
          display: flex;
          justify-content: center;
          align-items: center;

          font-style: normal;
          font-size: 10px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0px;
          color: #171C28;

          cursor: pointer;

          span:first-child {
            display: block;

            height: 8px;
            width: 8px;

            margin-right: 6px;
            border-radius: 2px;
          }
        }
      }
    }

    .grid-layer {
      display: flex;

      height: 220px;
      width: 100%;

      .grid-layer-block {
        height: 140px;
        border-left: 1px solid #E0E0E0;

        position: relative;

        background: #00000007 0% 0% no-repeat padding-box;

        .grid-layer-block-label {
          position: absolute;
          border-radius: 2px;

          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 8px;
          padding-right: 8px;

          font-style: normal;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0px;
          color: #FFFFFF;

          z-index: 1;

          &.fcp {
            bottom: -40px;
            background: #29AC7F 0% 0% no-repeat padding-box;
          }

          &.fcp.overlapped {
            bottom: -12px;
          }

          &.fmp {
            bottom: -40px;
            background: #FFC127 0% 0% no-repeat padding-box;
          }

          &.lcp {
            bottom: -40px;
            background: #F55458 0% 0% no-repeat padding-box;
          }

          &.lcp.overlapped {
            bottom: -68px;
          }

          &.hidden {
            display: none;
          }
        }

        .grid-layer-block-pointer {
          position: absolute;

          width: 2px;

          z-index: 1;

          &.fcp {
            bottom: -16px;
            height: 156px;
            background: #29AC7F 0% 0% no-repeat padding-box;
          }

          &.fcp.overlapped {
            bottom: 6px;
            height: 134px;
          }

          &.fmp {
            bottom: -16px;
            height: 156px;
            background: #FFC127 0% 0% no-repeat padding-box;
          }

          &.lcp {
            bottom: -16px;
            height: 156px;
            background: #F55458 0% 0% no-repeat padding-box;
          }

          &.lcp.overlapped {
            bottom: -44px;
            height: 184px;
          }

          &.hidden {
            display: none;
          }
        }

        &:first-child {
          border-left: none;
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
    }

    .bars-layer {
      position: absolute;

      width: 100%;

      top: 54px;
      left: 0;

      .bars-layer-container {
        display: flex;
        flex-direction: column;

        height: 100%;
        width: 100%;

        z-index: 1;

        .bars-layer-row {
          display: flex;

          height: 12px;
          width: 100%;

          position: relative;

          .bars-layer-block {
            position: absolute;
            height: 10px;
            border-radius: 1px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .filmstrip-screenshots {
      > div {
        .image-holder {
          img {
            max-height: 150px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1360px) {
    .filmstrip-screenshots {
      > div {
        .image-holder {
          img {
            max-height: 140px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1290px) {
    .filmstrip-screenshots {
      > div {
        .image-holder {
          img {
            max-height: 130px;
          }
        }
      }
    }

    .filmstrip-chart {
      .header-layer {
        justify-content: flex-end;

        .resource-type-legend {
          width: 75%;
        }
      }
    }
  }

  @media screen and (max-width: 1230px) {
    .filmstrip-screenshots {
      > div {
        .image-holder {
          img {
            max-height: 120px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1170px) {
    .filmstrip-screenshots {
      > div {
        .image-holder {
          img {
            max-height: 110px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1110px) {
    .filmstrip-screenshots {
      > div {
        .image-holder {
          img {
            max-height: 100px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .filmstrip-screenshots {
      > div {
        .image-holder {
          img {
            max-height: 90px;
          }
        }
      }
    }
  }
`;

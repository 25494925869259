import { AccountsDataKeys } from '../../enums/accounts';
import { SortOrder } from '../../enums/data';
import { ROWS_COUNTS } from '../../enums/projectSettings';
import { ILicense } from '../../services/LicensesService/types';

export enum AccountsActionTypes {
  ACCOUNTS_RELOAD = '[Accounts] accounts-reload',
  ACCOUNTS_REFRESH = '[Accounts] accounts-refresh',

  ACCOUNTS_SORT_KEY_REFRESH = '[Accounts] accounts-sort-key-refresh',
  ACCOUNTS_SORT_ORDER_REFRESH = '[Accounts] accounts-sort-order-refresh',

  ACCOUNT_DELETE = '[Accounts] account-delete',

  ACCOUNTS_SEARCH_REFRESH = '[Accounts] accounts-search-refresh',

  ACCOUNTS_RESOURCES_UPDATE = '[Accounts] accounts-resources-update',

  ACCOUNT_CREATE = '[Accounts] account-create',

  UI_MERGE = '[Accounts] ui-merge',
}

export interface IUIPayload {
  loading?: boolean;
  offset?: number;
  limit?: ROWS_COUNTS;
  count?: number | null;
}

export interface AccountsState {
  accounts: ILicense[];
  accountsSortOrder: SortOrder,
  accountsSortKey: AccountsDataKeys,
  accountsSearch: string;
  UI: IUIPayload;
}

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { StyledWrapper as Wrapper, StyledBar as Bar } from './StackedIndicator.styled';
import { ANIMATION_CLASSES } from '../../constants/animations';

export interface IProps {
  className?: string;
  hideCounter?: boolean;
  reset?: boolean;
  title?: string;
  color: string;
  value: number;
  maxValue?: number;
  hideTitle?: boolean;
  backColor?: string;
}

const StackedIndicator: React.FC<IProps> = ({
  className = '',
  hideCounter,
  reset,
  title,
  color,
  value,
  maxValue,
  hideTitle,
  backColor,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Wrapper className={clsx({ [className]: Boolean(className) })}>
      {!hideTitle && (
        <div className="stacked-indicator-statistics">
          <div className="stacked-indicator-title">
            {title}
          </div>
          <div className={clsx('stacked-indicator-counter', { hide: hideCounter })}>
            {`${value} / ${maxValue || '--'}`}
          </div>
        </div>
      )}
      <Bar
        className={clsx({
          reset,
          [ANIMATION_CLASSES.animatedSkeleton]: reset,
        })}
        color={color}
        backColor={backColor}
        value={value}
        maxValue={maxValue}
        mounted={mounted}
      />
    </Wrapper>
  );
};

export default StackedIndicator;

import { createSelector } from 'reselect';
import { RootState } from '../store';

const users = (state: RootState) => state.Users.users;
const usersSortOrder = (state: RootState) => state.Users.usersSortOrder;
const usersSortKey = (state: RootState) => state.Users.usersSortKey;
const usersSearch = (state: RootState) => state.Users.usersSearch;
const UI = (state: RootState) => state.Users.UI;

export const selectUsers = createSelector(
	[users],
	(users) => users,
);

export const selectUsersSortOrder = createSelector(
	[usersSortOrder],
	(usersSortOrder) => usersSortOrder,
);

export const selectUsersSortKey = createSelector(
	[usersSortKey],
	(usersSortKey) => usersSortKey,
);

export const selectUserByID = (userID: string) => createSelector(
	[users],
	(users) => users.find(({ user_id }) => user_id === userID),
)

export const selectUsersSearch = createSelector(
	[usersSearch],
	(usersSearch) => usersSearch,
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { StyledWrapper as Wrapper } from './Tabs.styled';

export interface ITabOption {
  key: string;
  value?: number | string;
}

export interface IProps {
  selectedKey?: string;
  options: ITabOption[];
  onSelect?: (tabKey: string) => void;
}

const Tabs: React.FC<IProps> = ({
  selectedKey,
  options,
  onSelect,
}) => {
  const { t } = useTranslation();

  const getSelectTabHandler = useCallback((tabKey: string) => () => {
    onSelect && onSelect(tabKey);
  }, [onSelect]);

  return (
    <Wrapper className="tabs">
      {options.map(({ key, value }) => (
        <div
          key={key}
          className={clsx('tab', { active: key === selectedKey })}
          onClick={getSelectTabHandler(key)}
        >
          {t(key)} {!!value && <span>{value}</span>}
        </div>
      ))}
    </Wrapper>
  );
};

export default Tabs;

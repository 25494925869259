export enum CoreWebVitalsTypes {
  Fast = 'fast',
  Moderate = 'moderate',
  Slow = 'slow',
}

export enum CoreWebVitalsValues {
  Top5 = 'TOP_5',
  Top10 = 'TOP_10',
}

import { CoreWebVitalsValues } from "../../enums/coreWebVitals";
import { IMetricsRankingCorrelation, IMetricsUrlGroup, IMetricsUrl, IUrlDetails } from "../../services/DataService/types";
import { Feature, SortOrder } from '../../enums/data';
import { ROWS_COUNTS } from "../../enums/projectSettings";
import { MetricsDataKeys } from '../../enums/metrics';

export enum MetricsActionTypes {
  RANKING_CORRELATION_RELOAD = '[Metrics] correlation-reload',
  RANKING_CORRELATION_REFRESH = '[Metrics] correlation-refresh',

  URL_GROUPS_RELOAD = '[Metrics] url-groups-reload',
  URL_GROUPS_REFRESH = '[Metrics] url-groups-refresh',

  URLS_RELOAD = '[Metrics] urls-reload',
  URLS_REFRESH = '[Metrics] urls-refresh',

  URLS_FULL_RELOAD = '[Metrics] urls-full-reload',
  URLS_FULL_REFRESH = '[Metrics] urls-full-refresh',

  URLS_SORT_ORDER_REFRESH = '[Metrics] urls-sort-order-refresh',
  URLS_SORT_KEY_REFRESH = '[Metrics] urls-sort-key-refresh',

  FEATURE_REFRESH = '[Metrics] feature-refresh',
  URL_GROUPS_QUANTIFIER_REFRESH = '[Metrics] url-groups-quantifier-refresh',
  URLS_QUANTIFIER_REFRESH = '[Metrics] urls-quantifier-refresh',

  URL_DETAILS_RELOAD = '[Metrics] url-details-reload',
  URL_DETAILS_REFRESH = '[Metrics] url-details-refresh',

  ACTIVE_URL_ID_REFRESH = '[Metrics] active-url-id-refresh',

  URL_SEARCH_REFRESH = '[Metrics] url-search-refresh',

  UI_MERGE = '[Metrics] ui-merge',

  RESET_ALL = '[Metrics] reset-all',
}

export interface IUIPayload {
  loading?: boolean;
  count?: number | null;
  limit?: ROWS_COUNTS;
  offset?: number;
  urlGroupsLoading?: boolean;
  urlsLoading?: boolean;
  urlsFullLoading?: boolean;
  urlDetailsLoading?: boolean;
}

export interface MetricsState {
  rankingCorrelationData: IMetricsRankingCorrelation[];
  urlGroupsData: IMetricsUrlGroup[];
  urlsData: IMetricsUrl[];
  urlsFullData: IMetricsUrl[];
  feature: Feature;
  urlGroupsQuantifier: CoreWebVitalsValues | string;
  urlsQuantifier: CoreWebVitalsValues | string;
  urlsSortOrder: SortOrder;
  urlsSortKey: MetricsDataKeys;
  urlDetails: IUrlDetails[];
  activeUrlID: string | null;
  urlSearch: string;
  UI: IUIPayload;
}

import React, { useCallback } from 'react';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import keys from "lodash/keys";
import isEqual from "lodash/isEqual";

import { Button, ButtonType } from "../../../components/styled/Button";

import * as FormikUtils from '../../../utils/formik';
import { companySchema } from '../../../utils/validation/schemas';
import { companySettingsSteps } from './assets';

import { Wrapper } from './CompanyForm.styled';
import { selectCompany, selectUI as selectProfileUI } from '../../../redux/profile-settings/selectors';
import { profileSettingsActions } from '../../../redux/profile-settings/actions';
import { SiderButtomTextKeys } from '../../../enums/locales/modals';
import { selectMainLicense, selectUI } from '../../../redux/licenses/selectors';

export const CompanyFormBag = FormikUtils.createFormBag();

export interface IProps {
  onSubmit: (values: FormikValues) => void;
}

const CompanyForm: React.FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectCompany);
  const license = useSelector(selectMainLicense);
  const { companyLoading } = useSelector(selectProfileUI);
  const { loading } = useSelector(selectUI);

  const onBlur = useCallback((e) => {
    dispatch(profileSettingsActions.companyRefresh({ [e.target.name]: e.target.value }));
  }, [CompanyFormBag.touched, CompanyFormBag.values, CompanyFormBag.errors]);

  const isDisabled = keys(CompanyFormBag.errors).length !== 0
    || isEqual(license?.company, data)
    || companyLoading
    || loading;

  return (
    <Wrapper>
      <Formik
        initialValues={data}
        validationSchema={companySchema}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          FormikUtils.fillFormBag(CompanyFormBag, formikProps);

          return (
            <Form>
              {companySettingsSteps.map(({ title, suffix = '', fields, id, className }) =>
                <React.Fragment key={id}>
                  <div id={id} className="fields-group-title">
                    <span>{t(title)}</span>
                    {suffix && <span className="suffix">({t(suffix)})</span>}
                  </div>
                  <div className={className}>
                    {fields.map(fieldConfig => {
                      const { name } = fieldConfig;
                      const dynamicFieldConfig: Record<string, unknown> = { ...fieldConfig, onBlur: onBlur };

                      return (
                        <Field name={name} key={name}>
                          {(fieldProps: FieldProps) => FormikUtils.createField(fieldProps, dynamicFieldConfig)}
                        </Field>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
              <Button
                type={ButtonType.Primary}
                disabled={isDisabled}
                icon={companyLoading ? <Spin size="small" /> : null}
                title={t(SiderButtomTextKeys.Save)}
              />
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
}

export default CompanyForm;
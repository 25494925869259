import { createSelector } from 'reselect';
import { RootState } from '../store';

const data = (state: RootState) => state.Vitals.data;
const quantifier = (state: RootState) => state.Vitals.quantifier;
const comparator = (state: RootState) => state.Vitals.comparator;
const UI = (state: RootState) => state.Vitals.UI;

export const selectData = createSelector(
  [data],
  (data) => data,
);

export const selectQuantifier = createSelector(
  [quantifier],
  (quantifier) => quantifier,
);

export const selectComparator = createSelector(
  [comparator],
  (comparator) => comparator,
);

export const selectUI = createSelector(
	[UI],
	(UI) => UI,
);

import React from 'react';
import clsx from 'clsx';
import { TFunction } from 'react-i18next';

import { beautifyUrl, formatNumber, roundNumber } from '../../../utils/common';
import { RESOURCE_COLORS_BY_TYPE } from '../../../constants/compoundAnalysis';
import { ResourceTypes, NetworkDetailsDataKeys } from '../../../enums/compoundAnalysis';
import { NetworkRequestsAnalysisDataKeysTitle } from '../../../enums/locales/compoundAnalysis';
import { SortOrder } from '../../../enums/data';
import { ISortParams } from './types';

import { IconArrowUpward, IconArrowDownward, IconExternalLink } from '../../../components/styled/icons';

export type IOpenURL = (url: string) => () => void;

export const getColumns = (t: TFunction, openUrl: IOpenURL, sortParams: ISortParams) => {
  const { sortKey, sortOrder, onSortChange } = sortParams;

  return [
    {
      dataIndex: NetworkDetailsDataKeys.Position,
      title: (
        <div
          className={clsx('header-cell position', { active: sortKey === NetworkDetailsDataKeys.Position })}
          onClick={onSortChange(NetworkDetailsDataKeys.Position)}
        >
          <span>#</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => <>{`${item.position}.`}</>,
      width: '3%',
    },
    {
      dataIndex: 'url_link',
      title: '',
      render: (item: any) => (
        <div className="data-cell url-link">
          <IconExternalLink onClick={openUrl(item.url)} />
        </div>
      ),
      width: '2%',
    },
    {
      dataIndex: NetworkDetailsDataKeys.Url,
      title: (
        <div
          className={clsx('header-cell url', { active: sortKey === NetworkDetailsDataKeys.Url })}
          onClick={onSortChange(NetworkDetailsDataKeys.Url)}
        >
          <span>URL</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => (
        <div className="data-cell url">
          <span title={item.url}>
            {beautifyUrl(item.url)}
          </span>
        </div>
      ),
      width: 'auto',
    },
    {
      dataIndex: NetworkDetailsDataKeys.ResourceType,
      title: (
        <div
          className={clsx('header-cell resource-type', { active: sortKey === NetworkDetailsDataKeys.ResourceType })}
          onClick={onSortChange(NetworkDetailsDataKeys.ResourceType)}
        >
          <span>{t(NetworkRequestsAnalysisDataKeysTitle.ResourceType)}</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => (
        <div className="data-cell resource-type">
          <div className="indicator" style={{ background: `${RESOURCE_COLORS_BY_TYPE[item.resource_type as ResourceTypes] || ''}` }} />
          <div className="data-value">{item.resource_type}</div>
        </div>
      ),
      width: '10%',
    },
    {
      dataIndex: 'start_time',
      title: (
        <div
          className={clsx('header-cell time', { active: sortKey === NetworkDetailsDataKeys.StartTime })}
          onClick={onSortChange(NetworkDetailsDataKeys.StartTime)}
        >
          <span>{t(NetworkRequestsAnalysisDataKeysTitle.StartTime)}</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => <>{Math.round(item.start_time)}</>,
      width: '8%',
    },
    {
      dataIndex: 'end_time',
      title: (
        <div
          className={clsx('header-cell time', { active: sortKey === NetworkDetailsDataKeys.EndTime })}
          onClick={onSortChange(NetworkDetailsDataKeys.EndTime)}
        >
          <span>{t(NetworkRequestsAnalysisDataKeysTitle.EndTime)}</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => <>{Math.round(item.end_time)}</>,
      width: '8%',
    },
    {
      dataIndex: NetworkDetailsDataKeys.Time ,
      title: (
        <div
          className={clsx('header-cell time', { active: sortKey === NetworkDetailsDataKeys.Time })}
          onClick={onSortChange(NetworkDetailsDataKeys.Time)}
        >
          <span>{t(NetworkRequestsAnalysisDataKeysTitle.Time)}</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => (
        <div className="data-cell time">
          <div className="data-value">
            {Math.round(item.time)}
            <div className="indicator" style={{ width: `${item.time_percentage}%` }} />
          </div>
        </div>
      ),
      width: '8%',
    },
    {
      dataIndex: NetworkDetailsDataKeys.ResourceSize,
      title: (
        <div
          className={clsx('header-cell time', { active: sortKey === NetworkDetailsDataKeys.ResourceSize })}
          onClick={onSortChange(NetworkDetailsDataKeys.ResourceSize)}
        >
          <span>{t(NetworkRequestsAnalysisDataKeysTitle.ResourceSize)}</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => (
        <div className="data-cell resource-size">
          <div className="data-value">
            {Math.round(item.resource_size)}
            <div className="indicator" style={{ width: `${item.resource_size_percentage}%` }} />
          </div>
        </div>
      ),
      width: '8%',
    },
    {
      dataIndex: NetworkDetailsDataKeys.TransferSize,
      title: (
        <div
          className={clsx('header-cell transfer-size', { active: sortKey === NetworkDetailsDataKeys.TransferSize })}
          onClick={onSortChange(NetworkDetailsDataKeys.TransferSize)}
        >
          <span>{t(NetworkRequestsAnalysisDataKeysTitle.TransferSize)}</span>
          <div className="sort-icon">
            {sortOrder === SortOrder.Desc ? <IconArrowDownward /> : <IconArrowUpward />}
          </div>
        </div>
      ),
      render: (item: any) => (
        <div className="data-cell transfer-size">
          <div className="data-value">
            {Math.round(item.transfer_size)}
            <div className="indicator" style={{ width: `${item.transfer_size_percentage}%` }} />
          </div>
        </div>
      ),
      width: '8%',
    },
  ];
};

import { THEME } from '../../constants/theme';
import { RecrawlInterval } from '../../enums/projects';

const DAYS_PER_MONTH = 28;
const WEEKS_PER_MONTH = 4;

export const getCrawlFrequencyMultiplier = (interval: RecrawlInterval | null): number => {

  if (interval === RecrawlInterval.MondayToFriday) {
    return WEEKS_PER_MONTH * 5;
  }

  if (interval === RecrawlInterval.BiWeekly) {
    return WEEKS_PER_MONTH / 2;
  }

  if (interval === RecrawlInterval.Weekly) {
    return WEEKS_PER_MONTH;
  }

  if (interval === RecrawlInterval.Daily) {
    return DAYS_PER_MONTH;
  }

  return 1;
};

export const getResourceUtilizationColor = (usedSlots: number, slots: number) => {
  const ratio = usedSlots / slots;

  if (ratio < 0.7) {
    return THEME.colors.fast;
  }

  if (ratio >= 0.7 && ratio < 1) {
    return THEME.colors.moderate;
  }

  return THEME.colors.slow;
}

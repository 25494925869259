import styled from "styled-components";
import { IProps } from "./Radio";

export const Wrapper = styled.div<Pick<IProps, 'error'>>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .radio-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border: 2px solid ${({ theme }) => theme.colors.grey};
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondaryDark};
    cursor: pointer;
    padding: 0 18px;
    margin-bottom: 12px;

    &.checked {
      border: 3px solid ${({ theme }) => theme.colors.linkBlue};
    }

    .radio-item-checked {
      margin-right: 8px;
      margin-top: 3px;

      .b {
        stroke: ${({ theme }) => theme.colors.linkBlue}!important;
        stroke-width: 3px!important;
      }
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &.large {
    .radio-item {
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 16px;
      min-width: 116px;
      height: 100px;
      font-size: 10px;
      font-weight: 600;

      .icon {
        height: 30px;
        margin-bottom: 12px;
      }

      .radio-item-checked {
        position: absolute;
        margin: 0;
        top: 10px;
        right: 12px;
      }
    }
  }
`;
import React, { ReactEventHandler } from 'react';
import clsx from 'clsx';

import { IconDelete } from '../../styled/icons';
import { StyledButton } from './Button.styled';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
}

export interface IProps {
  className?: string,
  type: ButtonType;
  disabled?: boolean;
  small?: boolean;
  rounded?: boolean;
  remove?: boolean;
  prefix?: JSX.Element | string;
  suffix?: JSX.Element | string;
  icon?: JSX.Element | null;
  title?: string;
  onClick?: ReactEventHandler;
}

const Button: React.FC<IProps> = ({
  className = '',
  type,
  disabled,
  small,
  rounded,
  remove,
  prefix,
  suffix,
  icon,
  title,
  onClick,
}) => {
  const buttonClassName = clsx({
    [className]: Boolean(className),
    primary: type === ButtonType.Primary,
    secondary: type === ButtonType.Secondary,
    outline: type === ButtonType.Outline,
    disabled,
    small,
    rounded,
    remove,
    prefix,
    suffix,
  });

  const iconComponent = remove ? <IconDelete /> : icon;

  return (
    <StyledButton className={buttonClassName} onClick={onClick}>
      <div className="content">
        {prefix && <div className="prefix-wrapper">{prefix}</div>}
        <div className="title-wrapper">
          {iconComponent && <div className="icon">{iconComponent}</div>}
          {title && <div className="title">{title}</div>}
        </div>
        {suffix && <div className="suffix-wrapper">{suffix}</div>}
      </div>
    </StyledButton>
  );
};

export default Button;

import { ActionUnion, createAction } from '../commonTypes';
import { IUIPayload, LicensesActionTypes } from './types';
import { EmptyObject } from '../../types/common.types';
import { ILicense } from '../../services/LicensesService/types';

export const licensesActions = {
  licensesReload: (): { type: LicensesActionTypes, payload: EmptyObject } => createAction(
    LicensesActionTypes.LICENSES_RELOAD,
  ),
  licensesRefresh: (payload: ILicense[]): { type: LicensesActionTypes, payload: ILicense[] } => createAction(
    LicensesActionTypes.LICENSES_REFRESH,
    payload,
  ),
  activeLicenseIDRefresh: (payload: string): { type: LicensesActionTypes, payload: string } => createAction(
    LicensesActionTypes.ACTIVE_LICENSE_ID_REFRESH,
    payload,
  ),
  activeLicenseIDStore: (): { type: LicensesActionTypes, payload: EmptyObject } => createAction(
    LicensesActionTypes.ACTIVE_LICENSE_ID_STORE,
  ),
  uiMerge: (payload: IUIPayload): { type: LicensesActionTypes, payload: IUIPayload } => createAction(
    LicensesActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: LicensesActionTypes, payload: EmptyObject } => createAction(
    LicensesActionTypes.RESET_ALL,
  ),
}

export type LicensesActions = ActionUnion<typeof licensesActions>;

import { CoreWebVitalsValues } from '../../enums/coreWebVitals';
import { IMetricsRankingCorrelation, IMetricsUrlGroup, IMetricsUrl, IUrlDetails } from "../../services/DataService/types";
import { EmptyObject } from '../../types/common.types';
import { ActionUnion, createAction } from '../commonTypes';
import { MetricsActionTypes, IUIPayload } from './types';
import { Feature, SortOrder } from '../../enums/data';
import { MetricsDataKeys } from '../../enums/metrics';

export const metricsActions = {
  rankingCorrelationReload: (): { type: MetricsActionTypes, payload: EmptyObject } => createAction(
    MetricsActionTypes.RANKING_CORRELATION_RELOAD
  ),
  rankingCorrelationRefresh: (payload: IMetricsRankingCorrelation[]): { type: MetricsActionTypes, payload: IMetricsRankingCorrelation[] } => createAction(
    MetricsActionTypes.RANKING_CORRELATION_REFRESH,
    payload,
  ),
  urlGroupsReload: (): { type: MetricsActionTypes, payload: EmptyObject } => createAction(
    MetricsActionTypes.URL_GROUPS_RELOAD
  ),
  urlGroupsRefresh: (payload: IMetricsUrlGroup[]): { type: MetricsActionTypes, payload: IMetricsUrlGroup[] } => createAction(
    MetricsActionTypes.URL_GROUPS_REFRESH,
    payload,
  ),
  urlsReload: (): { type: MetricsActionTypes, payload: EmptyObject } => createAction(
    MetricsActionTypes.URLS_RELOAD,
  ),
  urlsFullReload: (): { type: MetricsActionTypes, payload: EmptyObject } => createAction(
    MetricsActionTypes.URLS_FULL_RELOAD
  ),
  urlsRefresh: (payload: IMetricsUrl[]): { type: MetricsActionTypes, payload: IMetricsUrl[] } => createAction(
    MetricsActionTypes.URLS_REFRESH,
    payload,
  ),
  urlsFullRefresh: (payload: IMetricsUrl[]): { type: MetricsActionTypes, payload: IMetricsUrl[] } => createAction(
    MetricsActionTypes.URLS_FULL_REFRESH,
    payload,
  ),
  urlsSortOrderRefresh: (payload: SortOrder): { type: MetricsActionTypes, payload: SortOrder } => createAction(
    MetricsActionTypes.URLS_SORT_ORDER_REFRESH,
    payload,
  ),
  urlsSortKeyRefresh: (payload: MetricsDataKeys): { type: MetricsActionTypes, payload: MetricsDataKeys } => createAction(
    MetricsActionTypes.URLS_SORT_KEY_REFRESH,
    payload,
  ),
  featureRefresh: (payload: Feature): { type: MetricsActionTypes, payload: Feature } => createAction(
    MetricsActionTypes.FEATURE_REFRESH,
    payload,
  ),
  urlGroupsQuantifierRefresh: (payload: CoreWebVitalsValues | string): { type: MetricsActionTypes, payload: CoreWebVitalsValues | string } => createAction(
    MetricsActionTypes.URL_GROUPS_QUANTIFIER_REFRESH,
    payload,
  ),
  urlsQuantifierRefresh: (payload: CoreWebVitalsValues | string): { type: MetricsActionTypes, payload: CoreWebVitalsValues | string } => createAction(
    MetricsActionTypes.URLS_QUANTIFIER_REFRESH,
    payload,
  ),
  urlDetailsReload: (payload: string): { type: MetricsActionTypes, payload: string } => createAction(
    MetricsActionTypes.URL_DETAILS_RELOAD,
    payload,
  ),
  urlDetailsRefresh: (payload: IUrlDetails[]): { type: MetricsActionTypes, payload: IUrlDetails[] } => createAction(
    MetricsActionTypes.URL_DETAILS_REFRESH,
    payload,
  ),
  activeUrlIDRefresh: (payload: string | null): { type: MetricsActionTypes, payload: string | null } => createAction(
    MetricsActionTypes.ACTIVE_URL_ID_REFRESH,
    payload,
  ),
  urlSearchRefresh: (payload: string): { type: MetricsActionTypes, payload: string } => createAction(
    MetricsActionTypes.URL_SEARCH_REFRESH,
    payload,
  ),
  uiMerge: (payload: IUIPayload): { type: MetricsActionTypes, payload: IUIPayload } => createAction(
    MetricsActionTypes.UI_MERGE,
    payload,
  ),
  resetAll: (): { type: MetricsActionTypes, payload: EmptyObject } => createAction(
    MetricsActionTypes.RESET_ALL,
  ),
}

export type MetricsActions = ActionUnion<typeof metricsActions>;

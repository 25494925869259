import styled from "styled-components";
import { hexToRGBA } from "../../../../utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .heading {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .list-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    border-radius: 8px;
    padding-left: 8px;
    margin-bottom: 12px;
    word-break: break-all;
    font-size: 12px;

    .small {
      width: 100%;
      .ant-checkbox-wrapper {
        width: 100%;
      }
    }

    .label {
      width: 100%;
      margin-bottom: 0!important;
      font-size: 12px!important;
    }

    &.item-checked {
      background: ${({ theme }) => hexToRGBA(theme.colors.linkBlue, 0.1)};
      .label {
        color: ${({ theme }) => theme.colors.linkBlue};
        font-weight: 600;
      }
    } 
  }

  button {
    width: 100%;
    margin-top: 40px;
  }
`;
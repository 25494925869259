import React, { useMemo } from 'react';
import clsx from 'clsx';
import get from "lodash/get";

import { ANIMATION_CLASSES } from '../../constants/animations';
import { Pagination } from '../../components/Pagination';
import { TableHeader } from './TableHeader';

import { Wrapper } from './Table.styled';

export interface IColumn {
  dataIndex: string;
  title: string | JSX.Element;
  render?: (...args: any) => JSX.Element;
  width?: string;
}

export interface IProps {
  columns: IColumn[];
  data: any[];
  loading?: boolean;
  placeholdersCount?: number;
  pagination?: any;
}

const Table: React.FC<IProps> = ({
  columns,
  data,
  loading = false,
  placeholdersCount = 4,
  pagination,
}) => {
  const className = clsx('loading-placeholder', { [ANIMATION_CLASSES.animatedSkeleton]: loading });
  const emptyFeatures = useMemo(() => Array.from({ length: placeholdersCount }, () => ({})), [placeholdersCount]);

  const showPagination = pagination && !!pagination.total;

  return (
    <Wrapper>
      <table>
        <TableHeader columns={columns} />
        <tbody>
          {loading && emptyFeatures.map((_, index) => (
            <tr key={index}>
              {columns.map(({ dataIndex }) => (
                <td key={`${dataIndex}-${index}`}>
                  <div className={className}></div>
                </td>
              ))}
            </tr>
          ))}
          {!loading && data.map((item, index) => (
            <tr key={index} className={item.classes || ''}>
              {columns.map(({ dataIndex, render }) => (
                <td key={`${dataIndex}-${index}`}>
                  {render ? (
                    render(item, index)
                  ) : (
                    get(item, dataIndex)
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {showPagination && <Pagination {...pagination} />}
    </Wrapper>
  )
}

export default Table;
import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

import { hexToRGBA } from '../../../utils/theme';
import { THEME } from '../../../constants/theme';

export const GlobalStyle = createGlobalStyle`
  ${reset};

  * {
    box-sizing: border-box;
  }

  html,
  body {
    --tooltip-width: 350px;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 500;
    overflow: hidden;
  }

  .annotation-date {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    box-shadow: 0px 6px 16px #00000029;
    border-radius: 14px;
    border: none;

    .react-datepicker__navigation {
      top: 20px;

      &.react-datepicker__navigation--previous {
        left: 90px;
      }
      &.react-datepicker__navigation--next {
        right: 90px;
      }

      .react-datepicker__navigation-icon::before,
      .react-datepicker__navigation-icon:hover::before {
        border-color: #555BF0;
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__current-month {
      font-size: 13px;
      font-weight: 600;
    }

    .react-datepicker__day-names {
      padding: 0 60px;
    }

    .react-datepicker__day-name {
      font-size: 10px;
      color: #3C3C434D;
      text-transform: uppercase;
      width: 24px;
      line-height: 24px;
      margin: 24px 10px 0 10px;
    }

    .react-datepicker__header  {
      border-top-right-radius: 14px;
      border-top-left-radius: 14px;
      padding: 20px 0 0;
      background: #FFFFFF;
      border: none;
    }

    .react-datepicker__month {
      margin: 10px 60px 40px;

      .react-datepicker__day {
        width: 24px;
        line-height: 24px;
        margin: 2px 10px;
        border-radius: 6px;
        font-size: 14px;
        letter-spacing: 0.14px;

        &.react-datepicker__day--selected,
        &.react-datepicker__day--keyboard-selected {
          font-weight: 600;
          background: ${THEME.colors.primaryBlue};
        }

        &.react-datepicker__day--keyboard-selected {
          font-weight: 400;
          color: #000000;
          background: #ffffff;
          border: 1px solid ${THEME.colors.primaryBlue};
        }
      }
    }
  }

  .animated-fading {
    @keyframes fading {
        from {
          height: 60px;
          visibility: visible;
        }
        to {
          visibility: collapse;
          height: 0px;
          line-height: 0;
          opacity: 0;
          box-shadow: 0;
          margin: 0;
          border: 0;
          padding: 0;
        }
      }

    animation: fading .5s ease forwards;
  }

  .animated-appear {
    @keyframes appear {
        from {
          visibility: collapse;
          height: 0px;
          line-height: 0;
          opacity: 0;
          box-shadow: 0;
          margin: 0;
          border: 0;
          padding: 0;
        }
        to {
          visibility: visible;
          height: 60px;
        }
      }

    animation: appear .5s ease-in-out forwards;
  }

  .animated-skeleton {
    position: relative;
    overflow: hidden;
    z-index: 1;
    
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-image: linear-gradient(90deg, rgba(255,255,255,0) 46%, #fff 52%, rgba(255,255,255,0) 58%);
      background-size: 400% 100%;

      @keyframes skeleton-loading {
        from {
          background-position: 100% 50%;
        }
        to {
          background-position: 0 50%;
        }
      }

      animation: skeleton-loading 2s ease infinite;
    }
  }

  .ant-tooltip {
    max-width: var(--tooltip-width);
  }

  .ant-tooltip-content {

    .ant-tooltip-inner {
      min-width: var(--tooltip-width);
      padding: 20px;
      border-radius: 10px;

      font-style: normal;
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      letter-spacing: 0px;

      .bars-layer-tip {
        display: flex;
        flex-direction: column;

        .bars-layer-tip-link {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          margin-bottom: 10px;

          span {
            display: inline-block;
          }
    
          span:first-child {
            cursor: pointer;
            margin-right: 8px;

            svg {
              path {
                stroke: white;
              }

              line {
                stroke: white;
              }
            }
          }

          span:last-child {
            max-width: 280px;
          }
        }

        .bars-layer-tip-timing {
          padding-left: 24px;

          font-weight: bold;
        }
      }
    }
  }

  .ant-select-dropdown {
    z-index: 10001;
  }

  .ant-select-item-option-content {
    display: flex;
    flex-wrap: nowrap;

    .main {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 8px;
    }
  }

  .ant-select-item-option-grouped {
    padding-left: 16px!important;
  }

  .loader-modal-container {
    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 20px #00000010;
      border-radius: 13px;
    }
  }

  .request-result-modal-container {
    z-index: 10100;
    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 40px;

      .ant-modal-close {
        top: 32px;
        right: 26px;
      }

      .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 14rem;
      }
    }
  }

  .url-details-modal-container {
    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 24px;

      .ant-modal-close {
        top: 16px;
        right: 10px;
      }

      .ant-modal-header {
        padding-top: 24px;
        padding-bottom: 0px;
        border-radius: 24px;
        border: none;

        .ant-modal-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .url-details-modal-title {
            font-style: normal;
            font-size: 22px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;
          }

          .url-details-modal-link {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-left: 24px;

            font-style: normal;
            font-size: 14px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;

            cursor: pointer;

            a {
              margin-left: 8px;
              max-width: 800px;

              span {
                display: block;

                max-width: 100%;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .ant-modal-body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 14rem;

        font-style: normal;
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        letter-spacing: 0px;
      }
    }
  }

  .limit-modal-container {
    z-index: 10002;

    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 24px;
    }
    
    .ant-modal-close {
      top: 16px;
      right: 10px;
    }
  }

  .project-crawls-modal-container {
    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 24px;

      .ant-modal-close {
        top: 16px;
        right: 10px;
      }

      .ant-modal-header {
        padding-top: 40px;
        padding-left: 40px;
        padding-bottom: 0px;
        border-radius: 24px;
        border: none;

        box-shadow: 0px 2px 10px #0000000F;
        border-radius: 20px;

        .ant-modal-title {
          display: flex;
          flex-direction: column;

          .ant-modal-title-top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .project-crawls-modal-title {
              display: flex;
              justify-content: flex-start;

              .project-label {
                min-width: 140px;

                font-style: normal;
                font-size: 24px;
                font-weight: 600;
                line-height: 35px;
                letter-spacing: 0px;
              }

              .project-id {
                margin-left: 10px;
                margin-right: 20px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 15px;
                padding-right: 15px;

                border-radius: 19px;
                background: ${hexToRGBA('#F3F4F4', 0.5)};

                font-style: normal;
                font-size: 16px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.59px;
                color: ${hexToRGBA('#181818', 0.5)};
              }
            }

            .project-crawls-modal-actions {
              display: flex;
              justify-content: flex-end;

              margin-right: 50px;

              .project-delete-button,
              .project-edit-button {
                margin-left: 14px;
              }

              button {
                height: 30px;
                width: auto;
                max-width: unset;

                .title-wrapper {
                  padding-left: 18px;
                  padding-right: 18px;

                  .title {
                    white-space: nowrap;
                  }
                }
              }
            }
          }

          .ant-modal-title-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .project-section {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;

              width: 100%;

              padding-top: 30px;
              padding-bottom: 16px;

              .project-data-cell {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                margin-right: 20px;
                padding-bottom: 14px;

                .project-data-label,
                .project-data-value {
                  font-style: normal;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 21px;
                  letter-spacing: 0px;
                  color: #171C28;
                }

                .project-data-label {
                  margin-bottom: 16px;
                }

                .project-data-value {
                  display: flex;
                  align-items: center;
  
                  min-height: 32px;
                  font-weight: 500;

                  .domain-name {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .domain-name-picture {
                      margin-right: 8px;
                    }

                    .domain-name-value {
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }

                  .strategy-type {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: nowrap;

                    min-height: 32px;
                    width: 90%;
                    min-width: 100px;

                    padding-left: 10px;
                    padding-right: 18px;

                    background: ${hexToRGBA('#F3F4F4', 0.5)} 0% 0% no-repeat padding-box;
                    border-radius: 16px;

                    .strategy-type-icon {
                      display: flex;
                      margin-right: 6px;
                    }

                    .strategy-type-title {
                      font-style: normal;
                      font-size: 12px;
                      font-weight: 600;
                      line-height: 18px;
                      letter-spacing: 0px;
                      color: #181818;

                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                }

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                  .project-data-value {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                  }
                }

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }

      .ant-modal-body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 7rem;
        padding-top: 22px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;

        font-style: normal;
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        letter-spacing: 0px;
      }
    }
  }

  .project-move-modal-container {
    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 24px;

      .ant-modal-close {
        top: 16px;
        right: 10px;
      }

      .ant-modal-header {
        padding-top: 40px;
        padding-left: 40px;
        padding-bottom: 0px;
        border-radius: 24px;
        border: none;

        box-shadow: 0px 2px 10px #0000000F;
        border-radius: 20px;

        .ant-modal-title {
          display: flex;
          flex-direction: column;

          .ant-modal-title-top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .project-move-modal-title {
              display: flex;
              justify-content: flex-start;

              .project-label {
                min-width: 140px;

                font-style: normal;
                font-size: 24px;
                font-weight: 600;
                line-height: 35px;
                letter-spacing: 0px;
              }

              .project-id {
                margin-left: 10px;
                margin-right: 20px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 15px;
                padding-right: 15px;

                border-radius: 19px;
                background: ${hexToRGBA('#F3F4F4', 0.5)};

                font-style: normal;
                font-size: 16px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.59px;
                color: ${hexToRGBA('#181818', 0.5)};
              }
            }
          }

          .ant-modal-title-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .project-section {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;

              width: 100%;

              padding-top: 30px;
              padding-bottom: 16px;

              .project-data-cell {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                margin-right: 20px;
                padding-bottom: 14px;

                .project-data-label,
                .project-data-value {
                  font-style: normal;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 21px;
                  letter-spacing: 0px;
                  color: #171C28;
                }

                .project-data-label {
                  margin-bottom: 16px;
                }

                .project-data-value {
                  display: flex;
                  align-items: center;
  
                  min-height: 32px;
                  font-weight: 500;

                  .domain-name {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .domain-name-picture {
                      margin-right: 8px;
                    }

                    .domain-name-value {
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }

                  .strategy-type {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: nowrap;

                    min-height: 32px;
                    width: 90%;
                    min-width: 100px;

                    padding-left: 10px;
                    padding-right: 18px;

                    background: ${hexToRGBA('#F3F4F4', 0.5)} 0% 0% no-repeat padding-box;
                    border-radius: 16px;

                    .strategy-type-icon {
                      display: flex;
                      margin-right: 6px;
                    }

                    .strategy-type-title {
                      font-style: normal;
                      font-size: 12px;
                      font-weight: 600;
                      line-height: 18px;
                      letter-spacing: 0px;
                      color: #181818;

                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                }

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                  .project-data-value {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                  }
                }

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }

      .ant-modal-body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 7rem;
        padding-top: 22px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;

        font-style: normal;
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        letter-spacing: 0px;
      }

      .ant-modal-footer {
        border: none;
        padding-left: 26px;
        padding-right: 26px;
        padding-bottom: 20px;

        .project-move-modal-actions {
          display: flex;
          justify-content: flex-end;

          .cancel-button,
          .project-move-button {
            width: 160px;
          }

          .project-move-button {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .network-details-modal-container {
    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 24px;

      .ant-modal-close {
        top: 16px;
        right: 10px;
      }

      .ant-modal-header {
        padding-top: 24px;
        padding-bottom: 0px;
        border-radius: 24px;
        border: none;

        .ant-modal-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .network-details-modal-title {
            font-style: normal;
            font-size: 22px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;
          }

          .network-details-modal-link {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-left: 24px;

            font-style: normal;
            font-size: 14px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;

            cursor: pointer;

            a {
              margin-left: 8px;
              max-width: 800px;

              span {
                display: block;

                max-width: 100%;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .network-details-modal-crawl-date {
            color: ${THEME.colors.text.secondary};
            font-size: 14px;
            font-weight: 400;
            margin-left: 16px;
          }
        }
      }

      .ant-modal-body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 14rem;
        padding: 0;
        padding-bottom: 30px;
      }
    }
  }

  .onboard-modal-container { 
    .ant-modal {
      min-height: calc(100% - 60px);
      min-width: 70%;
      width: 70%;
      max-width: 70%;
      padding-bottom: 0;
    }

    .ant-modal-content {
      background: #555BF0 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 30px;
    }

    .ant-modal-body {
      padding: 42px;
    }

    .ant-modal-content,
    .ant-modal-body {
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
    }
  }

  .thumbnails-modal-container {
    .ant-modal-content {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 22px #222B450B;
      border-radius: 24px;

      .ant-modal-close {
        top: 16px;
        right: 10px;
      }

      .ant-modal-header {
        padding-top: 32px;
        padding-left: 48px;
        padding-bottom: 0px;
        border-radius: 24px;
        border: none;

        .ant-modal-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .thumbnails-modal-title {
            font-style: normal;
            font-size: 22px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;
          }

          .thumbnails-modal-link {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-left: 24px;

            font-style: normal;
            font-size: 14px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;

            cursor: pointer;

            a {
              margin-left: 8px;
            }
          }

          .thumbnails-modal-crawl-date {
            color: ${THEME.colors.text.secondary};
            font-size: 14px;
            font-weight: 400;
            margin-left: 16px;
          }
        }
      }

      .ant-modal-body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 14rem;
        padding: 0;
      }
    }
  }

  .ant-popconfirm {
    z-index: 10060;
    .ant-popover-inner {
      border-radius: 20px;
      min-width: 180px;

      .ant-popover-inner-content {
        padding: 20px;

        .ant-popover-message-title {
          padding: 0;
        }
      }

      .ant-popover-buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;

        margin-top: 10px;

        .ant-btn {
          min-height: 30px;
          min-width: 80px;
          margin: 0;
          border-radius: 30px;

          &.ant-btn-primary {
            background: ${THEME.colors.buttons.background.primary} 0% 0% no-repeat padding-box;
            border-color: ${THEME.colors.buttons.background.primary};
          }

          &:not(.ant-btn-primary) {
            &:hover {
              border-color: ${THEME.colors.buttons.background.primary};
              color: ${THEME.colors.buttons.background.primary};
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .url-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .url-details-modal-link {
              a {
                max-width: 700px;
              }
            }
          }
        }
      }
    }

    .network-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .network-details-modal-link {
              a {
                max-width: 700px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .url-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .url-details-modal-link {
              a {
                max-width: 600px;
              }
            }
          }
        }
      }
    }

    .network-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .network-details-modal-link {
              a {
                max-width: 600px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .url-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .url-details-modal-link {
              a {
                max-width: 500px;
              }
            }
          }
        }
      }
    }

    .network-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .network-details-modal-link {
              a {
                max-width: 500px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .url-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .url-details-modal-link {
              a {
                max-width: 400px;
              }
            }
          }
        }
      }
    }

    .network-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .network-details-modal-link {
              a {
                max-width: 400px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .url-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .url-details-modal-link {
              a {
                max-width: 300px;
              }
            }
          }
        }
      }
    }

    .network-details-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .network-details-modal-link {
              a {
                max-width: 300px;
              }
            }
          }
        }
      }
    }

    .project-crawls-modal-container {
      .ant-modal-content {
        .ant-modal-header {
          .ant-modal-title {
            .ant-modal-title-top {
              .project-crawls-modal-actions {
                flex-wrap: wrap;

                button {
                  margin-bottom: 12px
                }
              }
            }
          }
        }
      }
    }
  }

  .annotation {
    cursor: pointer;
  }
`;

import React, { useCallback, useEffect } from "react";
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { IFieldErrorMessage } from '../../../types/forms.types';
import { FieldLabelKeys, FieldPlaceholderKeys, FieldSubLabelKeys } from '../../../enums/locales/forms';

import { Field } from '../Field';

import { SimpleList as StyledSimpleList } from '../../styled/SimpleList';
import { IProps as IStyledProps } from '../../styled/SimpleList/SimpleList';

export interface IPartialStyledProps extends IStyledProps { }

export interface IProps extends FieldProps, IPartialStyledProps {
  className?: string;
  labelKey?: FieldLabelKeys;
  subLabel?: FieldSubLabelKeys;
  placeholderKey?: FieldPlaceholderKeys,
  noBottomMargin?: boolean;
}

const SimpleList: React.FC<IProps> = ({
  field,
  form,
  className = '',
  labelKey,
  subLabel,
  noBottomMargin,
  ...restProps
}) => {
  const { name } = field;
  const { errors, touched, isSubmitting, setFieldTouched, setFieldValue, setTouched } = form;

  const splitedName = name.split('.')[0];

  const error = errors[splitedName];
  const isTouched = Boolean(touched[splitedName]);
  const isError = Boolean(error) && isTouched;

  useEffect(() => {
    if (isSubmitting && !isTouched) {
      setFieldTouched(splitedName, true);
    }
  }, [isSubmitting, isTouched, splitedName, setFieldTouched]);

  const onChange = useCallback((value) => {
    setFieldValue(name, value);
  }, [field, name, isTouched]);

  const onBlur = useCallback(() => {
    field.onBlur({ target: { name, value: field.value } });
    setTouched({ ...touched, [name]: true });
    if (restProps.onBlur) {
      restProps.onBlur({ target: { name, value: field.value } });
    }
  }, [restProps, field, touched]);

  return (
    <Field
      className={className}
      labelKey={labelKey}
      subLabel={subLabel}
      error={error as unknown as IFieldErrorMessage}
      touched={isTouched}
      noBottomMargin={noBottomMargin}
    >
      <StyledSimpleList
        {...field}
        {...restProps}
        onChange={onChange}
        onBlur={onBlur}
        error={isError}
      />
    </Field>
  );
};

export default SimpleList;

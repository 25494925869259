const basicColors = {
  white: '#FFFFFF',
  black: '#000000',
  red: '#F55458',
  grey: '#DBDBDB',
  darkGrey: '#B9B9B9',
  lightBlack: '#242D46',
  primaryBg: '#F3F4F4',
  orange: '#FFC127',
  green: '#35C996',
  pink: '#F586FF',
  lightBlue: '#5CDFFF',
};

const namedColors = {
  primaryDark: '#181818',
  secondaryDark: '#171C28',
  primaryBlue: '#555BF0',
  secondaryBlue: '#5366F1',
  leaderBlue: '#38A1FE',
  linkBlue: '#5468F0',
  fast: '#35C996',
  moderate: '#FFC127',
  slow: '#F55458',
  tableRowBorder: '#E7E7E9',
};

export const THEME = {
  colors: {
    ...basicColors,
    ...namedColors,
    chart: {
      domain: namedColors.primaryBlue,
      market: basicColors.green,
      benchmark: basicColors.orange,
      group: '#434348',
      avg: '#818181',
    },
    text: {
      secondary: '#858585',
    },
    badge: {
      default: basicColors.red,
    },
    buttons: {
      background: {
        primary: namedColors.primaryBlue,
        secondary: '#DBDBDB34',
        outline: basicColors.white,
        disabled: '#171C2833',
        remove: basicColors.white,
      },
      border: {
        outline: basicColors.grey,
        remove: basicColors.grey,
      },
      font: {
        primary: basicColors.white,
        secondary: namedColors.primaryDark,
        outline: namedColors.secondaryDark,
        disabled: basicColors.white,
        remove: basicColors.red,
      }
    },
    inputs: {
      background: {
        default: basicColors.white,
        disabled: basicColors.grey,
        opacity: {
          default: 1,
          disabled: 0.3,
        },
      },
      border: {
        default: basicColors.grey,
        focus: namedColors.linkBlue,
        error: basicColors.red,
      },
      font: {
        default: '#242D46',
        disabled: '#242D46',
        search: '#171C2867',
        opacity: {
          default: 1,
          disabled: 0.4,
          search: 1,
        },
      },
    },
    inputLabels: {
      font: {
        default: namedColors.secondaryDark,
        disabled: namedColors.secondaryDark,
        error: basicColors.red,
        opacity: {
          default: 1,
          disabled: 0.4,
          error: 1,
        },
      },
    },
    inputMessages: {
      font: {
        error: basicColors.red,
        opacity: {
          error: 1,
        },
      },
    },
    checkboxes: {
      border: {
        standard: {
          unchecked: namedColors.secondaryDark,
          checked: namedColors.primaryBlue,
        },
        opacity: {
          standard: {
            unchecked: 0.5,
            checked: 1,
          },
        },
      },
      background: {
        standard: {
          checked: namedColors.primaryBlue,
        },
        opacity: {
          standard: {
            checked: 1,
          },
        },
      },
      font: {
        standard: '#242D46',
        small: '#24242A',
        opacity: {
          standard: 1,
          small: 1,
        },
      },
    },
    infoCard: {
      background: namedColors.primaryBlue,
      font: basicColors.white,
    },
    footer: {
      font: namedColors.secondaryDark,
    },
    sider: {
      mask: namedColors.secondaryDark,
      maskOpacity: 0.3,
    },
    modals: {
      background: {
        loader: namedColors.secondaryDark,
        opacity: {
          loader: 0.1,
        },
      },
    }
  },
  height: {
    buttons: {
      standard: '50px',
      small: '40px',
    },
    inputs: '50px',
    checkboxes: {
      standard: '16px',
      small: '12px',
    },
    topBarContainer: '110px',
  },
  width: {
    checkboxes: {
      standard: '16px',
      small: '12px',
    },
    sideBarContainer: '248px',
    sideBarContainerCollapsed: '104px',
  },
  maxWidth: {
    buttons: {
      standard: '340px',
      small: '160px',
    },
    inputs: '340px',
  },
  fontSize: {
    footer: '12px',
    inputs: '14px',
    inputLabels: '13px',
    inputMessages: '10px',
    checkboxes: {
      standard: '14px',
      small: '12px',
    },
  },
  fontWeight: {
    footer: 600,
    inputs: 'normal',
    inputLabels: 600,
    inputMessages: 600,
    checkboxes: {
      standard: 500,
      small: 500,
    },
  },
  lineHeight: {
    footer: '18px',
    inputs: '21px',
    inputLabels: '20px',
    inputMessages: '16px',
    checkboxes: {
      standard: '21px',
      small: '18px',
    },
  },
  letterSpacing: {
    footer: '0px',
    inputs: '0px',
    inputLabels: '0px',
    inputMessages: '0px',
    checkboxes: {
      standard: '0px',
      small: '0px',
    },
  },
  jsonTree: {
		scheme: 'monokai',
		base00: '#000000',
		base01: '#383830',
		base02: '#49483e',
		base03: '#75715e',
		base04: '#a59f85',
		base05: '#f8f8f2',
		base06: '#f5f4f1',
		base07: '#f9f8f5',
		base08: '#f92672',
		base09: '#fd971f',
		base0A: '#f4bf75',
		base0B: '#a6e22e',
		base0C: '#a1efe4',
		base0D: '#66d9ef',
		base0E: '#ae81ff',
		base0F: '#cc6633',
	},
};

import { KpiSummaryActions } from './actions';
import {
  KpiSummaryActionTypes,
  KpiSummaryState,
  IKpiSummarySortRefreshPayload,
  IKpiSummaryDataRestrictionsPayload,
  KpiSummaryDataPayload,
} from './types';
import { Feature, SortOrder } from '../../enums/data';

const initState: KpiSummaryState = {
  sort: {
    feature: Feature.PerformanceScore,
    order: SortOrder.Desc,
  },
  summaryDataRestrictions: {
    sortBy: 'url_count',
    order: SortOrder.Desc,
    count: 30,
  },
  summaryData: [],
};

export function kpiSummaryReducer(state: KpiSummaryState = initState, action: KpiSummaryActions): KpiSummaryState {
  switch (action.type) {
    case KpiSummaryActionTypes.KPI_SUMMARY_SORT_REFRESH: {
      return {
        ...state,
        sort: {
          ...action.payload as IKpiSummarySortRefreshPayload,
        },
      };
    }
    case KpiSummaryActionTypes.KPI_SUMMARY_DATA_RESTRICTIONS_MERGE: {
      return {
        ...state,
        summaryDataRestrictions: {
          ...state.summaryDataRestrictions,
          ...action.payload as IKpiSummaryDataRestrictionsPayload,
        },
      };
    }
    case KpiSummaryActionTypes.KPI_SUMMARY_DATA_REFRESH: {
      return {
        ...state,
        summaryData: [
          ...action.payload as KpiSummaryDataPayload,
        ],
      };
    }
    case KpiSummaryActionTypes.RESET_ALL: {
      return {
        ...initState,
      };
    }
    default: {
      return state;
    }
  }
}

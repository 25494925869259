import styled from 'styled-components';
import { ReactComponent as RawLogoLightSVG } from '../../../../assets/icons/logo_light.svg';

export const IconLogoLight = styled(RawLogoLightSVG)`
  .a, .b {
    fill: #fff;
  }
  
  .b {
    opacity: 0.404;
  }
`;

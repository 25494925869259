import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { NavigationMenuKeys } from '../../../../../enums/locales/sideBar';
import { StyledWrapper as Wrapper } from './NavigationItem.styled';

export interface IProps extends  React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  navKey: NavigationMenuKeys;
}

const NavigationItem: React.FC<IProps> = ({
  active,
  navKey,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper
      className={clsx({ active })}
      onClick={onClick}
    >
      {t(navKey)}
    </Wrapper>
  );
};

export default NavigationItem;

import styled from 'styled-components';
import { hexToRGBA } from '../../../utils/theme';

export const StyledWrapper = styled.div`
  display: flex;

  .ant-checkbox {
    top: 0.18em;

    .ant-checkbox-inner {
      border: 2px solid ${({ theme }) => hexToRGBA(
        theme.colors.checkboxes.border.standard.unchecked,
        theme.colors.checkboxes.border.opacity.standard.unchecked,
      )};

      &::after {
        transform: rotate(45deg) scale(0.9) translate(-65%, -65%);
      }
    }

    &:hover,
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => hexToRGBA(
          theme.colors.checkboxes.border.standard.checked,
          theme.colors.checkboxes.border.opacity.standard.checked,
        )};
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${({ theme }) => hexToRGBA(
          theme.colors.checkboxes.background.standard.checked,
          theme.colors.checkboxes.background.opacity.standard.checked,
        )};
      }
    }

    &.ant-checkbox-checked::after {
      visibility: hidden;
    }
  }

  .label {
    display: inline-block;
    margin-left: 5px;
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSize.checkboxes.standard};
    line-height: ${({ theme }) => theme.lineHeight.checkboxes.standard};
    letter-spacing: ${({ theme }) => theme.letterSpacing.checkboxes.standard};
    color: ${({ theme }) => hexToRGBA(
      theme.colors.checkboxes.font.standard,
      theme.colors.checkboxes.font.opacity.standard,
    )};
  }

  &.small {
    .ant-checkbox {
      top: 0.1em;

      .ant-checkbox-inner {
        height: ${({ theme }) => theme.height.checkboxes.small};
        width: ${({ theme }) => theme.width.checkboxes.small};

        &::after {
          transform: rotate(45deg) scale(0.65) translate(-120%, -60%);
        }
      }
    }

    .label {
      font-style: normal;
      font-size: ${({ theme }) => theme.fontSize.checkboxes.small};
      line-height: ${({ theme }) => theme.lineHeight.checkboxes.small};
      letter-spacing: ${({ theme }) => theme.letterSpacing.checkboxes.small};
      color: ${({ theme }) => hexToRGBA(
        theme.colors.checkboxes.font.small,
        theme.colors.checkboxes.font.opacity.small,
      )};
    }
  }
`;

import React from "react";

import { UI_ROUTES } from "../constants/routes";
import { NavigationMenuKeys } from "../enums/locales/sideBar";
import { INavigationGroup } from "../containers/SideBar/NavigationMenu/NavigationMenu";

import { IconAnomaly, IconDashboard, IconLeaderboards, IconMetrics } from "../components/styled/icons";

export const NAVIGATION_MENU_GROUPS: INavigationGroup[] = [
  {
    groupKey: NavigationMenuKeys.Dashboard,
    navKey: NavigationMenuKeys.Dashboard,
    icon: <IconDashboard />,
  },
  {
    groupKey: NavigationMenuKeys.PagespeedMetrics,
    navKey: null,
    subKeys: [
      NavigationMenuKeys.PerformanceScorePM,
      NavigationMenuKeys.FirstContentfulPaintPM,
      NavigationMenuKeys.LargestContentfulPaintPM,
      NavigationMenuKeys.FirstInputDelayPM,
      NavigationMenuKeys.CumulativeLayoutShiftPM,
      NavigationMenuKeys.InteractionToNextPaintPM,
      NavigationMenuKeys.ExperimentalTimeToFirstBytePM,
      NavigationMenuKeys.SpeedIndexPM,
      NavigationMenuKeys.TotalBlockingTimePM,
      NavigationMenuKeys.FirstMeaningfulPaintPM,
      NavigationMenuKeys.TimeToInteractivePM,
      NavigationMenuKeys.ServerResponseTimePM,
      NavigationMenuKeys.DOMSizePM,
      NavigationMenuKeys.NumTasksPM,
      NavigationMenuKeys.NumTasksOver50msPM,
      NavigationMenuKeys.NumTasksOver100msPM,
      NavigationMenuKeys.NumTasksOver500msPM,
      NavigationMenuKeys.UnusedCSSPM,
      NavigationMenuKeys.UnusedJSPM,
      NavigationMenuKeys.UsesTextCompressionPM,
      NavigationMenuKeys.UnminifiedJSPM,
      NavigationMenuKeys.UnminifiedCSSPM,
      NavigationMenuKeys.ModernImagesPM,
      NavigationMenuKeys.OffscreenImagesPM,
      NavigationMenuKeys.OptimizedImagesPM,
      NavigationMenuKeys.RenderBlockingResourcesPM,
      NavigationMenuKeys.NumFontsPM,
      NavigationMenuKeys.NumRequestsPM,
      NavigationMenuKeys.NumScriptsPM,
      NavigationMenuKeys.NumStylesheetsPM,
      NavigationMenuKeys.TotalByteWeightPM,
      NavigationMenuKeys.TotalTaskTimePM,
    ],
    icon: <IconMetrics />,
  },
  {
    groupKey: NavigationMenuKeys.Leaderboards,
    navKey: null,
    subKeys: [
      NavigationMenuKeys.PerformanceScore,
      NavigationMenuKeys.FirstContentfulPaint,
      NavigationMenuKeys.LargestContentfulPaint,
      NavigationMenuKeys.FirstInputDelay,
      NavigationMenuKeys.CumulativeLayoutShift,
      NavigationMenuKeys.SpeedIndex,
      NavigationMenuKeys.TotalBlockingTime,
      NavigationMenuKeys.FirstMeaningfulPaint,
      NavigationMenuKeys.TimeToInteractive,
      NavigationMenuKeys.ServerResponseTime,
      NavigationMenuKeys.DOMSize,
      NavigationMenuKeys.NumTasks,
      NavigationMenuKeys.NumTasksOver50ms,
      NavigationMenuKeys.NumTasksOver100ms,
      NavigationMenuKeys.NumTasksOver500ms,
      NavigationMenuKeys.UnusedCSS,
      NavigationMenuKeys.UnusedJS,
      NavigationMenuKeys.UsesTextCompression,
      NavigationMenuKeys.UnminifiedJS,
      NavigationMenuKeys.UnminifiedCSS,
      NavigationMenuKeys.ModernImages,
      NavigationMenuKeys.OffscreenImages,
      NavigationMenuKeys.OptimizedImages,
      NavigationMenuKeys.RenderBlockingResources,
      NavigationMenuKeys.NumFonts,
      NavigationMenuKeys.NumRequests,
      NavigationMenuKeys.NumScripts,
      NavigationMenuKeys.NumStylesheets,
      NavigationMenuKeys.TotalByteWeight,
      NavigationMenuKeys.TotalTaskTime,
    ],
    icon: <IconLeaderboards />,
  },
  {
    groupKey: NavigationMenuKeys.CompoundAnalysis,
    navKey: null,
    subKeys: [
      NavigationMenuKeys.NetworkRequestsAnalysis,
      NavigationMenuKeys.DirectoryAnalysis,
    ],
    icon: <IconAnomaly />,
  },
];

export const NAVIGATION_MAP = new Map([
  [NavigationMenuKeys.Dashboard, UI_ROUTES.dashboard],
  [UI_ROUTES.dashboard, NavigationMenuKeys.Dashboard],

  [NavigationMenuKeys.PagespeedMetrics, UI_ROUTES.metrics],
  [UI_ROUTES.metrics, NavigationMenuKeys.PagespeedMetrics],

  [NavigationMenuKeys.PerformanceScorePM, UI_ROUTES.metricsPerformanceScore],
  [UI_ROUTES.metricsPerformanceScore, NavigationMenuKeys.PerformanceScorePM],
  [NavigationMenuKeys.LargestContentfulPaintPM, UI_ROUTES.metricsLargestContentfulPaint],
  [UI_ROUTES.metricsLargestContentfulPaint, NavigationMenuKeys.LargestContentfulPaintPM],
  [NavigationMenuKeys.FirstInputDelayPM, UI_ROUTES.metricsFirstInputDelay],
  [UI_ROUTES.metricsFirstInputDelay, NavigationMenuKeys.FirstInputDelayPM],
  [NavigationMenuKeys.TotalBlockingTimePM, UI_ROUTES.metricsTotalBlockingTime],
  [UI_ROUTES.metricsTotalBlockingTime, NavigationMenuKeys.TotalBlockingTimePM],
  [NavigationMenuKeys.TimeToInteractivePM, UI_ROUTES.metricsTimeToInteractive],
  [UI_ROUTES.metricsTimeToInteractive, NavigationMenuKeys.TimeToInteractivePM],
  [NavigationMenuKeys.FirstContentfulPaintPM, UI_ROUTES.metricsFirstContentfulPaint],
  [UI_ROUTES.metricsFirstContentfulPaint, NavigationMenuKeys.FirstContentfulPaintPM],
  [NavigationMenuKeys.FirstMeaningfulPaintPM, UI_ROUTES.metricsFirstMeaningfulPaint],
  [UI_ROUTES.metricsFirstMeaningfulPaint, NavigationMenuKeys.FirstMeaningfulPaintPM],
  [NavigationMenuKeys.CumulativeLayoutShiftPM, UI_ROUTES.metricsCumulativeLayoutShift],
  [UI_ROUTES.metricsCumulativeLayoutShift, NavigationMenuKeys.CumulativeLayoutShiftPM],

  [NavigationMenuKeys.InteractionToNextPaintPM, UI_ROUTES.metricsInteractionToNextPaint],
  [UI_ROUTES.metricsInteractionToNextPaint, NavigationMenuKeys.InteractionToNextPaintPM],
  [NavigationMenuKeys.ExperimentalTimeToFirstBytePM, UI_ROUTES.metricsExperimentalTimeToFirstByte],
  [UI_ROUTES.metricsExperimentalTimeToFirstByte, NavigationMenuKeys.ExperimentalTimeToFirstBytePM],

  [NavigationMenuKeys.ServerResponseTimePM, UI_ROUTES.metricsServerResponseTime],
  [UI_ROUTES.metricsServerResponseTime, NavigationMenuKeys.ServerResponseTimePM],
  [NavigationMenuKeys.DOMSizePM, UI_ROUTES.metricsDOMSize],
  [UI_ROUTES.metricsDOMSize, NavigationMenuKeys.DOMSizePM],
  [NavigationMenuKeys.ModernImagesPM, UI_ROUTES.metricsModernImages],
  [UI_ROUTES.metricsModernImages, NavigationMenuKeys.ModernImagesPM],
  [NavigationMenuKeys.UnminifiedJSPM, UI_ROUTES.metricsUnminifiedJS],
  [UI_ROUTES.metricsUnminifiedJS, NavigationMenuKeys.UnminifiedJSPM],
  [NavigationMenuKeys.UnminifiedCSSPM, UI_ROUTES.metricsUnminifiedCSS],
  [UI_ROUTES.metricsUnminifiedCSS, NavigationMenuKeys.UnminifiedCSSPM],
  [NavigationMenuKeys.UnusedJSPM, UI_ROUTES.metricsUnusedJS],
  [UI_ROUTES.metricsUnusedJS, NavigationMenuKeys.UnusedJSPM],
  [NavigationMenuKeys.UnusedCSSPM, UI_ROUTES.metricsUnusedCSS],
  [UI_ROUTES.metricsUnusedCSS, NavigationMenuKeys.UnusedCSSPM],
  [NavigationMenuKeys.OptimizedImagesPM, UI_ROUTES.metricsOptimizedImages],
  [UI_ROUTES.metricsOptimizedImages, NavigationMenuKeys.OptimizedImagesPM],
  [NavigationMenuKeys.RenderBlockingResourcesPM, UI_ROUTES.metricsRenderBlockingResources],
  [UI_ROUTES.metricsRenderBlockingResources, NavigationMenuKeys.RenderBlockingResourcesPM],
  [NavigationMenuKeys.NumTasksOver50msPM, UI_ROUTES.metricsNumTasksOver50ms],
  [UI_ROUTES.metricsNumTasksOver50ms, NavigationMenuKeys.NumTasksOver50msPM],
  [NavigationMenuKeys.NumTasksOver100msPM, UI_ROUTES.metricsNumTasksOver100ms],
  [UI_ROUTES.metricsNumTasksOver100ms, NavigationMenuKeys.NumTasksOver100msPM],
  [NavigationMenuKeys.NumTasksOver500msPM, UI_ROUTES.metricsNumTasksOver500ms],
  [UI_ROUTES.metricsNumTasksOver500ms, NavigationMenuKeys.NumTasksOver500msPM],
  [NavigationMenuKeys.SpeedIndexPM, UI_ROUTES.metricsSpeedIndex],
  [UI_ROUTES.metricsSpeedIndex, NavigationMenuKeys.SpeedIndexPM],
  [NavigationMenuKeys.UsesTextCompressionPM, UI_ROUTES.metricsUsesTextCompression],
  [UI_ROUTES.metricsUsesTextCompression, NavigationMenuKeys.UsesTextCompressionPM],
  [NavigationMenuKeys.OffscreenImagesPM, UI_ROUTES.metricsOffscreenImages],
  [UI_ROUTES.metricsOffscreenImages, NavigationMenuKeys.OffscreenImagesPM],
  [NavigationMenuKeys.NumFontsPM, UI_ROUTES.metricsNumFonts],
  [UI_ROUTES.metricsNumFonts, NavigationMenuKeys.NumFontsPM],
  [NavigationMenuKeys.NumRequestsPM, UI_ROUTES.metricsNumRequests],
  [UI_ROUTES.metricsNumRequests, NavigationMenuKeys.NumRequestsPM],
  [NavigationMenuKeys.NumTasksPM, UI_ROUTES.metricsNumTasks],
  [UI_ROUTES.metricsNumTasks, NavigationMenuKeys.NumTasksPM],
  [NavigationMenuKeys.NumScriptsPM, UI_ROUTES.metricsNumScripts],
  [UI_ROUTES.metricsNumScripts, NavigationMenuKeys.NumScriptsPM],
  [NavigationMenuKeys.NumStylesheetsPM, UI_ROUTES.metricsNumStylesheets],
  [UI_ROUTES.metricsNumStylesheets, NavigationMenuKeys.NumStylesheetsPM],
  [NavigationMenuKeys.TotalByteWeightPM, UI_ROUTES.metricsTotalByteWeight],
  [UI_ROUTES.metricsTotalByteWeight, NavigationMenuKeys.TotalByteWeightPM],
  [NavigationMenuKeys.TotalTaskTimePM, UI_ROUTES.metricsTotalTaskTime],
  [UI_ROUTES.metricsTotalTaskTime, NavigationMenuKeys.TotalTaskTimePM],

  [NavigationMenuKeys.Leaderboards, UI_ROUTES.leaderboards],
  [UI_ROUTES.leaderboards, NavigationMenuKeys.Leaderboards],

  [NavigationMenuKeys.PerformanceScore, UI_ROUTES.leaderboardsPerformanceScore],
  [UI_ROUTES.leaderboardsPerformanceScore, NavigationMenuKeys.PerformanceScore],
  [NavigationMenuKeys.FirstContentfulPaint, UI_ROUTES.leaderboardsFirstContentfulPaint],
  [UI_ROUTES.leaderboardsFirstContentfulPaint, NavigationMenuKeys.FirstContentfulPaint],
  [NavigationMenuKeys.LargestContentfulPaint, UI_ROUTES.leaderboardsLargestContentfulPaint],
  [UI_ROUTES.leaderboardsLargestContentfulPaint, NavigationMenuKeys.LargestContentfulPaint],
  [NavigationMenuKeys.FirstInputDelay, UI_ROUTES.leaderboardsFirstInputDelay],
  [UI_ROUTES.leaderboardsFirstInputDelay, NavigationMenuKeys.FirstInputDelay],
  [NavigationMenuKeys.TimeToInteractive, UI_ROUTES.leaderboardsTimeToInteractive],
  [UI_ROUTES.leaderboardsTimeToInteractive, NavigationMenuKeys.TimeToInteractive],
  [NavigationMenuKeys.TotalBlockingTime, UI_ROUTES.leaderboardsTotalBlockingTime],
  [UI_ROUTES.leaderboardsTotalBlockingTime, NavigationMenuKeys.TotalBlockingTime],
  [NavigationMenuKeys.CumulativeLayoutShift, UI_ROUTES.leaderboardsCumulativeLayoutShift],
  [UI_ROUTES.leaderboardsCumulativeLayoutShift, NavigationMenuKeys.CumulativeLayoutShift],
  [NavigationMenuKeys.FirstMeaningfulPaint, UI_ROUTES.leaderboardsFirstMeaningfulPaint],
  [UI_ROUTES.leaderboardsFirstMeaningfulPaint, NavigationMenuKeys.FirstMeaningfulPaint],
  [NavigationMenuKeys.ServerResponseTime, UI_ROUTES.leaderboardsServerResponseTime],
  [UI_ROUTES.leaderboardsServerResponseTime, NavigationMenuKeys.ServerResponseTime],
  [NavigationMenuKeys.DOMSize, UI_ROUTES.leaderboardsDOMSize],
  [UI_ROUTES.leaderboardsDOMSize, NavigationMenuKeys.DOMSize],
  [NavigationMenuKeys.ModernImages, UI_ROUTES.leaderboardsModernImages],
  [UI_ROUTES.leaderboardsModernImages, NavigationMenuKeys.ModernImages],
  [NavigationMenuKeys.ModernImages, UI_ROUTES.leaderboardsModernImages],
  [UI_ROUTES.leaderboardsModernImages, NavigationMenuKeys.ModernImages],
  [NavigationMenuKeys.UnminifiedJS, UI_ROUTES.leaderboardsUnminifiedJS],
  [UI_ROUTES.leaderboardsUnminifiedJS, NavigationMenuKeys.UnminifiedJS],
  [NavigationMenuKeys.UnminifiedCSS, UI_ROUTES.leaderboardsUnminifiedCSS],
  [UI_ROUTES.leaderboardsUnminifiedCSS, NavigationMenuKeys.UnminifiedCSS],
  [NavigationMenuKeys.UnusedJS, UI_ROUTES.leaderboardsUnusedJS],
  [UI_ROUTES.leaderboardsUnusedJS, NavigationMenuKeys.UnusedJS],
  [NavigationMenuKeys.UnusedCSS, UI_ROUTES.leaderboardsUnusedCSS],
  [UI_ROUTES.leaderboardsUnusedCSS, NavigationMenuKeys.UnusedCSS],
  [NavigationMenuKeys.NumTasksOver50ms, UI_ROUTES.leaderboardsNumTasksOver50ms],
  [UI_ROUTES.leaderboardsNumTasksOver50ms, NavigationMenuKeys.NumTasksOver50ms],
  [NavigationMenuKeys.NumTasksOver100ms, UI_ROUTES.leaderboardsNumTasksOver100ms],
  [UI_ROUTES.leaderboardsNumTasksOver100ms, NavigationMenuKeys.NumTasksOver100ms],
  [NavigationMenuKeys.NumTasksOver500ms, UI_ROUTES.leaderboardsNumTasksOver500ms],
  [UI_ROUTES.leaderboardsNumTasksOver500ms, NavigationMenuKeys.NumTasksOver500ms],
  [NavigationMenuKeys.OptimizedImages, UI_ROUTES.leaderboardsOptimizedImages],
  [UI_ROUTES.leaderboardsOptimizedImages, NavigationMenuKeys.OptimizedImages],
  [NavigationMenuKeys.RenderBlockingResources, UI_ROUTES.leaderboardsRenderBlockingResources],
  [UI_ROUTES.leaderboardsRenderBlockingResources, NavigationMenuKeys.RenderBlockingResources],
  [NavigationMenuKeys.SpeedIndex, UI_ROUTES.leaderboardsSpeedIndex],
  [UI_ROUTES.leaderboardsSpeedIndex, NavigationMenuKeys.SpeedIndex],
  [NavigationMenuKeys.UsesTextCompression, UI_ROUTES.leaderboardsUsesTextCompression],
  [UI_ROUTES.leaderboardsUsesTextCompression, NavigationMenuKeys.UsesTextCompression],
  [NavigationMenuKeys.OffscreenImages, UI_ROUTES.leaderboardsOffscreenImages],
  [UI_ROUTES.leaderboardsOffscreenImages, NavigationMenuKeys.OffscreenImages],
  [NavigationMenuKeys.NumFonts, UI_ROUTES.leaderboardsNumFonts],
  [UI_ROUTES.leaderboardsNumFonts, NavigationMenuKeys.NumFonts],
  [NavigationMenuKeys.NumRequests, UI_ROUTES.leaderboardsNumRequests],
  [UI_ROUTES.leaderboardsNumRequests, NavigationMenuKeys.NumRequests],
  [NavigationMenuKeys.NumTasks, UI_ROUTES.leaderboardsNumTasks],
  [UI_ROUTES.leaderboardsNumTasks, NavigationMenuKeys.NumTasks],
  [NavigationMenuKeys.NumScripts, UI_ROUTES.leaderboardsNumScripts],
  [UI_ROUTES.leaderboardsNumScripts, NavigationMenuKeys.NumScripts],
  [NavigationMenuKeys.NumStylesheets, UI_ROUTES.leaderboardsNumStylesheets],
  [UI_ROUTES.leaderboardsNumStylesheets, NavigationMenuKeys.NumStylesheets],
  [NavigationMenuKeys.TotalByteWeight, UI_ROUTES.leaderboardsTotalByteWeight],
  [UI_ROUTES.leaderboardsTotalByteWeight, NavigationMenuKeys.TotalByteWeight],
  [NavigationMenuKeys.TotalTaskTime, UI_ROUTES.leaderboardsTotalTaskTime],
  [UI_ROUTES.leaderboardsTotalTaskTime, NavigationMenuKeys.TotalTaskTime],

  [NavigationMenuKeys.CompoundAnalysis, UI_ROUTES.compoundAnalysis],
  [UI_ROUTES.compoundAnalysis, NavigationMenuKeys.CompoundAnalysis],
  [NavigationMenuKeys.NetworkRequestsAnalysis, UI_ROUTES.networkRequestsAnalysis],
  [UI_ROUTES.networkRequestsAnalysis, NavigationMenuKeys.NetworkRequestsAnalysis],

  [NavigationMenuKeys.AnomalyAnalysis, UI_ROUTES.analysis],
  [UI_ROUTES.analysis, NavigationMenuKeys.AnomalyAnalysis],

  [NavigationMenuKeys.DirectoryAnalysis, UI_ROUTES.analysisDirectoryAnalysis],
  [UI_ROUTES.analysisDirectoryAnalysis, NavigationMenuKeys.DirectoryAnalysis],

  [NavigationMenuKeys.DirectoryAnalysis, UI_ROUTES.analysisDirectoryAnalysis],
  [UI_ROUTES.analysisDirectoryAnalysis, NavigationMenuKeys.DirectoryAnalysis],

  [NavigationMenuKeys.Accounts, UI_ROUTES.adminAccounts],
  [UI_ROUTES.adminAccounts, NavigationMenuKeys.Accounts],
  [NavigationMenuKeys.AccountDetails, UI_ROUTES.adminAccountDetails],
  [UI_ROUTES.adminAccountDetails, NavigationMenuKeys.AccountDetails],
]);

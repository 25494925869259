import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;

  height: 40px;
  width: 250px;

  .profile-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.open {
    .profile-container {
      top: -8px;
      right: -17px;
    }
  }
`;

import { appReducer } from './app/reducer';
import { authReducer } from './auth/reducer';
import { kpiSummaryReducer } from './kpi-summary/reducer';
import { projectsReducer } from './projects/reducer';
import { projectsOverviewReducer } from './projects-overview/reducer';
import { kpiReducer } from './kpi/reducer';
import { vitalsReducer } from './vitals/reducer';
import { historyReducer } from './history/reducer';
import { overviewReducer } from './overview/reducer';
import { modalsReducer } from './modals/reducer';
import { projectSettingsReducer } from './project-settings/reducer';
import { profileSettingsReducer } from './profile-settings/reducer';
import { metricsReducer } from './metrics/reducer';
import { leaderboardReducer } from './leaderboard/reducer';
import { compoundAnalysisReducer } from './compound-analysis/reducer';
import { directoryAnalysisReducer } from './directory-analysis/reducer';
import { licensesReducer } from './licenses/reducer';
import { accountDetailsReducer } from './account-details/reducer';
import { accountsReducer } from './accounts/reducer';
import { usersReducer } from './users/reducer';

export default {
	App: appReducer,
	Auth: authReducer,
	Projects: projectsReducer,
	ProjectsOverview: projectsOverviewReducer,
	KPI: kpiReducer,
	KpiSummary: kpiSummaryReducer,
	History: historyReducer,
	Overview: overviewReducer,
	Vitals: vitalsReducer,
	Metrics: metricsReducer,
	Leaderboard: leaderboardReducer,
	CompoundAnalysis: compoundAnalysisReducer,
	Modals: modalsReducer,
	ProjectSettings: projectSettingsReducer,
	ProfileSettings: profileSettingsReducer,
	Directory: directoryAnalysisReducer,
	Licenses: licensesReducer,
	Accounts: accountsReducer,
	Users: usersReducer,
	AccountDetails: accountDetailsReducer,
};

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .domain-picture,
  .domain-template {
    width: 24px;
    height: 24px;

    max-width: 24px;
    max-height: 24px;
    
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F2F2F3;
    border-radius: 3px;
    opacity: 1;
  }

  .domain-template {
    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #171C28;
  }

  &.empty {
    .domain-template {
      border: none;
    }
  }
`;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { QUERY_PARAMS_KEYS } from "../enums/queryParams";
import { selectActiveProjectGroupID, selectActiveProjectID } from "../redux/projects/selectors";
import { selectQueryParams } from "../redux/router/selectors";
import { setQueryParam } from "../utils/router";

export const useQueryParams = () => {
  const activeProjectID = useSelector(selectActiveProjectID);
  const activeProjectGroupID = useSelector(selectActiveProjectGroupID);
  const query = useSelector(selectQueryParams);

  useEffect(() => {
    if (activeProjectID && query[QUERY_PARAMS_KEYS.ProjectID] !== activeProjectID) {
      setQueryParam(QUERY_PARAMS_KEYS.ProjectID, activeProjectID, query);
    }
  }, [activeProjectID, query]);

  useEffect(() => {
    if (activeProjectID) {
      if (activeProjectGroupID && query[QUERY_PARAMS_KEYS.UrlGroupID] !== activeProjectGroupID) {
        setQueryParam(QUERY_PARAMS_KEYS.UrlGroupID, activeProjectGroupID, query);
      }
    }
  }, [activeProjectGroupID, activeProjectID, query]);
}
import { all } from 'redux-saga/effects';

import appSaga from './app/saga';
import authSaga from './auth/saga';
import kpiSummarySaga from './kpi-summary/saga';
import projectsSaga from './projects/saga';
import projectsOverviewSaga from './projects-overview/saga';
import projectSettingsSaga from './project-settings/saga';
import profileSettingsSaga from './profile-settings/saga';
import kpiSaga from './kpi/saga';
import historySaga from './history/saga';
import overviewSaga from './overview/saga';
import vitalsSaga from './vitals/saga';
import metricsSaga from './metrics/saga';
import leaderboardSaga from './leaderboard/saga';
import compoundAnalysisSaga from './compound-analysis/saga';
import directorySaga from './directory-analysis/saga';
import licensesSaga from './licenses/saga';
import accountsSaga from './accounts/saga';
import usersSaga from './users/saga';
import accountDetailsSaga from './account-details/saga';

export default function* rootSaga() {
  yield all([
    appSaga(),
    authSaga(),
    kpiSummarySaga(),
    projectsSaga(),
    projectsOverviewSaga(),
    projectSettingsSaga(),
    kpiSaga(),
    historySaga(),
    overviewSaga(),
    vitalsSaga(),
    metricsSaga(),
    leaderboardSaga(),
    compoundAnalysisSaga(),
    directorySaga(),
    profileSettingsSaga(),
    licensesSaga(),
    accountsSaga(),
    usersSaga(),
    accountDetailsSaga(),
  ]);
}

import { Duration } from 'date-fns';
import { Languages } from '../../enums/locales/languages';

export const createHttpsUrl = (url: string): string => {
  const regex = new RegExp(/https?:\/\//);
  const isHTTPLink = regex.test(url);
  const URL = isHTTPLink ? url : `https://${url}`;

  return URL;
}

export const roundNumber = (num: string | number = 0, lessThan = 1, pressision = 1000, defaultPressision = 100) => {
  return (Number(num) < lessThan
    ? Math.round(Number(num) * pressision) / pressision
    : Math.round(Number(num) * defaultPressision) / defaultPressision);
}

export const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = (x: number, y: number) => { return document.elementFromPoint(x, y) };

  // Return false if it's not in the viewport
  if (rect.right < 0 || rect.bottom < 0
    || rect.left > vWidth || rect.top > vHeight)
    return false;

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top))
    || el.contains(efp(rect.right, rect.top))
    || el.contains(efp(rect.right, rect.bottom))
    || el.contains(efp(rect.left, rect.bottom))
  );
}

export const formatNumber = (value = 0, lang = 'en') => {
  return value.toLocaleString(lang, { useGrouping: true, maximumFractionDigits: 5 });
}

export const isCrawlID = (id: string) => {
  const regex = new RegExp(/^CR-.*$/);
  return regex.test(id);
};

export const isUrl = (url: string): boolean => {
  const regex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm);
  return regex.test(url);
}

const enOrdinalSuffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

const deOrdinalSuffixes = new Map([
  ['one', 'te'],
  ['two', 'te'],
  ['few', 'te'],
  ['other', 'te'],
]);

const ordinalSuffixes = {
  [Languages.En]: enOrdinalSuffixes,
  [Languages.De]: deOrdinalSuffixes,
};

export const getOrdinalsSuffix = (n: number, lang: string) => {
  const rules = new Intl.PluralRules(lang, { type: 'ordinal' });
  const rule = rules.select(n);
  const suffix = ordinalSuffixes[lang as Languages] ? ordinalSuffixes[lang as Languages].get(rule) : enOrdinalSuffixes.get(rule);
  return `${suffix}`;
};


export const validateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const beautifyUrl = (url: string) => {
  return url?.replace('https://', '').replace('http:', '').replace('www.', '');
};
import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AntdModal from 'antd/lib/modal';

import { hexToRGBA } from '../../../utils/theme';
import { THEME } from '../../../constants/theme';

import { selectLoaderVisible, selectLoaderMessage } from '../../../redux/modals/selectors';

import { StyledBodyWrapper } from './LoaderModal.styled';

const LoaderModal: React.FC = () => {
  const timer = useRef(null as unknown as NodeJS.Timeout);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const isVisible = useSelector(selectLoaderVisible);
  const messageKey = useSelector(selectLoaderMessage);

  useEffect(() => {
    if (isVisible) {
      clearTimeout(timer.current);
      setVisible(true);
    } else {
      timer.current = setTimeout(() => setVisible(false), 600);
    }
    return () => clearTimeout(timer.current);
  }, [dispatch, setVisible, isVisible]);

  return (
    <AntdModal
      centered
      title={null}
      footer={null}
      closable={false}
      visible={visible}
      style={{ animationDuration: '0s' }}
      maskStyle={{
        background: `${hexToRGBA(
          THEME.colors.modals.background.loader,
          THEME.colors.modals.background.opacity.loader,
        )} 0% 0% no-repeat padding-box`,
      }}
      wrapClassName="loader-modal-container"
    >
      <StyledBodyWrapper>
        <div className="message">{t(messageKey, 'Loading...')}</div>
        <div className={clsx('loader', { finalized: !isVisible })} />
      </StyledBodyWrapper>
    </AntdModal>
  );
}

export default LoaderModal;
